<template>
    <div>
        <b-modal id="modal-bulk-description-update" no-close-on-esc centered no-close-on-backdrop hide-footer
        :body-class="'pt-3'"
        >
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Description
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="text-left">
                <b-form-group label="Description">
                    <b-form-textarea id="textarea" placeholder="Enter Description" rows="5"
                        v-model="description"></b-form-textarea>
                    <p v-if="errors && errors.description" class="field-error">
                        {{ errors.description[0] }}
                    </p>
                </b-form-group>
            </div>
            <div class="text-right modal-footer border-0 p-0 mt-3">
                <b-button class="mx-0 mr-3 h-40px w-100px" @click="resetPopup()" variant="secondary">Cancel</b-button>
                <b-button class="mx-0 mb-0 h-40px w-100px mt-0" variant="primary" @click="bulkUpdateDocs">Save</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: "BulkUpdateDescription",
    props: ["docIds"],
    data() {
        return {
            description: "",
            errors: {}
        }
    },
    methods: {
        ...mapActions(['updateBulkDocument', "setLoader", "setNotification"]),
        resetPopup() {
            this.description = ""
            this.errors = {}
            this.$bvModal.hide('modal-bulk-description-update')
            this.$emit('close')
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (value === null) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async bulkUpdateDocs() {
            try {
                const nullKeys = this.checkNullValues({
                    description: this.description
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    await this.updateBulkDocument({
                        "ids": this.docIds,
                        "description": this.description
                    })
                    this.setLoader(false)
                    await this.setNotification({
                        msg: `Documents Updated Successfully`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.$emit("updateDocs")
                    this.resetPopup()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                this.setLoader(false)
                if (typeof error === "object") {
                    this.errors = error;
                }
            }
        }

    },
}
</script>