<template>
  <div v-if="user && user.team_member">
    <header class="text-left search_equipment d-flex user-detail row">
      <div class="col-md-8 d-flex justify-content-between mx-3 mx-md-auto align-items-center">
        <b-img :src="require('@/assets/small-logo.png')" width="35" height="40"></b-img>
        <div class="d-flex align-items-center">
          <!-- <div class="position-relative mr-3">
                        <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M22.1609 15.7696L19.8273 13.436V10.3447C19.8246 8.20836 19.0299 6.14891 17.5968 4.56457C16.1638 2.98023 14.1941 1.98353 12.0688 1.76721V0H10.3447V1.76721C8.21934 1.98353 6.2497 2.98023 4.81663 4.56457C3.38355 6.14891 2.58884 8.20836 2.58617 10.3447V13.436L0.252582 15.7696C0.0909043 15.9312 4.88246e-05 16.1505 0 16.3791V18.9652C0 19.1939 0.0908235 19.4131 0.25249 19.5748C0.414157 19.7365 0.633425 19.8273 0.862056 19.8273H6.89645V20.4971C6.8777 21.5907 7.26317 22.6528 7.979 23.4799C8.69484 24.3069 9.69068 24.8407 10.7757 24.9789C11.375 25.0384 11.9801 24.9717 12.552 24.7832C13.124 24.5948 13.6502 24.2887 14.0968 23.8847C14.5434 23.4806 14.9004 22.9876 15.145 22.4373C15.3896 21.887 15.5163 21.2916 15.517 20.6893V19.8273H21.5514C21.78 19.8273 21.9993 19.7365 22.161 19.5748C22.3226 19.4131 22.4135 19.1939 22.4135 18.9652V16.3791C22.4134 16.1505 22.3226 15.9312 22.1609 15.7696ZM13.7929 20.6893C13.7929 21.3752 13.5204 22.033 13.0354 22.518C12.5504 23.003 11.8926 23.2755 11.2067 23.2755C10.5208 23.2755 9.86303 23.003 9.37803 22.518C8.89303 22.033 8.62056 21.3752 8.62056 20.6893V19.8273H13.7929V20.6893ZM20.6893 18.1032H1.72411V16.736L4.0577 14.4024C4.21938 14.2407 4.31023 14.0215 4.31028 13.7929V10.3447C4.31028 8.51562 5.03687 6.76148 6.3302 5.46815C7.62354 4.17481 9.37768 3.44822 11.2067 3.44822C13.0358 3.44822 14.7899 4.17481 16.0833 5.46815C17.3766 6.76148 18.1032 8.51562 18.1032 10.3447V13.7929C18.1032 14.0215 18.1941 14.2407 18.3558 14.4024L20.6893 16.736V18.1032Z"
                                fill="white" />
                        </svg>
                        <div class="notifications-count" v-if="userNotifications && userNotifications.filter((notification)=>!notification.is_read).length">
                            {{ userNotifications.filter((notification)=>!notification.is_read).length }}
                        </div>
                    </div> -->
          <NotificationHeader />
          <b-dropdown id="dropdown-right" right class="mx-2 dropdown dropdown-dark-section header-section-dropdown">
            <template slot="button-content">
              <b-img v-if="user.team_member.profile_picture" class="userSmallImage rounded-circle"
                :src="user.team_member.profile_picture"></b-img>
              <div v-else class="user-default-img">
                {{ user.first_name.charAt(0).toUpperCase() }}{{ user.last_name.charAt(0).toUpperCase() }}
              </div>
            </template>
            <b-dropdown-item><span class="font-weight-bold f-14">{{ user.first_name }} {{ user.last_name }}</span>
              <br /> <span class="f-12">{{ user.email }}</span>
            </b-dropdown-item>
            <hr class="my-2" />
            <b-dropdown-item to="/profile-page"><span class="f-14">View Profile</span></b-dropdown-item>
            <b-dropdown-item to="/notifications"><span class="f-14">View Notifications</span></b-dropdown-item>
            <b-dropdown-item to="/all-companies"><span class="f-14">View Companies</span></b-dropdown-item>
            <hr class="my-2" />
            <b-dropdown-item @click="OnlogoutUser"><span class="f-14">Logout</span></b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </header>
    <b-row>
      <b-col md="8" class="mx-3 mx-md-auto pt-64">
        <h1 class="font-weight-bold f-32 text-left mt-3 mb-3">My Profile</h1>
        <b-row>
          <b-col cols="12">
            <div class="pr-0 mb-3 d-block d-lg-flex w-100">
              <div class="">
                <div class="pt-3 px-3 bg-white d-none d-lg-block table-custom-border rounded company-menu-section">
                  <div class="company-tabs text-left">
                    <div class="company-tab" @click="activeTab = 'general'"
                      :class="activeTab === 'general' ? 'active' : ''">
                      General
                    </div>
                    <div class="company-tab" @click="activeTab = 'password'"
                      :class="activeTab === 'password' ? 'active' : ''">
                      Password
                    </div>
                    <div class="company-tab" @click="activeTab = 'settings'"
                      :class="activeTab === 'settings' ? 'active' : ''">
                      Settings
                    </div>
                  </div>
                </div>
                <div class="d-block d-lg-none mb-3">
                  <b-form-select class="bg-white" v-model="activeTab" :options="[
                    {
                      value: 'general',
                      text: 'General',
                    },
                    {
                      value: 'password',
                      text: 'Password',
                    },
                    {
                      value: 'settings',
                      text: 'Settings',
                    }
                  ]" />
                </div>
              </div>
              <div class="ml-lg-1 ml-0 table-custom-border rounded company-details">
                <div>
                  <General v-if="activeTab === 'general'" />
                  <Password v-if="activeTab === 'password'" />
                  <Settings v-if="activeTab === 'settings'" />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import General from "@/components/ProfilePage/General.vue";
import Password from "@/components/ProfilePage/Password.vue";
import Settings from "@/components/ProfilePage/Settings.vue";
import NotificationHeader from "@/components/Shared/NotificationHeader.vue";
export default {
  components: { General, Password, Settings, NotificationHeader },
  data() {
    return {
      activeTab: "general"
    }
  },
  computed: {
    ...mapGetters(["user", 'userNotifications']),
  },
  methods: {
    ...mapActions(["getCompany", "getUserByID", "updateTeamMember", "setNotification", "logoutUser"]),
    OnlogoutUser() {
      this.logoutUser();
      this.$intercom.shutdown()
      this.$router.push("/login");
    },
  },
  async mounted() {
    try {
      await this.getCompany()
      await this.getUserByID();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
.profile-form,
.password-section {
  box-shadow: 0px 4px 50px rgb(0 0 0 / 10%);
}

.profile-form .left_img {
  width: 220px;
  height: 210px;
  margin: 3px;
  border: 3px solid lightgrey;
}

.profile-page .heading {
  padding-bottom: calc(0.175rem + 1px);
}

.password-section {
  padding: 26px 27px 38px 36px;
}

.profile-page h4 {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .upload_btn {
    margin-bottom: 16px;
  }
}

@media (max-width: 575px) {

  .password-section,
  .profile-form {
    padding: 26px 20px 38px 20px !important;
  }
}
</style>
