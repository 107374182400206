<template>
    <div>
        <div class="">
            <header class="text-left search_equipment d-flex user-detail row">
                <div class="col-md-8 d-flex justify-content-between mx-3 mx-md-auto align-items-center">
                    <router-link to="/driver-details">
                        <b-img :src="require('@/assets/small-logo.png')" width="35" height="40"></b-img>
                    </router-link>
                    <div>
                        <b-dropdown id="dropdown-right" right class="mx-2 dropdown dropdown-dark-section header-section-dropdown">
                            <template slot="button-content">
                                <b-img v-if="user.team_member && user.team_member.profile_picture" class="userSmallImage rounded-circle" :src="user.team_member.profile_picture"></b-img>
            <div v-else class="user-default-img">
              {{ user.first_name.charAt(0).toUpperCase() }}{{ user.last_name.charAt(0).toUpperCase() }}
            </div>
                            </template>
                            <b-dropdown-item><span class="font-weight-bold f-14">{{ user.first_name }}
                                    {{ user.last_name }}</span>
                                <br /> <span class="f-12">{{ user.email }}</span>
                            </b-dropdown-item>
                            <div v-if="userRole !== 'Driver'">
                            <hr class="my-2" />
                            <b-dropdown-item to="/profile-page"><span class="f-14">View Profile</span></b-dropdown-item>
                            <b-dropdown-item to="/notifications"><span class="f-14">View Notifications</span></b-dropdown-item>
                            <b-dropdown-item to="/all-companies"><span class="f-14">View Companies</span></b-dropdown-item>
                            </div>
                            <hr class="my-2" />
                            <b-dropdown-item @click="OnlogoutUser"><span class="f-14">Logout</span></b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </header>
            <div class="row">
                <div class="col-md-8 mx-3 mx-md-auto pt-64">
                    <h1 class="font-weight-bold f-32 text-left mt-3 mb-3">Trailers</h1>
                    <div class="trailer-search-screen">
                        <div class="position-relative">
                            <b-form-input type="text" placeholder="Search for a Trailer" v-model="searchField"
                                @keydown.enter="filterEquipmentData"></b-form-input>
                            <div class="position-absolute icons-section">
                                <svg width="2" class="mr-3" height="32" viewBox="0 0 2 32" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.1" d="M1 0V32" stroke="#11004D" />
                                </svg>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_4914_3279)">
                                        <path
                                            d="M19.8104 18.9119L14.6467 13.8308C15.9989 12.3616 16.8298 10.4187 16.8298 8.28068C16.8291 3.7071 13.062 0 8.41465 0C3.76731 0 0.000183105 3.7071 0.000183105 8.28068C0.000183105 12.8543 3.76731 16.5614 8.41465 16.5614C10.4226 16.5614 12.2643 15.8668 13.7109 14.7122L18.8946 19.8134C19.1472 20.0622 19.5572 20.0622 19.8098 19.8134C20.063 19.5646 20.063 19.1607 19.8104 18.9119ZM8.41465 15.2873C4.4825 15.2873 1.29487 12.1504 1.29487 8.28068C1.29487 4.41101 4.4825 1.27403 8.41465 1.27403C12.3468 1.27403 15.5344 4.41101 15.5344 8.28068C15.5344 12.1504 12.3468 15.2873 8.41465 15.2873Z"
                                            fill="#E7E5ED" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4914_3279">
                                            <rect width="20" height="20" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>

                            </div>
                        </div>
                        <div class="btn-section d-flex align-items-center mt-3">
                            <b-button :variant="btnStatus === 'all' ? 'primary' : 'no-variant'" @click="btnStatus = 'all'"
                                class="f-14">All</b-button>
                            <b-button :variant="btnStatus === 'available' ? 'primary' : 'no-variant'"
                                @click="btnStatus = 'available'" class="ml-2 f-14">Available</b-button>
                        </div>
                        <div class="trailer-section mt-3 bg-white" @scroll="handleScroll">
                            <div class="trailer-detail-section cursor-pointer bg-white mb-3 d-flex align-items-center justify-content-between"
                                v-for="(equipment, index) in  equipmentData" :key="index"
                                @click="$router.push('/driver-equipment-details/' + equipment.id)">
                                <div>
                                    <p class="font-weight-bold f-14 text-left"
                                        :class="equipment.tags.length ? 'mb-1' : 'mb-0'">{{ equipment.unit_no }}</p>
                                    <p class="f-12 text-left mb-0 tags-section" v-if="equipment.tags.length">{{
                                        equipment.tags.map((tag, index) => tag.add_tag).join(" · ") }}</p>
                                </div>
                                <b-button variant="assigned-btn" class="f-12 z-1" v-if="equipment.status === 'Assigned'"
                                    @click.stop="showAssignedModal(equipment)">Assigned</b-button>
                                <b-button variant="primary" class="f-12 z-1" v-else-if="equipment.status === 'Active'"
                                    @click.stop="showAssignModal(equipment)">Assign</b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AssignEquipment :alreadyAssigned="alreadyAssigned" :title="modalTitle" :message="modalMessage"
            @assignEquipment="assignTrailer" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AssignEquipment from "@/components/driver/AssignEquipment.vue";
export default {
    name: "DriverTrailersSearch",
    components: { AssignEquipment },
    data() {
        return {
            btnStatus: "all",
            currentPage: 1,
            perPage: 25,
            equipmentData: [],
            modalTitle: "Confirmation",
            modalMessage: "Do you want to assign this trailer?",
            alreadyAssigned: false,
            searchField: "",
            selectedEquipment: null
        }
    },
    computed: {
        ...mapGetters(["user", "userRole"])
    },
    methods: {
        ...mapActions(["logoutUser", "getUserByID", "getAllEquipments", "updateTeamMember", "updateEquipment", "setNotification"]),
        handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (scrollTop + clientHeight >= scrollHeight) {
                this.currentPage++
                this.getEquipmentData()
            }
        },
        filterEquipmentData() {
            this.currentPage = 1
            this.equipmentData = []
            this.getEquipmentData()
        },
        async assignTrailer() {
            if (this.alreadyAssigned) {
                try {
                    await this.updateTeamMember({
                        id: this.selectedEquipment.driver.id,
                        payload: { user: this.selectedEquipment.driver, default_trailer: null },
                    })
                    await this.updateTeamMember({
                        id: this.user.team_member.id,
                        payload: { user: this.user.team_member.user, default_trailer: this.selectedEquipment.id },
                    })
                    await this.setNotification({
                        msg: "Trailer Assigned Successfully",
                        type: "success",
                        color: "green",
                    });
                    this.currentPage = 1
                    this.equipmentData = []
                    await this.getEquipmentData()
                    this.$bvModal.hide('assign-equipment')
                }
                catch (e) {
                    await this.setNotification({
                        msg: e,
                        type: "error",
                        color: "red",
                    });
                }
            }
            else {
                try {
                    await this.updateTeamMember({
                        id: this.user.team_member.id,
                        payload: { user: this.user.team_member.user, default_trailer: this.selectedEquipment.id },
                    })
                    await this.setNotification({
                        msg: "Trailer Assigned Successfully",
                        type: "success",
                        color: "green",
                    });
                    this.currentPage = 1
                    this.equipmentData = []
                    await this.getEquipmentData()
                    this.$bvModal.hide('assign-equipment')
                }
                catch (e) {
                    await this.setNotification({
                        msg: e,
                        type: "error",
                        color: "red",
                    });
                }
            }
        },
        OnlogoutUser() {
            this.logoutUser();
            this.$intercom.shutdown()
            this.$router.push("/login");
        },
        showAssignedModal(equipment) {
            this.modalTitle = "Another driver is currently using this trailer!",
                this.modalMessage = "Assigning this trailer will remove the other driver. Are you sure ?",
                this.alreadyAssigned = true
            this.selectedEquipment = equipment
            this.$bvModal.show('assign-equipment')
        },
        showAssignModal(equipment) {
            this.modalTitle = "Confirmation",
                this.modalMessage = "Do you want to assign this trailer?",
                this.alreadyAssigned = false
            this.selectedEquipment = equipment
            this.$bvModal.show('assign-equipment')
        },
        async getEquipmentData() {
            const url = `type=Trailer&page=${this.currentPage}&page_size=${this.perPage}${this.searchField ? `&search_field=${this.searchField}&search_list=unit_no,subtype,vin_no,plate_no,plate_state,driver` : ''}`
            let { records } = await this.getAllEquipments(url);
            if (records.length) {
                if (this.btnStatus === 'available') records = records.filter((record) => record.status === 'Active')
                this.equipmentData = [...this.equipmentData, ...records]
            }
            else this.equipmentData = []

        }
    },
    watch: {
        btnStatus() {
            this.currentPage = 1
            this.equipmentData = []
            this.getEquipmentData()
        },
    },
    async mounted() {
        if (!this.user) {
            await this.getUserByID()
        }
        await this.getEquipmentData()
    }
}
</script>
<style>
.trailer-search-screen .form-control {
    padding: 21px 18px !important;
    border: 1px solid rgba(17, 0, 77, 0.10) !important;
    border-radius: 5px !important;
    background: white !important;
    height: 60px !important;
}

.trailer-search-screen .icons-section {
    top: 15px;
    right: 20px;
}

.trailer-search-screen .trailer-section {
    max-height: 500px;
    overflow-y: auto;
}

.trailer-search-screen .btn-section .btn-no-variant {
    border: 1px solid rgba(73, 116, 214, 0.36) !important;
    border-radius: 5px;
    color: #23262F;
    opacity: 0.5;
}

.trailer-search-screen .trailer-detail-section {
    padding: 13px 24px;
    border-radius: 5px;
    box-shadow: 0 4px 50px 0 rgba(0, 0, 0, .1);
}

.trailer-search-screen .trailer-detail-section>.btn {
    width: 80px !important;
    height: 36px !important;
}

.trailer-search-screen .trailer-detail-section .btn-assigned-btn {
    background-color: #EAF0FF !important;
    color: #2D69F6 !important;
    opacity: 1 !important;
}

.trailer-search-screen .trailer-detail-section .tags-section {
    color: #23262F !important;
    font-weight: 500;
}
</style>