<template>
  <b-container fluid class="mt-2 px-1 px-sm-3 pb-3 team-member-page">
    <b-row>
      <b-col cols="" class="">
        <b-row>
          <b-col cols="">
            <div class="label mb-3 text-left">
              <router-link to="/">dashboard</router-link> /
              <router-link to="/drivers">drivers</router-link> /
              <span> driver</span>
            </div>
            <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
              <h2>Driver</h2>
            </div>
            <hr class="mt-0" />
            <div class="tags-section d-flex mb-3 align-items-center flex-wrap">
              <div>Tags:</div>
              <v-chip class="equipment-chips ml-2" close @click:close="removeTag(tag)"
                v-for="(tag, index) in existingTags" :key="index">
                {{ tag.add_tag }}
              </v-chip>
              <div class="cursor-pointer ml-2" @click="showTagsModal = true">
                + Add Tags
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- <div class="data-table tabs-box bg-white">
                <v-tabs class="team-tabs mb-0 bg-white" v-if="currentTeamMember.role === 'Driver'">
                    <v-tab @click="tabsValue = 0"><img :src="tabsValue === 0 ? require('@/assets/general-checked.svg') : require('@/assets/general.svg')" class="mr-6" />General Info</v-tab>
                    <v-tab @click="tabsValue = 1"><img :src="tabsValue === 1 ? require('@/assets/pay-info-checked.svg') : require('@/assets/pay-info.svg')" class="mr-6" />Pay Info </v-tab>
                </v-tabs>
            </div> -->
        <div class="data-table" v-if="currentTeamMember.role !== 'Driver' ||
          (currentTeamMember.role === 'Driver' && tabsValue === 0)
          ">
          <b-row>
            <b-col cols="12">
              <div class="bg-white px-4 rounded pt-4">
                <div class="object_detail">
                  <div class="num d-flex align-items-center justify-content-between mb-3 flex-wrap">
                    <div class="d-flex align-items-center">
                      <p class="mb-0 font-weight-bold">
                        {{ currentTeamMember.user.first_name }}
                        {{ currentTeamMember.user.last_name }}
                      </p>
                      <p class="mb-0 active ml-2" :class="currentTeamMember.status ? 'active' : 'inactive'
                        ">
                        {{ currentTeamMember.status ? "Active" : "Inactive" }}
                      </p>
                    </div>
                    <div class="mx-auto ml-sm-auto mr-sm-0 mt-2 mt-sm-0"
                      v-if="qrCodeUrl && currentTeamMember.role === 'Driver'">
                      <button v-if="memberPinCode" class="btn equipment text-white" @click="generateDriverQRCode(currentTeamMember.user.first_name +
                        ' ' +
                        currentTeamMember.user.last_name, currentTeamMember.id, currentTeamMember.user.first_name +
                        ' ' +
                        currentTeamMember.user.last_name +
                      '-qrcode', qrCodeUrl)">
                        Generate QR Code</button>
                      <div v-if="!memberPinCode">
                        <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
                          <b-button class="equipment" style="pointer-events: none" disabled>Generate QR Code</b-button>
                        </span>
                        <b-tooltip target="disabled-wrapper">Please Enter
                          <span class="font-weight-bold">Employee's Pin Code </span>to Generate QR Code</b-tooltip>
                      </div>
                    </div>
                    <div></div>
                  </div>
                  <b-row>
                    <b-col lg="6" md="6" sm="12">
                      <b-img class="left_img rounded-circle" style="
                          width: 220px;
                          height: 210px;
                          margin: 3px;
                          border: 3px solid lightgrey;
                        " :src="memberImageURL
                          ? memberImageURL
                          : currentTeamMember.profile_picture
                            ? currentTeamMember.profile_picture
                            : require('@/assets/user.png')
                          " accept="image/*" />
                      <div class="upload_btn">
                        <input ref="imageFile" type="file" name="imageFile" class="imageFile"
                          v-on:change="handleImageUpload" />
                        <b-button class="upload text-center mt-2">Upload</b-button>
                      </div>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                      <b-row>
                        <b-col lg="6" md="12" sm="12">
                          <b-form class="mb-2">
                            <b-form-group label="First Name" class="text-left">
                              <b-form-input type="text" placeholder="Enter First Name" required
                                v-model="currentTeamMember.user.first_name"></b-form-input>
                              <p v-if="errors &&
                                errors.user &&
                                errors.user.first_name
                                " class="field-error">
                                {{ errors.user.first_name[0] }}
                              </p>
                            </b-form-group>
                          </b-form>
                        </b-col>
                        <b-col lg="6" md="12" sm="12">
                          <b-form class="mb-2">
                            <b-form-group label="Last Name" class="text-left">
                              <b-form-input type="text" placeholder="Enter Last Name" required
                                v-model="currentTeamMember.user.last_name"></b-form-input>
                              <p v-if="errors && errors.user && errors.user.last_name
                                " class="field-error">
                                {{ errors.user.last_name[0] }}
                              </p>
                            </b-form-group>
                          </b-form>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" md="12" sm="12">
                          <b-form class="mb-2">
                            <b-form-group label="Role" class="text-left select_arrow">
                              <b-form-input v-model="currentTeamMember.role" disabled></b-form-input>
                              <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                </svg> -->
                            </b-form-group>
                          </b-form>
                        </b-col>
                        <b-col lg="6" md="12" sm="12" class="text-left mb-2">
                          <label>{{
                            currentTeamMember.status ? "Active" : "Inactive"
                          }}</label>
                          <b-form-checkbox v-model="currentTeamMember.status" name="check-button"
                            switch></b-form-checkbox>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6" md="12" sm="12" class="mt-2">
                          <b-form class="mb-2">
                            <b-form-group label="Email" class="text-left position-relative">
                              <b-form-input type="email" placeholder="Enter Email" required
                                v-model="currentTeamMember.user.email"></b-form-input>
                              <p v-if="errors && errors.user && errors.user.email
                                " class="field-error">
                                {{ errors.user.email[0] }}
                              </p>
                            </b-form-group>
                          </b-form>
                        </b-col>
                        <b-col lg="6" md="12" sm="12" class="mt-2">
                          <b-form-group label="Phone" class="text-left position-relative mb-2">
                            <vue-tel-input v-bind="bindProps" v-model="currentTeamMember.phone" class="w-100 f-14"
                              @input="checkNum" />
                            <p v-if="errors && errors.phone" class="field-error">
                              {{ errors.phone[0] }}
                            </p>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12" sm="12" class="my-2">

                          <b-form-group label="Username*" class="text-left label font-weight-normal mb-2">
                            <b-input-group class="username-input-field" :append="`.${this.userCompany}`">
                              <b-form-input type="text" autocomplete="new-username" placeholder="Enter Username"
                                v-model="memberUsername" class="unit"></b-form-input>
                            </b-input-group>
                            <p v-if="errors && errors.user && errors.user.username" class="field-error text-left mb-3">
                              {{ errors.user.username[0] }}
                            </p>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6" md="12" sm="12" class="my-2" v-if="currentTeamMember.role === 'Driver'">
                          <b-form class="mb-2">
                            <b-form-group label="Driver Type" class="text-left">
                              <b-form-select class="bg_white" v-model="currentTeamMember.driver_type" :options="[
                                { value: null, text: '' },
                                { value: 'Company', text: 'Company' },
                                {
                                  value: 'Owner Operator',
                                  text: 'Owner Operator',
                                },
                              ]"></b-form-select>
                              <p v-if="errors && errors.driver_type" class="field-error mb-3">
                                {{ errors.driver_type[0] }}
                              </p>
                            </b-form-group>
                          </b-form>
                        </b-col>
                      </b-row>

                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row v-if="currentTeamMember.role === 'Driver' && tabsValue === 0">
          <b-col cols="12">
            <div class="details specifications bg-white p-4 mt-4 rounded">
              <h5 class="text-left">Details</h5>
              <b-row align-v="center">
                <b-col lg="3" md="6">
                  <b-form-group label="License Number" class="text-left">
                    <b-form-input type="text" placeholder="Enter License Number" required
                      v-model="currentTeamMember.license_number" />
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="6">
                  <div class="text-left mb-3">
                    <label>License State</label>
                    <v-select class="bg_white" v-model="currentTeamMember.license_state.id" :options="states2"
                      :reduce="(option) => option.id" />
                  </div>
                </b-col>
                <!-- <b-col lg="3" md="6">
                  <div class="text-left mb-3">
                    <label>License Country</label>
                    <v-select
                      class="bg_white"
                      v-model="currentTeamMember.license_state.country"
                      :options="countries2"
                      @option:selected="getStates2"
                      :reduce="(option) => option.id"
                    />
                  </div>
                </b-col> -->
                <b-col lg="3" md="6">
                  <div class="text-left mb-3">
                    <label>CDL</label>
                    <b-form-select class="bg_white" v-model="currentTeamMember.CDL"
                      :options="boolOptions"></b-form-select>
                  </div>
                </b-col>
                <b-col lg="3" md="6" class="text-left">
                  <label for="dob-datepicker" class="state">Date of Birth</label>
                  <b-form-datepicker id="dob-datepicker" class="mb-3" v-model="currentTeamMember.dob" reset-button />
                  <p v-if="errors && errors.dob" class="field-error">
                    {{ errors.dob[0] }}
                  </p>
                </b-col>
                <b-col lg="3" md="6" class="text-left">
                  <b-form-group label="Driver ID" class="text-left">
                    <b-form-input type="text" placeholder="Enter Driver ID" required
                      v-model="currentTeamMember.employee_id"></b-form-input>
                    <p v-if="errors && errors.employee_id" class="field-error">
                      {{ errors.employee_id[0] }}
                    </p>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="6" class="text-left">
                  <b-form-group label="Driver Pin Code" class="text-left">
                    <b-form-input type="number" placeholder="Enter Driver Pin Code" v-model="currentTeamMember.pin_code"
                      @wheel="onWheel"></b-form-input>
                    <p v-if="errors && errors.pin_code" class="field-error mb-2">
                      {{ errors.pin_code[0] }}
                    </p>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="6" class="text-left">
                  <label for="active-datepicker" class="state">Active Date</label>
                  <b-form-datepicker id="active-datepicker" class="mb-3" v-model="currentTeamMember.activate_date"
                    reset-button />
                  <p v-if="errors && errors.activate_date" class="field-error">
                    {{ errors.activate_date[0] }}
                  </p>
                </b-col>
                <b-col lg="3" md="6" class="text-left">
                  <label for="inactive-datepicker" class="state">Inactive Date</label>
                  <b-form-datepicker id="inactive-datepicker" class="mb-3" v-model="currentTeamMember.inactive_date"
                    reset-button />
                  <p v-if="errors && errors.inactive_date" class="field-error">
                    {{ errors.inactive_date[0] }}
                  </p>
                </b-col>
                <!-- <b-col lg="3" md="6">
                                <b-form-group label="License Class" class="text-left">
                                    <b-form-input type="text" placeholder="Enter License Class" required v-model="currentTeamMember.license_class"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="6">
                                <div class="text-left mb-3">
                                    <label>CDL</label>
                                    <b-form-select class="bg_white" v-model="currentTeamMember.CDL" :options="boolOptions"></b-form-select>
                                </div>
                            </b-col>
                            <b-col lg="3" md="6">
                                <b-form-group label="Endorsments" class="text-left">
                                    <b-form-input type="text" placeholder="Enter Endorsments" required v-model="currentTeamMember.endorsement"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="6">
                                <b-form-group label="Restrictions" class="text-left">
                                    <b-form-input type="text" placeholder="Enter Restrictions" required v-model="currentTeamMember.restrictions"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="6">
                                <div class="text-left mb-3">
                                    <label class="state">Hazmat</label>
                                    <b-form-select class="bg_white" v-model="currentTeamMember.hazmat" :options="boolOptions"></b-form-select>
                                </div>
                            </b-col>
                            <b-col lg="3" md="6">
                                <b-form-group label="Years of Experience" class="text-left">
                                    <b-form-input type="text" autocomplete="new-password" placeholder="Enter Years of Experience" v-model="currentTeamMember.years_of_experience"></b-form-input>
                                </b-form-group>
                            </b-col> -->
              </b-row>
            </div>
          </b-col>
        </b-row>
        <!-- <b-row align-v="center">
                <b-col cols="12">
                    <div class="details bg-white p-4 mt-4 rounded" v-if="
                currentTeamMember.role !== 'Driver' ||
                (currentTeamMember.role === 'Driver' && tabsValue === 0)
              ">
                        <h5 class="text-left">Details</h5>
                        <b-row>
                            <b-col lg="3" md="6">
                                <b-form-group label="Address" class="text-left mb-3">
                                    <b-form-input type="text" placeholder="Enter Address" required v-model="currentTeamMember.address" />
                                    <p v-if="errors && errors.address" class="field-error">
                                        {{ errors.address[0] }}
                                    </p>
                                </b-form-group>
                            </b-col>
                            <b-col lg="2" md="6">
                                <b-form-group label="City" class="text-left mb-3">
                                    <b-form-input type="text" placeholder="Enter City" required v-model="currentTeamMember.city" />
                                    <p v-if="errors && errors.city" class="field-error">
                                        {{ errors.city[0] }}
                                    </p>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="6" class="text-left">
                                <label class="state">State</label>
                                <v-select class="bg_white mb-3" :options="states" v-model="currentTeamMember.state.id" :reduce="(option) => option.id" />
                                <p v-if="errors && errors.state" class="field-error">
                                    {{ errors.state[0] }}
                                </p>
                            </b-col>
                            <b-col lg="2" md="6">
                                <b-form-group label="Postal Code" class="text-left mb-3">
                                    <b-form-input type="text" placeholder="Enter Postal Code" required v-model="currentTeamMember.zipcode" />
                                    <p v-if="errors && errors.zipcode" class="field-error">
                                        {{ errors.zipcode[0] }}
                                    </p>
                                </b-form-group>
                            </b-col>
                            <b-col lg="2" md="6" class="text-left">
                                <label class="state">Country</label>
                                <v-select class="bg_white mb-3" v-model="currentTeamMember.state.country" @option:selected="getStates" :options="countries" :reduce="(option) => option.id" />
                                <p v-if="errors && errors.country" class="field-error">
                                    {{ errors.country[0] }}
                                </p>
                            </b-col>
                            <b-col lg="3" md="6" class="text-left">
                                <label for="active-datepicker" class="state">Active Date</label>
                                <b-form-datepicker id="active-datepicker" class="mb-3" v-model="currentTeamMember.activate_date" reset-button />
                                <p v-if="errors && errors.activate_date" class="field-error">
                                    {{ errors.activate_date[0] }}
                                </p>
                            </b-col>
                            <b-col lg="3" md="6" class="text-left">
                                <label for="inactive-datepicker" class="state">Inactive Date</label>
                                <b-form-datepicker id="inactive-datepicker" class="mb-3" v-model="currentTeamMember.inactive_date" reset-button />
                                <p v-if="errors && errors.inactive_date" class="field-error">
                                    {{ errors.inactive_date[0] }}
                                </p>
                            </b-col>
                            <b-col lg="3" md="6" class="text-left">
                                <label for="dob-datepicker" class="state">Date of Birth</label>
                                <b-form-datepicker id="dob-datepicker" class="mb-3" v-model="currentTeamMember.dob" reset-button />
                                <p v-if="errors && errors.dob" class="field-error">
                                    {{ errors.dob[0] }}
                                </p>
                            </b-col>
                            <b-col lg="3" md="6" class="text-left">
                                <b-form-group label="Tax ID" class="text-left mb-3">
                                    <b-form-input type="text" placeholder="Enter Tax ID" required v-model="currentTeamMember.tax_id" />
                                    <p v-if="errors && errors.tax_id" class="field-error">
                                        {{ errors.tax_id[0] }}
                                    </p>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="6">
                                <b-form>
                                    <b-form-group label="Emergency Contact" class="text-left mb-3">
                                        <b-form-input type="text" placeholder="Enter Emergency Contact" required v-model="currentTeamMember.emergency_contact_name" />
                                        <p v-if="errors && errors.emergency_contact_name" class="field-error">
                                            {{ errors.emergency_contact_name[0] }}
                                        </p>
                                    </b-form-group>
                                </b-form>
                            </b-col>
                            <b-col lg="3" md="6">
                                <b-form>
                                    <b-form-group label="Emergency Phone" class="text-left position-relative mb-3">
                                        <vue-tel-input v-bind="bindProps" v-model="currentTeamMember.emergency_contact_number" class="w-100 f-14" @input="checkNum2"/>

                                        <p v-if="errors && errors.emergency_contact_number" class="field-error">
                                            {{ errors.emergency_contact_number[0] }}
                                        </p>
                                    </b-form-group>
                                </b-form>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row> -->
        <div class="app_user_detail bg-white rounded p-4 mt-4" v-if="currentTeamMember.role !== 'Driver' ||
          (currentTeamMember.role === 'Driver' && tabsValue === 0)
          ">
          <h5 class="text-left">App User Status</h5>
          <div class="btns row align-items-center">
            <b-col lg="3" md="6" class="text-left">
              <label>{{
                currentTeamMember.user.is_active ? "Active" : "Inactive"
              }}</label>
              <b-form-checkbox name="check-button" switch size="lg" v-model="currentTeamMember.user.is_active"
                class="ml-2" />
              <p v-if="errors && errors.app_user" class="field-error">
                {{ errors.app_user[0] }}
              </p>
            </b-col>
            <b-col lg="3" md="6" class="text-left" v-if="currentTeamMember.role === 'Driver'">
              <label>Lock Vehicle</label>
              <b-form-checkbox name="check-button" switch size="lg" v-model="currentTeamMember.lock_vehicle"
                class="ml-2" />
            </b-col>
            <b-col lg="3" md="6" class="text-left" v-if="currentTeamMember.role === 'Driver'">
              <label>Lock Trailer</label>
              <b-form-checkbox name="check-button" switch size="lg" v-model="currentTeamMember.lock_trailer"
                class="ml-2" />
            </b-col>

            <b-col lg="3" md="6" class="text-left justify-content-around d-flex align-items-center flex-wrap">
              <b-button class="green invite m-0 w-f-content" @click="sendInviteTeam">
                Send Invite</b-button>
              <b-button v-b-modal.modal-password class="blue">
                Set Password</b-button>
            </b-col>
          </div>
          <div class="d-flex align-items-center mt-3" v-if="currentTeamMember.role !== 'Driver'" :class="$store.state.user.user &&
            $store.state.user.user.team_member.role === 'Admin' &&
            currentTeamMember.user.email !== company[0].owner.email
            ? 'justify-content-between'
            : 'justify-content-end'
            ">
            <div v-if="$store.state.user.user &&
              $store.state.user.user.team_member.role === 'Admin' &&
              currentTeamMember.user.email !== company[0].owner.email
              ">
              <b-button class="mt-3 mr-3" variant="danger" @click="showMemberModal = true">Delete</b-button>
            </div>
            <div class="table_btn">
              <b-button class="mt-3 mr-3 close-btn" @click="$router.go(-1)">Close</b-button>
              <b-button class="mt-3 btn save" @click="updateTeamMemberData">Save</b-button>
            </div>
          </div>
        </div>
        <b-row v-if="currentTeamMember.role === 'Driver' && tabsValue === 0">
          <b-col cols="12">
            <div class="details bg-white p-4 mt-4 rounded">
              <h5 class="text-left">Assigned Equipment</h5>
              <b-form class="mt-2">
                <b-row>
                  <b-col lg="3" md="6">
                    <div class="text-left mb-3">
                      <label class="state">Default Vehicle</label>
                      <v-select class="bg_white mb-4" :options="vehicleOptions" v-model="defaultVehicle"
                        :reduce="(option) => option.id" @search="searchVehicle">
                        <span slot="no-options">
                          Search Unit No...
                        </span>
                      </v-select>
                      <!-- <b-form-select class="bg_white" v-model="defaultVehicle" :options="vehicleOptions" /> -->
                    </div>
                  </b-col>
                  <b-col lg="3" md="6">
                    <div class="text-left mb-3">
                      <label class="state">Default Trailer</label>
                      <v-select class="mb-4 bg_white" :options="trailerOptions" v-model="defaultTrailer"
                        :reduce="(option) => option.id" @search="searchTrailer">
                        <span slot="no-options">
                          Search Unit No...
                        </span>
                      </v-select>
                    </div>
                  </b-col>
                  <b-col lg="3" md="6">
                    <div class="text-left mb-3" v-if="currentTeamMember.is_co_driver">
                      <label class="state">Lead Driver</label>
                      <b-form-input v-model="currentTeamMember.co_driver.name" class="bg_white" disabled />
                    </div>
                    <div class="text-left mb-3" v-else>
                      <label class="state">Co Driver</label>
                      <v-select class="mb-4 bg_white" :options="coDrivers" v-model="driver.co_driver"
                        :reduce="(option) => option.id" @input="sameAsDrivername" @search="searchDriver">
                        <span slot="no-options">
                          Search Name...
                        </span>
                      </v-select>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </div>
          </b-col>
        </b-row>
        <!-- <b-row v-if="
            currentTeamMember.role === 'Driver' && tabsValue === 0
          ">
                <b-col cols="12">
                    <div class="details bg-white p-4 mt-4 rounded">
                        <h5 class="text-left">Dispatch Settings</h5>
                        <b-form class="mt-2">
                            <b-row>
                                <b-col lg="3" md="6">
                                    <div class="ml-4 text-left">
                                        <label>Same As Driver</label>
                                        <b-form-checkbox name="check-button" v-model="currentTeamMember.is_dispatch_name" switch size="lg" @change="sameAsDrivername" />
                                    </div>
                                </b-col>
                                <b-col lg="3" md="6">
                                    <b-form-group label="Dispatch Name" class="text-left">
                                        <b-form-input type="text" placeholder="Enter License Class" v-model="currentTeamMember.dispatch_name" required :disabled="currentTeamMember.is_dispatch_name"></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-form>
                    </div>
                </b-col>
            </b-row> -->
        <!-- <b-row v-if="currentTeamMember.role === 'Driver' && tabsValue === 0
                    ">
                    <b-col cols="12">
                        <div class="details bg-white p-4 mt-4 rounded">
                            <h5 class="text-left">Groups</h5>
                            <b-form class="mt-2">
                                <b-row>
                                    <b-col lg="3" md="6">
                                        <div class="text-left mb-3">
                                            <label class="state">Group 1</label>
                                            <b-form-select class="bg_white" v-model="dispatch_group1"
                                                :options="driverDispatchGroups" />
                                        </div>
                                    </b-col>
                                    <b-col lg="3" md="6">
                                        <div class="text-left mb-3">
                                            <label class="state">Group 2</label>
                                            <b-form-select class="bg_white" v-model="dispatch_group2"
                                                :options="driverDispatchGroups" />
                                        </div>
                                    </b-col>

                                </b-row>
                            </b-form>
                        </div>
                    </b-col>
                </b-row> -->

        <!-- <b-row v-if="
            currentTeamMember.role === 'Driver' && tabsValue === 0
          ">
                <b-col cols="12">
                    <div class="details bg-white p-4 mt-4 rounded">
                        <h5 class="text-left">Compliance</h5>
                        <b-form>
                            <b-row>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="drug-datepicker" class="state">Pre-Emp Drug Test</label>
                                    <b-form-datepicker id="drug-datepicker" class="mb-2" v-model="currentTeamMember.pre_emp_drug_test_date" reset-button />
                                    <p v-if="errors && errors.pre_emp_drug_test_date" class="field-error">
                                        {{ errors.pre_emp_drug_test_date[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="pre-emp-mvr-datepicker" class="state">Pre-Emp MVR</label>
                                    <b-form-datepicker id="pre-emp-mvr-datepicker" class="mb-2" v-model="currentTeamMember.pre_emp_mvr_date" reset-button />
                                    <p v-if="errors && errors.pre_emp_mvr_date" class="field-error">
                                        {{ errors.pre_emp_mvr_date[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="pre-emp-clearing-datepicker" class="state">Pre-Emp Clearinghouse</label>
                                    <b-form-datepicker id="pre-emp-clearing-datepicker" class="mb-2" v-model="currentTeamMember.pre_emp_clearing_house_date" reset-button />
                                    <p v-if="errors && errors.pre_emp_clearing_house_date" class="field-error">
                                        {{ errors.pre_emp_clearing_house_date[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="driver_type" class="state">Drivers Type</label>
                                    <b-form-select class="bg_white mb-2" v-model="currentTeamMember.driver_type" :options="[
                        '',
                        'Company',
                        'Owner Operator',
                        'Contractor Driver',
                      ]" />
                                    <p v-if="errors && errors.driver_type" class="field-error">
                                        {{ errors.driver_type[0] }}
                                    </p>
                                </b-col>
                            </b-row>
                            <b-row class="mt-4">
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="co-insurance" class="state">Co. Insurance</label>
                                    <b-form-checkbox v-model="currentTeamMember.co_insurance" name="check-button" switch size="lg"></b-form-checkbox>
                                </b-col>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="pd-insurance" class="state">PD. Insurance</label>
                                    <b-form-checkbox v-model="currentTeamMember.pd_insurance" name="check-button" switch size="lg"></b-form-checkbox>
                                </b-col>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="occ-acc-insurance" class="state">Occ-Acc Insurance</label>
                                    <b-form-checkbox v-model="currentTeamMember.occ_acc_insurance" name="check-button" switch size="lg"></b-form-checkbox>
                                </b-col>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="workers-comp" class="state">Workers Comp</label>
                                    <b-form-checkbox v-model="currentTeamMember.workers_comp" name="check-button" switch size="lg"></b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-form>
                    </div>
                </b-col>
            </b-row> -->

        <!-- <b-row v-if="
           currentTeamMember.role === 'Driver' && tabsValue === 0
          ">
                <b-col cols="12">
                    <div class="details expiry_data bg-white p-4 mt-4 rounded">
                        <h5 class="text-left">Expiry Data</h5>
                        <b-form>
                            <b-row>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="license-datepicker" class="state">License</label>
                                    <b-form-datepicker id="license-datepicker" class="mb-2" v-model="currentTeamMember.license_date" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.license_date" class="field-error">
                                        {{ errors.license_date[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="medical-datepicker" class="state">Medical Card</label>
                                    <b-form-datepicker id="medical-datepicker" class="mb-2" v-model="currentTeamMember.medical_card_date" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.medical_card_date" class="field-error">
                                        {{ errors.medical_card_date[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="hazmat-datepicker" class="state">Hazmat</label>
                                    <b-form-datepicker id="hazmat-datepicker" class="mb-2" v-model="currentTeamMember.hazmat_date" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.hazmat_date" class="field-error">
                                        {{ errors.hazmat_date[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="twic-datepicker" class="state">TWIC Card</label>
                                    <b-form-datepicker id="twic-datepicker" class="mb-2" v-model="currentTeamMember.twic_card_date" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.twic_card_date" class="field-error">
                                        {{ errors.twic_card_date[0] }}
                                    </p>
                                </b-col>
                            </b-row>
                        </b-form>
                        <b-form class="mt-3">
                            <b-row>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="insurance-datepicker" class="state">Insurance</label>
                                    <b-form-datepicker id="insurance-datepicker" class="mb-2" v-model="currentTeamMember.insurance_date" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.insurance_date" class="field-error">
                                        {{ errors.insurance_date[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="mvr-datepicker" class="state">MVR Review</label>
                                    <b-form-datepicker id="mvr-datepicker" class="mb-2" v-model="currentTeamMember.mvr_review_date" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.mvr_review_date" class="field-error">
                                        {{ errors.mvr_review_date[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="clearing-datepicker" class="state">Clearinghouse</label>
                                    <b-form-datepicker id="clearing-datepicker" class="mb-2" v-model="currentTeamMember.clearing_house_date" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.clearing_house_date" class="field-error">
                                        {{ errors.clearing_house_date[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="6" class="text-left">
                                    <label for="other-datepicker" class="state">Other</label>
                                    <b-form-datepicker id="other-datepicker" class="mb-2" v-model="currentTeamMember.other_date" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.other_date" class="field-error">
                                        {{ errors.other_date[0] }}
                                    </p>
                                </b-col>
                            </b-row>
                        </b-form>
                    </div>
                </b-col>
            </b-row> -->

        <div v-if="currentTeamMember.role === 'Driver' && tabsValue === 0" class="data-table bg-white p-4 rounded mt-4">
          <div class="table_row">
            <div class="d-flex justify-content-between align-items-center flex-wrap">
              <h4>Documents</h4>
              <b-button @click="addDocument"
                class="export equipment d-flex justify-content-center align-items-center mb-3"
                variant="primary"
                >
                <b-icon icon="plus" aria-hidden="true"></b-icon>
                <p class="mb-0 ml-2 text-white">Add Document</p>
              </b-button>
            </div>

            <b-table responsive :fields="fields" :items="items" hover :per-page="perPage"
              :current-page="currentPage">
              <template #cell(name)="data">
                <a :href="`#${data.value
                  .replace(/[^a-z]+/i, '-')
                  .toLowerCase()}`">{{ data.value }}</a>
              </template>
              <template #cell(description)="data">
                <p v-if="data.value && data.value !== 'null'">
                  {{ data.value }}
                </p>
                <p v-else>-</p>
              </template>
              <template #cell(public)="data">
                <b-form-checkbox v-model="data.value" name="check-button" class="ml-2" switch @change="
                  updateDocumentOnToggle(data.item.id, 'public', data.value)
                  " size="lg" />
              </template>
              <template #cell(expiry_date)="data">
                <span v-html="data.value"></span>
              </template>
              <template #cell(driver)="data">
                <b-form-checkbox v-model="data.value" name="check-button" class="ml-2" switch @change="
                  updateDocumentOnToggle(data.item.id, 'driver', data.value)
                  " size="lg" />
              </template>
              <template #cell(action)="data">
                <b-icon v-if="data.item.link" style="fill: #2D69F6" class="mr-4" icon="eye-fill"
                  @click="openLink(data.item.pk)" />
                <b-icon style="fill: limegreen" class="mr-4" icon="pencil" @click="editDocument(data.item.id)" />
                <b-icon style="fill:#FF0000" icon="trash-fill" @click="showPopup(data.item.pk)" />
              </template>
            </b-table>
            <div class="entries-pagination mb-5">
              <div class="d-flex align-items-center justify-content-between flex-wrap">
                <div class="text-left show-entries mr-md-3 pl-3 pl-md-0 ml-md-0">
                  <label for="" class="ml-0">Show</label>
                  <b-form-select v-model="perPage" :options="pageOptions" />
                  <label for="" class="me-0">entries</label>
                </div>
                <div class="pagination">
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table"></b-pagination>
                </div>
                <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
              </div>
            </div>
            <div class="ml-2 d-flex align-items-center" :class="$store.state.user.user &&
              $store.state.user.user.team_member.role === 'Admin'
              ? 'justify-content-between'
              : 'justify-content-end'
              ">
              <div v-if="$store.state.user.user &&
                $store.state.user.user.team_member.role === 'Admin'
                ">
                <b-button class="mt-3 mr-3" variant="danger" @click="showMemberModal = true">Delete</b-button>
              </div>
              <div class="table_btn">
                <b-button class="mt-3 mr-3 close-btn" @click="$router.go(-1)">Close</b-button>
                <b-button class="mt-3 save" @click="updateTeamMemberData">Save</b-button>
              </div>
            </div>
          </div>
          <qriously class="d-none" :value="`https://app.digitalpermitbook.com/driver-details?driver=${teamMemberId}`"
            :size="200" ref="qrcode" />
        </div>
        <DriverPayInfo v-if="currentTeamMember.role === 'Driver' && tabsValue === 1"
          :currentTeamMember="currentTeamMember" :states="states" :countries="countries" />

        <!-- Modal -->
        <b-modal id="modal-center" centered hide-footer no-close-on-backdrop>
          <template #modal-header="{ close }">
            <h5 class="modal-title">
              {{ editDocumentFlag ? "Edit Document" : "Add Document" }}
            </h5>
            <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
              Close Modal
            </b-button>
          </template>
          <div class="d-block text-center">
            <b-row>
              <b-col lg="6" md="8" sm="12">
                <div class="text-left mb-3">
                  <label for="modal-datepicker" class="state">Date</label>
                  <b-form-datepicker id="modal-datepicker" class="mb-2" v-model="documentData.date"
                    reset-button></b-form-datepicker>
                  <p v-if="errors && errors.date" class="field-error">
                    {{ errors.date[0] }}
                  </p>
                </div>
                <!-- <div class="text-left mb-3">
                                    <label class="state">Type</label>
                                    <b-form-select class="bg_white" v-model="documentData.type"
                                        :options="documentOptions"></b-form-select>
                                    <p v-if="errors && errors.type" class="field-error">
                                        {{ errors.type[0] }}
                                    </p>
                                </div> -->
                <div class="text-left mb-3">
                  <label for="modal-expiry-date" class="state">Expiry Date</label>
                  <b-form-datepicker id="modal-expiry-date" class="mb-2" v-model="documentData.expiry_date"
                    reset-button></b-form-datepicker>
                </div>
                <div class="text-left mb-3">
                  <label class="state">Description</label>
                  <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                    v-model="documentData.description"></b-form-textarea>
                  <p v-if="errors && errors.description" class="field-error">
                    {{ errors.description[0] }}
                  </p>
                </div>
                <div class="text-left">
                  <label class="state">Driver</label>
                  <b-form-checkbox v-model="documentData.driver" name="check-button" switch size="lg" class="ml-2" />
                </div>
              </b-col>
              <b-col lg="6" md="8" sm="12">
                <div class="upload-btn-wrapper">
                  <b-img :src="require('@/assets/drop.svg')"></b-img>
                  <button v-if="!fileName" class="foo">
                    Drop your document here, or browse
                  </button>
                  <button v-else class="foo">{{ fileName }}</button>
                  <p v-if="fileName" class="mb-0 field-error cursor-pointer font-12 position-relative error-button"
                    @click="removeFile()">
                    Delete
                  </p>
                  <input ref="file" type="file" name="myfile" v-on:change="handleFileUpload"
                    accept=".pdf, .png, .jpg, .jpeg" />
                  <p v-if="errors && errors.document" class="field-error">
                    {{ errors.document[0] }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="text-right modal-footer border-0 p-0">
            <b-button class="mt-3 mr-3 btn cancel" block @click="$bvModal.hide('modal-center')">Cancel</b-button>
            <b-button class="mt-3 btn save mx-0" @click="sendDocumentData">Save</b-button>
          </div>
        </b-modal>
        <b-modal id="modal-password" centered hide-footer no-close-on-backdrop>
          <template #modal-header="{ close }">
            <h5 class="modal-title">
              Set Password
            </h5>
            <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
              Close Modal
            </b-button>
          </template>
          <div class="d-block text-center">
            <b-row align-v="center">
              <b-col md="12">
                <b-form-input id="pass-field" v-model="password" type="password" class="mb-2"
                  placeholder="Enter Password" />
                <p v-if="errors && errors.password" class="field-error float-start">
                  {{ errors.password[0] }}
                </p>
              </b-col>
              <b-col md="12" class="mt-3">
                <div class="profile_btn ml-2 d-flex justify-content-end align-items-center">
                  <b-button class="mt-3 mr-3 close-btn" @click="$bvModal.hide('modal-password')">Close</b-button>
                  <b-button class="mt-3 save" @click="setPass">Save</b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-modal>
      </b-col>
    </b-row>
    <DeleteModal v-if="showDocumentModal" @confirm="deleteDocumentItem($event)" />
    <DeleteModal v-if="showMemberModal" @confirm="deleteMemberItem($event)"
      :message="'Do you want to delete this Driver?'" />
    <AddTags v-if="showTagsModal" @confirmTags="addTag($event)" :existingTags="currentTeamMember.tags" :type="'Driver'"
    :driverID="teamMemberId" :co_driver="driver.co_driver"
    />
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "../../router";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import DeleteModal from "@/components/DeleteModal.vue";
import AwesomeMask from "awesome-mask";
import DriverPayInfo from "@/components/DriverPayInfo.vue";
import AddTags from "@/components/Equipment/AddTags.vue";
import * as moment from "moment-timezone";
import { areAllDigitsSame } from "../../services/helper";
import generateQRCode from "../../services/generateDriverQRCode";
export default {
  name: "team-member",
  components: {
    vSelect,
    DeleteModal,
    DriverPayInfo,
    AddTags
  },
  directives: {
    mask: AwesomeMask,
  },
  computed: {
    ...mapGetters([
      "currentTeamMember",
      "allEquipments",
      "allCountries",
      "allDrivers",
      "dispatchGroups",
      "company",
      "userCompany"
    ]),
    rows() {
      return this.items.length;
    },
    positionText() {
      let endIndex = this.currentPage * this.perPage,
        startIndex =
          this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.rows ? this.rows : endIndex) +
        " of " +
        this.rows +
        " entries"
      );
    },
  },
  watch: {
    currentTeamMember() {
      this.existingTags = this.currentTeamMember.tags;
    },
  },
  data() {
    return {
      states: [],
      states2: [],
      coDrivers: [],
      driverDispatchGroups: [],
      countries: [],
      countries2: [],
      dispatch_group1: null,
      dispatch_group2: null,
      memberUsername: "",
      selected: null,
      existingTags: [],
      showTagsModal: false,
      itemId: 0,
      qrCodeUrl: "",
      tabsValue: 0,
      showDocumentModal: false,
      showMemberModal: false,
      teamMemberId: this.$route.params.id,
      defaultVehicle: null,
      defaultTrailer: null,
      password: null,
      errors: {
        date: null,
        type: null,
        document: null,
        description: null,
      },
      isPhoneValid: false,
      isPhoneValid2: false,
      bindProps: {
        mode: "international",
        autoFormat: true,
        preferredCountries: ["US", "CA", "MX"],
        inputOptions: {
          placeholder: "Enter a phone no.",
          maxlength: 30,
        },
        dropdownOptions: {
          showSearchBox: true,
          showDialCodeInList: true,
          showFlags: true,
          showDialCodeInSelection: true,
          width: "300px",
        },
      },
      fileName: null,
      memberPinCode: "",
      memberImage: null,
      memberImageURL: null,
      driver: {
        sameAsDriver: false,
        co_driver: null,
      },
      vehicleOptions: [],
      trailerOptions: [],
      editDocumentFlag: false,
      documentIndex: null,
      documentData: {
        object_id: null,
        date: null,
        type: null,
        expiry_date: "",
        document: null,
        description: "",
        driver: true,
      },
      documentOptions: [
        {
          value: null,
          text: "",
        },
        {
          value: "License",
          text: "License",
        },
        {
          value: "MVR",
          text: "MVR",
        },
        {
          value: "Medical Card",
          text: "Medical Card",
        },
        {
          value: "Insurance",
          text: "Insurance",
        },
        {
          value: "Drug Test",
          text: "Drug Test",
        },
        {
          value: "Other",
          text: "Other",
        },
      ],
      options: [
        {
          value: "Illinois",
          text: "Illinois",
        },
        {
          value: "Chicago",
          text: "Chicago",
        },
        {
          value: "Mexico",
          text: "Mexico",
        },
      ],
      boolOptions: [
        {
          value: null,
          text: "",
        },
        {
          value: true,
          text: "Yes",
        },
        {
          value: false,
          text: "No",
        },
      ],
      roleOptions: [
        {
          value: "Driver",
          text: "Driver",
        },
      ],
      perPage: 25,
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      currentPage: 1,
      items: [],
      fields: [
        {
          key: "Date",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
        {
          key: "Description",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
        {
          key: "expiry_date",
          label: "Expiry Date",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
        {
          key: "Driver",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
        // {
        //   key: 'Public',
        //   sortable: true,
        // },
        {
          key: "Action",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
      ],
      computed: {
        rows() {
          return this.items.length;
        },
      },
    };
  },
  methods: {
    ...mapActions([
      "getTeamMember",
      "setLoader",
      "sendInvite",
      "getDispatchGroups",
      "getAllDrivers",
      "updateTeamMember",
      "deleteTeamMember",
      "setNotification",
      "sendTeamMemberDocument",
      "updateTeamMemberDocument",
      "deleteCompanyDocument",
      "getAllEquipments",
      "retrieveDocument",
      "getAllCountries",
      "setPassword",
    ]),
    async removeTag(tag) {
      const index = this.existingTags.indexOf(tag);
      this.existingTags.splice(index, 1);
      await this.updateTeamMemberData();
    },
    generateDriverQRCode(driverName, id, fileName, qrCode) {
      generateQRCode(driverName, id, fileName, qrCode)
    },
    onWheel(e) {
      e.target.blur();
    },
    removeFile() {
      this.fileName = null;
      this.$refs.file.value = "";
    },
    async searchVehicle(searchText) {
      const url = `unit_no=${searchText}&type=Vehicle&status=Active&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
      await this.getAllEquipments(url);
      this.typeOptions()
    },
    async searchTrailer(searchText) {
      const url = `unit_no=${searchText}&type=Trailer&status=Active&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
      await this.getAllEquipments(url);
      this.subTypeOptions()
    },
    async searchDriver(searchText) {
      const url = `name=${searchText}&page=1&page_size=10`
      await this.getAllDrivers(url);
      this.coDrivers = []
      this.allDrivers
        .filter(
          (driver) =>
            driver.id !== this.currentTeamMember.id && !driver.co_driver
        )
        .map((item) => {
          this.coDrivers.push({
            id: item.id,
            label: item.name,
          });
        });
    },
    deleteDocumentItem(item) {
      if (item) {
        this.deleteDocument(this.itemId);
      }
      this.showDocumentModal = false;
    },
    deleteMemberItem(item) {
      if (item) {
        this.deleteMember(this.itemId);
      }
      this.showMemberModal = false;
    },
    sameAsDrivername() {
      if (this.currentTeamMember.is_dispatch_name) {
        if (this.driver.co_driver && this.driver.co_driver) {
          this.currentTeamMember.dispatch_name = this.driver.co_driver.name
        } else {
          this.currentTeamMember.dispatch_name =
            this.currentTeamMember.user.first_name +
            " " +
            this.currentTeamMember.user.last_name;
        }
      }
    },
    showPopup(id) {
      this.showDocumentModal = true;
      this.itemId = id;
    },
    checkNum(data, phoneObject) {
      if (phoneObject.valid !== undefined) {
        console.log(phoneObject);
        this.isPhoneValid = phoneObject.valid;
      }
    },
    checkNum2(data, phoneObject) {
      if (phoneObject.valid !== undefined) {
        this.isPhoneValid2 = phoneObject.valid;
      }
    },
    async getCountries(item) {
      await this.getAllCountries();
      this.countries = this.allCountries.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      this.getStates(this.countries[0], item);
    },
    async getCountries2(item) {
      this.countries2 = this.allCountries.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      this.getStates2(this.countries2[0], item);
    },
    getStates(selectedOption, item) {
      this.states = this.allCountries
        .filter((country) => country.id === selectedOption.id)[0]
        .state.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      if (this.states.length === 0) {
        this.currentTeamMember.state.id = null;
      } else {
        if (this.currentTeamMember.state && item) {
          this.currentTeamMember.state.id = item;
        } else if (this.currentTeamMember.state) {
          this.currentTeamMember.state.id = this.states.filter(
            (state) => state.label === "-"
          )[0].id;
        }
      }
    },

    getStates2(selectedOption, item) {
      this.states2 = this.allCountries
        .filter((country) => country.id === selectedOption.id)[0]
        .state.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      if (this.states2.length === 0) {
        this.currentTeamMember.license_state.id = null;
      } else {
        if (this.currentTeamMember.license_state && item) {
          this.currentTeamMember.license_state.id = item;
        } else if (this.currentTeamMember.license_state) {
          this.currentTeamMember.license_state.id = this.states2.filter(
            (state) => state.label === "-"
          )[0].id;
        }
      }
    },

    typeOptions() {
      let options = [];
      if (this.currentTeamMember.default_vehicle) {
        options.push({
          id: this.currentTeamMember.default_vehicle.id,
          label: this.currentTeamMember.default_vehicle.unit_no,
        });
      }
      this.allEquipments.forEach((x) => {
        options.push({
          id: x.id,
          label: x.unit_no,
        });
      });
      this.vehicleOptions = this.vehicleOptions.concat(options)
      this.vehicleOptions = [...new Map(this.vehicleOptions.map(item => [item['id'], item])).values()]
    },
    subTypeOptions() {
      let options = [];
      if (this.currentTeamMember.default_trailer) {
        options.push({
          id: this.currentTeamMember.default_trailer.id,
          label: this.currentTeamMember.default_trailer.unit_no,
        });
      }
      this.allEquipments.forEach((x) => {
        options.push({
          id: x.id,
          label: x.unit_no,
        });
      });
      this.trailerOptions = this.trailerOptions.concat(options)
      this.trailerOptions = [...new Map(this.trailerOptions.map(item => [item['id'], item])).values()]
    },

    addDocument() {
      this.$bvModal.show("modal-center");
      this.fileName = null;
      this.editDocumentFlag = false;
      this.documentData = {
        object_id: null,
        date: new Date(),
        type: null,
        document: null,
        description: null,
        expiry_date: "",
        driver: true,
      };
      this.errors = {
        date: null,
        type: null,
        document: null,
        description: null,
      };
    },
    addTag(item) {
      console.log(item);
      this.showTagsModal = false;
    },
    async setPass() {
      try {
        if (this.password) {
          await this.setPassword({
            user: this.teamMemberId,
            password: this.password,
          });
          await this.setNotification({
            msg: "Password is set successfully!!",
            type: "success",
            color: "green",
          });
          this.$bvModal.hide("modal-password");
        } else {
          throw {
            password: ["Please Enter Password."],
          };
        }
      } catch (error) {
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }
    },
    async openLink(id) {
      const doc = await this.retrieveDocument(id);
      console.log(doc);
      window.open(doc.document, "_blank");
    },
    editDocument(id) {
      this.editDocumentFlag = true;
      this.documentIndex = id;
      this.documentData = this.currentTeamMember.docs[id];
      if (this.documentData.document) {
        let file_name = "";
        if (typeof this.documentData.document === "string") {
          file_name = this.documentData.document;
        } else {
          file_name = this.documentData.document.name;
        }
        this.fileName = file_name.substring(file_name.lastIndexOf("/") + 1);
      } else {
        this.documentData.document = null;
        this.fileName = "";
        this.errors = {
          date: null,
          type: null,
          document: null,
          description: null,
        };
      }
      this.$bvModal.show("modal-center");
    },
    async deleteDocument(id) {
      try {
        const docs = await this.deleteCompanyDocument(`${id}/?company_id=${this.userCompany}`);
        if (docs) {
          this.currentTeamMember.docs = docs;
        }
        this.updateDocs();
        await this.setNotification({
          msg: "Document successfully deleted!!",
          type: "success",
          color: "green",
        });
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    handleFileUpload() {
      this.documentData.document = this.$refs.file.files[0];
      this.fileName = this.documentData.document.name;
    },
    handleImageUpload() {
      this.memberImage = this.$refs.imageFile.files[0];
      this.memberImageURL = URL.createObjectURL(this.memberImage);
      this.updateTeamMemberImage();
    },

    updateDocs() {
      this.items = [];
      this.currentTeamMember.docs.forEach((doc, id) => {
        let color;
        if (doc.expiry_date) {
          const currentDate = new Date();
          const expiryDate = new Date(doc.expiry_date);
          const diffDays = parseInt(
            (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
            10
          );
          if (diffDays > 31) {
            color = "color:black";
          } else if (diffDays <= 31 && diffDays >= 1) {
            color = "color:#FFC800";
          } else color = "color:#FF0000";
        }
        this.items.push({
          id: id,
          pk: doc.id,
          Date: moment(doc.date).format("MMM DD, YYYY"),
          Description: doc.description,
          Public: doc.public,
          Driver: doc.driver,
          link: doc.document,
          expiry_date: doc.expiry_date
            ? `<span style=${color}>${moment(doc.expiry_date).format(
              "MMM DD, YYYY"
            )}</span>`
            : "-",
        });
      });
    },
    async updateDocumentOnToggle(id, key, value) {
      try {
        this.currentTeamMember.docs[id][key] = value;
        if (!this.currentTeamMember.docs[id].expiry_date)
          delete this.currentTeamMember.docs[id].expiry_date;
        delete this.currentTeamMember.docs[id].document;
        console.log(this.currentTeamMember.docs[id]);
        await this.updateTeamMemberDocument({
          id: this.currentTeamMember.docs[id].id,
          payload: this.currentTeamMember.docs[id],
        });
        await this.setNotification({
          msg: "Document Successfully updated",
          type: "success",
          color: "green",
        });
      } catch (error) {
        console.log(error);
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    async sendDocumentData() {
      try {
        this.setLoader(true);
        let formData = new FormData();
        if (this.$refs.file.files.length === 0) {
          this.documentData.document = null;
        }
        formData.append("object_id", this.currentTeamMember.id);
        formData.append(
          "date",
          moment(this.documentData.date).format("YYYY-MM-DD")
        );
        formData.append("type", this.documentData.type);
        formData.append("description", this.documentData.description);
        if (this.documentData.document)
          formData.append(
            "document",
            new File(
              [this.documentData.document],
              this.documentData.document["name"].split(".")[0] +
              "_" + this.userCompany+"_"+
              new Date().getTime() +
              "." +
              this.documentData.document["type"].split("/").pop(),
              { type: this.documentData.document["type"] }
            )
          );
        if (this.documentData.expiry_date)
          formData.append("expiry_date", this.documentData.expiry_date);
        formData.append("driver", this.documentData.driver);
        formData.append("model_type", "teammember");
        formData.append("company_id", parseInt(localStorage.getItem("userSelectedCompany")));
        if (this.editDocumentFlag) {
          formData.append(
            "public",
            this.currentTeamMember.docs[this.documentIndex].public
          );
          this.documentData = await this.updateTeamMemberDocument({
            id: this.documentData.id,
            payload: formData,
          });
          await this.setNotification({
            msg: "Document Successfully updated!!",
            type: "success",
            color: "green",
          });
          let color;
          if (this.documentData.expiry_date) {
            const currentDate = new Date();
            const expiryDate = new Date(this.documentData.expiry_date);
            const diffDays = parseInt(
              (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
              10
            );
            if (diffDays > 31) {
              color = "color:black";
            } else if (diffDays <= 31 && diffDays >= 1) {
              color = "color:#FFC800";
            } else color = "color:#FF0000";
          }
          this.currentTeamMember.docs[this.documentIndex] = this.documentData;
          this.items[this.documentIndex].Date = moment(
            this.documentData.date
          ).format("MMM DD, YYYY");
          this.items[this.documentIndex].Type = this.documentData.type;
          this.items[this.documentIndex].Description =
            this.documentData.description;
          this.items[this.documentIndex].expiry_date = this.documentData
            .expiry_date
            ? `<span style=${color}>${moment(
              this.documentData.expiry_date
            ).format("MMM DD, YYYY")}</span`
            : "-";
          this.items[this.documentIndex].Driver = this.documentData.driver;
          this.editDocumentFlag = false;
          this.updateDocs();
          this.documentData = {
            object_id: null,
            date: null,
            type: null,
            expiry_date: "",
            document: null,
            description: null,
            driver: true,
          };
          this.fileName = null;
          this.$bvModal.hide("modal-center");
        } else {
          if (this.documentData.date) {
            const docs = await this.sendTeamMemberDocument(formData);
            this.currentTeamMember.docs = docs;
            this.updateDocs();
            await this.setNotification({
              msg: `Document Successfully ${this.editDocumentFlag ? "updated" : "added"
                }!!`,
              type: "success",
              color: "green",
            });
            this.documentData = {
              object_id: null,
              date: null,
              type: null,
              expiry_date: "",
              document: null,
              description: null,
              driver: true,
            };
            this.fileName = null;
            this.$bvModal.hide("modal-center");
          } else {
            this.errors.date = this.documentData.date
              ? null
              : ["This field is required."];
            // this.errors.description = this.documentData.description? null: ['This field is required.']

            console.log(this.errors);
          }
        }
        this.setLoader(false);
      } catch (error) {
        this.setLoader(false);
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }
    },
    async deleteMember() {
      try {
        await this.deleteTeamMember(this.currentTeamMember.id);
        await this.setNotification({
          msg: "Member successfully deleted!!",
          type: "success",
          color: "green",
        });
        this.$router.go(-1);
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    async updateTeamMemberImage() {
      try {
        delete this.currentTeamMember.user.password;
        let formData = new FormData();
        formData.append("user", JSON.stringify(this.currentTeamMember.user));
        formData.append(
          "profile_picture",
          this.memberImage,
          this.memberImage.name
        );
        await this.updateTeamMember({
          id: this.teamMemberId,
          payload: formData
        });
        if (this.currentTeamMember) {
          await this.setNotification({
            msg: `Successfully updated Profile Picture of ${this.currentTeamMember.user.first_name} ${this.currentTeamMember.user.last_name}`,
            type: "success",
            color: "green",
          });
        }
      } catch (error) {
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }
    },
    phoneRule: (value) => {
      const pattern = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
      return pattern.test(value);
    },
    async updateTeamMemberData() {
      try {
        if (!this.memberUsername) {
          await this.setNotification({
            msg: "Username is required",
            type: "error",
            color: "red",
          });
          return;
        }
        if (this.memberUsername && this.memberUsername.length < 4) {
          await this.setNotification({
            msg: "Username should have minimum 4 characters",
            type: "error",
            color: "red",
          });
          return;
        }
        if (this.memberUsername && this.memberUsername.includes("@")) {
          await this.setNotification({
            msg: "Username shouldn't contain @",
            type: "error",
            color: "red",
          });
          return;
        }
        if (this.currentTeamMember.phone) {
          if (!this.isPhoneValid) {
            throw {
              phone: ["Wrong format"],
            };
          }
        }

        if (
          this.currentTeamMember.role === "Driver" &&
          this.currentTeamMember.emergency_contact_number
        ) {
          if (!this.isPhoneValid2) {
            throw {
              emergency_contact_number: ["Wrong format"],
            };
          }
        }

        if (
          this.currentTeamMember.role === "Driver" &&
          this.currentTeamMember.pin_code
        ) {
          if (this.currentTeamMember.pin_code.length > 4) {
            await this.setNotification({
              msg: "Max 4 digits allowed",
              type: "error",
              color: "red",
            });
            return;
          } else if (areAllDigitsSame(this.currentTeamMember.pin_code)) {
            await this.setNotification({
              msg: "All digits should not be same",
              type: "error",
              color: "red",
            });
            return;
          } else this.membersPinCode = this.currentTeamMember.pin_code;
        }

        delete this.currentTeamMember.profile_picture;
        delete this.currentTeamMember.user.password;
        this.currentTeamMember.default_vehicle = this.defaultVehicle;
        this.currentTeamMember.default_trailer = this.defaultTrailer;
        this.currentTeamMember.user.username =
          this.memberUsername + "." + this.userCompany;
        let currentMember = {
          ...this.currentTeamMember,
          state: this.currentTeamMember.state.id
            ? this.currentTeamMember.state.id
            : 54,
          license_state: this.currentTeamMember.license_state.id
            ? this.currentTeamMember.license_state.id
            : 54,
          dispatch_name:
            this.currentTeamMember.is_dispatch_name && this.driver.co_driver
              ? this.driver.co_driver.name
              : this.currentTeamMember.dispatch_name,
          dispatch_group1: this.dispatch_group1,
          dispatch_group2: this.dispatch_group2,
          co_driver: this.driver.co_driver,
          activate_date: this.currentTeamMember.activate_date
            ? this.currentTeamMember.activate_date
            : null,
          clearing_house_date: this.currentTeamMember.clearing_house_date
            ? this.currentTeamMember.clearing_house_date
            : null,
          dob: this.currentTeamMember.dob ? this.currentTeamMember.dob : null,
          inactive_date: this.currentTeamMember.inactive_date
            ? this.currentTeamMember.inactive_date
            : null,
          pre_emp_drug_test_date: this.currentTeamMember.pre_emp_drug_test_date
            ? this.currentTeamMember.pre_emp_drug_test_date
            : null,
          pre_emp_mvr_date: this.currentTeamMember.pre_emp_mvr_date
            ? this.currentTeamMember.pre_emp_mvr_date
            : null,
          pre_emp_clearing_house_date: this.currentTeamMember
            .pre_emp_clearing_house_date
            ? this.currentTeamMember.pre_emp_clearing_house_date
            : null,
          hazmat_date: this.currentTeamMember.hazmat_date
            ? this.currentTeamMember.hazmat_date
            : null,
          insurance_date: this.currentTeamMember.insurance_date
            ? this.currentTeamMember.insurance_date
            : null,
          license_date: this.currentTeamMember.license_date
            ? this.currentTeamMember.license_date
            : null,
          medical_card_date: this.currentTeamMember.medical_card_date
            ? this.currentTeamMember.medical_card_date
            : null,
          mvr_review_date: this.currentTeamMember.mvr_review_date
            ? this.currentTeamMember.mvr_review_date
            : null,
          other_date: this.currentTeamMember.other_date
            ? this.currentTeamMember.other_date
            : null,
          twic_card_date: this.currentTeamMember.twic_card_date
            ? this.currentTeamMember.twic_card_date
            : null,
          lock_trailer: this.currentTeamMember.lock_trailer ? true : false,
        };
        this.currentTeamMember.years_of_experience =
          this.currentTeamMember.years_of_experience !== ""
            ? this.currentTeamMember.years_of_experience
            : null;
        await this.updateTeamMember({
          id: this.teamMemberId,
          payload: {
            ...currentMember,
            tags: {
              new_tag: { add_tag: null, company: this.userCompany },
              tag_list: this.existingTags.length
                ? this.existingTags.map((item) => item.id)
                : [],
            }
          }
        });
        if (this.currentTeamMember) {
          await this.setNotification({
            msg: `Successfully updated ${this.currentTeamMember.user.first_name} ${this.currentTeamMember.user.last_name}`,
            type: "success",
            color: "green",
          });
        }
        this.$router.go(-1);
      } catch (error) {
        console.log(error);
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }
    },
    async sendInviteTeam() {
      try {
        await this.sendInvite({
          user: this.currentTeamMember.id,
          invite_type: "user-invite"
        });
        await this.setNotification({
          msg: "Email Sent",
          type: "success",
          color: "green",
        });
      } catch (error) {
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
          error = "Couldnot send email";
        }
        console.log(error);
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
  },

  async mounted() {
    try {
      await this.getTeamMember(this.teamMemberId);
      this.dispatch_group1 = this.currentTeamMember.dispatch_group1
        ? this.currentTeamMember.dispatch_group1.id
        : null;
      this.dispatch_group2 = this.currentTeamMember.dispatch_group2
        ? this.currentTeamMember.dispatch_group2.id
        : null;
      await this.getCountries(this.currentTeamMember.state.id);
      this.memberUsername = this.currentTeamMember.user.username.split(
        `.${this.userCompany}`
      )[0];
      if (this.currentTeamMember.role === "Driver") {
        await this.getCountries2(this.currentTeamMember.license_state.id);
        await this.$nextTick();
        if (this.teamMemberId && this.currentTeamMember.role === "Driver") {
          this.memberPinCode = this.currentTeamMember.pin_code;
          this.qrCodeUrl =
            this.$refs?.qrcode.qrious._canvasRenderer.element.toDataURL();
        }
        this.driver.co_driver = this.currentTeamMember.co_driver
          ? this.currentTeamMember.co_driver.id
          : null;
        await this.getDispatchGroups(this.userCompany);

        if (this.currentTeamMember.co_driver) {
          this.coDrivers.push({
            id: this.currentTeamMember.co_driver.id,
            label: this.currentTeamMember.co_driver.name,
          });
        }
        this.coDrivers = this.coDrivers.sort((a, b) =>
          a.label.localeCompare(b.label)
        );
        this.driverDispatchGroups.push({
          value: null,
          text: "",
        });
        this.dispatchGroups.map((item) => {
          this.driverDispatchGroups.push({
            value: item.id,
            text: item.name,
          });
        });
      }
      // await this.getAllEquipments();

      // await this.typeOptions();
      // await this.subTypeOptions();
      if (this.currentTeamMember.default_vehicle) {
        this.defaultVehicle = this.currentTeamMember.default_vehicle.id;
        if (this.currentTeamMember.default_vehicle) {
          this.vehicleOptions.push({
            id: this.currentTeamMember.default_vehicle.id,
            label: this.currentTeamMember.default_vehicle.unit_no,
          });
        }
      }
      if (this.currentTeamMember.default_trailer) {
        this.defaultTrailer = this.currentTeamMember.default_trailer.id;
        if (this.currentTeamMember.default_trailer) {
          this.trailerOptions.push({
            id: this.currentTeamMember.default_trailer.id,
            label: this.currentTeamMember.default_trailer.unit_no,
          });
        }
      }

      this.updateDocs();

    } catch (error) {
      console.log(error);
      await this.setNotification({
        msg: error,
        type: "error",
        color: "red",
      });
      await router.push("/");
    }
  },
};
</script>

<style>
.team-tabs {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.team-tabs .v-slide-group__prev {
  display: none !important;
}

.team-member-page .bi-x.cross_icon {
  position: absolute;
  right: 20px !important;
  top: 40px !important;
}

.upload_btn {
  position: relative;
  width: fit-content;
  margin: auto;
}

.upload_btn input {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  width: -webkit-fill-available;
}

.upload_btn .btn {
  background-color: var(--btn-bg-color);
  cursor: pointer;
}

#dropzone {
  height: 100%;
  width: 265px;
}

.object_detail .num p.font-weight-bold {
  font-size: 18px !important;
}

.col-form-label,
label {
  font-size: var(--small) !important;
  font-weight: 500;
}

fieldset .b-icon :not(svg) {
  color: var(--black);
}

fieldset .b-icon {
  right: 10px;
  top: 40px;
}

h5 {
  font-weight: 600 !important;
  color: var(--black);
  margin-bottom: 12px !important;
}

.modal h5 {
  color: #fff;
  margin-bottom: 0 !important;
}

.app_user_detail .btns button {
  border-radius: 5px;
  font-size: var(--small);
}

.app_user_detail .btns .green {
  background-color: var(--bg-green);
}

.tabs-box {
  padding: 0px 32px !important;
}

.v-tab {
  text-transform: inherit !important;
}

.app_user_detail .btns .green.invite {
  background-color: var(--bg-green);
  justify-content: center;
  padding: 0.375rem 0.75rem;
}

.app_user_detail .btns .blue {
  background-color: var(--btn-bg-color);
}

.table_btn .btn {
  background-color: #dddddd;
  border-color: #dddddd;
}

.left_img {
  width: 300px;
  object-fit: cover;
  height: 280px;
  margin: 3px;
  border: 3px solid lightgrey;
}

.team-member-page .select_arrow {
  position: relative;
}

.team-member-page .select_arrow svg {
  position: absolute;
  top: 40px;
  right: 10px;
}

.v-tabs {
  margin-bottom: 34px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.v-tab {
  color: #797979 !important;
}

.v-tab--active,
.v-tabs-slider {
  color: #2D69F6 !important;
  font-weight: 700;
}

@media (max-width: 991px) {
  .left_img {
    width: 100%;
    margin-bottom: 15px !important;
  }

  .imageFile {
    margin-bottom: 15px;
  }
}

@media (max-width: 575px) {
  .object_detail .num p.font-weight-bold {
    font-size: 12px !important;
  }

  input.form-control,
  .custom-select {
    font-size: var(--extrasmall);
  }

  fieldset,
  .b-form-datepicker.mb-1 {
    margin-bottom: 15px !important;
  }

  .btns button {
    margin-top: 15px !important;
  }

  .app_user_detail .btns {
    display: block !important;
  }
}

@media (max-width: 380px) {
  .left_img {
    width: 140px !important;
    height: 130px !important;
  }

  .imageFile {
    font-size: 10px;
    width: 150px;
  }
}
</style>
