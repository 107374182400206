<template>
    <div>
        <div class="filter-box">
            <div class="d-flex mb-4 px-3 px-sm-4">
                <b-form-group class="text-left label font-weight-normal search-field mb-0">
                    <b-input-group class="username-input-field">
                        <b-form-input type="text" placeholder="Search" v-model="filterFields.search" class="unit f-12"
                            @keyup.enter="getRecurringScheduleData()"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="primary" @click="getRecurringScheduleData()">
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
        </div>
        <b-table class="mt-2" responsive :fields="fields" :items="items" hover :current-page="currentPage"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy"
            :sticky-header="'calc(100vh - 330px)'"
            :show-empty="emptyTable">
            <template #cell(action)="data">
                <svg @click="recordID = data.item.id" class="mr-4 cursor-pointer" width="21" height="21" viewBox="0 0 21 21"
                    fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.9452 17.9664L1.71484 19.7109L3.45934 13.4806L14.8678 2.12751C14.9967 1.99564 15.1508 1.89086 15.3207 1.81932C15.4907 1.74779 15.6733 1.71094 15.8578 1.71094C16.0422 1.71094 16.2248 1.74779 16.3948 1.81932C16.5647 1.89086 16.7188 1.99564 16.8477 2.12751L19.2983 4.59196C19.428 4.72066 19.531 4.87381 19.6014 5.04251C19.6717 5.21123 19.7078 5.3922 19.7078 5.57497C19.7078 5.75774 19.6717 5.93871 19.6014 6.10743C19.531 6.27615 19.428 6.42928 19.2983 6.55798L7.9452 17.9664Z"
                        stroke="#2CCE89" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <svg v-if="allowDeleteRecord" @click="showDeletePopup(data.item.id)" class="cursor-pointer" width="19" height="20"
                    viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                        stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                        d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                        stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </template>
        </b-table>
        <div class="entries-pagination px-0 px-sm-2">
            <div class="d-flex align-items-center flex-wrap">
                <div class="text-left show-entries mr-md-3 pl-3 pl-md-0 ml-md-0">
                    <b-form-select v-model="perPage" :options="pageOptions" />
                </div>
                <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
                <div class="pagination">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <AddRecurringSchedule @close="$emit('close')" :allowAddRecurringSchedule="allowAddRecurringSchedule"
            @recordAdded="getRecurringScheduleData()" />
        <EditRecurringSchedule :recordID="recordID" @resetEditRecord="recordID = null"
            @recordUpdated="getRecurringScheduleData()" 
            :allowUpdateRecord="allowUpdateRecord"
            />
        <DeleteModal v-if="showDeleteModal" @confirm="deleteItem($event)" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import AddRecurringSchedule from './AddRecurringSchedule.vue';
import DeleteModal from "@/components/DeleteModal.vue";
import EditRecurringSchedule from './EditRecurringSchedule.vue';
export default {
    name: "RecurringSchedule",
    components: { AddRecurringSchedule, DeleteModal, EditRecurringSchedule },
    props: ["allowAddRecurringSchedule"],
    data() {
        return {
            emptyTable: false,
            recordID: null,
            perPage: 10,
            fields: [
                {
                    key: "description",
                    label: "Description",
                    sortable: true,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "interval",
                    label: "Interval",
                    sortable: true,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "units",
                    label: "Units",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "action",
                    label: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
            items: [],
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
            positionText: "",
            rows: 0,
            currentPage: 1,
            itemId: null,
            showDeleteModal: false,
            isBusy: false,
            sortBy: undefined,
            sortDesc: undefined,
            filterFields: {
                search: ''
            }
        }
    },
    computed: {
        ...mapGetters(["userCompany","rolePermissions"]),
        allowUpdateRecord() {
            return this.rolePermissions && this.rolePermissions['Maintenance'] && this.rolePermissions['Maintenance'][1].allow
        },
        allowDeleteRecord() {
            return this.rolePermissions && this.rolePermissions['Maintenance'] && this.rolePermissions['Maintenance'][3].allow
        }
    },
    watch: {
        perPage: {
            async handler() {
                await this.getRecurringScheduleData();
            },
            immediate: true,
        },
        currentPage: {
            async handler() {
                await this.getRecurringScheduleData();
            },
        },
        sortBy: {
            async handler() {
                await this.getRecurringScheduleData();
            },
        },
        sortDesc: {
            async handler() {
                await this.getRecurringScheduleData();
            },
        },
        filterFields: {
            handler() {
                this.currentPage = 1
            },
            deep: true
        },
    },
    methods: {
        ...mapActions(['getRecurringSchedule', 'deleteRecurringSchedule', "setLoader", "setNotification"]),
        showDeletePopup(id) {
            console.log(id)
            this.showDeleteModal = true;
            this.itemId = id;
        },
        deleteItem(item) {
            if (item) {
                this.deleteRecord(this.itemId);
            }
            this.showDeleteModal = false;
        },
        async deleteRecord(id) {
            try {
                this.setLoader(true)
                await this.deleteRecurringSchedule(`${id}/`);
                await this.setNotification({
                    msg: "Recurring Schedule successfully deleted!!",
                    type: "success",
                    color: "green",
                });
                await this.getRecurringScheduleData()
                this.setLoader(false)
            } catch (error) {
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        async getRecurringScheduleData() {
            try {
                this.isBusy = true
                let url = ""
                Object.keys(this.filterFields).map((key) => {
                    if (this.filterFields[key] !== '') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=description&sort_order=desc&`
                }
                url += `page=${this.currentPage}&page_size=${this.perPage}`
                const data = await this.getRecurringSchedule(url);
                console.log(data)
                if (data.total_entries) {
                    this.rows = data.total_entries
                    this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} - ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
                }
                else {
                    this.rows = 0
                    this.positionText = `0 - 0 of 0`
                }
                this.items = []
                data.records.forEach((item) => {
                    this.items.push({
                        id: item.id,
                        description: item.description,
                        interval: item.interval_type === '2' ? item.interval.toLocaleString() + " mi" : item.interval + " Days",
                        units: item.total_units
                    })
                })
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
                this.isBusy = false
            }
            catch (e) {
                console.log(e)
                this.isBusy = false
            }
        }
    },
}
</script>