<template>
  <b-container fluid class="mt-2 px-1 px-sm-3 pb-3">
    <b-row>
      <b-col cols="" class="">
        <router-link to="/company">
          <div class="label mb-3 text-left back-arrow">
            <b-img :src="require('@/assets/back-arrow.svg')"></b-img>
          </div>
        </router-link>
        <div
          class="expiry-data d-flex justify-content-between align-items-center mb-4"
        >
          <h2>License Cart</h2>
        </div>

        <div class="cart position-relative bg-white rounded p-4">
          <b-row>
            <b-col lg="6" md="12" sm="12" cols="12">
              <b-row>
                <div class="w-100" v-if="LicenseOptions">
                  <div
                    class="px-0 w-100"
                    v-for="option in LicenseOptions"
                    :key="option.id"
                  >
                    <b-col cols="12" class="px-0">
                      <b-card
                        :class="
                          selectedLicense !== null &&
                          selectedLicense === option.id
                            ? 'mb-2 py-2 position-relative card active'
                            : 'mb-2 py-2 position-relative card'
                        "
                      >
                        <div
                          class="save position-absolute"
                          v-if="
                            discountedPer > 0 &&
                            selectedLicense !== null &&
                            selectedLicense === option.id
                          "
                        >
                          Save {{ discountedPer }}%
                        </div>
                        <b-card-text class="mb-0 my-auto">
                          <b-form-group
                            class="form-check d-flex align-items-center"
                            v-slot="{ ariaDescribedby }"
                          >
                            <b-form-radio
                              class="form-check-input position-absolute"
                              type="radio"
                              :value="option.id"
                              v-model="selectedLicense"
                              :aria-describedby="ariaDescribedby"
                            >
                            </b-form-radio>
                            <label class="form-check-label" for="radio1">
                              ${{ option.unit_amount_usd }} /
                              {{ option.duration_years ? "Year" : "Month" }}
                              <br />
                              <span
                                class="billed"
                                v-if="option.duration_years >= 2"
                                >Billed every
                                {{ option.duration_years }} years</span
                              >
                              <span
                                class="billed"
                                v-else-if="option.duration_months"
                                >Billed every
                                {{ option.duration_months }} month</span
                              >
                              <span class="billed" v-else>Billed year</span>
                            </label>
                          </b-form-group>
                        </b-card-text>
                        <b-card-text class="mb-0">
                          <div class="input-group">
                            <b-button
                              type="button"
                              class="btn-number button-black"
                              data-type="plus"
                              data-field="quant[1]"
                              @click="addQuantity(option.id)"
                              :disabled="option.quantity == 30"
                            >
                              <b-icon icon="plus">+</b-icon>
                            </b-button>
                            <input
                              type="number"
                              name="quant[1]"
                              class="form-control input-number"
                              min="8"
                              max="30"
                              v-model="option.quantity"
                            />

                            <b-button
                              type="button"
                              class="btn-number button-black"
                              :disabled="option.quantity == 0"
                              data-type="minus"
                              data-field="quant[1]"
                              @click="removeQuantity(option.id)"
                            >
                              <b-icon icon="dash">-</b-icon>
                            </b-button>
                          </div>
                        </b-card-text>
                        <b-card-text
                          class="mb-0 price"
                          v-if="option.duration_years"
                        >
                          ${{
                            option.unit_amount_usd *
                            option.quantity *
                            option.duration_years
                          }}.00
                        </b-card-text>
                        <b-card-text class="mb-0 price" v-else>
                          ${{
                            option.unit_amount_usd *
                            option.quantity *
                            option.duration_months
                          }}.00
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </div>
                </div>
              </b-row>
              <b-form class="d-flex my-3">
                <b-form-input
                  id="inline-form-input-name"
                  class="mb-sm-0 mr-3 code"
                  placeholder="Enter Discount Code"
                  v-model="couponCode"
                  :disabled="discountedPer > 0"
                ></b-form-input>
                <b-button
                  variant="primary"
                  class="apply"
                  @click="applyCoupon"
                  :disabled="discountedPer > 0"
                  >{{ discountedPer > 0 ? "Applied" : "Apply" }}</b-button
                >
              </b-form>
              <div
                class="total pt-3 pb-2 d-flex justify-content-between align-items-center"
              >
                <div class="total_heading">Subtotal</div>
                <div class="price">${{ subTotal }}.00</div>
              </div>
              <div
                class="discount pb-3 d-flex justify-content-between align-items-center"
              >
                <div class="disc_heading">Discount</div>
                <div class="price">
                  ${{ (discountedPer / 100) * subTotal }}.00
                </div>
              </div>
              <div
                class="total_price py-3 d-flex justify-content-between align-items-center"
              >
                <div class="heading">Total</div>
                <div class="price">${{ totalPrice }}.00</div>
              </div>

              <div class="secure mt-4 mb-3 mx-auto">
                <b-img
                  class="ml-2"
                  :src="require('@/assets/secure.png')"
                ></b-img>
                Guaranteed <span> safe & secure</span> checkout
                <b-img
                  class="ml-2 mt-0"
                  :src="require('@/assets/stripe.png')"
                ></b-img>
              </div>
              <div class="payment_method">
                <b-img :src="require('@/assets/payment-method.png')"></b-img>
              </div>
            </b-col>
            <b-col lg="6" md="12" sm="12">
              <div class="pay p-4">
                <p><span> Pay with card</span></p>
                <div class="error">{{ stripeValidationError }}</div>
                <b-form>
                  <h4 class="card-title text-left text-white pt-4 pb-3">
                    Card Details
                  </h4>
                  <b-form-group label="Name on Card:">
                    <b-form-input
                      type="text"
                      required
                      v-model="cardHoldername"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Card Number:">
                    <div id="card-number-element" class="form-control p-2">
                      <!-- A Stripe Element will be inserted here. -->
                    </div>
                  </b-form-group>

                  <b-row>
                    <b-col lg="6" md="6" sm="12">
                      <b-form-group label="Expiration Date:">
                        <div id="card-expiry-element" class="form-control p-2">
                          <!-- A Stripe Element will be inserted here. -->
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                      <b-form-group label="CCV:">
                        <div id="card-cvc-element" class="form-control p-2">
                          <!-- A Stripe Element will be inserted here. -->
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>

                <div
                  class="total_price py-3 d-flex justify-content-between align-items-center"
                >
                  <div class="heading text-white">Total</div>
                  <div class="price text-white">${{ totalPrice }}.00</div>
                </div>
                <div class="checkout text-right">
                  <b-button
                    class="bg-white"
                    @click="checkout"
                    :disabled="isLoading || totalPrice <= 0"
                    >Checkout <b-icon icon="arrow-right"></b-icon
                  ></b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "License-cart",
  data() {
    return {
      stripe: null,
      LicenseOptions: [],
      selectedLicense: null,
      isCouponApplied: false,
      discountedPer: 0,
      couponCode: "",
      elements: null,
      cardHoldername: "",
      cardNumberElement: undefined,
      cardExpiryElement: undefined,
      cardCVCElement: undefined,
      stripeValidationError: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["licenseOptions", "coupons", "company"]),
    subTotal: function () {
      var subtotal = 0;
      this.LicenseOptions.map((opt) => {
        if (this.selectedLicense !== null && this.selectedLicense === opt.id) {
          if (opt.duration_years) {
            subtotal += opt.unit_amount_usd * opt.quantity * opt.duration_years;
          } else {
            subtotal +=
              opt.unit_amount_usd * opt.quantity * opt.duration_months;
          }
        }
      });
      return subtotal;
    },
    totalPrice: function () {
      // IF DISCOUNT EXIST

      if (this.discountedPer > 0) {
        return this.subTotal - this.subTotal * (this.discountedPer / 100);
      } else {
        return this.subTotal;
      }
    },
  },
  async mounted() {
    // GET LISENCE OPTIONS
    await this.getLicenseOptions();

    // GET COUPONS
    await this.getcoupons();
    await this.getCompany();
    this.stripe = window.Stripe(`${process.env.VUE_APP_STRIPE_KEY}`);
    var style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: "16px",
        color: "white",
      },
    };
    this.LicenseOptions = this.licenseOptions.map((opt) => {
      return { ...opt, quantity: 1 };
    });
    (this.elements = this.stripe.elements()),
      (this.cardNumberElement = this.elements.create("cardNumber", {
        showIcon: true,
        style: style,
      }));
    this.cardNumberElement.mount("#card-number-element");
    this.cardExpiryElement = this.elements.create("cardExpiry", {
      style: style,
    });
    this.cardExpiryElement.mount("#card-expiry-element");
    this.cardCVCElement = this.elements.create("cardCvc", { style: style });
    this.cardCVCElement.mount("#card-cvc-element");
    this.cardNumberElement.on("change", this.setValidationError);
    this.cardExpiryElement.on("change", this.setValidationError);
    this.cardCVCElement.on("change", this.setValidationError);
  },
  methods: {
    ...mapActions([
      "getLicenseOptions",
      "getcoupons",
      "stripeCheckout",
      "setNotification",
      "getCompany",
    ]),
    addQuantity(id) {
      this.LicenseOptions = this.LicenseOptions.map((opt) => {
        if (opt.id == id) {
          return {
            ...opt,
            quantity: opt.quantity + 1,
          };
        } else {
          return opt;
        }
      });
    },
    removeQuantity(id) {
      this.LicenseOptions = this.LicenseOptions.map((opt) => {
        if (opt.id == id) {
          return {
            ...opt,
            quantity: opt.quantity - 1,
          };
        } else {
          return opt;
        }
      });
    },
    setValidationError(event) {
      this.stripeValidationError = event.error ? event.error.message : "";
    },
    applyCoupon() {
      // check if coupon exist
      this.coupons.map((opt) => {
        if (this.couponCode.toLowerCase() === opt.name.toLowerCase()) {
          this.discountedPer = opt.percent_off;
          this.couponCode = opt.name;
          this.isCouponApplied = true;
        }
      });
    },

    async checkout() {
      this.isLoading = true;
      if (!this.cardHoldername.length) {
        this.isLoading = false;
        return (this.stripeValidationError = "Invalid Card Holder name");
      }
      const data = {
        name: this.cardHoldername,
      };
      await this.stripe
        .createToken(this.cardNumberElement, data)
        .then(async (result) => {
          if (result.error) {
            this.isLoading = false;
            this.stripeValidationError = result.error.message;
          } else {
            const selectedItems = [];
            this.LicenseOptions.map((opt) => {
              if (
                this.selectedLicense !== null &&
                this.selectedLicense === opt.id
              ) {
                selectedItems.push({
                  id: opt.id,
                  quantity: opt.quantity,
                });
              }
            });
            console.log(this.company);
            var stripeObject = {
              email: this.company[0].billing_email,
              license: selectedItems,
              couponCode: this.couponCode,
              discountedPer: this.discountedPer,
              isCouponApplied: this.isCouponApplied,
              stripeToken: result.token.id,
            };
            console.log(stripeObject);
            try {
              const response = await this.stripeCheckout(stripeObject);
              if (response.error) {
                throw response.error;
              } else if (
                response.status &&
                response.status.includes("Invalid")
              ) {
                throw response.status;
              }
              if (response) {
                await this.setNotification({
                  msg: "Successfully Purchased",
                  type: "success",
                  color: "green",
                });
                localStorage.removeItem("licenseExpired");
                this.$router.push("/company");
              }
            } catch (error) {
              console.log(error);
              await this.setNotification({
                msg: "Failed to Purchased. " + error,
                type: "error",
                color: "red",
              });
            }

            this.cardHoldername = "";
            (this.discountedPer = 0), (this.couponCode = "");
            this.selectedLicense = [];
            this.cardNumberElement.clear();
            this.cardExpiryElement.clear();
            this.cardCVCElement.clear();
            this.isLoading = false;
          }
        });
    },
  },
};
</script>
<style>
.back-arrow {
  background-color: #fff;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.label img {
  cursor: pointer;
}
.cart .card {
  border: 1px solid #e5e5e5 !important;
  border-radius: 5px;
  height: 71px;
  cursor: pointer;
  padding: 10px !important;
}
.cart .card.active {
  border: 1px solid var(--btn-bg-color) !important;
}
.cart .card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 !important;
}

/* .form-check-input .custom-control-label::before{
  display: none;
}
.form-check-input .custom-control-label::after{
  display: none;
} */
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: unset !important;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: unset;
}
.form-check-input .custom-control-label::before,
.form-check-input .custom-control-label::after {
  height: 20px;
  width: 20px;
}
.form-check-input.custom-control {
  min-height: 20px;
  min-width: 20px;
}
.form-check-input .custom-control-input {
  left: 0;
  bottom: 0;
  right: 0;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  border-radius: 50%;
}
.cart .form-check-input:checked[type="radio"] {
  background-image: none !important;
  background-image: url("../../assets/radio-circile.png") !important;
  box-shadow: inset 0 0 0px 4px #ffffff !important;
}
.cart .form-check-input:checked[type="radio"] {
  background-image: none;
}
.cart .form-check-input:checked {
  background-color: var(--btn-bg-color);
}
.form-check-input:active {
  filter: brightness(100%) !important;
}
.cart .form-check-label {
  font-size: var(--small);
  color: var(--black);
  font-weight: 700;
  text-align: left;
  margin-left: 8px;
  line-height: 18px;
  width: 115px;
  cursor: pointer;
}
.cart .form-check-label .billed {
  font-size: var(--extrasmall);
  color: var(--black);
  opacity: 0.6;
  font-weight: 400;
}
.cart .save {
  top: -10px;
  left: 10px;
  background: var(--btn-bg-color);
  color: #fff;
  border-radius: 5px;
  padding: 0px 10px;
  font-size: var(--extrasmall);
}
.card .price {
  font-size: 16px;
  font-weight: 700;
}
.input-group {
  height: 40px;
}
.input-group input {
  height: 29px !important;
  text-align: center;
  padding: 0;
  background-color: #f5f5f5;
  font-size: var(--extrasmall);
  border: none;
}
.cart .card-body .card-text input.form-control {
  border: none !important;
}
.input-group input:focus {
  background-color: #f5f5f5;
}
.input-group .btn-number {
  width: 28px;
  height: 29px;
  padding: 0;
  background-color: transparent !important;
}
.code {
  background-color: #f5f5f5 !important;
  border-color: #e3e3e3 !important;
}
.code::placeholder {
  font-size: var(--small);
  color: rbg(0 0 0 / 10%);
}
.code:focus,
.code:active {
  box-shadow: unset;
  border-color: #e3e3e3 !important;
}
.apply {
  background-color: var(--btn-bg-color) !important;
  width: 115px;
}
.total {
  border-top: 1px solid rgb(0 0 0 / 10%);
}
.discount {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}
.total .price,
.discount .price {
  font-weight: 700;
  font-size: var(--small);
}
.total_heading,
.disc_heading {
  font-size: var(--small);
  color: rgb(0 0 0 / 40%);
}
.total_price {
  font-weight: 700;
  font-size: var(--small);
}
.secure {
  font-size: var(--extrasmall);
  width: 330px;
}
.secure img {
  margin-top: -3px;
}
.secure span {
  font-weight: 700;
}
.pay {
  background-color: var(--btn-bg-color);
  border-radius: 5px;
  width: 450px;
  position: absolute;
  top: -45px;
  right: 17px;
}
.pay button {
  background-color: #000000;
  width: 100%;
}
.pay button:hover,
.pay button:focus,
.pay button:active {
  background-color: #000000;
}
.pay p {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #fff;
  opacity: 0.6;
  line-height: 0.1em;
  margin: 30px 0px;
}
.pay p span {
  background-color: var(--btn-bg-color);
  padding: 0px 10px;
  color: #fff;
  font-weight: 100;
  font-size: var(--small);
}
.card-title {
  font-size: 18px;
}
.pay form {
  border-bottom: 1px solid rgb(255 255 255 / 10%);
}
.pay .form-control {
  background-color: rgba(245, 245, 245, 0.1) !important;
  border: 1px solid rgba(227, 227, 227, 0.3);
  color: #fff;
  margin-bottom: 20px;
}
.pay .form-control::placeholder {
  font-size: var(--small);
}
.pay .form-control:focus {
  color: #fff;
  border-color: rgba(227, 227, 227, 0.3);
}
.pay .col-form-label {
  text-align: left;
  color: #fff;
  opacity: 1;
  font-size: var(--small);
  font-weight: 600;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.checkout button {
  color: var(--btn-bg-color) !important;
  width: 149px;
  height: 40px;
  font-size: var(--small);
  font-weight: 500;
}
.checkout button .b-icon :not(svg) {
  color: var(--btn-bg-color) !important;
}
/* .b-icon :not(svg){
        color: var(--black);
    } */
.error {
  color: #c30000;
  margin-top: -18px;
  margin-bottom: -20px;
}
.button-black svg {
  fill: black;
}
@media (max-width: 1300px) {
  .pay {
    width: 400px;
  }
  .cart .card-body {
    flex-wrap: wrap;
  }
}
@media (max-width: 1200px) {
  .pay {
    width: 330px;
  }
  .cart .card {
    padding-top: 15px !important;
    padding-bottom: 0px !important;
    height: auto;
  }
  .cart .card .card-text {
    margin-bottom: 15px !important;
  }
}
@media (max-width: 991px) {
  .cart .card {
    height: auto;
    width: auto;
  }
  .pay {
    position: static !important;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
    transform: translate(0px, 0px);
    margin-top: 30px;
  }
}
@media (max-width: 500px) {
  .cart .form-check-label {
    width: auto;
  }
  .secure {
    width: auto;
  }
  .pay.p-4 {
    padding: 0.5rem !important;
  }
  .cart .card {
    padding: 5px;
  }
  .cart.position-relative {
    padding: 1rem 0.5rem !important;
  }
}
</style>
