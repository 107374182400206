<template>
  <b-container fluid class="mt-2 px-1 px-sm-3 pb-3">
    <b-row>
      <b-col cols="" class="equipment-page">
        <b-row>
          <b-col cols="">
            <div class="label mb-3 text-left">
              <router-link to="/">dashboard</router-link> /
              <router-link to="/equipment-list">equipment list</router-link> /
              <span> equipment</span>
            </div>
            <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
              <h2>Equipment</h2>
            </div>
            <hr class="mt-0" />
            <div class="tags-section d-flex mb-3 align-items-center flex-wrap">
              <div>Tags:</div>
              <v-chip class="equipment-chips ml-2" close @click:close="removeTag(tag)"
                v-for="(tag, index) in existingTags" :key="index">
                {{ tag.add_tag }}
              </v-chip>
              <div class="cursor-pointer ml-2" @click="showTagsModal = true">
                + Add Tags
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <div class="data-table bg-white px-4 rounded pt-4">
              <div class="object_detail">
                <div class="num d-flex align-items-center justify-content-center justify-content-sm-start mb-3 flex-wrap">
                  <p class="mb-0 font-weight-bold">
                    {{ currentEquipment.unit_no }}
                  </p>
                  <p class="mb-0 ml-2" :class="currentEquipment.status === 'Active'
                      ? 'active'
                      : currentEquipment.status === 'Assigned'
                        ? 'assigned'
                        : 'inactive'
                    ">
                    {{ currentEquipment.status }}
                  </p>
                  <div class="mx-auto ml-sm-auto mr-sm-0 mt-2 mt-sm-0" v-if="qrCodeUrl">
                    <button
                      class="btn equipment text-white"
                      @click="generateEquipmentQRCode(currentEquipment.unit_no,currentEquipment.unit_no + '-qrcode',qrCodeUrl)"
                      >
                      Generate QR Code</button>
                  </div>
                </div>
                <b-row>
                  <b-col sm="12">
                    <b-row>
                      <b-col lg="3" md="3" sm="12">
                        <b-form class="mb-3">
                          <b-form-group label="Unit #:" class="text-left">
                            <b-form-input type="text" placeholder="Enter Unit #:" required
                              v-model="currentEquipment.unit_no"></b-form-input>
                            <p v-if="errors && errors.unit_no" class="field-error">
                              {{ errors.unit_no[0] }}
                            </p>
                          </b-form-group>
                        </b-form>
                      </b-col>
                      <qriously v-if="currentEquipment" class="d-none" :value="'https://app.digitalpermitbook.com/search-equipment?equipment=' +
                        currentEquipment.id
                        " :size="200" ref="qrcode" />
                      <b-col lg="3" md="3" sm="12" class="text-left mb-3">
                        <label>Active</label>
                        <b-form-checkbox v-if="currentEquipment.status === 'Assigned'" :checked="true" disabled
                          name="check-button" switch size="lg" class="ml-2"></b-form-checkbox>
                        <b-form-checkbox v-else v-model="equipmentStatus" name="check-button" switch
                          @change="changeEquipmentStatus" size="lg" class="ml-2"></b-form-checkbox>
                        <p v-if="errors && errors.status" class="field-error">
                          {{ errors.status[0] }}
                        </p>
                      </b-col>
                      <b-col lg="3" md="3" sm="12" class="text-left select_arrow mb-3">
                        <label class="state">Type</label>
                        <b-form-select v-model="currentEquipment.type" :options="typeOptions"></b-form-select>
                        <p v-if="errors && errors.type" class="field-error">
                          {{ errors.type[0] }}
                        </p>
                      </b-col>
                      <b-col lg="3" md="3" sm="12" class="text-left select_arrow mb-3">
                        <b-form-group label="Subtype" class="text-left mb-3">
                          <b-form-input type="text" placeholder="Enter Year" required
                            v-model="currentEquipment.subtype"></b-form-input>
                          <p v-if="errors && errors.year" class="field-error">
                            {{ errors.subtype[0] }}
                          </p>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="3" md="4" sm="6">
                        <b-form-group label="Year" class="text-left mb-3">
                          <b-form-input type="text" placeholder="Enter Year" required
                            v-model="currentEquipment.year"></b-form-input>
                          <p v-if="errors && errors.year" class="field-error">
                            {{ errors.year[0] }}
                          </p>
                        </b-form-group>
                      </b-col>
                      <b-col lg="3" md="4" sm="6" class="mb-3">
                        <b-form-group label="Make" class="text-left">
                          <b-form-input type="text" placeholder="Enter Make" required
                            v-model="currentEquipment.make"></b-form-input>
                          <p v-if="errors && errors.make" class="field-error">
                            {{ errors.make[0] }}
                          </p>
                        </b-form-group>
                      </b-col>
                      <b-col lg="3" md="4" sm="6" class="mb-3">
                        <b-form-group label="Model" class="text-left">
                          <b-form-input type="text" placeholder="Enter Model" required
                            v-model="currentEquipment.model"></b-form-input>
                          <p v-if="errors && errors.model" class="field-error">
                            {{ errors.model[0] }}
                          </p>
                        </b-form-group>
                      </b-col>
                      <b-col lg="3" md="4" sm="6" class="text-left mb-3">
                        <b-form-group label="Vin #:" class="text-left">
                          <b-form-input type="text" placeholder="Enter Vin #:" required
                            v-model="currentEquipment.vin_no"></b-form-input>
                          <p v-if="errors && errors.vin_no" class="field-error">
                            {{ errors.vin_no[0] }}
                          </p>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col lg="3" md="3" sm="12" class="text-left mb-3">
                        <b-form-group label="Plate #:" class="text-left">
                          <b-form-input type="text" placeholder="Enter Plate #:" required
                            v-model="currentEquipment.plate_no"></b-form-input>
                          <p v-if="errors && errors.plate_no" class="field-error">
                            {{ errors.plate_no[0] }}
                          </p>
                        </b-form-group>
                      </b-col>
                      <b-col
                        lg="3"
                        md="3"
                        sm="12"
                        class="text-left select_arrow mb-3"
                      >
                        <label class="state">State</label>
                        <v-select
                          class="bg_white"
                          v-model="currentEquipment.state.id"
                          :options="states"
                          :reduce="(option) => option.id"
                        />
                        <p v-if="errors && errors.state" class="field-error">
                          {{ errors.state[0] }}
                        </p>
                      </b-col>
                      <!-- <b-col
                        lg="3"
                        md="3"
                        sm="12"
                        class="text-left select_arrow mb-3"
                      >
                        <label class="state">Country</label>
                        <v-select
                          class="bg_white"
                          v-model="currentEquipment.state.country"
                          :options="countries"
                          @option:selected="getStates"
                          :reduce="(option) => option.id"
                        />
                        <p v-if="errors && errors.state" class="field-error">
                          {{ errors.country[0] }}
                        </p>
                      </b-col> -->
                      <!-- <b-col lg="3" md="4" sm="6" class="text-left mt-3">
                                            <label for="company_plate" class="state">Company Plate</label>
                                            <b-form-checkbox v-model="currentEquipment.company_plate" name="check-button" switch size="lg"></b-form-checkbox>
                                        </b-col> -->
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="details bg-white p-4 mt-4 rounded">
              <h5 class="text-left">Details</h5>
              <b-row>
                <b-col lg="3" md="4" sm="6" class="mb-3">
                  <b-form-group label="Length" class="text-left">
                    <b-form-input type="number" placeholder="Enter Length" required @keypress="isNumber($event)"
                      v-model="currentEquipment.length"></b-form-input>
                    <p v-if="errors && errors.length" class="field-error">
                      {{ errors.length[0] }}
                    </p>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="6" class="mb-3">
                  <b-form-group label="Weight" class="text-left">
                    <b-form-input type="text" placeholder="Enter Weight" required @keypress="isNumber($event)"
                      v-model="currentEquipment.weight"></b-form-input>
                    <p v-if="errors && errors.weight" class="field-error">
                      {{ errors.weight[0] }}
                    </p>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="6" class="mb-3">
                  <b-form-group label="Number of Axles" class="text-left">
                    <b-form-input type="text" placeholder="Enter Number of Axles" required @keypress="isNumber($event)"
                      v-model="currentEquipment.number_of_axles"></b-form-input>
                    <p v-if="errors && errors.number_of_axles" class="field-error">
                      {{ errors.number_of_axles[0] }}
                    </p>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="6">
                  <b-form-group label="Color" class="text-left">
                    <b-form-input type="text" placeholder="Enter Color" required
                      v-model="currentEquipment.color"></b-form-input>
                    <p v-if="errors && errors.color" class="field-error">
                      {{ errors.color[0] }}
                    </p>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="6">
                  <b-form-group label="Value" class="text-left">
                    <b-form-input type="text" placeholder="Enter Value" required
                      v-model="currentEquipment.value"></b-form-input>
                    <p v-if="errors && errors.value" class="field-error">
                      {{ errors.value[0] }}
                    </p>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="6" class="mb-3">
                  <b-form-group label="Serial Number" class="text-left">
                    <b-form-input type="text" placeholder="Enter Serial Number"
                      v-model="currentEquipment.serial_number"></b-form-input>
                    <p v-if="errors && errors.serial_number" class="field-error">
                      {{ errors.serial_number }}
                    </p>
                  </b-form-group>
                </b-col>
                <b-col lg="3" md="4" sm="6" class="text-left select_arrow mb-3">
                  <label class="state">Fuel Type</label>
                  <b-form-select class="bg_white" v-model="currentEquipment.fuel_type"
                    :options="fuelOptions"></b-form-select>
                  <p v-if="errors && errors.fuel_type" class="field-error">
                    {{ errors.fuel_type[0] }}
                  </p>
                </b-col>
                <b-col lg="3" md="4" sm="6" class="text-left select_arrow">
                  <label class="state">Ownership</label>
                  <b-form-select class="bg_white" v-model="currentEquipment.ownership"
                    :options="ownershipOptions"></b-form-select>
                  <p v-if="errors && errors.ownership" class="field-error">
                    {{ errors.ownership[0] }}
                  </p>
                </b-col>
                <b-col lg="3" md="4" sm="6" class="text-left">
                  <label for="active-datepicker" class="state">Active Date</label>
                  <b-form-datepicker id="active-datepicker" class="mb-2" v-model="currentEquipment.active_date"
                    reset-button></b-form-datepicker>
                  <p v-if="errors && errors.active_date" class="field-error">
                    {{ errors.active_date[0] }}
                  </p>
                </b-col>
                <b-col lg="3" md="4" sm="6" class="text-left">
                  <label for="inactive-datepicker" class="state">Inactive Date</label>
                  <b-form-datepicker id="inactive-datepicker" class="mb-2" v-model="currentEquipment.inactivate_date"
                    reset-button></b-form-datepicker>
                  <p v-if="errors && errors.inactivate_date" class="field-error">
                    {{ errors.inactivate_date[0] }}
                  </p>
                </b-col>
               
              </b-row>
            </div>
          </b-col>
        </b-row>
        <!-- <b-row>
                <b-col cols="12">
                    <div class="details bg-white p-4 mt-4 rounded">
                        <h5 class="text-left">Groups</h5>
                        <b-form class="mt-2">
                            <b-row>
                                <b-col lg="3" md="6">
                                    <div class="text-left mb-3">
                                        <label class="state">Group 1</label>
                                        <b-form-select class="bg_white" v-model="dispatch_group1" :options="equipmentDispatchGroups" />
                                    </div>
                                </b-col>
                                <b-col lg="3" md="6">
                                    <div class="text-left mb-3">
                                        <label class="state">Group 2</label>
                                        <b-form-select class="bg_white" v-model="dispatch_group2" :options="equipmentDispatchGroups" />
                                    </div>
                                </b-col>

                            </b-row>
                        </b-form>
                    </div>
                </b-col>
            </b-row> -->
        <!-- <b-row>
                <b-col cols="12">
                    <div class="details specifications bg-white p-4 mt-4 rounded">
                        <h5 class="text-left">Specifications</h5>
                        <b-form>
                            <b-row>
                                <b-col lg="3" md="4" sm="6">
                                    <b-form-group label="Length" class="text-left">
                                        <b-form-input type="number" placeholder="Enter Length" required @keypress="isNumber($event)" v-model="currentEquipment.length"></b-form-input>
                                        <p v-if="errors && errors.length" class="field-error">
                                            {{ errors.length[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="3" md="4" sm="6">
                                    <b-form-group label="Width" class="text-left">
                                        <b-form-input type="number" placeholder="Enter Width" required @keypress="isNumber($event)" v-model="currentEquipment.width"></b-form-input>
                                        <p v-if="errors && errors.width" class="field-error">
                                            {{ errors.width[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="3" md="4" sm="6">
                                    <b-form-group label="Height" class="text-left">
                                        <b-form-input type="text" placeholder="Enter Height" required @keypress="isNumber($event)" v-model="currentEquipment.height"></b-form-input>
                                        <p v-if="errors && errors.height" class="field-error">
                                            {{ errors.height[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="3" md="4" sm="6">
                                    <b-form-group label="Weight" class="text-left">
                                        <b-form-input type="text" placeholder="Enter Weight" required @keypress="isNumber($event)" v-model="currentEquipment.weight"></b-form-input>
                                        <p v-if="errors && errors.weight" class="field-error">
                                            {{ errors.weight[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-form>
                        <b-form class="mt-3">
                            <b-row>
                                <b-col lg="3" md="4" sm="6" class="text-left">
                                    <b-form-group label="Capacity" class="text-left">
                                        <b-form-input type="text" placeholder="Enter Capacity" required @keypress="isNumber($event)" v-model="currentEquipment.capacity"></b-form-input>
                                        <p v-if="errors && errors.capacity" class="field-error">
                                            {{ errors.capacity[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="3" md="4" sm="6" class="text-left">
                                    <b-form-group label="Gross Weight" class="text-left">
                                        <b-form-input type="text" placeholder="Enter Gross Weight" required @keypress="isNumber($event)" v-model="currentEquipment.gross_weight"></b-form-input>
                                        <p v-if="errors && errors.gross_weight" class="field-error">
                                            {{ errors.gross_weight[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="3" md="4" sm="6">
                                    <b-form-group label="Number of Axles" class="text-left">
                                        <b-form-input type="text" placeholder="Enter Number of Axles" required @keypress="isNumber($event)" v-model="currentEquipment.number_of_axles"></b-form-input>
                                        <p v-if="errors && errors.number_of_axles" class="field-error">
                                            {{ errors.number_of_axles[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                                <b-col lg="3" md="4" sm="6" class="text-left select_arrow">
                                    <label class="state">Fuel Type</label>
                                    <b-form-select class="bg_white" v-model="currentEquipment.fuel_type" :options="fuelOptions"></b-form-select>
                                  
                                    <p v-if="errors && errors.fuel_type" class="field-error">
                                        {{ errors.fuel_type[0] }}
                                    </p>
                                </b-col>
                            </b-row>
                        </b-form>
                    </div>
                </b-col>
            </b-row> -->
        <!-- <b-row>
                <b-col cols="12">
                    <div class="details bg-white p-4 mt-4 rounded">
                        <h5 class="text-left">Compliance</h5>
                        <b-row>
                            <b-col lg="3" md="4" sm="6" class="text-left">
                                <label for="co-insurance" class="state">Co. Insurance</label>
                                <b-form-checkbox v-model="currentEquipment.co_insurance" name="check-button" switch size="lg"></b-form-checkbox>
                            </b-col>
                            <b-col lg="3" md="4" sm="6" class="text-left">
                                <label for="co-insurance" class="state">PD. Insurance</label>
                                <b-form-checkbox v-model="currentEquipment.pd_insurance" name="check-button" switch size="lg"></b-form-checkbox>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row> -->
        <!-- <b-row>
                <b-col cols="12">
                    <div class="details expiry_data bg-white p-4 mt-4 rounded">
                        <h5 class="text-left">Expiry Data</h5>
                        <b-form>
                            <b-row>
                                <b-col lg="3" md="4" sm="6" class="text-left">
                                    <label for="plate-datepicker" class="state">Plate</label>
                                    <b-form-datepicker id="plate-datepicker" class="mb-2" v-model="currentEquipment.plate" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.plate" class="field-error">
                                        {{ errors.plate[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="4" sm="6" class="text-left">
                                    <label for="inspection-datepicker" class="state">Inspection</label>
                                    <b-form-datepicker id="inspection-datepicker" class="mb-2" v-model="currentEquipment.inspection" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.inspection" class="field-error">
                                        {{ errors.inspection[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="4" sm="6" class="text-left">
                                    <label for="insurance-datepicker" class="state">Insurance</label>
                                    <b-form-datepicker id="insurance-datepicker" class="mb-2" v-model="currentEquipment.insurance" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.insurance" class="field-error">
                                        {{ errors.insurance[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="4" sm="6" class="text-left">
                                    <label for="ny-permit-datepicker" class="state">NY Permit</label>
                                    <b-form-datepicker id="ny-permit-datepicker" class="mb-2" v-model="currentEquipment.NY_permit" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.NY_permit" class="field-error">
                                        {{ errors.NY_permit[0] }}
                                    </p>
                                </b-col>
                            </b-row>
                        </b-form>
                        <b-form class="mt-3">
                            <b-row>
                                <b-col lg="3" md="4" sm="6" class="text-left">
                                    <label for="permit1-datepicker" class="state">Permit 1</label>
                                    <b-form-datepicker id="permit1-datepicker" class="mb-2" v-model="currentEquipment.permit1" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.permit1" class="field-error">
                                        {{ errors.permit1[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="4" sm="6" class="text-left">
                                    <label for="permit2-datepicker" class="state">Permit 2</label>
                                    <b-form-datepicker id="permit2-datepicker" class="mb-2" v-model="currentEquipment.permit2" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.permit2" class="field-error">
                                        {{ errors.permit2[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="4" sm="6" class="text-left">
                                    <label for="permit3-datepicker" class="state">Permit 3</label>
                                    <b-form-datepicker id="permit3-datepicker" class="mb-2" v-model="currentEquipment.permit3" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.permit3" class="field-error">
                                        {{ errors.permit3[0] }}
                                    </p>
                                </b-col>
                                <b-col lg="3" md="4" sm="6" class="text-left">
                                    <label for="permit4-datepicker" class="state">Permit 4</label>
                                    <b-form-datepicker id="permit4-datepicker" class="mb-2" v-model="currentEquipment.permit4" reset-button></b-form-datepicker>
                                    <p v-if="errors && errors.permit4" class="field-error">
                                        {{ errors.permit4[0] }}
                                    </p>
                                </b-col>
                            </b-row>
                        </b-form>
                    </div>
                </b-col>
            </b-row> -->
        <div class="data-table bg-white p-4 rounded mt-4">
          <div class="table_row">
            <div class="d-flex justify-content-between align-items-center flex-wrap">
              <h4>Documents</h4>
              <b-button @click="addDocument" class="export equipment d-flex justify-content-center align-items-center mb-3"
              variant="primary"
              >
                  <b-icon icon="plus" aria-hidden="true"></b-icon>
                  <p class="mb-0 ml-2 text-white">Add Document</p>
                </b-button>
              
            </div>

            <b-table responsive :fields="fields" :items="items" hover :per-page="perPage"
              :current-page="currentPage">
              <template #cell(name)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <a :href="`#${data.value
                  .replace(/[^a-z]+/i, '-')
                  .toLowerCase()}`">{{ data.value }}</a>
              </template>
              <template #cell(description)="data">
                {{ data.value && data.value !== "null" ? data.value : "-" }}
              </template>
              <template #cell(expiry_date)="data">
                <span v-html="data.value"></span>
              </template>
              <template #cell(public)="data">
                <b-form-checkbox v-model="data.value" name="check-button" switch @change="
                  updateDocumentOnToggle(data.item.id, 'public', data.value)
                  " size="lg" class="ml-2" />
              </template>
              <template #cell(driver)="data">
                <b-form-checkbox v-model="data.value" name="check-button" switch @change="
                  updateDocumentOnToggle(data.item.id, 'driver', data.value)
                  " size="lg" class="ml-2" />
              </template>
              <template #cell(action)="data">
                <b-icon v-if="data.item.link" style="fill: #2D69F6" class="mr-4" icon="eye-fill"
                  @click="openLink(data.item.pk)" />
                <b-icon style="fill: limegreen" class="mr-4" icon="pencil" @click="editDocument(data.item.id)" />
                <b-icon style="fill:#FF0000" icon="trash-fill" @click="showPopup(data.item.pk)" />
              </template>
            </b-table>
            <div class="entries-pagination mb-5">
              <div class="d-flex align-items-center justify-content-between flex-wrap">
                <div class="text-left show-entries mr-md-3 pl-3 pl-md-0 ml-md-0">
                  <label for="" class="ml-0">Show</label>
                  <b-form-select v-model="perPage" :options="pageOptions" />
                  <label for="" class="me-0">entries</label>
                </div>
                <p class="my-0 pr-3 pr-md-0 mr-md-1">{{ positionText }}</p>
                <div class="pagination">
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table"></b-pagination>
                </div>
              </div>
            </div>
            <div class="ml-2 d-flex align-items-center" :class="$store.state.user.user &&
                $store.state.user.user.team_member.role === 'Admin'
                ? 'justify-content-between'
                : 'justify-content-end'
              ">
              <div v-if="$store.state.user.user &&
                $store.state.user.user.team_member.role === 'Admin'
                ">
                <b-button class="mt-3 mr-3" variant="danger" @click="showEquipmentModal = true">Delete</b-button>
              </div>
              <div class="table_btn">
                <b-button class="mt-3 mr-3 btn close-btn" href="/equipment-list">Close</b-button>
                <b-button class="mt-3 btn save" variant="primary" @click="updateEquipmentData">Save</b-button>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <!-- Modal -->
    <b-modal id="modal-center" centered hide-footer
      @shown="resetField" no-close-on-backdrop
      no-close-on-esc
      >
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          {{ editDocumentFlag ? "Edit Document" : "Add Document" }}
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <div class="d-block text-center">
        <b-row>
          <b-col lg="6" md="8" sm="12">
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Date</label>
              <b-form-datepicker id="modal-datepicker" class="" v-model="documentData.date"
                reset-button></b-form-datepicker>
              <p v-if="errors && errors.date" class="field-error">
                {{ errors.date[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label for="modal-expiry-date" class="state">Expiry Date</label>
              <b-form-datepicker id="modal-expiry-date" class="" v-model="documentData.expiry_date"
                reset-button></b-form-datepicker>
              <p v-if="errors && errors.expiry_date" class="field-error">
                {{ errors.expiry_date[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label class="state">Description</label>
              <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                v-model="documentData.description"></b-form-textarea>
              <p v-if="errors && errors.description" class="field-error">
                {{ errors.description[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label class="state">Driver</label>
              <b-form-checkbox v-model="documentData.driver" name="check-button" switch size="lg" class="ml-2" />
            </div>
            <div class="text-left">
              <label class="state">Public:</label>
              <b-form-checkbox v-model="documentData.public" class="ml-2" name="public-button" switch size="lg" />
            </div>
          </b-col>
          <b-col lg="6" md="8" sm="12">
            <div class="upload-btn-wrapper">
              <b-img :src="require('@/assets/drop.svg')"></b-img>
              <button v-if="!fileName" class="foo">
                Drop your document here, or browse
              </button>
              <button v-else class="foo">{{ fileName }}</button>
              <p v-if="fileName" class="mb-0 field-error cursor-pointer font-12 position-relative error-button"
                @click="removeFile()">
                Delete
              </p>
              <input ref="docFile" type="file" name="docFile" v-on:change="handleFileUpload"
                accept=".pdf, .png, .jpg, .jpeg" />
              <p v-if="errors && errors.document" class="field-error">
                {{ errors.document[0] }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button class="mt-3 mr-3 btn cancel" block @click="$bvModal.hide('modal-center')">Cancel</b-button>
        <b-button class="mt-3 btn save mx-0" variant="primary" @click="sendDocumentData">Save</b-button>
      </div>
    </b-modal>
    <DeleteModal v-if="showDocumentModal" @confirm="deleteDocumentItem($event)" />
    <DeleteModal v-if="showEquipmentModal" @confirm="deleteEquipmentItem($event)"
      :message="'Do you want to delete this Equipment?'" />
    <AddTags v-if="showTagsModal" @confirmTags="addTag($event)" :existingTags="currentEquipment.tags" :type="'Equipment'"/>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "../../router";
import DeleteModal from "@/components/DeleteModal.vue";
import AddTags from "@/components/Equipment/AddTags.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as moment from "moment-timezone";
import generateQRCode from "../../services/generateEquipmentQRCode";
export default {
  name: "equipment",
  components: {
    DeleteModal,
    vSelect,
    AddTags,
  },
  data() {
    return {
      states: [],
      countries: [],
      selected: null,
      checked: false,
      dispatch_group1: null,
      dispatch_group2: null,
      equipmentDispatchGroups: [],
      existingTags: [],
      equipmentId: this.$route.params.id,
      editDocumentFlag: false,
      showTagsModal: false,
      qrCodeUrl: "",
      itemId: 0,
      showDocumentModal: false,
      showEquipmentModal: false,
      documentData: {
        object_id: null,
        date: null,
        type: null,
        expiry_date: "",
        document: null,
        description: null,
        driver: true,
        public: false,
      },
      documentIndex: null,
      equipmentStatus: false,
      fileName: null,
      equipmentImage: null,
      equipmentImageUrl: null,
      errors: {
        date: null,
        type: null,
        document: null,
        description: null,
      },
      options: [
        {
          value: null,
          text: "Vehicle",
        },
        {
          value: "a",
          text: "Trailer",
        },
        {
          value: "b",
          text: "Bus",
        },
      ],
      typeOptions: [
        {
          value: "Vehicle",
          text: "Vehicle",
        },
        {
          value: "Trailer",
          text: "Trailer",
        },
      ],
      documentOptions: [
        {
          value: null,
          text: "",
        },
        {
          value: "Registration",
          text: "Registration",
        },
        {
          value: "Inspection",
          text: "Inspection",
        },
        {
          value: "Insurance",
          text: "Insurance",
        },
        {
          value: "Permit",
          text: "Permit",
        },
        {
          value: "Other",
          text: "Other",
        },
      ],
      stateOptions: [
        {
          value: "Illinois",
          text: "Illinois",
        },
        {
          value: "Chicago",
          text: "Chicago",
        },
        {
          value: "Mexico",
          text: "Mexico",
        },
      ],
      ownershipOptions: [
        {
          value: null,
          text: "",
        },
        {
          value: "Owned",
          text: "Owned",
        },
        {
          value: "Leased",
          text: "Leased",
        },
        {
          value: "Owner Operator",
          text: "Owner Operator",
        },
      ],
      fuelOptions: [
        {
          value: null,
          text: "",
        },
        {
          value: "Diesel",
          text: "Diesel",
        },
        {
          value: "Bio-Diesel",
          text: "Bio-Diesel",
        },
        {
          value: "Gasoline",
          text: "Gasoline",
        },
        {
          value: "Ethanol",
          text: "Ethanol",
        },
      ],

      perPage: 25,
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      currentPage: 1,
      items: [],
      fields: [
        {
          key: "Date",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
        {
          key: "Description",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
        {
          key: "expiry_date",
          label: "Expiry Date",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
        {
          key: "Driver",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
        {
          label: "Public",
          key: "Public",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
        {
          key: "Action",
          sortable: true,
          thClass:'font-table-weight-bold'
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "equipmentDocuments",
      "currentEquipment",
      "allStates",
      "allCountries",
      "dispatchGroups",
      "company",
      "userCompany"
    ]),
    rows() {
      return this.items.length;
    },
    positionText() {
      let endIndex = this.currentPage * this.perPage,
        startIndex =
          this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.rows ? this.rows : endIndex) +
        " of " +
        this.rows +
        " entries"
      );
    },
    subTypeOptions() {
      let options = [];
      if (this.currentEquipment.type === "Vehicle") {
        options = [
          {
            value: "Truck",
            text: "Truck",
          },
          {
            value: "Semi-Truck",
            text: "Semi-Truck",
          },
          {
            value: "Day-Cab",
            text: "Day-Cab",
          },
          {
            value: "Straight-Truck",
            text: "Straight-Truck",
          },
          {
            value: "Box-Truck",
            text: "Box-Truck",
          },
          {
            value: "Flatbed-Truck",
            text: "Flatbed-Truck",
          },
          {
            value: "Dump-Truck",
            text: "Dump-Truck",
          },
          {
            value: "Refrigerated-Truck",
            text: "Refrigerated-Truck",
          },
          {
            value: "Tow-Truck",
            text: "Tow-Truck",
          },
          {
            value: "Cargo-Van",
            text: "Cargo-Van",
          },
          {
            value: "Van",
            text: "Van",
          },
          {
            value: "Auto",
            text: "Auto",
          },
          {
            value: "SUV",
            text: "SUV",
          },
        ];
      } else if (this.currentEquipment.type === "Trailer") {
        options = [
          {
            value: "Dry-Van",
            text: "Dry-Van",
          },
          {
            value: "Dry-Van-Air-Ride",
            text: "Dry-Van-Air-Ride",
          },
          {
            value: "Reefer",
            text: "Reefer",
          },
          {
            value: "Flatbed",
            text: "Flatbed",
          },
          {
            value: "Stepdeck",
            text: "Stepdeck",
          },
          {
            value: "Conestoga",
            text: "Conestoga",
          },
          {
            value: "Conestoga-Drop",
            text: "Conestoga-Drop",
          },
          {
            value: "Double-Drop",
            text: "Double-Drop",
          },
          {
            value: "Gooseneck",
            text: "Gooseneck",
          },
          {
            value: "Tanker",
            text: "Tanker",
          },
          {
            value: "Dump",
            text: "Dump",
          },
          {
            value: "Lowboy",
            text: "Lowboy",
          },
          {
            value: "Auto-Hauler",
            text: "Auto-Hauler",
          },
          {
            value: "Chassis",
            text: "Chassis",
          },
          {
            value: "Container",
            text: "Container",
          },
          {
            value: "Livestock",
            text: "Livestock",
          },
        ];
      }
      return options;
    },
  },
  methods: {
    ...mapActions([
      "sendEquipmentDocument",
      "getEquipment",
      "setLoader",
      "updateEquipmentDocument",
      "deleteEquipmentDocument",
      "getDispatchGroups",
      "getAllStates",
      "updateEquipment",
      "setNotification",
      "deleteEquipment",
      "getAllCountries",
      "retrieveDocument",
    ]),
    async removeTag(tag) {
      const index = this.existingTags.indexOf(tag);
      this.existingTags.splice(index, 1);
      await this.updateEquipmentData();
    },
    deleteDocumentItem(item) {
      if (item) {
        this.deleteDocument(this.itemId);
      }
      this.showDocumentModal = false;
    },
    removeFile() {
      this.fileName = null;
      this.$refs.file.value = "";
    },
    deleteEquipmentItem(item) {
      if (item) {
        this.deleteTeamEquipment(this.itemId);
      }
      this.showDocumentModal = false;
    },
    addTag(item) {
      console.log(item);
      this.showTagsModal = false;
    },
    async deleteTeamEquipment() {
      try {
        await this.deleteEquipment(this.currentEquipment.id);
        await this.setNotification({
          msg: "Equipment successfully deleted!!",
          type: "success",
          color: "green",
        });
        this.$router.push("/equipment-list");
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    changeEquipmentStatus() {
      this.currentEquipment.status = this.equipmentStatus
        ? "Active"
        : "Inactive";
    },
    generateEquipmentQRCode(unitNo, fileName, qrCode) {
      generateQRCode(unitNo, fileName, qrCode)
    },
    async getCountries() {
      await this.getAllCountries();
      this.countries = this.allCountries.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      this.getStates(this.countries[0]);
    },
    getStates(selectedOption) {
      this.states = this.allCountries
        .filter((country) => country.id === selectedOption.id)[0]
        .state.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      if (this.states.length === 0) {
        this.currentEquipment.state.id = null;
      } else {
        if (this.currentEquipment.state) {
          this.currentEquipment.state.id = this.states.filter(
            (state) => state.label === "-"
          )[0].id;
        }
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addDocument() {
      this.fileName = null;
      this.editDocumentFlag = false;
      this.$bvModal.show("modal-center");
      this.documentData = {
        object_id: null,
        date: new Date(),
        type: null,
        document: null,
        expiry_date: "",
        description: null,
        driver: true,
        public: false,
      };
      this.errors = {
        date: null,
        type: null,
        document: null,
        expiry_date: "",
        description: null,
      };
    },
    resetField() {
      this.$refs.docFile.value = null;
    },
    handleFileUpload() {
      this.documentData.document = this.$refs.docFile.files[0];
      this.fileName = this.documentData.document.name;
    },
    handleImageUpload() {
      this.equipmentImage = this.$refs.imageFile.files[0];
      this.equipmentImageUrl = URL.createObjectURL(this.equipmentImage);
      this.updateEquipmentImage();
    },
    async updateEquipmentImage() {
      try {
        let formData = new FormData();
        formData.append(
          "picture",
          this.equipmentImage,
          this.equipmentImage.name
        );
        await this.updateEquipment({
          id: this.equipmentId,
          payload: formData,
        });
        if (this.currentEquipment) {
          await this.setNotification({
            msg: `Successfully updated Picture of ${this.currentEquipment.unit_no}`,
            type: "success",
            color: "green",
          });
        }
      } catch (error) {
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }
    },
    async openLink(id) {
      const doc = await this.retrieveDocument(id);
      console.log(doc);
      window.open(doc.document, "_blank");
    },
    editDocument(id) {
      this.editDocumentFlag = true;
      this.documentIndex = id;
      this.documentData = this.currentEquipment.docs[id];
      // console.log(this.documentData.document)
      if (this.documentData.document) {
        let file_name = "";
        if (typeof this.documentData.document === "string") {
          file_name = this.documentData.document;
        } else {
          file_name = this.documentData.document.name;
        }
        this.fileName = file_name.substring(file_name.lastIndexOf("/") + 1);
      }
      this.$bvModal.show("modal-center");
    },
    showPopup(id) {
      this.showDocumentModal = true;
      this.itemId = id;
    },
    async deleteDocument(id) {
      try {
        const docs = await this.deleteEquipmentDocument(`${id}/?company_id=${this.userCompany}`);
        if (docs) {
          this.currentEquipment.docs = docs;
        }
        this.updateDocs();

        await this.setNotification({
          msg: "Document successfully deleted!!",
          type: "success",
          color: "green",
        });
      } catch (error) {
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    async updateDocumentOnToggle(id, key, value) {
      try {
        this.currentEquipment.docs[id][key] = value;
        if (!this.currentEquipment.docs[id].expiry_date)
          delete this.currentEquipment.docs[id].expiry_date;
        delete this.currentEquipment.docs[id].document;
        await this.updateEquipmentDocument({
          id: this.currentEquipment.docs[id].id,
          payload: this.currentEquipment.docs[id],
        });
        await this.setNotification({
          msg: "Document Successfully updated!!",
          type: "success",
          color: "green",
        });
      } catch (error) {
        console.log(error);
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    async sendDocumentData() {
      try {
        this.setLoader(true);
        let formData = new FormData();
        if (this.$refs.docFile.files.length === 0) {
          this.documentData.document = null;
        }
        formData.append("object_id", this.currentEquipment.id);
        formData.append(
          "date",
          moment(this.documentData.date).format("YYYY-MM-DD")
        );
        formData.append("type", this.documentData.type);
        formData.append("description", this.documentData.description);
        if (this.documentData.expiry_date)
          formData.append("expiry_date", this.documentData.expiry_date);
        
        if (this.documentData.document)
          formData.append(
            "document",
            new File(
              [this.documentData.document],
              this.documentData.document["name"].split(".")[0] +
              "_" + this.userCompany+"_"+
              new Date().getTime() +
              "." +
              this.documentData.document["type"].split("/").pop(),
              { type: this.documentData.document["type"] }
            )
          );
        formData.append("driver", this.documentData.driver);
        formData.append("public", this.documentData.public);
        formData.append("model_type", "equipment");
        formData.append("company_id", parseInt(localStorage.getItem("userSelectedCompany")));
        if (this.editDocumentFlag) {
          this.documentData = await this.updateEquipmentDocument({
            id: this.documentData.id,
            payload: formData,
          });
          this.editDocumentFlag = false;
          await this.setNotification({
            msg: "Document Successfully updated!!",
            type: "success",
            color: "green",
          });
          let color;
          if (this.documentData.expiry_date) {
            const currentDate = new Date();
            const expiryDate = new Date(this.documentData.expiry_date);
            const diffDays = parseInt(
              (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
              10
            );
            if (diffDays > 31) {
              color = "color:black";
            } else if (diffDays <= 31 && diffDays >= 1) {
              color = "color:#FFC800";
            } else color = "color:#FF0000";
          }
          this.currentEquipment.docs[this.documentIndex] = this.documentData;
          this.items[this.documentIndex].Date = moment(
            this.documentData.date
          ).format("MMM DD, YYYY");
          this.items[this.documentIndex].Type = this.documentData.type;
          this.items[this.documentIndex].Description =
            this.documentData.description;
          this.items[this.documentIndex].expiry_date = this.documentData
            .expiry_date
            ? `<span style=${color}>${moment(
              this.documentData.expiry_date
            ).format("MMM DD, YYYY")}</span`
            : "-";
          this.items[this.documentIndex] = this.documentData.driver;
          this.editDocumentFlag = false;
          this.$bvModal.hide("modal-center");
          this.documentData = {
            object_id: null,
            date: null,
            type: null,
            document: null,
            description: null,
            expiry_date: "",
            driver: true,
            public: false,
          };
          this.updateDocs();
        } else {
          if (this.documentData.date) {
            const docs = await this.sendEquipmentDocument(formData);
            if (docs) {
              this.currentEquipment.docs = docs;
              this.updateDocs();
              await this.setNotification({
                msg: "Document Successfully added!!",
                type: "success",
                color: "green",
              });
            }
            this.$bvModal.hide("modal-center");
            this.documentData = {
              object_id: null,
              date: null,
              type: null,
              document: null,
              description: null,
              expiry_date: "",
              driver: true,
              public: false,
            };
          } else {
            this.errors.date = this.documentData.date
              ? null
              : ["This field is required."];
            // this.errors.description = this.documentData.description? null: ['This field is required.']
            console.log(this.errors);
          }
        }
        this.setLoader(false);
      } catch (error) {
        this.setLoader(false);
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }
    },
    async updateEquipmentData() {
      try {
        Object.keys(this.currentEquipment).forEach((e) => {
          if (this.currentEquipment[e] === "") {
            this.currentEquipment[e] = null;
          }
        });
        delete this.currentEquipment.picture;
        delete this.currentEquipment.company;
        delete this.currentEquipment.country;

        let equipmentData = {
          ...this.currentEquipment,
          state: this.currentEquipment.state.id
            ? this.currentEquipment.state.id
            : 54,
          plate_no: this.currentEquipment.plate_no
            ? this.currentEquipment.plate_no.toUpperCase()
            : null,
          vin_no: this.currentEquipment.vin_no
            ? this.currentEquipment.vin_no.toUpperCase()
            : null,
          dispatch_group1: this.dispatch_group1,
          dispatch_group2: this.dispatch_group2,
        };
        await this.updateEquipment({
          id: this.equipmentId,
          payload: {
            ...equipmentData,
            tags: {
              new_tag: { add_tag: null, company: this.userCompany },
              tag_list: this.existingTags.length
                ? this.existingTags.map((item) => item.id)
                : [],
            },
          },
        });
        if (this.currentEquipment) {
          await this.setNotification({
            msg: `Successfully updated ${this.currentEquipment.unit_no}`,
            type: "success",
            color: "green",
          });
          this.$router.push("/equipment-list");
        }
      } catch (error) {
        if (typeof error === "object") {
          console.log(error);
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        } else {
          await this.setNotification({
            msg: error,
            type: "error",
            color: "red",
          });
        }
      }
    },
    updateDocs() {
      this.items = [];
      this.currentEquipment.docs.forEach((doc, id) => {
        let color;
        if (doc.expiry_date) {
          const currentDate = new Date();
          const expiryDate = new Date(doc.expiry_date);
          const diffDays = parseInt(
            (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
            10
          );
          if (diffDays > 31) {
            color = "color:black";
          } else if (diffDays <= 31 && diffDays >= 1) {
            color = "color:#FFC800";
          } else color = "color:#FF0000";
        }
        this.items.push({
          id: id,
          pk: doc.id,
          Date: moment(doc.date).format("MMM DD, YYYY"),
          Description: doc.description,
          expiry_date: doc.expiry_date
            ? `<span style=${color}>${moment(doc.expiry_date).format(
              "MMM DD, YYYY"
            )}</span>`
            : "-",
          Public: doc.public,
          Driver: doc.driver,
          link: doc.document,
        });
      });
    },
  },
  watch: {
    currentEquipment() {
      this.existingTags = this.currentEquipment.tags;
    },
  },
  async mounted() {
    try {
      await this.getCountries();
      await this.getEquipment(this.equipmentId);
      this.dispatch_group1 = this.currentEquipment.dispatch_group1
        ? this.currentEquipment.dispatch_group1.id
        : null;
      this.dispatch_group2 = this.currentEquipment.dispatch_group2
        ? this.currentEquipment.dispatch_group2.id
        : null;
      await this.getDispatchGroups(this.userCompany);
      this.equipmentDispatchGroups.push({
        value: null,
        text: "",
      });
      this.dispatchGroups.map((item) => {
        this.equipmentDispatchGroups.push({
          value: item.id,
          text: item.name,
        });
      });
      this.equipmentImageUrl = this.currentEquipment.picture;
      this.equipmentStatus = this.currentEquipment.status === "Active";
      this.updateDocs();
      await this.$nextTick();
      if (this.currentEquipment.vin_no) {
        this.qrCodeUrl =
          this.$refs?.qrcode.qrious._canvasRenderer.element.toDataURL();
      }
    } catch (error) {
      console.log(error);
      await this.setNotification({
        msg: error,
        type: "error",
        color: "red",
      });
      await router.push("/");
    }
  },
};
</script>

<style>
.equipment-page .bi-x.cross_icon {
  position: absolute;
  right: 20px !important;
  top: 41px !important;
}

.width-fit-content {
  width: fit-content;
}

.upload_btn {
  position: relative;
  width: fit-content;
  margin: auto;
}

.upload_btn input {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  width: -webkit-fill-available;
}

.upload_btn .btn {
  background-color: var(--btn-bg-color);
  cursor: pointer;
}

.upload-btn-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-top: 30px;
  border: 1px dashed #bdbdbd;
  border-radius: 5px;
  height: 210px;
}

.upload-btn-wrapper img, .upload-btn-wrapper .user-default-img {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.upload-btn-wrapper .user-default-img{
  top: 50%;
}
.upload-btn-profile-page-wrapper img{
  top: 40%;
  width: 100px;
}

.upload-btn-wrapper .img {
  top: 30% !important;
}
.upload-btn-wrapper .foo {
  background-color: #ffffff38;
  padding: 150px 25px 20px;
  color: var(--black);
  font-size: var(--extrasmall);
  width: 16.5rem;
}

.upload-btn-wrapper .foo-2 {
  background-color: #ffffff38;
  padding: 120px 25px 20px;
  color: var(--black);
  font-size: var(--extrasmall);
  width: 16.5rem;
}
.upload-btn-wrapper input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.bg_white {
  background-color: #fff !important;
}

textarea.form-control {
  background-color: #fafafa;
  border: 1px solid #dee2e6;
}

textarea.form-control:focus {
  box-shadow: unset;
  background-color: #fafafa;
  border: 1px solid #dee2e6;
}

.table_btn .close-btn {
  width: 100px;
  background-color: #dddddd !important;
  color: var(--black) !important;
  height: 36px;
}

.table_btn .close-btn:hover,
.table_btn .close-btn:focus,
.table_btn .close-btn:active {
  background-color: #dddddd !important;
  color: var(--black) !important;
}

.table_btn .save {
  width: 95px;
  background-color: var(--btn-bg-color) !important;
}

.table_btn .save:hover,
.table_btn .save:focus,
.table_btn .save:active {
  background-color: var(--btn-bg-color) !important;
}

h5 {
  font-size: 18px !important;
  font-weight: 600;
}

.object_detail .num p {
  font-size: var(--small);
  color: var(--black);
}

.object_detail .num .active {
  background-color: var(--btn-bg-color);
  border-radius: 5px;
  font-size: var(--extrasmall);
  color: #fff;
  width: 50px;
}

.object_detail .num .inactive {
  background-color: var(--bg-grey);
  border-radius: 5px;
  font-size: var(--extrasmall);
  color: #000;
  width: 57px;
}

.object_detail .num .assigned {
  background-color: var(--bg-green);
  border-radius: 5px;
  font-size: var(--extrasmall);
  color: #000;
  width: 57px;
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: #2D69F6 !important;
}

input.form-control,
.custom-select,.username-input-field > input, .vue-tel-input {
  background-color: #fafafa !important;
  border: 1px solid #e3e3e3 !important;
  box-shadow: unset;
  font-size: var(--small);
  height: 40px !important;
  text-align: left !important;
  padding: 0.375rem 0.75rem;
}

.custom-select.bg-white{
  background-color: white !important;
}

input.form-control:focus {
  background-color: #f5f5f5;
  border: 1px solid #dee2e6;
}

input.form-control::placeholder  {
  color: var(--black);
}

.col-form-label {
  opacity: 1;
}

.state {
  padding-bottom: calc(0.375rem + -1px);
  margin-bottom: 0rem;
}

.equipment-page .select_arrow {
  position: relative;
}

.equipment-page .modal .select_arrow svg {
  position: absolute;
  top: 42px;
  right: 10px;
}

.b-form-datepicker {
  background-color: #fafafa !important;
  font-size: var(--extrasmall) !important;
  height: 40px !important;
  align-items: center !important;
}

.b-form-datepicker .b-icon :not(svg) {
  color: var(--black);
  background-color: var(--black) !important;
}

.details .col-form-label,
.details .state,
.expiry_data label {
  font-size: var(--small);
  color: var(--black);
}

.data-table .document {
  width: 153px;
}

.tags-section {
  color: #797979 !important;
  font-size: 14px;
}

.equipment-chips {
  color: #3a6ce7 !important;
  background: rgba(94, 114, 228, 0.1) !important;
}

.add-tags .v-application--wrap {
  min-height: 100% !important;
}

@media (max-width: 767px) {
  .object_detail img {
    margin-bottom: 30px;
  }
}

@media (max-width: 375px) {
  #example-datepicker__value_ {
    font-size: 10px !important;
    font-weight: 600;
  }
}
</style>
