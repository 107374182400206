<template>
    <div>
        <div class="filter-box">
            <div class="d-flex mb-4 px-3 px-sm-4">
                <b-form-group class="text-left label font-weight-normal search-field mb-0">
                    <b-input-group class="username-input-field">
                        <b-form-input type="text" placeholder="Search" v-model="filterFields.search" class="unit f-12"
                            @keyup.enter="getMaintenanceScheduleData()"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="primary" @click="getMaintenanceScheduleData()">
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <div class="ml-auto align-items-center d-none d-sm-flex flex-wrap">
                    <div class="position-relative">
                        <div class="position-absolute notification-badge" v-if="activeFiltersCount">
                            {{ activeFiltersCount }}
                        </div>
                        <button class="btn btn-filter-section" @click="$bvModal.show('modal-filter')">
                            <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="lucide lucide-sliders-horizontal w-4 h-4"
                                    data-id="element-964">
                                    <line x1="21" x2="14" y1="4" y2="4"></line>
                                    <line x1="10" x2="3" y1="4" y2="4"></line>
                                    <line x1="21" x2="12" y1="12" y2="12"></line>
                                    <line x1="8" x2="3" y1="12" y2="12"></line>
                                    <line x1="21" x2="16" y1="20" y2="20"></line>
                                    <line x1="12" x2="3" y1="20" y2="20"></line>
                                    <line x1="14" x2="14" y1="2" y2="6"></line>
                                    <line x1="8" x2="8" y1="10" y2="14"></line>
                                    <line x1="16" x2="16" y1="18" y2="22"></line>
                                </svg>
                                <p class="mb-0 ml-2">Filters</p>
                            </div>
                        </button>
                    </div>
                    <div class="table-columns-section ml-2 mt-0" @click="showColumns = true">
                        <button class="btn btn-table-columns-section">
                            <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="lucide lucide-columns2 w-4 h-4" data-id="element-961">
                                    <rect width="18" height="18" x="3" y="3" rx="2"></rect>
                                    <path d="M12 3v18"></path>
                                </svg>
                                <p class="mb-0 ml-2">Columns</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <b-table class="mt-2" responsive :fields="visibleFields" :items="items" hover :current-page="currentPage"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy"
            :sticky-header="'calc(100vh - 330px)'" :show-empty="emptyTable">
            <template #cell(name)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <a :href="`#${data.value
                    .replace(/[^a-z]+/i, '-')
                    .toLowerCase()}`">{{ data.value }}</a>
            </template>
            <template #cell(description)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                {{
                    data.value && data.value !== "null" ? data.value : "-"
                }}
            </template>
            <template #cell(due)="data">
                <span v-html="data.value"></span>
            </template>
            <template #cell(interval)="data">
                <span v-html="data.value"></span>
            </template>
            <template #cell(action)="data">
                <svg @click="updateCompleteSchedule(data.item)" class="cursor-pointer" width="20" height="20"
                    viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="10" cy="10" r="10" fill="#2D69F6" />
                    <path d="M6.66669 9.99981L8.88891 12.222L12.5926 7.77759" fill="#2D69F6" />
                    <path d="M6.66669 9.99981L8.88891 12.222L12.5926 7.77759" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <!-- <b-icon style="fill: limegreen" class="mr-4" icon="pencil" @click="editSchedule(data.item)" />
                <b-icon 
                style="fill:#FF0000" icon="trash-fill" @click="showDeletePopup(data.item.pk)" /> -->
            </template>
        </b-table>
        <div class="entries-pagination px-0 px-sm-2">
            <div class="d-flex align-items-center flex-wrap">
                <div class="text-left show-entries mr-md-3 pl-3 pl-md-0 ml-md-0">
                    <b-form-select v-model="perPage" :options="pageOptions" />
                </div>
                <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
                <div class="pagination">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <b-modal id="modal-schedule-complete" class="maintenance_modal" centered hide-footer :header-bg-variant="'white'"
            header-class="border-none">
            <template #modal-header="{ close }">
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="d-block text-center">
                <h3 class="f-18 text-center mb-5">Do you want to complete this Schedule ?</h3>
                <b-button variant="primary" class="mb-3 schedule-btn" @click="completeSchedule">Yes</b-button> <br />
                <b-button variant="primary" class="mb-3 schedule-btn" @click="completeScheduleAndAddRecord">Yes, and Add
                    Record</b-button><br />
                <b-button variant="secondary" class="mb-3 schedule-btn"
                    @click="$bvModal.hide('modal-schedule-complete')">Cancel</b-button>
            </div>
        </b-modal>
        <DeleteModal v-if="showModal" @confirm="deleteItem($event)" />
        <AddRecord :unitNo="unitNo" :description="description" :allowAddRecord="allowAddRecord"
            @close="allowAddRecord = false" @recordAdded="getMaintenanceNewData($event)" />
        <EditRecord :recordID="recordID" @resetMaintenance="recordID = null" />
        <AddSchedule @scheduleUpdated="getMaintenanceScheduleData()" />
        <TableColumns :showColumns="showColumns" :fields="fields" @getUpdatedFieldsList="getUpdatedFieldsList($event)"
            @updateFields="updateFields($event)" @resetTableFields="resetTableFields()" @resetData="showColumns = false" />
        <b-modal id="modal-filter" centered no-close-on-backdrop :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
            :body-class="'px-3 pt-3 pb-1'" no-close-on-esc>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Filters
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="body">
                <b-form-group label="Type" class="text-left select_arrow label mb-3">
                    <b-form-select v-model="filterFields.type" class="input f-12" :options="[
                        {
                            value: '',
                            text: '',
                        },
                        {
                            value: 'Vehicle',
                            text: 'Vehicle',
                        },
                        {
                            value: 'Trailer',
                            text: 'Trailer',
                        }
                    ]" @change="getMaintenanceScheduleData()"></b-form-select>
                </b-form-group>
                <b-form-group label="Tags" class="text-left w-equipment-box mb-3">
                    <v-select multiple class="f-12 bg-grey" :options="allTags" :reduce="(option) => option.id"
                        v-model="filterFields.tags" @input="getMaintenanceScheduleData()" />
                </b-form-group>
            </div>
            <template #modal-footer="">
                <div class="text-right modal-footer border-0 p-0 mt-0 justify-content-between mx-0">
                    <b-button class="mx-0 mb-0 h-40px w-100px m-0 btn-outline-primary" variant="primary"
                        @click="resetFilterFields(); $bvModal.hide('modal-filter')">Reset</b-button>
                    <b-button class="mx-0 h-40px w-100px m-0 m-0" block
                        @click="$bvModal.hide('modal-filter')">Close</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import * as moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css"
import AddRecord from "@/components/Maintenance/AddRecord";
import EditRecord from "@/components/Maintenance/EditRecord";
import DeleteModal from "@/components/DeleteModal.vue";
import AddSchedule from "@/components/Maintenance/AddSchedule";
import TableColumns from "@/components/Shared/TableColumns.vue";
export default {
    name: "Schedule",
    components: { TableColumns, vSelect, DeleteModal, AddRecord, AddSchedule, EditRecord },
    data() {
        return {
            showColumns: false,
            unitNo: null,
            allowAddRecord: false,
            recordID: null,
            description: "",
            filterFields: {
                search: '',
                type: '',
                tags: [],
                status: ''
            },
            scheduledata: null,
            fields: [],
            defaultFields: [
                {
                    key: "unit_no",
                    label: "Unit #",
                    sortable: true,
                    visible: true,
                    disabled: true,
                    field: "text",
                    stickyColumn: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "type",
                    label: "Type",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "dropdown",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "odometer",
                    label: "Odometer",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "dropdown",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "description",
                    label: "Description",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "interval",
                    label: "Interval",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "dropdown",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "due",
                    label: "Due",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    label: "Action",
                    sortable: true,
                    visible: true,
                    disabled: true,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
            ],
            items: [],
            rows: 0,
            allTags: [],
            positionText: "",
            sortBy: undefined,
            sortDesc: undefined,
            isBusy: false,
            perPage: 10,
            emptyTable: false,
            showModal: false,
            itemId: null,
            currentPage: 1,
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
        }
    },
    methods: {
        ...mapActions(["getMaintenanceSchedule", "getAllTags", "patchMaintenanceSchedule", "deleteMaintenanceSchedule", 'setMaintenanceSchedule', 'setNotification', 'getMaintenanceScheduleExportData', 'setLoader', 'updateUserPreferences', 'deleteUserPreferences']),
        async resetTableFields() {
            try {
                this.setLoader(true)
                await this.deleteUserPreferences(`Schedule/`)
                this.showColumns = false
                this.setLoader(false)
            }
            catch (e) {
                this.setLoader(false)
                console.log(e)
            }
        },
        async updateFields(updatedFields) {
            this.fields = updatedFields
            this.filterFields = {
                search: '',
                type: '',
                tags: [],
                status: ''
            }
            try {
                await this.updateUserPreferences({
                    "columns": updatedFields
                })
                await this.getMaintenanceScheduleData()
            }
            catch (e) {
                console.log(e)
            }
        },
        async getUpdatedFieldsList(updatedFields) {
            this.fields = updatedFields
            try {
                const updatedOrderList = updatedFields.map((item, index) => {
                    return { ...item, display_order: index + 1 }
                })
                await this.updateUserPreferences(
                    {
                        "columns": updatedOrderList
                    })
            }
            catch (e) {
                console.log(e)
            }
        },
        async getMaintenanceNewData(id = null) {
            this.allowAddRecord = false
            if (id) this.recordID = id
        },
        async resetFilterFields() {
            this.filterFields = {
                search: '',
                type: '',
                tags: [],
                status: ''
            }
            await this.getMaintenanceScheduleData()
        },
        showDeletePopup(id) {
            this.showModal = true;
            this.itemId = id;
        },
        editSchedule(item) {
            this.setMaintenanceSchedule(item)
            this.$bvModal.show('modal-schedule')
        },
        deleteItem(item) {
            if (item) {
                this.deleteRecord(this.itemId);
            }
            this.showModal = false;
        },
        async deleteRecord(id) {
            try {
                this.setLoader(true)
                await this.deleteMaintenanceSchedule(`${id}/?company_id=${this.userCompany}`);
                await this.getMaintenanceScheduleData()
                await this.setNotification({
                    msg: "Maintenance Schedule successfully deleted!!",
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
            } catch (error) {
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        updateCompleteSchedule(data) {
            this.scheduledata = data
            this.$bvModal.show("modal-schedule-complete")
        },
        async completeScheduleAndAddRecord() {
            this.unitNo = {
                id: this.scheduledata.unit,
                label: this.scheduledata.unit_no,
                type: "equipment",
                odometer: this.scheduledata.odometer
            }
            this.description = this.scheduledata.description
            await this.completeSchedule()
            this.allowAddRecord = true
        },
        async completeSchedule() {
            try {
                this.setLoader(true)
                const today = new Date();
                this.scheduledata.complete_date =
                    today.getFullYear() +
                    "-" +
                    (today.getMonth() + 1) +
                    "-" +
                    today.getDate();
                await this.patchMaintenanceSchedule({
                    id: this.scheduledata.pk,
                    payload: {
                        status: "completed"
                    },
                });
                // await this.deleteMaintenanceSchedule(`${this.scheduledata.pk}/?company_id=${this.userCompany}`);
                await this.getMaintenanceScheduleData()
                await this.setNotification({
                    msg: "Schedule Completed Successfully!!",
                    type: "success",
                    color: "green",
                });
                this.$bvModal.hide('modal-schedule-complete')
                this.scheduledata = null
                this.setLoader(false)
            } catch (error) {
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        async downloadExcel() {
            try {
                this.setLoader(true)
                let url = ""
                let tags = ""
                Object.keys(this.filterFields).map((key) => {
                    if (key === "tags" && this.filterFields[key].length > 0) {
                        this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
                        url += `${key}=${tags}&`
                    }
                    else if (this.filterFields[key] !== '' && key !== 'tags') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=due&sort_order=asc&`
                }
                const data = await this.getMaintenanceScheduleExportData(url)
                const blob = new Blob([data], { type: 'text/csv' });
                const csvUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = csvUrl;
                a.download = 'schedule.csv';
                a.target = "_blank"
                document.body.appendChild(a);
                a.click();
                a.remove()
                window.URL.revokeObjectURL(url);
                await this.setNotification({
                    msg: `File Exported Successfully`,
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
            }
            catch (e) {
                this.setLoader(false)

            }
        },
        async getMaintenanceScheduleData() {
            try {
                this.isBusy = true
                let url = ""
                let tags = ""
                Object.keys(this.filterFields).map((key) => {
                    if (key === "tags" && this.filterFields[key].length > 0) {
                        this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
                        url += `${key}=${tags}&`
                    }
                    else if (this.filterFields[key] !== '' && key !== 'tags') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=due&sort_order=asc&`
                }
                url += `page=${this.currentPage}&page_size=${this.perPage}`
                const data = await this.getMaintenanceSchedule(url);
                if (data.total_entries) {
                    this.rows = data.total_entries
                    this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} - ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
                }
                else {
                    this.rows = 0
                    this.positionText = `0 - 0 of 0`
                }
                this.items = [];
                this.maintenanceSchedule.forEach(async (m, id) => {
                    let color;
                    if (m.due) {
                        if (m.next_service_miles) {
                            if (m.due <= 0) {
                                color = "color:#FF0000";
                            } else if (m.due <= 3000) {
                                color = "color:#FFC800";
                            }
                        }
                        else {
                            if (m.due <= 0) {
                                color = "color:#FF0000";
                            } else if (m.due <= 10) {
                                color = "color:#FFC800";
                            }
                        }
                    }
                    this.items.push({
                        id: id,
                        pk: m.id,
                        unit_no: m.unit_name,
                        Type: m.unit_type,
                        odometer: m.current_odometer.toLocaleString(),
                        interval: m.interval ? m.next_service_miles ? `<span style=${color}>${m.interval.toLocaleString()} mi</span>` : `<span style=${color}>${moment(m.interval).format("MMM DD, YYYY")}</span>` : '-',
                        due: m.due ? m.next_service_miles ? `<span style=${color}>${m.due.toLocaleString()} mi</span>` : `<span style=${color}>${m.due} Days</span>` : '-',
                        description: m.description,
                        complete_date: m.complete_date
                            ? moment(m.complete_date).format("MMM DD, YYYY")
                            : "-",
                        unit_id: m.unit_no?.id,
                        type: m.unit_type,
                        unit: m.unit_no
                    });
                });
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
                this.isBusy = false
            }
            catch (e) {
                this.isBusy = false
                console.log(e)
            }
        },
    },
    computed: {
        ...mapGetters(['maintenanceSchedule', 'tags', 'company', 'userCompany', 'rolePermissions', 'userPreferences', 'user']),
        allowUpdateRecord() {
            return this.rolePermissions && this.rolePermissions['Maintenance'] && this.rolePermissions['Maintenance'][1].allow
        },
        activeFiltersCount() {
            return Object.keys(this.filterFields).reduce((count, key) => {
                if (key === 'tags') {
                    return count + (this.filterFields.tags.length > 0 ? 1 : 0);
                } else if (
                    this.filterFields[key] !== '' &&
                    this.filterFields[key] !== null
                ) {
                    return count + 1;
                }
                return count;
            }, 0);
        },
        visibleFields() {
            if (!this.allowUpdateRecord) return this.fields.filter(field => field.visible && field.key !== 'Action')
            return this.fields.filter(field => field.visible)
        }
    },
    watch: {
        userPreferences: {
            async handler() {
                if (this.userPreferences) {
                    this.fields = this.userPreferences['Schedule'].sort((a, b) => a.display_order - b.display_order).map((col) => {
                        return { ...col, thClass: 'font-table-weight-bold', ...col.key === 'selected' ? { thStyle: { minWidth: "20px", width: "20px" } } : {} }
                    })
                }
            },
            immediate: true
        },
        perPage: {
            async handler() {
                await this.getMaintenanceScheduleData();
            },
            immediate: true,
        },
        currentPage: {
            async handler() {
                await this.getMaintenanceScheduleData();
            },
        },
        sortBy: {
            async handler() {
                await this.getMaintenanceScheduleData();
            },
        },
        sortDesc: {
            async handler() {
                await this.getMaintenanceScheduleData();
            },
        },
        filterFields: {
            handler() {
                this.currentPage = 1
            },
            deep: true
        },
        company: {
            async handler() {
                await this.getAllTags(this.userCompany);
                this.tags.map((item) => {
                    this.allTags.push({
                        id: item.add_tag,
                        label: item.add_tag,
                    });
                });
            },
            deep: true,
            immediate: true
        }
    },
}
</script>