<template>
    <div>
        <div class="filter-box">
            <div class="d-flex mb-4 px-3 px-sm-4">
                <b-form-group class="text-left label font-weight-normal search-field mb-0">
                    <b-input-group class="username-input-field">
                        <b-form-input type="text" placeholder="Search" v-model="filterFields.search" class="unit f-12"
                            @keyup.enter="getAccidentData()"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="primary" @click="getAccidentData()">
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <div class="ml-auto align-items-center d-sm-flex d-none flex-wrap">
                    <div class="position-relative">
                        <div class="position-absolute notification-badge" v-if="activeFiltersCount">
                            {{ activeFiltersCount }}
                        </div>
                        <button class="btn btn-filter-section" @click="$bvModal.show('modal-filter')">
                            <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="lucide lucide-sliders-horizontal w-4 h-4"
                                    data-id="element-964">
                                    <line x1="21" x2="14" y1="4" y2="4"></line>
                                    <line x1="10" x2="3" y1="4" y2="4"></line>
                                    <line x1="21" x2="12" y1="12" y2="12"></line>
                                    <line x1="8" x2="3" y1="12" y2="12"></line>
                                    <line x1="21" x2="16" y1="20" y2="20"></line>
                                    <line x1="12" x2="3" y1="20" y2="20"></line>
                                    <line x1="14" x2="14" y1="2" y2="6"></line>
                                    <line x1="8" x2="8" y1="10" y2="14"></line>
                                    <line x1="16" x2="16" y1="18" y2="22"></line>
                                </svg>
                                <p class="mb-0 ml-2">Filters</p>
                            </div>
                        </button>
                    </div>
                    <div class="table-columns-section ml-2 mt-0" @click="showColumns = true">
                        <button class="btn btn-table-columns-section">
                            <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="lucide lucide-columns2 w-4 h-4" data-id="element-961">
                                    <rect width="18" height="18" x="3" y="3" rx="2"></rect>
                                    <path d="M12 3v18"></path>
                                </svg>
                                <p class="mb-0 ml-2">Columns</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <b-table class="mt-2" responsive :fields="visibleFields" :items="items" hover :current-page="currentPage"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy"
            :sticky-header="'calc(100vh - 330px)'" :show-empty="emptyTable">
            <template #cell(record_no)="data">
                <p class="mb-0 cursor-pointer text_primary mb-0" @click="accidentID = data.item.id" v-if="allowViewRecord">
                    {{ data.value }}</p>
                <p class="mb-0" v-else>{{ data.value }}</p>
            </template>
            <template #cell(dot_status)="data">
                <p class="mb-0">{{ data.value ? 'Yes' : 'No' }}</p>
            </template>
            <template #cell(fault_status)="data">
                <p class="mb-0">{{ data.value ? 'Yes' : 'No' }}</p>
            </template>
            <template #cell(notes)="data">
                <v-app v-if="data.value">
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <span class="w-fit-content item-description text-left" v-on="on" v-bind="attrs">{{ data.value
                            }}</span>
                        </template>{{ formatTooltipText(data.value) }}
                    </v-tooltip>
                </v-app>
                <p v-else>-</p>
            </template>
            <template #cell(status)="data">
                <b-button class="btn f-14 pe-none d-flex justify-content-center align-items-center"
                    :variant="data.item.status === 'New' ? 'warning-status' : data.item.status === 'Urgent' ? 'danger-status' : data.item.status === 'Open' ? 'success-status' : 'secondary-status'">
                    <p class="mb-0">{{ data.value }}</p>
                </b-button>
            </template>
        </b-table>
        <div class="entries-pagination px-0 px-sm-2">
            <div class="d-flex align-items-center flex-wrap">
                <div class="text-left show-entries mr-md-3 pl-3 pl-md-0 ml-md-0">
                    <b-form-select v-model="perPage" :options="pageOptions" />
                </div>
                <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
                <div class="pagination">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <AddAccident :states="states" @close="$emit('close')" :allowAddAccident="allowAddAccident"
            @accidentAdded="getAccidentNewData($event)" />
        <EditAccident :states="states" :accidentID="accidentID" @resetEditAccident="accidentID = null"
            @accidentUpdated="getAccidentData()" />
        <TableColumns :showColumns="showColumns" :fields="fields" @getUpdatedFieldsList="getUpdatedFieldsList($event)"
            @updateFields="updateFields($event)" @resetTableFields="resetTableFields()" @resetData="showColumns = false" />
        <b-modal id="modal-filter" centered no-close-on-backdrop :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
            :body-class="'px-3 pt-3 pb-1'" no-close-on-esc>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Filters
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="body">
                <b-form-group label="Dot" class="text-left select_arrow label mb-3">
                    <b-form-select v-model="filterFields.dot_status" type="text" class="input f-12" :options="[
                        {
                            value: '',
                            text: '',
                        },
                        {
                            value: 'True',
                            text: 'Yes'
                        },
                        {
                            value: 'False',
                            text: 'No'
                        }

                    ]" @change="getAccidentData()"></b-form-select>
                </b-form-group>
                <b-form-group label="Fault" class="text-left select_arrow label mb-3">
                    <b-form-select v-model="filterFields.fault_status" type="text" class="input f-12" :options="[
                        {
                            value: '',
                            text: '',
                        },
                        {
                            value: 'True',
                            text: 'Yes'
                        },
                        {
                            value: 'False',
                            text: 'No'
                        }

                    ]" @change="getAccidentData()"></b-form-select>
                </b-form-group>
                <b-form-group label="Status" class="text-left select_arrow label mb-3">
                    <b-form-select v-model="filterFields.status" type="text" class="input f-12" :options="[
                        {
                            value: '',
                            text: '',
                        },
                        {
                            value: 'New',
                            text: 'New',
                        },
                        {
                            value: 'Open',
                            text: 'Open',
                        },
                        {
                            value: 'Closed',
                            text: 'Closed',
                        },
                        {
                            value: 'Urgent',
                            text: 'Urgent',
                        }

                    ]" @change="getAccidentData()"></b-form-select>
                </b-form-group>
            </div>
            <template #modal-footer="">
                <div class="text-right modal-footer border-0 p-0 mt-0 justify-content-between mx-0">
                    <b-button class="mx-0 mb-0 h-40px w-100px m-0 btn-outline-primary" variant="primary"
                        @click="resetFilterFields(); $bvModal.hide('modal-filter')">Reset</b-button>
                    <b-button class="mx-0 h-40px w-100px m-0 m-0" block
                        @click="$bvModal.hide('modal-filter')">Close</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import * as moment from "moment-timezone";
import AddAccident from './AddAccident.vue';
import EditAccident from './EditAccident.vue';
import TableColumns from "@/components/Shared/TableColumns.vue";
export default {
    name: "Accidents",
    components: { AddAccident, EditAccident, TableColumns },
    props: ["allowAddAccident", 'states'],
    data() {
        return {
            showColumns: false,
            items: [],
            accidentID: null,
            currentPage: 1,
            rows: 0,
            perPage: 10,
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
            emptyTable: false,
            sortBy: undefined,
            sortDesc: undefined,
            isBusy: false,
            positionText: "",
            fields: [],
            filterFields: {
                search: '',
                dot_status: '',
                fault_status: '',
                status: ''
            },
            defaultFields: [
                {
                    key: "record_no",
                    label: "Record #",
                    sortable: true,
                    visible: true,
                    disabled: true,
                    field: "text",
                    stickyColumn: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "driver",
                    label: "Driver",
                    sortable: true,
                    visible: true,
                    disabled: true,
                    field: "text",
                    stickyColumn: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Vehicle",
                    key: "vehicle",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "Dropdown",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "trailer",
                    label: "Trailer",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "date",
                    label: "Date",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "dot_status",
                    label: "Dot",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "fault_status",
                    label: "Fault",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "notes",
                    label: "Notes",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "status",
                    label: "Status",
                    sortable: true,
                    visible: true,
                    disabled: true,
                    field: "Dropdown",
                    thClass: 'font-table-weight-bold'
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['rolePermissions', 'userPreferences', 'user']),
        allowViewRecord() {
            return this.rolePermissions && this.rolePermissions['Safety'] && this.rolePermissions['Safety'][0].allow
        },
        activeFiltersCount() {
            return Object.keys(this.filterFields).reduce((count, key) => {
                if (
                    this.filterFields[key] !== '' &&
                    this.filterFields[key] !== null
                ) {
                    return count + 1;
                }
                return count;
            }, 0);
        },
        visibleFields() {
            return this.fields.filter(field => field?.visible)
        }
    },
    methods: {
        ...mapActions(['getAllAccidentData', 'updateUserPreferences', 'deleteUserPreferences', 'setLoader']),
        async getUpdatedFieldsList(updatedFields) {
            this.fields = updatedFields
            try {
                const updatedOrderList = updatedFields.map((item, index) => {
                    return { ...item, display_order: index + 1 }
                })
                await this.updateUserPreferences(
                    {
                        "columns": updatedOrderList
                    })
            }
            catch (e) {
                console.log(e)
            }
        },
        async getAccidentNewData(id) {
            await this.getAccidentData()
            if (id) this.accidentID = id
            this.$emit('close');
        },
        formatTooltipText(text) {
            const maxLineLength = window.screen.width <= 767 ? 50 : 80;
            let formattedText = "";
            let lineLength = 0;

            text.split(' ').forEach(word => {
                if (lineLength + word.length + 1 > maxLineLength) {
                    formattedText += "\n";
                    lineLength = 0;
                }
                formattedText += (lineLength === 0 ? "" : " ") + word;
                lineLength += word.length + 1;
            });

            return formattedText;
        },
        async updateFields(updatedFields) {
            this.fields = updatedFields
            this.filterFields = {
                search: '',
                type: '',
                tags: [],
                status: ''
            }
            try {
                await this.updateUserPreferences({
                    "columns": updatedFields
                })
                await this.getAccidentData()
            }
            catch (e) {
                console.log(e)
            }
        },
        async getAccidentData() {
            try {
                this.items = []
                this.isBusy = true
                let url = ""
                Object.keys(this.filterFields).map((key) => {
                    if (this.filterFields[key] !== '') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=record_no&sort_order=desc&`
                }
                url += `page=${this.currentPage}&page_size=${this.perPage}`
                const data = await this.getAllAccidentData(url);
                if (data.total_entries) {
                    this.rows = data.total_entries
                    this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} - ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
                }
                else {
                    this.rows = 0
                    this.positionText = `0 - 0 of 0`
                }
                data.records.map((item) => {
                    this.items.push({
                        id: item.id,
                        record_no: item.record_no,
                        driver: item.driver_name,
                        vehicle: item.vehicle_name ? item.vehicle_name : '-',
                        trailer: item.trailer_name ? item.trailer_name : '-',
                        date: item.date ? moment(item.date).format("MMM DD, YYYY") : '-',
                        notes: item.notes ? item.notes : '-',
                        dot_status: item.dot_status,
                        fault_status: item.fault_status,
                        status: item.status,
                    });
                })

                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
                this.isBusy = false
            }
            catch (e) {
                this.isBusy = false
                console.log(e)
            }
        },
        async resetTableFields() {
            try {
                this.setLoader(true)
                await this.deleteUserPreferences(`Safety Accidents/`)
                this.showColumns = false
                this.setLoader(false)
            }
            catch (e) {
                this.setLoader(false)
                console.log(e)
            }
        },
        async resetFilterFields() {
            this.filterFields = {
                search: '',
                dot_status: '',
                fault_status: '',
                status: ''
            },
                await this.getAccidentData();
        }
    },
    watch: {
        userPreferences: {
            async handler() {
                if (this.userPreferences) {
                    this.fields = this.userPreferences['Safety Accidents'].sort((a, b) => a.display_order - b.display_order).map((col) => {
                        return { ...col, thClass: 'font-table-weight-bold', ...col.key === 'selected' ? { thStyle: { minWidth: "20px", width: "20px" } } : {} }
                    })
                }
            },
            immediate: true
        },
        perPage: {
            async handler() {
                await this.getAccidentData();
            },
            immediate: true,
        },
        currentPage: {
            async handler() {
                await this.getAccidentData();
            },
        },
        sortBy: {
            async handler() {
                await this.getAccidentData();
            },
        },
        sortDesc: {
            async handler() {
                await this.getAccidentData();
            },
        },
        filterFields: {
            handler() {
                this.currentPage = 1
            },
            deep: true
        },
    },
}
</script>