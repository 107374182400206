<template>
  <div>
    <div class="profile-form bg-white p-3 rounded">
      <h5 class="font-weight-bold font-18 text-left">General</h5>
      <b-row class="align-items-center">

        <b-col sm="5">
          <div class="upload-btn-wrapper upload-btn-profile-page-wrapper mt-2 text-black text-black"
            :class="{ 'h-auto': memberImageURL }">
            <b-img :src="memberImageURL
              ? memberImageURL
              : require('@/assets/user.png')
              " :class="memberImageURL ? 'img_custom' : 'w-available'"></b-img>
            <button v-if="!memberImageURL" class="foo">
              Drop your picture here, or click here to browse
            </button>
            <input ref="imageFile" type="file" name="imageFile" id="logoUpload" v-on:change="handleImageUpload"
              accept="image/*" />
          </div>
          <div class="buttons text-left">
            <b-button class="mt-2 cancel" v-if="memberImageURL" @click="resetImage">Reset</b-button>
            <b-button class="ml-3 mt-2 save" v-if="memberImage" @click="updateTeamMemberImage()"
              variant="primary">Save</b-button>
          </div>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col sm="12" class="mb-3">
          <b-row>
            <b-col sm="8">
              <b-form-group label="First Name" class="text-left mb-3">
                <b-form-input type="text" v-model="user.first_name" placeholder="Enter First Name"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8">
              <b-form-group label="Last Name" class="text-left mb-3">
                <b-form-input type="text" v-model="user.last_name" placeholder="Enter Last Name"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8">
              <b-form-group label="Email" class="text-left mb-3">
                <b-form-input type="text" v-model="user.email" placeholder="Enter Email Address" disabled></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8">
              <b-form-group label="Username" class="text-left mb-3">
                <b-form-input type="text" v-model="memberUsername" placeholder="Enter Username"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="8">
              <b-form-group label="Phone" class="text-left mb-0">
                <b-input-group size="lg" class="w-100" prepend="+1">
                  <b-form-input v-mask="phoneMask" class="f-14" v-model="userPhoneNumber"></b-form-input>
                </b-input-group>
                <p v-if="errors && errors.phone" class="field-error">
                  {{ errors.phone[0] }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="d-flex align-items-center flex-wrap">
        <b-button to="/" class="mx-0 h-40px w-100px mt-3 mr-3 m-0" variant="secondary">Close</b-button>
        <b-button v-if="hasChanged" class="mt-3 save h-40px w-100px m-0" variant="primary" @click="updateUserDetails()"
          type="button">Save</b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { checkValueExists, checkPhoneFormat } from '../../services/helper';
export default {
  name: "General",
  data() {
    return {
      errors: {},
      checkValueExists,
      checkPhoneFormat,
      memberImage: null,
      memberImageURL: null,
      defaultData: {},
      memberUsername: "",
    }
  },
  computed: {
    ...mapGetters(["user", 'company']),
    userPhoneNumber: {
      get: function () {
        return this.user.team_member.phone ? this.user.team_member.phone.startsWith("+1") ? this.user.team_member.phone.slice(2).trim() : this.user.team_member.phone : ''
      },
      set: function (newValue) {
        this.user.team_member.phone = newValue
      }
    },
    hasChanged() {
      return this.defaultData.memberUsername?.split(
        `.${this.company[0]?.company_ID}`
      )[0] != this.memberUsername || this.defaultData.userPhoneNumber !== this.userPhoneNumber || Object.keys(this.defaultData['user']).filter(field => {
        return this.checkValueExists(this.user[field]) != this.checkValueExists(this.defaultData['user'][field])

      }).length > 0
    },
    phoneMask() {
      return this.userPhoneNumber.length > 0
        ? [
          /\d/, /\d/, /\d/, " ",
          /\d/, /\d/, /\d/, " ",
          /\d/, /\d/, /\d/, /\d/
        ]
        : false; // Allows empty input
    },
  },
  methods: {
    ...mapActions(['setNotification', 'updateTeamMember', 'getUserByID', 'setLoader']),
    resetImage() {
      this.memberImage = null
      this.memberImageURL = null
      this.$refs.imageFile.value = null
      this.updateTeamMemberImage(true)
    },
    handleImageUpload() {
      this.memberImage = this.$refs.imageFile.files[0];
      this.memberImageURL = URL.createObjectURL(this.memberImage);
    },
    async updateUserDetails() {
      try {
        if (!this.memberUsername) {
          await this.setNotification({
            msg: "Username is required",
            type: "error",
            color: "red",
          });
          return;
        }
        if (this.memberUsername && this.memberUsername.length < 4) {
          await this.setNotification({
            msg: "Username should have minimum 4 characters",
            type: "error",
            color: "red",
          });
          return;
        }
        if (this.memberUsername && this.memberUsername.includes(`.${this.company[0].company_ID}`)) {
          await this.setNotification({
            msg: "Username can not contain .compid",
            type: "error",
            color: "red",
          });
          return;
        }
        if (this.user.team_member.phone) {
          if (!this.checkPhoneFormat(this.userPhoneNumber)) {
            throw {
              phone: ["Wrong format"],
            };
          }
        }
        await this.updateTeamMember({
          id: this.user.team_member.id,
          payload: {
            phone: this.user.team_member.phone ? !this.user.team_member.phone.includes("+1") ? "+1 " + this.user.team_member.phone : this.user.team_member.phone : '',
            email_expiry_date: this.user.team_member.email_expiry_date,
            get_document_added_email: this.user.team_member.get_document_added_email,
            get_driver_dot_mode_email: this.user.team_member.get_driver_dot_mode_email,
            user: {
              first_name: this.user.first_name,
              last_name: this.user.last_name,
              username: this.memberUsername
            }
          },
        });
        await this.getUserByID()
        await this.setNotification({
          msg: "User Details Updated!!",
          type: "success",
          color: "green",
        });
        this.setDefaultData()
        this.errors = {
          phone: null,
        };
      } catch (error) {
        if (typeof error === "object") {
          this.errors = error;
        }
        else {
          await this.setNotification({
            msg: error,
            type: "error",
            color: "red",
          });
        }
      }
    },
    async updateTeamMemberImage(removeImage = false) {
      try {
        this.setLoader(true)
        let formData = new FormData();
        if (!removeImage) {
          formData.append(
            "profile_picture",
            this.memberImage,
            this.memberImage.name
          );
        } else {
          formData.append(
            "profile_picture",
            ""
          );
        }
        await this.updateTeamMember({
          id: this.user.team_member.id,
          payload: formData,
        });
        await this.getUserByID()
        await this.setNotification({
          msg: `Successfully updated Profile Picture of ${this.user.first_name} ${this.user.last_name}`,
          type: "success",
          color: "green",
        });
        this.memberImage = null
        this.setLoader(false)
      } catch (error) {
        console.log(error)
        this.setLoader(false)
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    setDefaultData() {
      this.defaultData = {
        user: {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
        },
        memberUsername: this.memberUsername,
        userPhoneNumber: this.user.team_member.phone ? this.user.team_member.phone.startsWith("+1") ? this.user.team_member.phone.slice(2).trim() : this.user.team_member.phone : ''
      }
    }
  },
  mounted() {
    this.memberImageURL = this.user.team_member.profile_picture
    this.memberUsername = this.user.team_member.user.username.split(
      `.${this.company[0].company_ID}`
    )[0];
    this.setDefaultData()
  }
}
</script>