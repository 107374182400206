import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import ForgotPassword from "../views/auth/ForgotPassword.vue";
import ResetPassword from "../views/auth/ResetPassword.vue";
import ChangePassword from "../views/auth/ChangePassword.vue";
import TeamMember from "../views/website/TeamMember.vue";
import Maintenance from "../views/website/Maintenance.vue";
import Safety from "../views/website/Safety.vue";
import SearchEquipment from "../views/SearchEquipment.vue";
import Website from "../views/Website.vue";
import LicenseCart from "../views/website/LicenseCart.vue";
import ExpiryData from "../views/website/ExpiryData.vue";
import Dashboard from "../views/website/Dashboard.vue";
import EquipmentList from "../views/website/EquipmentList.vue";
import Equipment from "../views/website/Equipment.vue";
import Team from "../views/website/Team.vue";
import Customers from "../views/website/Customers.vue";
import Locations from "../views/website/Locations.vue";
import CustomerDetails from "../views/website/CustomerDetails.vue";
import ProfilePage from "../views/website/ProfilePage.vue";
import Notifications from "../views/website/Notifications.vue";
import DriverDetails from "../views/driver/DriverDetails.vue";
import Documents from "../views/website/Documents.vue";
import DriverSearch from "../views/website/DriverSearch.vue"
import AllCompanies from "../views/allCompanies.vue"
import CompanyDetails from "../views/CompanyDetails.vue"
import Company from "../views/website/Company.vue"
import DriverDetailsSearch from "../views/driver/DriverDetailsSearch.vue"
import DriverVehiclesSearch from "../views/driver/DriverVehiclesSearch.vue"
import DriverTrailersSearch from "../views/driver/DriverTrailersSearch.vue"
import DriverEquipmentDetails from "../views/driver/DriverEquipmentDetails.vue"
import Reports from "../views/website/Reports.vue"
import ReportDetails from "../views/website/reports/ReportDetails.vue"
// import Reports from "../views/website/reports/Reports.vue";
// import DriverInsuranceReport from "../views/website/reports/DriverInsuranceReport.vue";
// import EquipmentInsuranceReport from "../views/website/reports/EquipmentInsuranceReport.vue";
// import DriverBasicReport from "../views/website/reports/DriverBasicReport.vue";
// import EquipmentBasicReport from "../views/website/reports/EquipmentBasicReport.vue";
// import ExpiryReport from "../views/website/reports/ExpiryReport.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Website,
    name: "Website",
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "/reports",
        name: "Reports",
        component: Reports,
      },
      {
        path:"/reports/:name",
        name:"ReportDetails",
        component:ReportDetails
      },
      {
        path: "/company",
        name: "Company",
        component: Company,
      },
      {
        path: "/company/motive",
        name: "CompanyMotive",
        component: Company,
      },
      {
        path: "/license-cart",
        name: "LicenseCart",
        component: LicenseCart,
      },
      {
        path: "/expiry-data",
        name: "ExpiryData",
        component: ExpiryData,
      },
      {
        path: "/equipment-list/vehicles",
        name: "Vehicle",
        component: EquipmentList,
      },
      {
        path: "/equipment-list/trailers",
        name: "Trailer",
        component: EquipmentList,
      },
      {
        path: "/equipment/:id",
        name: "Equipment",
        component: Equipment,
      },
      {
        path: "/drivers",
        name: "Team",
        component: Team,
      },
      {
        path: "/customers",
        name: "Customers",
        component: Customers,
      },
      {
        path: "/customer/:id",
        name: "CustomerDetails",
        component: CustomerDetails,
      },
      {
        path: "/locations",
        name: "Locations",
        component: Locations,
      },
      {
        path: "/driver/:id",
        name: "TeamMember",
        component: TeamMember,
      },
      {
        path: "/maintenance",
        name: "Maintenance",
        component: Maintenance,
      },
      {
        path: "/safety",
        name: "Safety",
        component: Safety,
      },
      {
        path: "/documents",
        name: "Documents",
        component: Documents,
      },
      // {
      //   path: "/reports",
      //   name: "Reports",
      //   component: Reports,
      // },
      // {
      //   path: "/reports/driver/insurance",
      //   name: "DriverInsuranceReport",
      //   component: DriverInsuranceReport,
      // },
      // {
      //   path: "/reports/equipment/insurance",
      //   name: "EquipmentInsuranceReport",
      //   component: EquipmentInsuranceReport,
      // },
      // {
      //   path: "/reports/driver/basic",
      //   name: "DriverBasicReport",
      //   component: DriverBasicReport,
      // },
      // {
      //   path: "/reports/equipment/basic",
      //   name: "EquipmentBasicReport",
      //   component: EquipmentBasicReport,
      // },
      // {
      //   path: "/reports/expiry",
      //   name: "ExpiryReport",
      //   component: ExpiryReport,
      // },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/password-reset-confirm/:uid/:token",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/app/set-password/:uid/:token",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/search-equipment",
    name: "SearchEquipment",
    component: SearchEquipment,
  },
  {
    path: "/driver-search",
    name: "DriverSearch",
    component: DriverSearch,
  },
  {
    path: "/driver-details",
    name: "DriverDetails",
    component: DriverDetails,
    meta: { roleDriver: true },
  },
  {
    path: "/driver-vehicles-search",
    name: "DriverVehiclesSearch",
    component: DriverVehiclesSearch,
    meta: { roleDriver: true },
  },
  {
    path: "/driver-trailers-search",
    name: "DriverTrailersSearch",
    component: DriverTrailersSearch,
    meta: { roleDriver: true },
  },
  {
    path: "/driver-equipment-details/:id",
    name: "DriverEquipmentDetails",
    component: DriverEquipmentDetails,
    meta: { roleDriver: true },
  },
  {
    path: "/all-companies",
    name: "AllCompanies",
    component: AllCompanies,
  },
  {
    path: "/profile-page",
    name: "ProfilePage",
    component: ProfilePage,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
  },
  {
    path: "/driver-details-search",
    name: "DriverDetailsSearch",
    component: DriverDetailsSearch
  },
  {
    path: "/company/:name",
    name: "CompanyDetails",
    component: CompanyDetails
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (
    to.name === "Login" ||
    to.name === "Register" ||
    to.name === "ForgotPassword" ||
    to.name === "ResetPassword" ||
    to.name === 'ChangePassword'
  ) {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("role") && localStorage.getItem("role") === "Driver") next({ name: "DriverDetails" });
      if (localStorage.getItem("role") && localStorage.getItem("role") !== "Driver") next({ name: "Dashboard" });
    } else next();
  } else {
    if (
      !localStorage.getItem("token") &&
      to.name !== "SearchEquipment" &&
      to.name !== "CompanyDetails" &&
      to.name !== "DriverDetails" &&
      to.name !== "DriverSearch"
    ) {
      next({ name: "Login" });
      next();
    } else if (
      localStorage.getItem("token") &&
      localStorage.getItem("licenseExpired") &&
      localStorage.getItem("licenseExpired") === "true" &&
      to.name !== "Company" &&
      to.name !== "LicenseCart"
    ) {
      next({ name: "Company" });
    } else {
      if (localStorage.getItem("role") && localStorage.getItem("role") === 'Driver' && !to.meta.roleDriver) {
        next({ name: "DriverDetails" });
      }
      else if (localStorage.getItem("role") && localStorage.getItem("role") !== 'Driver' && to.meta.roleDriver && to.name !== "DriverDetails") {
        next({ name: "Dashboard" });
      }
      else {
        next();
      }
    }
  }
});
export default router;
