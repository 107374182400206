<template>
    <div>
        <b-modal id="modal-smart-drop-plus" centered hide-footer no-close-on-backdrop :body-class="'px-4 pb-4 pt-3'" no-close-on-esc
        >
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Smart Drop +
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="d-block text-center">
                <b-row>
                    <b-col lg="6" md="8" sm="12">
                        <div class="text-left mb-3">
                            <label class="state">Type:</label>
                            <b-form-select type="text" class="input f-12" v-model="documentData.type" :options="[
                                {
                                    value: '',
                                    text: '',
                                },
                                {
                                    value: 'Registration',
                                    text: 'Registration',
                                },
                                {
                                    value: 'Permit',
                                    text: 'Permit',
                                },
                                {
                                    value: 'Dot Inspection',
                                    text: 'DOT Inspection',
                                },
                                {
                                    value: 'CDL',
                                    text: 'CDL',
                                },
                                {
                                    value: 'Medical Card',
                                    text: 'Medical Card',
                                }
                            ]"></b-form-select>
                             <p v-if="errors && errors.type" class="field-error">
                                {{ errors.type[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3" v-if="documentData.type === 'Permit'">
                            <label for="modal-expiry-date" class="state">Expiry Date:</label>
                            <date-picker v-model="documentData.expiry_date" :formatter="momentFormat"
                                :range-separator="' - '" @clear="documentData.expiry_date = ''" placeholder="Select Date"
                                class="date-picker-custom" :teleport="'body'"></date-picker>
                        </div>
                        <div class="text-left mb-3" v-if="documentData.type === 'Registration'">
                            <label class="state">Expiry Date Required:</label>
                            <b-form-select type="text" v-model="documentData.expiry_date_required" class="input f-12"
                                :options="[
                                    {
                                        value: null,
                                        text: '',
                                    },
                                    {
                                        value: 'True',
                                        text: 'Yes',
                                    },
                                    {
                                        value: 'False',
                                        text: 'No',
                                    }
                                ]"></b-form-select>
                            <p v-if="errors && errors.expiry_date_required" class="field-error">
                                {{ errors.expiry_date_required[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Model:</label>
                            <b-form-select type="text" v-model="documentData.model" class="input f-12" :options="[
                                {
                                    value: '',
                                    text: '',
                                },
                                {
                                    value: 'Driver',
                                    text: 'Driver',
                                },
                                {
                                    value: 'Vehicle',
                                    text: 'Vehicle',
                                },
                                {
                                    value: 'Trailer',
                                    text: 'Trailer',
                                }
                            ]"></b-form-select>
                            <p v-if="errors && errors.model" class="field-error">
                                {{ errors.model[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Type:</label>
                            <b-form-select type="text" v-model="documentData.doc_type" class="input f-12"
                                :options="getDotOptions" @change="setDocDescription($event)"></b-form-select>
                            <p v-if="errors && errors.doc_type" class="field-error">
                                {{ errors.doc_type[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Description:</label>
                            <b-form-textarea id="textarea" class="f-12" v-model="documentData.description"
                                placeholder="Enter Description" rows="5"></b-form-textarea>
                            <p v-if="errors && errors.description" class="field-error">
                                {{ errors.description[0] }}
                            </p>
                        </div>
                    </b-col>
                    <b-col lg="6" md="8" sm="12">
                        <div class="upload-btn-wrapper">
                            <b-img :src="require('@/assets/drop.svg')"></b-img>
                            <button v-if="!smartDropPlusUploadFileName" class="foo">
                                Browse or drop your zip file here. Supported files are: PDF, JPG, PNG, TIFF.
                            </button>
                            <button v-else class="foo">{{ smartDropPlusUploadFileName }}</button>
                            <p v-if="smartDropPlusUploadFileName"
                                class="mb-0 field-error cursor-pointer font-12 position-relative error-button"
                                @click="removeFile()">
                                Delete
                            </p>
                            <input ref="smartDropPlus" type="file" name="myfile" v-on:change="handleFileUpload"
                                accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" />
                            <p v-if="errors && errors.document" class="field-error">
                                {{ errors.document[0] }}
                            </p>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="text-right modal-footer border-0 p-0">
                <b-button class="mt-3 mr-3 btn cancel" block @click="resetPopup()">Cancel</b-button>
                <b-button class="mt-3 btn save mx-0" variant="primary" @click="sendDocumentData">Save</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import * as moment from "moment-timezone";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    name: "SmartDropPlus",
    components:{DatePicker},
    data() {
        return {
            smartDropPlusUploadFileName: "",
            momentFormat: {
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            errors: {},
            documentData: {
                type: "",
                expiry_date_required: "",
                expiry_date: "",
                description: "",
                document: null,
                model: "",
                doc_type: ""
            }
        }
    },
    computed: {
        ...mapGetters(["userCompany", 'company', "docTypeItems"]),
        getDotOptions() {
            if (this.documentData.model)
                return [...[{
                    value: "",
                    text: "",
                }], ...this.docTypeItems?.filter((item) => item.model === this.documentData.model || item.model === 'All').map((item) => {
                    return {
                        value: item.id,
                        text: item.type,
                    }
                })]
            else return []
        },
    },
    methods: {
        ...mapActions(["smartDropPlus", "setLoader", "setNotification"]),
        setDocDescription(event) {
            if (event) {
                this.documentData.description = this.getDotOptions.filter((option) => option.value === event)[0].text
            }
            else this.documentData.description = ""
        },
        handleFileUpload() {
            this.documentData.document = this.$refs.smartDropPlus.files[0];
            this.smartDropPlusUploadFileName = this.documentData.document.name;
        },
        removeFile() {
            this.smartDropPlusUploadFileName = null;
            this.$refs.smartDropPlus.value = "";
        },
        resetPopup() {
            this.$bvModal.hide('modal-smart-drop-plus')
            this.documentData = {
                type: "",
                expiry_date_required: "",
                expiry_date: "",
                description: "",
                document: null,
                model: "",
                doc_type: ""
            }
            this.removeFile()
            this.errors = {}
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async sendDocumentData() {
            try {
                const nullKeys = this.checkNullValues({
                    ...this.documentData.type === 'Registration' ? { expiry_date_required: this.documentData.expiry_date_required } : {},
                    type:this.documentData.type,
                    document: this.documentData.document,
                    description: this.documentData.description,
                    ...this.company[0].doc_type ? { model: this.documentData.model, doc_type: this.documentData.doc_type } : {}
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true);
                    let formData = new FormData();
                    formData.append("type", this.documentData.type);
                    formData.append("company_id", this.userCompany);
                    formData.append("description", this.documentData.description);
                    if (this.documentData.doc_type) formData.append("doc_type", this.documentData.doc_type);
                    if(this.documentData.type === 'Dot Inspection') formData.append("expiry_date_required", this.documentData.type === 'Dot Inspection' && this.documentData.expiry_date_required === '' ? 'False' : this.documentData.expiry_date_required);
                    if (this.documentData.expiry_date) formData.append("expiry_date", moment(this.documentData.expiry_date).format("YYYY-MM-DD"));
                    if (this.documentData.document) formData.append("zip_file", this.documentData.document);
                    await this.smartDropPlus(formData)
                    await this.setNotification({
                        msg: "Document Successfully added!!",
                        type: "success",
                        color: "green",
                    });
                    this.resetPopup()
                    this.setLoader(false);
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                }
            }
        }
    },
}
</script>