<template>
    <div>
        <div class="filter-box">
            <div class="d-flex mb-4 px-3 px-sm-4">
                <b-form-group class="text-left label font-weight-normal search-field mb-0">
                    <b-input-group class="username-input-field">
                        <b-form-input type="text" placeholder="Search" v-model="filterFields.search" class="unit f-12"
                            @keyup.enter="getMaintenanceData()"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="primary" @click="getMaintenanceData()">
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group class="d-none d-md-block mb-0">
                    <date-picker v-model="filterFields.date" :formatter="momentFormat" @change="getMaintenanceData()"
                        :range-separator="' - '" @clear="filterFields.date = ''" placeholder="Select Date" range
                        :shortcuts="shortcuts"></date-picker>
                </b-form-group>
                <div class="ml-auto d-none d-sm-flex flex-wrap align-items-center">
                    <div class="position-relative">
                        <div class="position-absolute notification-badge" v-if="activeFiltersCount">
                            {{ activeFiltersCount }}
                        </div>
                        <button class="btn btn-filter-section" @click="$bvModal.show('modal-filter')">
                            <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="lucide lucide-sliders-horizontal w-4 h-4"
                                    data-id="element-964">
                                    <line x1="21" x2="14" y1="4" y2="4"></line>
                                    <line x1="10" x2="3" y1="4" y2="4"></line>
                                    <line x1="21" x2="12" y1="12" y2="12"></line>
                                    <line x1="8" x2="3" y1="12" y2="12"></line>
                                    <line x1="21" x2="16" y1="20" y2="20"></line>
                                    <line x1="12" x2="3" y1="20" y2="20"></line>
                                    <line x1="14" x2="14" y1="2" y2="6"></line>
                                    <line x1="8" x2="8" y1="10" y2="14"></line>
                                    <line x1="16" x2="16" y1="18" y2="22"></line>
                                </svg>
                                <p class="mb-0 ml-2">Filters</p>
                            </div>
                        </button>
                    </div>
                    <div class="table-columns-section ml-2 mt-0" @click="showColumns = true">
                        <button class="btn btn-table-columns-section">
                            <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="lucide lucide-columns2 w-4 h-4" data-id="element-961">
                                    <rect width="18" height="18" x="3" y="3" rx="2"></rect>
                                    <path d="M12 3v18"></path>
                                </svg>
                                <p class="mb-0 ml-2">Columns</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <b-table class="mt-2" responsive :fields="visibleFields" :items="items" hover :current-page="currentPage"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy"
            :sticky-header="'calc(100vh - 330px)'" :show-empty="emptyTable">

            <template #cell(record_no)="data">
                <p class="mb-0 cursor-pointer text_primary mb-0" @click="recordID = data.item.pk" v-if="allowViewRecord">{{
                    data.value }}</p>
                <p class="mb-0" v-else>{{ data.value }}</p>
            </template>
            <template #cell(description)="data">
                <v-app v-if="data.value && data.value !== 'null'">
                    <v-tooltip top>
                        <template #activator="{ on, attrs }">
                            <span class="w-fit-content item-description text-left mw-400px" v-on="on" v-bind="attrs">{{
                                data.value }}</span>
                        </template>{{ formatTooltipText(data.value) }}
                    </v-tooltip>
                </v-app>
                <p v-else>-</p>
            </template>

            <template #cell(amount)="data">
                {{
                    data.value !== "-" ? `$${getAmount(data.value)}` : "-"
                }}
            </template>
            <template #cell(odometer)="data">
                {{ data.value ? data.value.toLocaleString() : "" }}
            </template>
            <template #cell(status)="data">
                <b-button class="btn f-14 pe-none d-flex justify-content-center align-items-center"
                    :variant="data.item.status === 'New' ? 'warning-status' : data.item.status === 'Urgent' ? 'danger-status' : data.item.status === 'Open' ? 'success-status' : 'secondary-status'">
                    <p class="mb-0">{{ data.value }}</p>
                </b-button>
            </template>
            <template v-slot:custom-foot v-if="visibleFields.filter((item) => item.key === 'amount').length">
                <tr class="sticky-footer">
                    <td v-for="(field, index) in visibleFields" :key="index">
                        <span v-if="field.key === 'amount'" class="font-weight-bold">{{ totalAmount ?
                            `$${getAmount(totalAmount)}` : "-" }}</span>
                    </td>
                </tr>
            </template>
        </b-table>
        <div class="entries-pagination px-0 px-sm-2">
            <div class="d-flex align-items-center flex-wrap">
                <div class="text-left show-entries mr-md-3 pl-3 pl-md-0 ml-md-0">
                    <b-form-select v-model="perPage" :options="pageOptions" />
                </div>
                <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
                <div class="pagination">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <DeleteModal v-if="showModal" @confirm="deleteItem($event)" />
        <EditRecord :recordID="recordID" @resetMaintenance="recordID = null" @recordUpdated="getMaintenanceData()" />
        <AddRecord @close="$emit('close')" :allowAddRecord="allowAddRecord" @recordAdded="getMaintenanceNewData($event)" />
        <TableColumns :showColumns="showColumns" :fields="fields" @getUpdatedFieldsList="getUpdatedFieldsList($event)"
            @updateFields="updateFields($event)" @resetTableFields="resetTableFields()" @resetData="showColumns = false" />
        <b-modal id="modal-filter" centered no-close-on-backdrop :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
            :body-class="'px-3 pt-3 pb-1'" no-close-on-esc>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Filters
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="body">
                <b-form-group label="Type" class="text-left select_arrow label mb-3">
                    <b-form-select v-model="filterFields.type" class="input f-12" :options="[
                        {
                            value: '',
                            text: '',
                        },
                        {
                            value: 'Vehicle',
                            text: 'Vehicle',
                        },
                        {
                            value: 'Trailer',
                            text: 'Trailer',
                        }
                    ]" @change="getMaintenanceData()"></b-form-select>
                </b-form-group>
                <b-form-group label="Tags" class="text-left w-equipment-box mb-3">
                    <v-select multiple class="f-12 bg-grey" :options="allTags" :reduce="(option) => option.id"
                        v-model="filterFields.tags" @input="getMaintenanceData()" />
                </b-form-group>
                <b-form-group label="Status" class="text-left select_arrow label mb-3">
                    <b-form-select v-model="filterFields.status" type="text" class="input f-12" :options="[
                        {
                            value: '',
                            text: '',
                        },
                        {
                            value: 'New',
                            text: 'New',
                        },
                        {
                            value: 'Open',
                            text: 'Open',
                        },
                        {
                            value: 'Closed',
                            text: 'Closed',
                        },
                        {
                            value: 'Urgent',
                            text: 'Urgent',
                        }

                    ]" @change="getMaintenanceData()"></b-form-select>
                </b-form-group>

            </div>
            <template #modal-footer="">
                <div class="text-right modal-footer border-0 p-0 mt-0 mx-0 justify-content-between">
                    <b-button class="mx-0 mb-0 h-40px w-100px m-0 btn-outline-primary" variant="primary"
                        @click="resetFilterFields(); $bvModal.hide('modal-filter')">Reset</b-button>
                    <b-button class="mx-0 h-40px w-100px m-0 m-0" block
                        @click="$bvModal.hide('modal-filter')">Close</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AddRecord from "@/components/Maintenance/AddRecord";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css"
import TableColumns from "@/components/Shared/TableColumns.vue";
import * as moment from "moment-timezone";
// import DateRangePicker from 'vue2-daterange-picker'
import DeleteModal from "@/components/DeleteModal.vue";
import axios from "axios";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import EditRecord from "@/components/Maintenance/EditRecord";

export default {
    name: "Records",
    components: { vSelect, DeleteModal, DatePicker, AddRecord, EditRecord, TableColumns },
    props: ["allowAddRecord"],
    data() {
        return {
            showColumns: false,
            recordID: null,
            filterFields: {
                search: '',
                date: '',
                type: '',
                tags: [],
                status: ''
            },
            momentFormat: {
                //[optional] Date to String
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                //[optional]  String to Date
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            rows: 0,
            allTags: [],
            positionText: "",
            defaultFields: [
                {
                    key: "record_no",
                    label: "Record #",
                    sortable: true,
                    visible: true,
                    disabled: true,
                    field: "text",
                    stickyColumn: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "unit_no",
                    label: "Unit #",
                    sortable: true,
                    visible: true,
                    disabled: true,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Type",
                    key: "type",
                    sortable: true,
                    visible: true,
                    disabled: true,
                    field: "Dropdown",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "date",
                    label: "Date",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "description",
                    label: "Description",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "odometer",
                    label: "Odometer",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "amount",
                    label: "Amount",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "status",
                    label: "Status",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "Dropdown",
                    thClass: 'font-table-weight-bold'
                },
            ],
            fields: [],
            items: [],
            totalAmount: 0,
            showModal: false,
            itemId: null,
            sortBy: undefined,
            sortDesc: undefined,
            maintenanceId: null,
            isBusy: false,
            perPage: 10,
            emptyTable: false,
            currentPage: 1,
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
            shortcuts: [
                {
                    text: 'This Week',
                    onClick: () => {
                        const today = new Date();
                        const dayOfWeek = today.getDay() || 7;
                        const startOfWeek = new Date(today);
                        startOfWeek.setDate(today.getDate() - dayOfWeek + 1);
                        this.filterFields.date = [startOfWeek, today];
                        this.getMaintenanceData()
                    },
                },
                {
                    text: 'This Month',
                    onClick: () => {
                        const today = new Date();
                        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                        this.filterFields.date = [startOfMonth, today];
                        this.getMaintenanceData()
                    },
                },
                {
                    text: 'This Year',
                    onClick: () => {
                        const today = new Date();
                        const startOfYear = new Date(today.getFullYear(), 0, 1);
                        this.filterFields.date = [startOfYear, today];
                        this.getMaintenanceData()
                    },
                },
                {
                    text: 'Last Week',
                    onClick: () => {
                        const today = new Date();
                        const dayOfWeek = today.getDay() || 7;
                        const startOfLastWeek = new Date(today);
                        startOfLastWeek.setDate(today.getDate() - dayOfWeek - 6);
                        const endOfLastWeek = new Date(today);
                        endOfLastWeek.setDate(today.getDate() - dayOfWeek);
                        this.filterFields.date = [startOfLastWeek, endOfLastWeek];
                        this.getMaintenanceData()
                    },
                },
                {
                    text: 'Last Month',
                    onClick: () => {
                        const today = new Date();
                        const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                        const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                        this.filterFields.date = [startOfLastMonth, endOfLastMonth];
                        this.getMaintenanceData()
                    },
                },
                {
                    text: 'Last Year',
                    onClick: () => {
                        const today = new Date();
                        const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
                        const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31);
                        this.filterFields.date = [startOfLastYear, endOfLastYear];
                        this.getMaintenanceData()
                    },
                },
            ]
        }
    },
    methods: {
        ...mapActions(['setLoader', 'getMaintenance', 'getAllTags', "setMaintenance", "deleteMaintenance", 'setNotification', 'getMaintenanceRecordsExportData', 'updateUserPreferences', 'deleteUserPreferences']),
        async getUpdatedFieldsList(updatedFields) {
            this.fields = updatedFields
            try {
                const updatedOrderList = updatedFields.map((item, index) => {
                    return { ...item, display_order: index + 1 }
                })
                await this.updateUserPreferences(
                    {
                        "columns": updatedOrderList
                    })
            }
            catch (e) {
                console.log(e)
            }
        },
        async getMaintenanceNewData(id = null) {
            this.allowAddRecord = false
            await this.getMaintenanceData()
            if (id) this.recordID = id
            this.$emit('close');
        },
        formatTooltipText(text) {
            const maxLineLength = window.screen.width <= 767 ? 50 : 80;
            let formattedText = "";
            let lineLength = 0;

            text.split(' ').forEach(word => {
                if (lineLength + word.length + 1 > maxLineLength) {
                    formattedText += "\n";
                    lineLength = 0;
                }
                formattedText += (lineLength === 0 ? "" : " ") + word;
                lineLength += word.length + 1;
            });

            return formattedText;
        },
        async resetFilterFields() {
            this.filterFields = {
                search: '',
                type: '',
                date: '',
                tags: [],
                status: ''
            }
            await this.getMaintenanceData();
        },
        async deleteRecord(id) {
            try {
                this.setLoader(true)
                await this.deleteMaintenance(`${id}/?company_id=${this.userCompany}`);
                await this.setNotification({
                    msg: "Maintenance Record successfully deleted!!",
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
            } catch (error) {
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        showDeletePopup(id) {
            this.showModal = true;
            this.itemId = id;
        },
        deleteItem(item) {
            if (item) {
                this.deleteRecord(this.itemId);
            }
            this.showModal = false;
        },
        async resetTableFields() {
            try {
                this.setLoader(true)
                await this.deleteUserPreferences(`Maintenance/`)
                this.showColumns = false
                this.setLoader(false)
            }
            catch (e) {
                this.setLoader(false)
                console.log(e)
            }
        },
        async updateFields(updatedFields) {
            this.fields = updatedFields
            this.filterFields = {
                search: '',
                type: '',
                date: '',
                tags: [],
                status: ''
            }
            try {
                await this.updateUserPreferences({
                    "columns": updatedFields
                })
                await this.getMaintenanceData();
            }
            catch (e) {
                console.log(e)
            }
        },
        editMaintenance(item) {
            this.setMaintenance(item)
            this.$bvModal.show('modal-maintenance')
        },
        downloadFile(uri) {
            axios({
                method: 'get',
                url: uri,
                responseType: 'blob',
            }).then(response => {
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                const parts = uri.split('/');
                const lastPart = parts[parts.length - 1];
                const fileName = lastPart.split('?')[0];
                a.download = fileName
                document.body.appendChild(a);
                a.click();
                a.remove()
                window.URL.revokeObjectURL(url);
            });
        },
        getAmount(val) {
            return parseFloat(val).toLocaleString("en", {
                useGrouping: false,
                minimumFractionDigits: 2,
            });
        },
        async downloadExcel() {
            try {
                this.setLoader(true)
                let url = ""
                let tags = ""
                Object.keys(this.filterFields).map((key) => {
                    if (key === "tags" && this.filterFields[key].length > 0) {
                        this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
                        url += `${key}=${tags}&`
                    }
                    else if (key === 'date' && this.filterFields['date'][0]) {
                        url += `start_date=${moment(this.filterFields['date'][0]).format("YYYY-MM-DD")}&end_date=${moment(this.filterFields['date'][1]).format("YYYY-MM-DD")}&`
                    }
                    else if (this.filterFields[key] !== '' && key !== 'tags' && key !== 'date') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=unit_no&sort_order=asc&`
                }
                const data = await this.getMaintenanceRecordsExportData(url)
                const blob = new Blob([data], { type: 'text/csv' });
                const csvUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = csvUrl;
                a.download = 'records.csv';
                a.target = "_blank"
                document.body.appendChild(a);
                a.click();
                a.remove()
                window.URL.revokeObjectURL(url);
                await this.setNotification({
                    msg: `File Exported Successfully`,
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
            }
            catch (e) {
                this.setLoader(false)

            }
        },
        async getMaintenanceData() {
            try {
                this.isBusy = true
                let url = ""
                let tags = ""
                Object.keys(this.filterFields).map((key) => {
                    if (key === "tags" && this.filterFields[key].length > 0) {
                        this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
                        url += `${key}=${tags}&`
                    }
                    else if (key === 'date' && this.filterFields['date'][0]) {
                        url += `start_date=${moment(this.filterFields['date'][0]).format("YYYY-MM-DD")}&end_date=${moment(this.filterFields['date'][1]).format("YYYY-MM-DD")}&`
                    }
                    else if (this.filterFields[key] !== '' && key !== 'tags' && key !== 'date') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=record_no&sort_order=desc&`
                }
                url += `page=${this.currentPage}&page_size=${this.perPage}`
                const data = await this.getMaintenance(url);
                if (data.total_entries) {
                    this.rows = data.total_entries
                    this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} - ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
                }
                else {
                    this.rows = 0
                    this.positionText = `0 - 0 of 0`
                }
                this.items = []
                this.totalAmount = data.total_amount
                data.records.forEach((m, id) => {
                    this.items.push({
                        id: id,
                        pk: m.id,
                        record_no: m.record_no,
                        unit_no: m.unit_no.unit_no,
                        type: m.unit_no.type,
                        date: moment(m.date).format("MMM DD, YYYY"),
                        description: m.description,
                        odometer: m.odometer,
                        amount: m.amount !== "0.0000" ? parseFloat(m.amount).toFixed(2) : "-",
                        link: m.document,
                        unit_id: m.unit_no?.id,
                        status: m.status
                    });
                });
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
                this.isBusy = false
            }
            catch (e) {
                this.isBusy = false
                console.log(e)
            }
        },
    },
    computed: {
        ...mapGetters(['maintenance', 'tags', 'company', 'userCompany', 'rolePermissions', 'userPreferences', 'user']),
        allowViewRecord() {
            return this.rolePermissions && this.rolePermissions['Maintenance'] && this.rolePermissions['Maintenance'][0].allow
        },
        activeFiltersCount() {
            return Object.keys(this.filterFields).reduce((count, key) => {
                if (key === 'tags') {
                    return count + (this.filterFields.tags.length > 0 ? 1 : 0);
                } else if (
                    this.filterFields[key] !== '' &&
                    this.filterFields[key] !== null
                ) {
                    return count + 1;
                }
                return count;
            }, 0);
        },
        visibleFields() {
            return this.fields.filter(field => field.visible)
        }
    },
    watch: {
        userPreferences: {
            async handler() {
                if (this.userPreferences) {
                    this.fields = this.userPreferences['Maintenance'].sort((a, b) => a.display_order - b.display_order).map((col) => {
                        return { ...col, thClass: 'font-table-weight-bold', ...col.key === 'selected' ? { thStyle: { minWidth: "20px", width: "20px" } } : {} }
                    })
                }
            },
            immediate: true
        },
        perPage: {
            async handler() {
                await this.getMaintenanceData();
            },
            immediate: true,
        },
        currentPage: {
            async handler() {
                await this.getMaintenanceData();
            },
        },
        sortBy: {
            async handler() {
                await this.getMaintenanceData();
            },
        },
        sortDesc: {
            async handler() {
                await this.getMaintenanceData();
            },
        },
        filterFields: {
            handler() {
                this.currentPage = 1
            },
            deep: true
        },
        company: {
            async handler() {
                await this.getAllTags(this.userCompany);
                this.tags.map((item) => {
                    this.allTags.push({
                        id: item.add_tag,
                        label: item.add_tag,
                    });
                });
            },
            deep: true,
            immediate: true
        }
    },


}
</script>
<style>
.mx-datepicker-range {
    width: 250px !important;
}

.mx-datepicker-range .mx-input-wrapper .mx-input {
    background-color: #fafafa !important;
    border: 1px solid #e3e3e3 !important;
    height: 40px;
    box-shadow: none !important;
}

.mw-400px {
    max-width: 400px !important;
}</style>