<template>
  <b-modal id="modal-set-password-default-drivers" no-close-on-esc centered no-close-on-backdrop
    :footer-class="'d-block px-3 pt-0 pb-3 m-0'">
    <template #modal-header="{ close }">
      <h5 class="modal-title">
        Set Default Driver Password
      </h5>
      <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
        Close Modal
      </b-button>
    </template>

    <div class="text-center">
      <b-form-group label="" class="text-left password show-password-section">
        <b-form-input :type="showPassword ? 'text' : 'password'" placeholder="Enter Password" required
          v-model="default_password" />
        <b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'" @click="showPassword = !showPassword"></b-icon>
        <p v-if="errors && errors.default_password" class="field-error">
          {{ errors.default_password[0] }}
        </p>
      </b-form-group>
    </div>
    <template #modal-footer>
      <div class="text-right modal-footer border-0 p-0 mx-2">
        <b-button class="mx-0 mr-3 h-40px w-100px m-0" @click="resetPopup()" variant="secondary">Cancel</b-button>
        <div class="d-flex align-items m-0">
          <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary"
            @click="setPasswordforDefaultDrivers">Save</b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "DefaultDriversPasswordPopup",
  data() {
    return {
      showPassword: false,
      default_password: "",
      errors: {}
    }
  },
  computed: {
    ...mapGetters(["company", 'userCompany'])
  },
  methods: {
    ...mapActions(["setLoader", 'updateCompany', 'setNotification']),
    resetPopup() {
      this.$bvModal.hide('modal-set-password-default-drivers');
      this.showPassword = false
      this.default_password = ""
    },
    payloadToFormData(payload) {
      let formData = new FormData();
      for (let [key, value] of Object.entries(payload)) {
        if (key === "logo" && this.companyLogo) {
          formData.append(key, this.companyLogo, this.companyLogo.name);
        } else if (key !== "logo") {
          if (value !== null) {
            formData.append(key, value);
          }
        }
      }
      return formData;
    },
    async setPasswordforDefaultDrivers() {
      try {
        this.errors = {}
        const nullKeys = this.checkNullValues({
          default_password: this.default_password,
        });
        if (nullKeys.length === 0) {
          this.setLoader(true)
          let companyData = {
            ...this.company[0],
            state: this.company[0].state.id,
            company_id: this.userCompany,
            default_password: this.default_password
          };
          const formData = this.payloadToFormData(companyData);
          await this.updateCompany({
            id: `${this.userCompany}`,
            payload: formData,
          });
          await this.setNotification({
            msg: `Successfully updated default password for drivers`,
            type: "success",
            color: "green",
          });
          this.setLoader(false)
          this.resetPopup()
        }
        else {
          let error = {};
          nullKeys.forEach((x) => {
            error[x] = ["This field is required."];
          });
          throw error;
        }
      }
      catch (error) {
        this.setLoader(false)
        if (typeof error === "object") {
          this.errors = error;
        }
      }
    },
    checkNullValues(payload) {
      let nullKeys = [];
      for (let [key, value] of Object.entries(payload)) {
        if (value === "") {
          nullKeys.push(key);
        }
      }
      return nullKeys;
    }
  }
}
</script>