<template>
  <b-container fluid class="form forgot-form bg-white">
    <b-row>
      <b-col sm="12" class="left-side vh-100">
        <b-row>
          <b-col lg="9" md="12" sm="12" class="m-md-0 my-0 my-md-5">
            <div class="fix-width fix-width-login">
              <div class="logo mb-3 pb-3 text-left">
                <b-img
                  class=""
                  :src="require('@/assets/new-logo.png')"
                  width="300"
                  height="50"
                ></b-img>
              </div>
              <b-form @submit.prevent="handleSubmit">
                <b-form-group
                  id="input-group-1"
                  label="Email:"
                  label-for="input-1"
                  class="text-left label"
                >
                  <b-form-input
                    id="input-1"
                    type="email"
                    class="mb-3 input"
                    placeholder="Enter Email"
                    required
                    v-model="user.email"
                  ></b-form-input>
                </b-form-group>

                <b-button type="submit" class="w-100 mt-2 login h-40px"
                variant="primary"
                  >Send Reset Link</b-button
                >
                <div class="signup mt-3">
                  Back to
                  <router-link to="/login">Login</router-link>
                </div>
              </b-form>
            </div>
          </b-col>
          <!-- <p class="mb-0 f-14 opacity-50 copyright-text">© {{new Date().getFullYear()}} Copyright Digital Permit Book LLC.</p> -->
        </b-row>
      </b-col>
      <b-col cols="6" md="6" class="p-0 auth-bg-img">
        <Auth />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Auth from "../../components/auth/Auth.vue";
import { mapActions } from "vuex";
export default {
  components: {
    Auth,
  },
  name: "ForgotPassword",
  data() {
    return {
      user: {
        email: "",
      },
    };
  },
  methods: {
    ...mapActions(["resetPassword", "setNotification"]),
    async handleSubmit() {
      if (!this.user.email) {
        this.setNotification({
          msg: "Please enter email",
          type: "error",
          color: "red",
        });
      } else {
        try {
          const { detail } = await this.resetPassword(this.user.email);
          if (detail) {
            this.setNotification({
              msg: "Password reset e-mail has been sent.",
              type: "success",
              color: "green",
            });
            this.$router.push("/");
          }
        } catch (error) {
          this.setNotification({
            msg: error,
            type: "error",
            color: "red",
          });
        }
      }
    },
  },
};
</script>

<style>
.forgot-form .auth-bg-img {
  height: 100vh !important;
}
</style>
