<template>
  <div class="table mb-0 pt-3 bg-white rounded">
    <div class="expiry-data d-flex justify-content-between flex-wrap px-3 align-items-center mb-3">
      <h3 class="font-weight-bold f-20 text-left mb-0 text-black">Divisions</h3>
      <b-button @click="$bvModal.show('modal-division')"
        v-if="allowAddDivision"
        class="export equipment d-flex justify-content-center align-items-center w-fit-content" variant="primary">
        <b-icon icon="plus" class="p-0" aria-hidden="true"></b-icon>
        <p class="mb-0 ml-2 text-white px-0">Add Division</p>
      </b-button>
    </div>
    <b-table responsive :fields="fields" :items="items" hover :per-page="perPage" :current-page="currentPage" show-empty>
      <template #cell(division)="data">
        <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
      </template>
      <template #cell(name)="data">
        <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
      </template>
      <template #cell(phone)="data">
        <p class="mb-0">{{ data.value ? data.value : '-' }}</p>
      </template>
      <template #cell(address)="data">
        <p>{{ getAddress(data.item) }}</p>
      </template>
      <template #cell(action)="data">
        <svg v-if="!data.item.default && allowUpdateDivision" @click="editDivisionItem(data.item)" class="mr-4 cursor-pointer" width="21" height="21" viewBox="0 0 21 21"
          fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.9452 17.9664L1.71484 19.7109L3.45934 13.4806L14.8678 2.12751C14.9967 1.99564 15.1508 1.89086 15.3207 1.81932C15.4907 1.74779 15.6733 1.71094 15.8578 1.71094C16.0422 1.71094 16.2248 1.74779 16.3948 1.81932C16.5647 1.89086 16.7188 1.99564 16.8477 2.12751L19.2983 4.59196C19.428 4.72066 19.531 4.87381 19.6014 5.04251C19.6717 5.21123 19.7078 5.3922 19.7078 5.57497C19.7078 5.75774 19.6717 5.93871 19.6014 6.10743C19.531 6.27615 19.428 6.42928 19.2983 6.55798L7.9452 17.9664Z"
            stroke="#2CCE89" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div v-else>-</div>
      </template>
    </b-table>
    <div class="entries-pagination px-0 px-sm-2">
      <div class="d-flex align-items-center flex-wrap">
        <div class="text-left show-entries mr-md-3 pl-3 pl-md-0 ml-md-0">
          <b-form-select v-model="perPage" :options="pageOptions" />
        </div>
        <div class="pagination">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
            aria-controls="my-table"></b-pagination>
        </div>
        <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">
          {{ positionText }}
        </p>
      </div>
    </div>
    <b-modal id="modal-division" centered no-close-on-backdrop :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
      :body-class="'equipment-edit-modal'" no-close-on-esc :header-class="'align-items-center'">
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          {{ editDivisionFlag ? "Edit Division" : "Add Division" }}
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <div class="body">
        <b-row>
          <b-col class="mt-3" lg="12" md="12" sm="12">
            <b-form-group label="Division:" class="text-left label font-weight-normal">
              <b-form-input type="text" placeholder="Enter Division" class="unit"
                v-model="divisionPayload.division"
                :disabled="!allowAddDivision && (!editDivisionFlag || ( editDivisionFlag && !allowUpdateDivision))"                ></b-form-input>
              <p v-if="errors && errors.division" class="field-error mb-lg-3">
                {{ errors.division[0] }}
              </p>
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12" sm="12">
            <b-form-group label="Name:" class="text-left label font-weight-normal">
              <b-form-input type="text" placeholder="Enter Name" class="unit"
                v-model="divisionPayload.name"
                :disabled="!allowAddDivision && (!editDivisionFlag || ( editDivisionFlag && !allowUpdateDivision))"                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12" sm="12">
            <b-form-group label="Address:" class="text-left label font-weight-normal">
              <b-form-input type="text" placeholder="Enter Address" class="unit"
                v-model="divisionPayload.address"
                :disabled="!allowAddDivision && (!editDivisionFlag || ( editDivisionFlag && !allowUpdateDivision))"                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12" sm="12">
            <b-form-group label="City:" class="text-left label font-weight-normal">
              <b-form-input type="text" placeholder="Enter City" class="unit"
                v-model="divisionPayload.city"
                :disabled="!allowAddDivision && (!editDivisionFlag || ( editDivisionFlag && !allowUpdateDivision))"                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group label="State:" class="text-left">
              <v-select class="bg_white w-100" v-model="divisionPayload.state" :options="states" item-value="id"
                item-text="label" :reduce="(option) => option.id" 
                :disabled="!allowAddDivision && (!editDivisionFlag || ( editDivisionFlag && !allowUpdateDivision))"                />
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12" sm="12">
            <b-form-group label="Zip:" class="text-left label font-weight-normal">
              <b-form-input type="text" placeholder="Enter Zip" class="unit"
                v-model="divisionPayload.zipcode"
                :disabled="!allowAddDivision && (!editDivisionFlag || ( editDivisionFlag && !allowUpdateDivision))"                ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12" md="12" sm="12">
            <b-form-group label="Phone:" class="text-left position-relative mb-2">
              <vue-tel-input v-bind="bindProps" v-model="divisionPayload.phone" class="w-100 f-12" @input="checkNum" 
              :disabled="!allowAddDivision && (!editDivisionFlag || ( editDivisionFlag && !allowUpdateDivision))"
              />
              <p v-if="errors && errors.phone" class="field-error">
                {{ errors.phone[0] }}
              </p>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer>
        <div class="text-right modal-footer border-0 p-0 mx-2">
          <b-button class="mx-0 h-40px w-100px m-0" :class="(allowAddDivision && !editDivisionFlag) || (editDivisionFlag && allowUpdateDivision) ? 'mr-3':''" @click="resetForm()" variant="secondary">Cancel</b-button>
          <div class="d-flex align-items m-0">
            <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="updateDivisionData()"
            v-if="(allowAddDivision && !editDivisionFlag) || (editDivisionFlag && allowUpdateDivision)"
            >Save</b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Divisions",
  components: { vSelect },
  data() {
    return {
      editDivisionFlag: false,
      states: [],
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      fields: [
        {
          key: "division",
          label: "Division",
          sortable: true,
          thClass: 'font-table-weight-bold',
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
          thClass: 'font-table-weight-bold'
        },
        {
          key: "address",
          label: "Address",
          sortable: true,
          thClass: 'font-table-weight-bold'
        },
        {
          key: "phone",
          label: "Phone",
          sortable: true,
          thClass: 'font-table-weight-bold'
        },
        {
          key: "action",
          label: "Action",
          sortable: true,
          thClass: 'font-table-weight-bold'
        },
      ],
      divisionPayload: {
        division: "",
        name: "",
        address: "",
        city: "",
        zipcode: "",
        phone: "",
        state: null
      },
      perPage: 50,
      currentPage: 1,
      items: [],
      errors: {},
      isPhoneValid: false,
      divisionID: null,
      bindProps: {
        mode: "international",
        autoFormat: true,
        preferredCountries: ["US", "CA", "MX"],
        inputOptions: {
          placeholder: "Enter a phone no.",
          maxlength: 30,
        },
        dropdownOptions: {
          showSearchBox: true,
          showDialCodeInList: true,
          showFlags: true,
          showDialCodeInSelection: true,
          width: "300px",
        },
      },
    }
  },
  computed: {
    ...mapGetters(['allCountries','rolePermissions']),
    allowViewDivision(){
           return this.rolePermissions && this.rolePermissions['Division'] && this.rolePermissions['Division'][0].allow
        },
        allowAddDivision(){
           return this.rolePermissions && this.rolePermissions['Division'] && this.rolePermissions['Division'][2].allow
        },
        allowUpdateDivision(){
           return this.rolePermissions && this.rolePermissions['Division'] && this.rolePermissions['Division'][1].allow
        },
        allowDeleteDivision(){
           return this.rolePermissions && this.rolePermissions['Division'] && this.rolePermissions['Division'][3].allow
        },
    rows() {
      return this.items.length;
    },
    positionText() {
      let endIndex = this.currentPage * this.perPage,
        startIndex =
          this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

      return (
        startIndex +
        " to " +
        (endIndex > this.rows ? this.rows : endIndex) +
        " of " +
        this.rows
      );
    }
  },
  methods: {
    ...mapActions(['getAllCountries', 'getAllDivisions', 'updateDivision', 'setLoader', 'setNotification', 'addDivision']),
    checkNullValues(payload) {
      let nullKeys = [];
      for (let [key, value] of Object.entries(payload)) {
        if (!value) {
          nullKeys.push(key);
        }
      }
      return nullKeys;
    },
    editDivisionItem(data) {
      this.editDivisionFlag = true
      this.divisionPayload = {
        division: data.division,
        name: data.name,
        address: data.address,
        city: data.city,
        zipcode: data.zipcode,
        phone: data.phone,
        state: data.state.id
      }
      this.divisionID = data.id
      this.$bvModal.show('modal-division')
    },
    getAddress(data) {
      const address = `${data.address ? `${data.address}, ` : ''}${data.city ? `${data.city}, ` : ''}${data.state ? `${data.state.name} ` : ''}${data.zipcode ? `${data.zipcode}` : ''}`
      if (address) return address
      return '-'
    },
    async getCountries() {
      await this.getAllCountries();
      const countries = this.allCountries.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      this.getStates(countries[0]);
    },
    getStates(selectedOption) {
      this.states = this.allCountries
        .filter((country) => country.id === selectedOption.id)[0]
        .state.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      this.divisionPayload.state = this.states.filter(
        (state) => state.label === "-"
      )[0].id;
    },
    checkNum(data, phoneObject) {
      if (phoneObject.valid !== undefined) {
        this.isPhoneValid = phoneObject.valid;
      }
    },
    resetForm() {
      this.errors = {}
      this.$bvModal.hide('modal-division')
      this.editDivisionFlag = false,
        this.divisionPayload = {
          division: "",
          name: "",
          address: "",
          city: "",
          zipcode: "",
          phone: "",
          state: this.states.filter(
            (state) => state.label === "-"
          )[0].id
        }
    },
    async updateDivisionData() {
      try {
        this.errors = {}
        const nullKeys = this.checkNullValues({
          division: this.divisionPayload.division
        });
        if (nullKeys.length === 0) {
          if (this.divisionPayload.phone) {
          if (!this.isPhoneValid) {
            throw {
              phone: ["Wrong format"],
            };
          }
        }
          let method = 'added'
          if (this.editDivisionFlag) {
            method = 'updated'
            try {
              this.setLoader(true)
              console.log(this.divisionID)
              await this.updateDivision({
                id: this.divisionID,
                payload: { ...this.divisionPayload, company: this.userCompany }
              })
            }
            catch (e) {
              this.setLoader(false)
              await this.setNotification({
                msg: e,
                type: "error",
                color: "red",
              });
              return
            }
          }
          else {
            try {
              this.setLoader(true)
              await this.addDivision({ ...this.divisionPayload, company: this.userCompany })
            }
            catch (e) {
              this.setLoader(false)
              await this.setNotification({
                msg: e,
                type: "error",
                color: "red",
              });
              return
            }
          }
          await this.setNotification({
            msg: `Division successfully ${method}!!`,
            type: "success",
            color: "green",
          });
          this.setLoader(false)
          this.resetForm()
          this.getDivisionData()
        }
        else {
          let error = {};
          nullKeys.forEach((x) => {
            error[x] = ["This field is required."];
          });
          throw error;
        }
      }
      catch (error) {
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }
    },
    async getDivisionData() {
      try {
        const data = await this.getAllDivisions()
        this.items = []
        data.map((item) => {
          this.items.push({
            division: item.division,
            name: item.name,
            phone: item.phone,
            address: item.address,
            state: item.state_obj,
            city: item.city,
            zipcode: item.zipcode,
            id: item.id,
            default: item.default
          })
        })

      }
      catch (e) {
        console.log(e)
      }
    }
  },
  async mounted() {
    await this.getCountries();
    await this.getDivisionData()

  }
}
</script>