<template>
    <div>
        <b-modal id="modal-bulk-expiry-date-update" no-close-on-esc centered no-close-on-backdrop hide-footer
            :body-class="'pt-3'">
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Expiry Date
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="text-left mb-3">
                <label for="modal-bulk-expiry-date" class="state">Expiry Date:</label>
                <date-picker v-model="expiry_date" :formatter="momentFormat" @clear="expiry_date = ''"
                    placeholder="Select Date" class="date-picker-custom" :teleport="'body'"></date-picker>
                <p v-if="errors && errors.expiry_date" class="field-error">
                    {{ errors.expiry_date[0] }}
                </p>
            </div>
            <div class="text-right modal-footer border-0 p-0 mt-3">
                <b-button class="mx-0 mr-3 h-40px w-100px" @click="resetPopup()" variant="secondary">Cancel</b-button>
                <b-button class="mx-0 mb-0 h-40px w-100px mt-0" variant="primary" @click="bulkUpdateDocs">Save</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import DatePicker from 'vue2-datepicker';
import * as moment from "moment-timezone";
import 'vue2-datepicker/index.css';
export default {
    name: "BulkExpiryDateUpdate",
    components: { DatePicker },
    props: ["docIds"],
    data() {
        return {
            momentFormat: {
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            errors: {},
            expiry_date: ""
        }
    },
    methods: {
        ...mapActions(['updateBulkDocument', "setLoader", "setNotification"]),
        resetPopup() {
            this.expiry_date = ""
            this.errors = {}
            this.$bvModal.hide('modal-bulk-expiry-date-update')
            this.$emit('close')
        },
        async bulkUpdateDocs() {
            try {
                this.setLoader(true)
                await this.updateBulkDocument({
                    "ids": this.docIds,
                    "expiry_date": this.expiry_date ? moment(this.expiry_date).format("YYYY-MM-DD") : null
                })
                this.setLoader(false)
                await this.setNotification({
                    msg: `Documents Updated Successfully`,
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
                this.$emit("updateDocs")
                this.resetPopup()
            }

            catch (error) {
                console.log(error)
                this.setLoader(false)
                if (typeof error === "object") {
                    this.errors = error;
                }
            }
        },
    },
}
</script>