<template>
    <div>
        <b-form @submit.prevent="checkUserEmailExists" v-if="!userExists && !newUser">
            <b-form-group label="Email:" label-for="check-user" class="text-left label text-capitalize">
                <b-form-input type="email" placeholder="Enter Email" class="input" required
                    v-model="user.email"></b-form-input>
            </b-form-group>
            <b-button type="submit" class="w-100 mt-3 login" variant="primary">Next</b-button>
        </b-form>
        <b-form @submit.prevent="verifyUserEmail" v-else-if="newUser && !userExists">
            <b-form-group label="Email:" label-for="check-user" class="text-left label text-capitalize">
                <b-form-input type="email" placeholder="Enter Email" disabled class="input" required
                    v-model="user.email"></b-form-input>
            </b-form-group>
            <b-form-group label="OTP Code:" label-for="check-user" class="text-left label text-capitalize">
                <b-form-input type="text" placeholder="Enter OTP Code" class="input" required
                    v-model="user.otp"></b-form-input>
            </b-form-group>
            <b-button type="submit" class="w-100 mt-3 login" variant="primary">Next</b-button>
        </b-form>
        <b-form @submit.prevent="onLogin" v-else-if="userExists && !newUser">
            <b-form-group label="Email:" label-for="check-user" class="text-left label text-capitalize">
                <b-form-input type="email" placeholder="Enter Email" class="input" required
                    v-model="user.email"></b-form-input>
            </b-form-group>
            <b-form-group label="Password:" class="text-left label password password-2">
                <b-form-input class="input" placeholder="Enter Password" required :type="showPassword ? 'text' : 'password'"
                    v-model="user.password1"></b-form-input>
                <b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'" @click="showPassword = !showPassword"></b-icon>
            </b-form-group>
            <b-button type="submit" class="w-100 mt-3 login h-40px" variant="primary">Next</b-button>
        </b-form>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showPassword: false
        }
    },
    name: "Step1",
    props: ['checkUserEmailExists', 'user', 'userExists', 'onLogin','newUser','verifyUserEmail']
}
</script>