<template>
  <div>
    <div id="sidebar-wrapper" class="sidebar">
      <!-- <div class="logo-details" :class="{ 'd-none': openSidebar && window.screen.width <= 767 }">
        <router-link to="/">
          <b-img class="small-logo" :src="require('@/assets/small-logo.png')" width="29px" height="32px"></b-img>
        </router-link>
        <div class="logo_name d-flex justify-content-between align-items-center w-100">
          <router-link to="/">
            <b-img class="" :src="require('@/assets/form-logo.png')" width="125" height="35"></b-img>
          </router-link>
          <svg width="10" class="cursor-pointer d-block d-md-none" @click="toggleMenu" height="14" viewBox="0 0 10 14"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.42859 13L1.57145 7L8.42859 1" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
      </div> -->
      <ul class="nav-list position-relative mt-0" :class="{ 'd-none': openSidebar && window.screen.width <= 767 }">
        <li class="my-0">
          <b-dropdown no-caret dropdown :disabled="userCompanies.length === 1" class="mb-0 mt-1 company-dropdown"
            :class="openSidebar ? 'company-dropdown-half-menu' : 'd-company-dropdown-block'">
            <template #button-content>
              <div class="d-flex align-items-center"
                :class="openSidebar ? 'justify-content-center' : 'justify-content-between'">
                <div class="d-flex align-items-center">
                  <div class="companies-icon-section f-18 active" v-if="company[0]">
                    {{ company[0].name.charAt(0).toUpperCase() }}
                  </div>
                  <span class="f-14 ml-3 company-name" v-if="company[0]" :class="openSidebar ? 'd-none' : 'd-block'">{{ company[0].name
                  }}</span>
                </div>
                <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 11L5 15L9 11" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                  <path d="M9 5L5 1L1 5" stroke="white" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>

              </div>
            </template>
            <b-dropdown-item class="f-14" to='/all-companies'>View All...</b-dropdown-item>
            <b-dropdown-item @click="setUserCompany(item.id)" class="f-14 d-flex align-items-center company-names-section"
              v-for="(item, index) in userCompanies" :key="index">
              <div class="companies-icon-section bg-black f-18">
                {{ item.name.charAt(0).toUpperCase() }}
              </div>
              <span class="ml-3">{{ item.name }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </li>
        <hr class="mt-0 mb-4 company-dropdown-line-break"/>
        <li>
          <SidebarItem :routeTo="'/'" :name="'Dashboard'" :activeRoute="activeRoute"
            :hoverImg="require('@/assets/dashboard-active.svg')" :img="require('@/assets/dashboard.svg')" />
        </li>
        <li v-if="showDriverSection">
          <SidebarItem :routeTo="'/drivers'" :name="'Drivers'" :activeRoute="activeRoute"
            :hoverImg="require('@/assets/drivers-active.svg')" :img="require('@/assets/drivers.svg')" />
        </li>
        <b-button v-if="showEquipmentSection" :class="[activeRoute.includes('/equipment-list') ? 'active' : '', isEquipmentHovered ? 'hover' : '']"
          v-b-toggle.collapse-equipment class="d-flex align-items-center justify-content-start sidebar-accordion"
          @mouseover="isEquipmentHovered = true" @mouseleave="isEquipmentHovered = false"
          >
          <img
            :src="activeRoute.includes('/equipment-list') || isEquipmentHovered ? require('@/assets/equipment-active.svg') : require('@/assets/equipment.svg')"
            alt="equipment" />
          <span>Equipment</span>
          <div class="when-opened ml-auto">
            <i class="fa fa-angle-up" aria-hidden="true"></i>
          </div>
          <div class="when-closed ml-auto">
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </div>
        </b-button>
        <b-collapse id="collapse-equipment" v-model="visibleEquipmentDropdown">
          <li>
            <SidebarItem :routeTo="'/equipment-list/vehicles'" :name="'Vehicles'" :activeRoute="activeRoute" />
          </li>
          <li>
            <SidebarItem :routeTo="'/equipment-list/trailers'" :name="'Trailers'" :activeRoute="activeRoute" />
          </li>
        </b-collapse>
        <li>
          <SidebarItem :routeTo="'/documents'" :name="'Documents'" :activeRoute="activeRoute"
            :hoverImg="require('@/assets/documents-active.svg')" :img="require('@/assets/documents.svg')" />
        </li>
        <li v-if="(company && company[0] && company[0].show_maintenance) && showMaintenanceSection">
          <SidebarItem :routeTo="'/maintenance'" :name="'Maintenance'" :activeRoute="activeRoute"
            :hoverImg="require('@/assets/maintenance-active.svg')" :img="require('@/assets/maintenance.svg')" />
        </li>
        <li v-if="(company && company[0] && company[0].show_safety) && showSafetySection">
          <SidebarItem :routeTo="'/safety'" :name="'Safety'" :activeRoute="activeRoute"
            :hoverImg="require('@/assets/safety-active.svg')" :img="require('@/assets/safety.svg')" />
        </li>
        <li v-if="showReportsSection">
          <SidebarItem :routeTo="'/reports'" :name="'Reports'" :activeRoute="activeRoute"
            :hoverImg="require('@/assets/report-active.svg')" :img="require('@/assets/report.svg')" />
        </li>
        <li>
          <SidebarItem :routeTo="'/company'" :name="'Company'" :activeRoute="activeRoute"
            :hoverImg="require('@/assets/company-active.svg')" :img="require('@/assets/company.svg')" />
        </li>
        <!-- <li class="sidebar-close-btn my-0">
          <hr class="my-0" />
          <div class="text-center cursor-pointer" @click="toggleMenu">
            <svg v-if="openSidebar" width="8" height="14" viewBox="0 0 8 14" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13L7 7L1 1" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <svg v-else width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.42859 13L1.57145 7L8.42859 1" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>

          </div>
        </li> -->
      </ul>
    </div>
    <button class="navbar-toggler d-block d-sm-none" type="button" id="menu-toggle"
      data-bs-target="#navbar-collapse-x" @click="toggleMenu">
      <div class="hamburger-toggle" :class="{ active: openSidebar }">
        <div class="hamburger">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </button>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "../../router";
import SidebarItem from "./SidebarItem.vue";
export default {
  name: "Sidebar",
  components: { SidebarItem },
  data() {
    return {
      openSidebar: false,
      activeRoute: "/",
      companyName: "",
      window,
      isEquipmentHovered:false,
      visibleEquipmentDropdown:false
    };
  },
  computed: {
    ...mapGetters(["userCompanies", "userCompany", "company", 'rolePermissions']),
    showEquipmentSection() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][0].allow
    },
    showDriverSection() {
      return this.rolePermissions && this.rolePermissions['Drivers'] && (this.rolePermissions['Drivers'][0].allow || this.rolePermissions['Drivers'][1].allow)
    },
    showMaintenanceSection() {
      return this.rolePermissions && this.rolePermissions['Maintenance'] && this.rolePermissions['Maintenance'][0].allow
    },
    showReportsSection() {
      return this.rolePermissions && this.rolePermissions['Reports'] && this.rolePermissions['Reports'][0].allow
    },
    showSafetySection() {
      return this.rolePermissions && this.rolePermissions['Safety'] && this.rolePermissions['Safety'][0].allow
    }
  },
  watch: {
    $route: {
      handler() {
        this.activeRoute = this.$route.path;
        if(this.activeRoute.includes('/equipment-list')) this.visibleEquipmentDropdown = true
        else this.visibleEquipmentDropdown = false
        if (window.screen.width <= 767) {
          this.openSidebar = true;
          this.$emit("sidebarState", this.openSidebar);
        }
      },
      immediate: true,
    },
    userCompanies: {
      handler() {
        this.companyName = this.userCompanies.filter((company) => company.id == this.userCompany)[0]?.name
      },
    },
  },
  methods: {
    ...mapActions(["logoutUser"]),
    logout() {
      this.logoutUser();
      this.$intercom.shutdown()
      router.push("/login");
    },
    toggleMenu() {
      this.openSidebar = !this.openSidebar;
      this.$emit("sidebarState", this.openSidebar);
    },
    setUserCompany(id) {
      localStorage.setItem('userSelectedCompany', id)
      window.location.reload()
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

#wrapper {
  padding-left: 240px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

.company-dropdown .company-name {
  max-width: 122px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

#sidebar-wrapper {
  z-index: 6;
  position: fixed;
  left: 250px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #000;
  margin-left: -250px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  /* z-index: 10000; */
}

.sidebar-brand a img {
  width: 130px;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

.sidebar-close-btn {
  position: absolute !important;
  bottom: 90px;
  left: 0;
  right: 0;
}

.sidebar-close-btn>hr {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.sidebar-close-btn>div {
  margin-top: 0.8rem;
}

#page-content-wrapper .container-fluid .border {
  border-bottom: none !important;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

.company-dropdown-half-menu>button>div>svg {
  display: none;
}

/* TOGGLED */
.navbar-toggler {
  border: none;
  padding: 0;
  outline: none;
  left: 0;
  top: -100px;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler .hamburger-toggle {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  z-index: 11;
  top: 7px;
  right: -114px;
}

.navbar-toggler .hamburger-toggle .hamburger {
  position: absolute;
  transform: translate(0, 0) rotate(45deg);
  right: 42px;
  top: 0px;
  width: 0%;
  height: 50%;
  pointer-events: none;
  z-index: 1000;
}

.navbar-toggler .hamburger-toggle .hamburger span {
  width: 25px;
  height: 3px;
  position: absolute;
  background: #2D69F6;
  border-radius: 2px;
  z-index: 1;
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.2s cubic-bezier(0.77, 0.2, 0.05, 1), all 0.2s ease-in-out;
}

.navbar-toggler .hamburger-toggle .hamburger span:first-child {
  top: 1%;
  left: 2.5px;
  transform: rotate(-45deg);
}

.navbar-toggler .hamburger-toggle .hamburger span:nth-child(2) {
  top: 5px;
  left: 7px;
  transform: rotate(-45deg);
}

.navbar-toggler .hamburger-toggle .hamburger span:last-child {
  left: 12px;
  bottom: 50%;
  transform: rotate(-45deg);
}

/* Sidebar Styles */
.sidebar-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

#wrapper.toggled {
  padding-left: 62px;
}

#sidebar-wrapper {
  width: 240px;
  padding: 0;
}

#wrapper.toggled #sidebar-wrapper {
  width: 70px;
}

#wrapper.toggled #sidebar-wrapper .sidebar {
  opacity: 0;
}

#page-content-wrapper {
  padding: 0;
  position: relative;
}

#wrapper.toggled #page-content-wrapper {
  position: relative;
  margin-right: 0;
}

.d-company-dropdown-block {
  display: block;
}

.company-dropdown-half-menu>button {
  width: 60px !important;
  padding: 0.5rem !important;
}


.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 77px;
  background: #11101d;
  z-index: 99;
  transition: all 0.5s ease;
}

.sidebar.open {
  width: inherit;
}

.sidebar .logo-details {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 36.5px 17px;
}

.sidebar .logo-details .icon {
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  opacity: 1;
  transition: all 0.5s ease;
}

#wrapper.toggled .logo-details .logo_name {
  opacity: 0;
}

.sidebar .logo-details .logo_name .close {
  color: rgb(255 255 255 / 40%);
  cursor: pointer;
}

.sidebar .logo-details .logo_name .close :not(svg) {
  color: #fff !important;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
  opacity: 1;
}

.sidebar .logo-details #btn {
  position: fixed;
  top: 20px;
  left: 30px;
  transform: translate(60px, 0px);
  font-size: 22px;
  transition: all 0.4s ease;
  font-size: 23px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;
  color: #fff;
  border-radius: 5px;
}

.sidebar.open .logo-details #btn {
  visibility: hidden;
  opacity: 0;
}

.sidebar i {
  color: #fff;
  height: 30px;
  min-width: 43px;
}

.sidebar .nav-list {
  margin-top: 10px;
  height: 100%;
  padding: 0;
  overflow-y: auto;
}

.sidebar li {
  position: relative;
  margin: 8px 10px;
  list-style: none;
  width: 220px;
}

.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}

.sidebar .bx-search {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 22px;
  color: #fff;
}

.sidebar li a {
  display: flex;
  height: 49px;
  width: 100%;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 5px;
}

.sidebar li a.active,
.sidebar li a:active {
  background: rgba(255, 255, 255, 0.1) !important;
}

.sidebar li a:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}

/* .sidebar li a.active img,
.sidebar li a:active img {
  margin-left: 17px;
}

.sidebar li a.active img {
  margin-left: 22px !important;
} */

.sidebar li a.router-link-exact-active .links_name,
.sidebar li a:hover .links_name {
  opacity: 1;
}

.sidebar li a .links_name {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  opacity: 0.6;
  pointer-events: none;
  transition: 0.4s;
  padding: 20px 0px;
}

#wrapper.toggled .links_name {
  opacity: 0;
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}

#wrapper .logo-details .small-logo {
  display: none;
}


#wrapper #menu-toggle {
  display: block;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 6;
}

#wrapper.toggled .logo-details .small-logo {
  display: block;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
  transition: all 0.5s ease;
  color: #fff;
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}

.sidebar li.profile {
  position: absolute;
  height: 60px;
  left: 0;
  right: 0;
  bottom: -8px;
  padding: 10px 14px;
  transition: all 0.5s ease;
  overflow: hidden;
  cursor: pointer;
}

.sidebar.open li.profile {
  width: 100%;
}

.sidebar li img {
  height: 20px;
  min-width: 50px;
  object-fit: contain;
  /* margin-left: 20px; */
}

/* .sidebar li img {
  margin-left: 25px !important;
} */

.sidebar .bx-log-out img {
  margin-left: 0px;
  margin-right: 15px;
  cursor: pointer;
}

.sidebar li.profile .name,
.sidebar li.profile .job {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  white-space: nowrap;
}

.sidebar li.profile .job {
  font-size: 12px;
}

.sidebar .profile #log_out {
  position: absolute;
  top: 50%;
  left: 0;
  padding-left: 17px;
  text-align: left;
  transform: translateY(-50%);
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-radius: 0px;
  transition: all 0.5s ease;
}


.sidebar .profile .links_name {
  font-style: normal;
  font-size: 14px;
}

.sidebar.open .profile #log_out {
  width: 50px;
  background: none;
}

.home-section {
  position: relative;
  background: #e4e9f7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}

.sidebar.open~.home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 18px;
}


.company-dropdown>button {
  background-color: transparent !important;
  color: white !important;
  width: 215px;
  height: 51px;
  border-radius: 5px !important;
  border: none !important
}

.company-dropdown>ul {
  background-color: white !important;
  color: black !important;
  width: 100%;
  z-index: 1;
  padding: 0px !important;
  border-radius: 5px !important;
  max-height: 180px;
  overflow-y: auto;
  width: 222px;
  transform: translate3d(4px, 45px, 0px) !important;
}

.company-dropdown-half-menu>ul {
  position: fixed !important;
  top: 0px;
  left: 0px;
  transform: translate3d(20px, 130px, 8px) !important;
}


.company-dropdown>ul>li {
  margin: 0rem !important;
}

.company-dropdown>ul>li>a {
  padding: 0rem 0.75rem !important;
  font-weight: 500;
}

.company-dropdown>ul>li>a:hover {
  background-color: #F6F7F9 !important;
}

.companies-icon-section.active {
  background-color: white !important;
  color: black !important
}

.company-dropdown .disabled {
  opacity: 1 !important;
  cursor: auto;
}

.company-names-section>a>span {
  max-width: 200px;
  padding: 0;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
}

.sidebar-accordion {
  height: 49px;
  background: transparent !important;
  color: white !important;
  padding: 0px !important;
  border: none !important;
  width: 220px;
  margin-left: 10px;
  margin-right: 10px;
}

.sidebar-accordion>img {
  height: 20px;
  min-width: 50px;
  object-fit: contain;
}

.sidebar-accordion>span {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  white-space: nowrap;
  opacity: 0.6;
  pointer-events: none;
  transition: 0.4s;
  padding: 20px 0px;
}

.sidebar-accordion i {
  opacity: 0.6;
  font-size: 20px;
  min-width: 39px;
  position: relative;
  top: 4px;
}
.sidebar-accordion.hover {
  background: rgba(255, 255, 255, 0.1) !important;
}
/* .sidebar-accordion.active {
  background: rgba(255, 255, 255, 0.1) !important;
} */

.sidebar-accordion.active>span,
.sidebar-accordion.active i, .sidebar-accordion.hover>span, .sidebar-accordion.hover i {
  opacity: 1;
}
.company-dropdown-line-break{
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
  display: none;
}

@media (max-width: 991px) {
  .sidebar .logo-details {
    padding: 36.5px 18px;
  }

  .sidebar.open .logo-details .logo_name img {
    width: 130px !important;
  }
}

@media (max-width: 767px) {
  #wrapper.toggled {
    padding-left: 0px;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 0px;
  }

}

/* @media (max-width: 767px) {
  #wrapper.toggle #menu-toggle {
    display: none;
  }

  #wrapper #sidebar-wrapper {
    width: 77px;
    overflow: hidden;
  }

  #wrapper {
    padding-left: 0px;
  }

  #wrapper .links_name,
  #wrapper .logo_name {
    opacity: 0;
  }

  #wrapper .small-logo {
    display: block !important;
    margin-left: 5px;
  }

  .sidebar li img {
    margin-left: 28px;
  }

  .sidebar li a.active img,
  .sidebar li a:active img {
    margin-left: 26px;
  }

  .sidebar .bx-log-out img {
    margin-left: 10px;
  }
  .company-dropdown{
    display: none !important;
  }
} */

@media (max-width: 575px) {
  .sidebar .logo-details #btn {
    top: 40px;
    left: 25px;
  }
  #wrapper {
    padding-left: 0px;
  }
  #sidebar-wrapper{
    top:57px;
  }
  .company-dropdown {
    width: 100%;
  }
  .company-dropdown>button {
    width: 100%;
    padding: 0.375rem 0.8rem;
  }

  .navbar-toggler .hamburger-toggle {
    right: -42px;
  }
}
</style>
