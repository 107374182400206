<template>
    <div>
        <div class="filter-box">
            <div class="d-flex mb-4 px-3 px-sm-4">
                <b-form-group class="text-left label font-weight-normal search-field mb-0">
                    <b-input-group class="username-input-field">
                        <b-form-input type="text" placeholder="Search" v-model="filterFields.search" class="unit f-12"
                            @keyup.enter="getMaintenanceTotalData()"></b-form-input>
                        <b-input-group-append>
                            <b-button variant="primary" @click="getMaintenanceTotalData()">
                                <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                        stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group class="d-none d-md-block mb-0">
                    <date-picker v-model="filterFields.date" :formatter="momentFormat" @change="getMaintenanceTotalData()"
                        :range-separator="' - '" placeholder="Select Date" @clear="filterFields.date = ''" range
                        :shortcuts="shortcuts"></date-picker>
                </b-form-group>
                <div class="ml-auto d-none d-sm-flex flex-wrap align-items-center">
                    <div class="position-relative">
                        <div class="position-absolute notification-badge" v-if="activeFiltersCount">
                            {{ activeFiltersCount }}
                        </div>
                        <button class="btn btn-filter-section" @click="$bvModal.show('modal-filter')">
                            <div class="d-flex align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="lucide lucide-sliders-horizontal w-4 h-4"
                                    data-id="element-964">
                                    <line x1="21" x2="14" y1="4" y2="4"></line>
                                    <line x1="10" x2="3" y1="4" y2="4"></line>
                                    <line x1="21" x2="12" y1="12" y2="12"></line>
                                    <line x1="8" x2="3" y1="12" y2="12"></line>
                                    <line x1="21" x2="16" y1="20" y2="20"></line>
                                    <line x1="12" x2="3" y1="20" y2="20"></line>
                                    <line x1="14" x2="14" y1="2" y2="6"></line>
                                    <line x1="8" x2="8" y1="10" y2="14"></line>
                                    <line x1="16" x2="16" y1="18" y2="22"></line>
                                </svg>
                                <p class="mb-0 ml-2">Filters</p>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <b-table class="mt-2" responsive :fields="visibleFields" :items="items" hover :current-page="currentPage"
            :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy"
            :sticky-header="'calc(100vh - 330px)'" :show-empty="emptyTable">

            <template #cell(amount)="data">
                {{
                    data.value !== "-" ? `$${getAmount(data.value)}` : "-"
                }}
            </template>
            <template v-slot:custom-foot>
                <tr class="sticky-footer">
                    <td></td>
                    <td></td>
                    <th class="text-left f-14">{{ totalAmount ? `$${getAmount(totalAmount)}` : "-" }}</th>
                </tr>
            </template>
        </b-table>
        <div class="entries-pagination px-0 px-sm-2">
            <div class="d-flex align-items-center flex-wrap">
                <div class="text-left show-entries mr-md-3 pl-3 pl-md-0 ml-md-0">
                    <b-form-select v-model="perPage" :options="pageOptions" />
                </div>
                <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
                <div class="pagination">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                        aria-controls="my-table"></b-pagination>
                </div>
            </div>
        </div>
        <b-modal id="modal-filter" centered no-close-on-backdrop :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
            :body-class="'px-3 pt-3 pb-1'" no-close-on-esc>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Filters
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="body">
                <b-form-group label="Type" class="text-left select_arrow label mb-3">
                    <b-form-select v-model="filterFields.type" class="input f-12" :options="[
                        {
                            value: '',
                            text: '',
                        },
                        {
                            value: 'Vehicle',
                            text: 'Vehicle',
                        },
                        {
                            value: 'Trailer',
                            text: 'Trailer',
                        }
                    ]" @change="getMaintenanceTotalData()"></b-form-select>
                </b-form-group>
                <b-form-group label="Tags" class="text-left w-equipment-box mb-3">
                    <v-select multiple class="f-12 bg-grey" :options="allTags" :reduce="(option) => option.id"
                        v-model="filterFields.tags" @input="getMaintenanceTotalData()" />
                </b-form-group>

            </div>
            <template #modal-footer="">
                <div class="text-right modal-footer border-0 p-0 mt-0 justify-content-between mx-0">
                    <b-button class="mx-0 mb-0 h-40px w-100px m-0 btn-outline-primary" variant="primary"
                        @click="resetFilterFields(); $bvModal.hide('modal-filter')">Reset</b-button>
                    <b-button class="mx-0 h-40px w-100px m-0 m-0" block
                        @click="$bvModal.hide('modal-filter')">Close</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css"
import * as moment from "moment-timezone";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name: "Totals",
    components: { vSelect, DatePicker },
    data() {
        return {
            filterFields: {
                search: '',
                type: '',
                date: '',
                tags: [],
                status: ''
            },
            momentFormat: {
                //[optional] Date to String
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                //[optional]  String to Date
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            fields: [
                {
                    key: "unit_no",
                    label: "Unit #",
                    sortable: true,
                    visible: true,
                    disabled: true,
                    field: "text",
                    stickyColumn: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "type",
                    label: "Type",
                    sortable: true,
                    visible: true,
                    disabled: true,
                    field: "text",
                    stickyColumn: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "amount",
                    label: "Amount",
                    sortable: true,
                    visible: true,
                    disabled: false,
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
            ],
            items: [],
            rows: 0,
            totalAmount: 0,
            allTags: [],
            positionText: "",
            sortBy: undefined,
            sortDesc: undefined,
            isBusy: false,
            perPage: 10,
            emptyTable: false,
            currentPage: 1,
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
            shortcuts: [
                {
                    text: 'This Week',
                    onClick: () => {
                        const today = new Date();
                        const dayOfWeek = today.getDay() || 7;
                        const startOfWeek = new Date(today);
                        startOfWeek.setDate(today.getDate() - dayOfWeek + 1);
                        this.filterFields.date = [startOfWeek, today];
                        this.getMaintenanceTotalData()
                    },
                },
                {
                    text: 'This Month',
                    onClick: () => {
                        const today = new Date();
                        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                        this.filterFields.date = [startOfMonth, today];
                        this.getMaintenanceTotalData()
                    },
                },
                {
                    text: 'This Year',
                    onClick: () => {
                        const today = new Date();
                        const startOfYear = new Date(today.getFullYear(), 0, 1);
                        this.filterFields.date = [startOfYear, today];
                        this.getMaintenanceTotalData()
                    },
                },
                {
                    text: 'Last Week',
                    onClick: () => {
                        const today = new Date();
                        const dayOfWeek = today.getDay() || 7;
                        const startOfLastWeek = new Date(today);
                        startOfLastWeek.setDate(today.getDate() - dayOfWeek - 6);
                        const endOfLastWeek = new Date(today);
                        endOfLastWeek.setDate(today.getDate() - dayOfWeek);
                        this.filterFields.date = [startOfLastWeek, endOfLastWeek];
                        this.getMaintenanceTotalData()
                    },
                },
                {
                    text: 'Last Month',
                    onClick: () => {
                        const today = new Date();
                        const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                        const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
                        this.filterFields.date = [startOfLastMonth, endOfLastMonth];
                        this.getMaintenanceTotalData()
                    },
                },
                {
                    text: 'Last Year',
                    onClick: () => {
                        const today = new Date();
                        const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
                        const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31);
                        this.filterFields.date = [startOfLastYear, endOfLastYear];
                        this.getMaintenanceTotalData()
                    },
                },
            ]
        }
    },
    methods: {
        ...mapActions(["getMaintenanceTotal", "getAllTags", "getMaintenanceTotalRecordsExportData", "setLoader", "setNotification"]),
        getAmount(val) {
            return parseFloat(val).toLocaleString("en", {
                useGrouping: false,
                minimumFractionDigits: 2,
            });
        },
        async resetFilterFields() {
            this.filterFields = {
                search: '',
                type: '',
                date: '',
                tags: [],
                status: ''
            }
            await this.getMaintenanceTotalData()
        },
        async downloadExcel() {
            try {
                this.setLoader(true)
                let url = ""
                let tags = ""
                Object.keys(this.filterFields).map((key) => {
                    if (key === "tags" && this.filterFields[key].length > 0) {
                        this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
                        url += `${key}=${tags}&`
                    }
                    else if (key === 'date' && this.filterFields['date'][0]) {
                        url += `start_date=${moment(this.filterFields['date'][0]).format("YYYY-MM-DD")}&end_date=${moment(this.filterFields['date'][1]).format("YYYY-MM-DD")}&`
                    }
                    else if (this.filterFields[key] !== '' && key !== 'tags' && key !== 'date') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=unit_no&sort_order=asc&`
                }
                const data = await this.getMaintenanceTotalRecordsExportData(url)
                const blob = new Blob([data], { type: 'text/csv' });
                const csvUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = csvUrl;
                a.download = 'total-records.csv';
                a.target = "_blank"
                document.body.appendChild(a);
                a.click();
                a.remove()
                window.URL.revokeObjectURL(url);
                await this.setNotification({
                    msg: `File Exported Successfully`,
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
            }
            catch (e) {
                this.setLoader(false)
                console.log(e)
            }
        },
        async getMaintenanceTotalData() {
            try {
                this.isBusy = true
                let url = ""
                let tags = ""
                Object.keys(this.filterFields).map((key) => {
                    if (key === "tags" && this.filterFields[key].length > 0) {
                        this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
                        url += `${key}=${tags}&`
                    }
                    else if (key === 'date' && this.filterFields['date'][0]) {
                        url += `start_date=${moment(this.filterFields['date'][0]).format("YYYY-MM-DD")}&end_date=${moment(this.filterFields['date'][1]).format("YYYY-MM-DD")}&`
                    }
                    else if (this.filterFields[key] !== '' && key !== 'tags' && key !== 'date') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=unit_no&sort_order=asc&`
                }
                url += `page=${this.currentPage}&page_size=${this.perPage}`
                console.log(url)
                const data = await this.getMaintenanceTotal(url);
                if (data.total_entries) {
                    this.rows = data.total_entries
                    this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} - ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
                }
                else {
                    this.rows = 0
                    this.positionText = `0 - 0 of 0`
                }
                this.totalAmount = data.total_amount
                this.items = [];
                data.records.forEach(async (m, id) => {
                    this.items.push({
                        id: id,
                        pk: m.id,
                        unit_no: m.unit_no,
                        type: m.type,
                        amount: m.total_maintenance_amount
                    });
                });
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
                this.isBusy = false
                console.log(this.items)
            }
            catch (e) {
                this.isBusy = false
                console.log(e)
            }
        },
    },
    computed: {
        ...mapGetters(['tags', 'company', 'userCompany']),
        visibleFields() {
            return this.fields.filter(field => field.visible)
        },
        activeFiltersCount() {
            return Object.keys(this.filterFields).reduce((count, key) => {
                if (key === 'tags') {
                    return count + (this.filterFields.tags.length > 0 ? 1 : 0);
                } else if (
                    this.filterFields[key] !== '' &&
                    this.filterFields[key] !== null
                ) {
                    return count + 1;
                }
                return count;
            }, 0);
        },
    },
    watch: {
        perPage: {
            async handler() {
                await this.getMaintenanceTotalData();
            },
            immediate: true,
        },
        currentPage: {
            async handler() {
                await this.getMaintenanceTotalData();
            },
        },
        sortBy: {
            async handler() {
                await this.getMaintenanceTotalData();
            },
        },
        sortDesc: {
            async handler() {
                await this.getMaintenanceTotalData();
            },
        },
        filterFields: {
            handler() {
                this.currentPage = 1
            },
            deep: true
        },
        company: {
            async handler() {
                await this.getAllTags(this.userCompany);
                this.tags.map((item) => {
                    this.allTags.push({
                        id: item.add_tag,
                        label: item.add_tag,
                    });
                });
            },
            deep: true,
            immediate: true
        }
    }
}
</script>