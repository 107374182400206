<template>
    <div @mouseover="isHovered = true" @mouseleave="isHovered = false">
        <router-link :to="routeTo"
            :class="[(activeRoute.includes(routeTo) && routeTo !== '/') || (activeRoute === routeTo) ? 'active' : '', img ? '':'no-img']">
            <b-img class="" v-if="img" :src="((activeRoute.includes(routeTo) && routeTo !== '/') || (activeRoute === routeTo) || isHovered)
                ? hoverImg
                : img
                "></b-img>
            <span class="links_name">{{ name }}</span>
        </router-link>
    </div>
</template>
<script>
export default {
    name: "SidebarItem",
    props: ["routeTo", "activeRoute", 'img', 'hoverImg', "name"],
    data() {
        return {
            isHovered: false
        }
    },
}
</script>
<style>
.sidebar li a.no-img {
    width: 185px;
    margin: 10px 35px 0px;
    display: flex;
    border-left: none !important;
    border-radius: 5px;
}
.sidebar li a.no-img .links_name {
    margin-left: 14px;
}
.sidebar li a.no-img.active {
    background: rgba(255, 255, 255, 0.1) !important;
}
</style>