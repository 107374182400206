<template>
    <div>
        <b-modal id="modal-load-sample-data" class="maintenance_modal" centered hide-footer :header-bg-variant="'white'"
            header-class="border-none" no-close-on-esc no-close-on-backdrop>
            <template #modal-header="{ close }">
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="d-block text-center">
                <h3 class="f-18 text-center mb-3 font-weight-bold">Would you like to load sample data?</h3>
                <p class="mb-3 f-14">Start testing now with sample drivers, vehicles, trailers and documents already loaded
                    for you.</p>
                <b-button variant="primary" class="mb-3 schedule-btn h-40px text-white d-flex align-items-center justify-content-center mx-auto" @click="loadSampleData()">Yes</b-button>
                <b-button variant="secondary" class="mb-3 schedule-btn h-40px d-flex align-items-center justify-content-center mx-auto" @click="closePopup()">No</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
    name: "LoadSampleData",
    methods: {
        ...mapActions(['setLoader', "loadCompanySampleData", "setNotification"]),
        closePopup() {
            this.$bvModal.hide("modal-load-sample-data");
            this.$router.push("/all-companies");
        },
        async loadSampleData() {
            try {
                this.setLoader(true);
                await this.loadCompanySampleData()
                await this.setNotification({
                    msg: "Data Imported Successfully",
                    type: "success",
                    color: "green",
                });
                this.closePopup()
                this.setLoader(false);
            }
            catch (e) {
                this.setLoader(false);

            }
        },
    }
}
</script>