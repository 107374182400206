<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" :title="'Add Driver'" backdrop shadow
            :visible="allowAddTeamMember" :right="true" no-header-close no-close-on-backdrop no-close-on-esc>
            <div class="d-block text-center mt-3">
                <b-row class="align-items-center px-4">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="First Name * :" class="text-left mb-0">
                            <b-form-input v-model="newTeamMember.first_name" type="text" placeholder="Enter First Name"
                                class="unit" required></b-form-input>
                            <p v-if="errors && errors.first_name" class="field-error">
                                {{ errors.first_name[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Last Name * :" class="text-left mb-0">
                            <b-form-input v-model="newTeamMember.last_name" type="text" placeholder="Enter Last Name"
                                class="unit" required></b-form-input>
                            <p v-if="errors && errors.last_name" class="field-error">
                                {{ errors.last_name[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Username * :" class="text-left mb-0">
                            <b-form-input type="text" autocomplete="new-username" placeholder="Enter Username"
                                v-model="newTeamMember.username" class="unit"></b-form-input>
                            <p v-if="errors && errors.username" class="field-error text-left">
                                {{ errors.username[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Password * :"
                            class="text-left mb-0 label font-weight-normal password show-password-section">
                            <b-form-input :type="showPassword ? 'text' : 'password'" autocomplete="new-password"
                                placeholder="Enter Password" v-model="newTeamMember.password" class="unit"></b-form-input>
                            <b-icon :icon="showPassword ? 'eye-fill' : 'eye-slash-fill'"
                                @click="showPassword = !showPassword"></b-icon>
                            <p v-if="errors && errors.password" class="field-error text-left">
                                {{ errors.password[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Email:" class="text-left mb-0">
                            <b-form-input v-model="newTeamMember.email" type="text" placeholder="Enter Email" class="unit"
                                required></b-form-input>
                            <p v-if="errors &&
                                errors.email
                                " class="field-error">
                                {{ errors.email[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Phone:" class="text-left position-relative mb-0">
                                <b-input-group size="lg" class="w-100" prepend="+1">
                                            <b-form-input v-mask="phoneMask" class="f-14" v-model="newTeamMember.phone"
                                                ></b-form-input>
                                        </b-input-group>
                            <p v-if="errors && errors.phone" class="field-error">
                                {{ errors.phone[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="License #:" class="text-left mb-0">
                            <b-form-input v-model="newTeamMember.license_number" type="text" placeholder="Enter License #"
                                class="unit" required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">State:</label>
                        <v-select class="f-12 h-40px" :options="states" :reduce="(option) => option.id"
                            v-model="newTeamMember.license_state.id" />
                    </b-col>
                    <b-col lg="6" md="12" sm="12" class="text-left mt-3">
                        <v-app>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <label class="text-left w-fit-content" v-on="on" v-bind="attrs">Sent Invite</label>
                                </template> Email required
                            </v-tooltip>
                        </v-app>
                        <b-form-checkbox name="check-button" class="ml-1px" v-model="newTeamMember.inviteNewMember"
                            :disabled="!checkEmail(newTeamMember.email)" switch></b-form-checkbox>
                    </b-col>
                </b-row>
                <hr class="m-4" />
                <b-row class="px-4">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Tags</h3>
                    <b-col md="12" class="mt-3">
                        <v-select class="f-12" :options="allTags" multiple :reduce="(option) => option.id"
                            v-model="driverTags" />
                    </b-col>
                </b-row>
                <hr class="m-4" />
                <b-row class="px-4">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Division</h3>
                    <b-col sm="12" class="mt-3">
                        <v-select class="f-12" :clearable="false" :options="allDivisons" v-model="newTeamMember.division"
                            :reduce="(option) => option.id" />
                    </b-col>
                    <p v-if="errors && errors.division" class="field-error px-3">
                        {{ errors.division[0] }}
                    </p>
                </b-row>
                <hr class="m-4" />
                <b-row class="px-4">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Details</h3>
                    <!-- <b-col md="6" sm="12" class="text-left select_arrow mt-3">
                        <label>Type:</label>
                        <b-form-select class="f-12" :options="[
                            { value: null, text: '' },
                            { value: 'Company', text: 'Company' },
                            {
                                value: 'Owner Operator',
                                text: 'Owner Operator',
                            },
                        ]" v-model="newTeamMember.driver_type"></b-form-select>
                        <p v-if="errors && errors.driver_type" class="field-error mb-lg-3">
                            {{ errors.driver_type[0] }}
                        </p>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left select_arrow mt-3">
                        <label>CDL:</label>
                        <b-form-select class="f-12" :options="[
                            {
                                value: null,
                                text: '',
                            },
                            {
                                value: true,
                                text: 'Yes',
                            },
                            {
                                value: false,
                                text: 'No',
                            },
                        ]" v-model="newTeamMember.CDL"></b-form-select>
                    </b-col> -->
                    <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Driver ID:" class="text-left mb-0">
                            <b-form-input v-model="newTeamMember.employee_id" type="text" placeholder="Enter Driver ID"
                                class="unit" required></b-form-input>
                            <p v-if="errors && errors.employee_id" class="field-error">
                                {{ errors.employee_id[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                    <!-- <b-col md="6" sm="12" class="mt-3">
                        <b-form-group label="Driver Pin:" class="text-left mb-0">
                            <b-form-input v-model="newTeamMember.pin_code" type="text" placeholder="Enter Pin" class="unit"
                                required></b-form-input>
                            <p v-if="errors && errors.pin_code" class="field-error">
                                {{ errors.pin_code[0] }}
                            </p>
                        </b-form-group>
                    </b-col> -->
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label for="dob-datepicker" class="state">DOB:</label>
                        <date-picker v-model="newTeamMember.dob" :formatter="momentFormat"
                            :range-separator="' - '" @clear="newTeamMember.dob = ''" placeholder="Select Date"
                            class="date-picker-custom" :teleport="'body'"></date-picker>
                        <p v-if="errors && errors.dob" class="field-error">
                            {{ errors.dob[0] }}
                        </p>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label for="active-date" class="state">Active Date:</label>
                        <date-picker v-model="newTeamMember.activate_date" :formatter="momentFormat"
                            :range-separator="' - '" @clear="newTeamMember.activate_date = ''" placeholder="Select Date"
                            class="date-picker-custom" :teleport="'body'"></date-picker>
                        <p v-if="errors && errors.activate_date" class="field-error">
                            {{ errors.activate_date[0] }}
                        </p>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label for="inactive-date" class="state">Inactive Date:</label>
                        <date-picker v-model="newTeamMember.inactive_date" :formatter="momentFormat"
                            :range-separator="' - '" @clear="newTeamMember.inactive_date = ''" placeholder="Select Date"
                            class="date-picker-custom" :teleport="'body'"></date-picker>
                        <p v-if="errors && errors.inactive_date" class="field-error">
                            {{ errors.inactive_date[0] }}
                        </p>
                    </b-col>
                </b-row>
                <hr class="m-4" />
                <b-row class="px-4 sidebar-b-margin">
                    <h3 class="f-18 font-weight-bold col-12 text-left">Assigned Equipment & Co Driver</h3>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Vehicle:</label>
                        <v-select class="f-12" :options="vehicleOptions" v-model="newTeamMember.default_vehicle"
                            :reduce="(option) => option.id" @search="searchVehicle">
                            <span slot="no-options">
                                Search Unit No...
                            </span>
                        </v-select>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Trailer:</label>
                        <v-select class="f-12" :options="trailerOptions" v-model="newTeamMember.default_trailer"
                            :reduce="(option) => option.id" @search="searchTrailer">
                            <span slot="no-options">
                                Search Unit No...
                            </span>
                        </v-select>
                    </b-col>
                    <b-col md="6" sm="12" class="text-left mt-3">
                        <label class="state">Co Driver:</label>
                        <v-select class="f-12" :options="coDrivers" v-model="newTeamMember.co_driver"
                            :reduce="(option) => option.id" @search="searchDriver">
                            <span slot="no-options">
                                Search Name...
                            </span>
                        </v-select>
                    </b-col>
                </b-row>
            </div>
            <div class="sidebar-footer position-fixed">
                <div class="text-right modal-footer border-0 p-0">
                    <b-button class="mx-0 mr-3 h-40px w-100px" @click="resetPopup()" variant="secondary">Cancel</b-button>
                    <b-button class="mx-0 h-40px w-125px px-2 mr-3 d-none d-sm-block" @click="onAddNewTeamMember(true)"
                        variant="outline-primary">Save & Open</b-button>
                    <b-button class="mx-0 h-40px w-100px" @click="onAddNewTeamMember()" variant="primary">Save</b-button>
                </div>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as moment from "moment-timezone";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { checkEmail, checkPhoneFormat } from '../../services/helper';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
    name: "AddTeamMember",
    components: { vSelect, DatePicker },
    data() {
        return {
            checkEmail,
            checkPhoneFormat,
            errors: {},
            driverTags: [],
            momentFormat: {
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            trailerOptions: [],
            vehicleOptions: [],
            showAdditionalSetting: false,
            coDrivers: [],
            showPassword: false,
            newTeamMember: {
                first_name: "",
                last_name: "",
                role: "Driver",
                email: "",
                username: "",
                phone: "",
                password: "",
                inviteNewMember: false,
                division: null,
                license_number: "",
                license_state: {
                    id: 54,
                    country: 1,
                },
                employee_id: "",
                dob: null,
                default_vehicle: null,
                default_trailer: null,
                co_driver: null,
                activate_date: new Date(),
                inactive_date: null
            },
        }
    },
    props: ['allowAddTeamMember', "states"],
    computed: {
        ...mapGetters([
            "company",
            "userCompany",
            "tags",
            "allEquipments",
            "allDrivers",
            "divisions"
        ]),
        phoneMask() {
            return this.newTeamMember.phone.length > 0
                ? [
                    /\d/, /\d/, /\d/, " ",
                    /\d/, /\d/, /\d/, " ",
                    /\d/, /\d/, /\d/, /\d/
                ]
                : false; // Allows empty input
        },
        allTags() {
            return this.tags.map((item) => {
                return {
                    id: item.id,
                    label: item.add_tag,
                }
            })
        },
        allDivisons() {
            return this.divisions.map((item) => {
                return {
                    id: item.id,
                    label: item.division,
                }
            })
        },
    },
    methods: {
        ...mapActions([
            "getAllTags",
            "addTeamMember",
            "setNotification",
            "setLoader",
            "getAllEquipments",
            "getAllDrivers",
            "sendInvite"
        ]),
        resetPopup(close = true) {
            this.vehicleOptions = []
            this.trailerOptions = []
            this.coDrivers = []
            this.errors = {}
            this.showAdditionalSetting = false
            this.newTeamMember = {
                first_name: "",
                last_name: "",
                role: "Driver",
                email: "",
                username: "",
                phone: "",
                password: "",
                inviteNewMember: false,
                co_driver: null,
                division: null,
                license_number: "",
                license_state: {
                    id: 54,
                    country: 1,
                },
                employee_id: "",
                dob: null,
                default_vehicle: null,
                default_trailer: null,
                activate_date: new Date(),
                inactive_date: null
            }
            if (close) this.$emit('close');
        },
        async sendInviteTeamMember(id) {
            try {
                await this.sendInvite({
                    user: id,
                    invite_type: "driver-invite",
                    password: this.newTeamMember.password ? this.newTeamMember.password : null
                });
            } catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                    error = "Couldnot send email";
                }
                console.log(error);
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        async onAddNewTeamMember(openTeamMember = false) {
            const user = {
                first_name: this.newTeamMember.first_name,
                last_name: this.newTeamMember.last_name,
                username:
                    this.newTeamMember.username + "." + this.company[0].company_ID,
            };
            if (this.newTeamMember.password)
                user["password"] = this.newTeamMember.password;
            if (this.newTeamMember.email) {
                user["email"] = this.newTeamMember.email;
            }
            let postData = {
                user,
                role: this.newTeamMember.role,
                phone: this.newTeamMember.phone,
            };
            if (this.newTeamMember.role === "Driver") {
                postData = {
                    ...postData,
                    ...this.newTeamMember,
                    tags: this.driverTags,
                    dob:this.newTeamMember.dob ? moment(this.newTeamMember.dob).format("YYYY-MM-DD") : null,
                    activate_date: moment(this.newTeamMember.activate_date).format('YYYY-MM-DD'),
                    inactive_date: this.newTeamMember.inactive_date ? moment(this.newTeamMember.inactive_date).format("YYYY-MM-DD") : null,
                    license_number: this.newTeamMember.license_number,
                    license_state: this.newTeamMember.license_state.id
                        ? this.newTeamMember.license_state.id
                        : 54,
                    lock_trailer: this.company[0].lock_trailer,
                    lock_vehicle: this.company[0].lock_vehicle,
                    phone: this.newTeamMember.phone ? !this.newTeamMember.phone.includes("+1") ? "+1 " + this.newTeamMember.phone : this.newTeamMember.phone : '',
                };
            }
            try {
                if (this.newTeamMember.phone) {
                    if (!this.checkPhoneFormat(this.newTeamMember.phone)) {
                        throw {
                            phone: ["Wrong format"],
                        };
                    }
                }
                if (this.newTeamMember.email) {
                    if (!this.checkEmail(this.newTeamMember.email)) {
                        throw {
                            email: ["Wrong format"],
                        };
                    }
                }
                if (this.newTeamMember.username) {
                    if (this.newTeamMember.username.includes("@") || this.newTeamMember.username.includes(" ")) {
                        throw {
                            username: ["Username shouldn't contain @ or a space"],
                        };
                    }
                }
                if (this.newTeamMember.username && this.newTeamMember.username.includes(`.${this.company[0].company_ID}`)) {
                    await this.setNotification({
                        msg: "Username can not contain .compid",
                        type: "error",
                        color: "red",
                    });
                    return;
                }
                const nullKeys = this.checkNullValues({
                    first_name: this.newTeamMember.first_name,
                    last_name: this.newTeamMember.last_name,
                    role: this.newTeamMember.role,
                    username: this.newTeamMember.username,
                    password: this.newTeamMember.password,
                    ...this.company[0].is_division_required ? { division: this.newTeamMember.division } : {}
                });

                if (nullKeys.length === 0) {
                    try {
                        this.setLoader(true);
                        const newMember = await this.addTeamMember(postData);
                        if (newMember) {
                            if (
                                this.newTeamMember.inviteNewMember &&
                                this.newTeamMember.email
                            ) {
                                if (this.newTeamMember.email) {
                                    await this.sendInviteTeamMember(newMember.id);
                                    await this.setNotification({
                                        msg: `Successfully added ${newMember.user.first_name} ${newMember.user.last_name} and Invitation is also sent`,
                                        type: "success",
                                        color: "green",
                                    });
                                } else
                                    await this.setNotification({
                                        msg: `Successfully added ${newMember.user.first_name} ${newMember.user.last_name} and Invitation is not sent as Email is required in order to send invite.`,
                                        type: "success",
                                        color: "green",
                                    });
                            } else {
                                await this.setNotification({
                                    msg: `Successfully added ${newMember.user.first_name} ${newMember.user.last_name}.`,
                                    type: "success",
                                    color: "green",
                                });
                            }
                            this.resetPopup(false)
                            if (openTeamMember) this.$emit("getNewData", newMember.id)
                            else this.$emit("getNewData", null)
                        }
                        this.showPassword = false
                        this.setLoader(false);
                    } catch (e) {
                        console.log(e)
                        this.setLoader(false);
                        if (Array.isArray(e)) {
                            await this.setNotification({
                                msg: e[0],
                                type: "error",
                                color: "red",
                            });
                        }
                        else if (typeof e === "object") {
                            console.log(e)
                            await this.setNotification({
                                msg: e['error'][0],
                                type: "error",
                                color: "red",
                            });
                        }
                        else {
                            await this.setNotification({
                                msg: e,
                                type: "error",
                                color: "red",
                            });
                        }

                    }
                } else {
                    this.setLoader(false);
                    let error = {};
                    nullKeys.forEach((x) => {
                        if (x === 'password' && this.newTeamMember.password && this.newTeamMember.password.length < 4) error['password'] = ['Ensure this field has at least 4 characters.']
                        else error[x] = ["This field is required."];
                    });
                    console.log(error)
                    throw error;
                }
            } catch (error) {
                console.log(error)
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },

        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (key === "password" && value.length < 4 || !value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async searchVehicle(searchText) {
            const url = `unit_no=${searchText}&type=Vehicle&status=Active&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.typeOptions()
        },
        async searchTrailer(searchText) {
            const url = `unit_no=${searchText}&type=Trailer&status=Active&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
            await this.getAllEquipments(url);
            this.subTypeOptions()
        },
        typeOptions() {
            let options = [];
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.vehicleOptions = this.vehicleOptions.concat(options)
            this.vehicleOptions = [...new Map(this.vehicleOptions.map(item => [item['id'], item])).values()]
        },
        subTypeOptions() {
            let options = [];
            this.allEquipments.forEach((x) => {
                options.push({
                    id: x.id,
                    label: x.unit_no,
                });
            });
            this.trailerOptions = this.trailerOptions.concat(options)
            this.trailerOptions = [...new Map(this.trailerOptions.map(item => [item['id'], item])).values()]
        },
        async searchDriver(searchText) {
            const url = `name=${searchText}&page=1&page_size=10&status=True`
            await this.getAllDrivers(url);
            this.coDrivers = []
            this.allDrivers
                .filter(
                    (driver) =>
                        !driver.co_driver
                )
                .map((item) => {
                    this.coDrivers.push({
                        id: item.id,
                        label: item.name,
                    });
                });
        },
    },
    async mounted() {
        await this.getAllTags(this.userCompany);
    }
}
</script>