<template>
  <div class="integration-box">
    <div class="d-flex align-items-center flex-wrap">
      <img :src="require('@/assets/samsara.svg')" />
      <button
        v-if="status"
        type="button"
        class="btn btn-primary export equipment ml-0 ml-sm-4 mt-2 mt-sm-0 h-40px w-100px"
      >
        Active
      </button>
      <button
        v-else
        type="button"
        class="h-40px btn btn-secondary close-btn ml-4 mt-2 mt-sm-0"
      >
        Inactive
      </button>
    </div>
    <p class="mt-3 text-tertiary">
      Samsara integration will integrate your drivers, vehicles and trailers to
      our platform.
    </p>
    <!-- <div v-if="status">
      <div
        class="d-flex align-items-center flex-wrap mb-3"
        v-for="(item, index) in buttonsData"
        :key="index"
      >
        <div>
          <p class="mb-0">{{ item.title }}</p>
          <button
            @click="setSyncData(item.link)"
            type="button"
            :disabled="item.status === true ? false : true"
            class="h-40px btn btn-primary export equipment mt-2 w-100px text-white"
          >
            Sync Data
          </button>
        </div>
      </div>
    </div> -->
    <div
      class="d-flex align-items-center justify-content-between buttons-section flex-wrap"
    >
    <div>
      <b-button
        type="button"
        v-if="status"
        class="w-125px h-40px"
        variant="danger"
        @click="removeSamsaraToken()"
      >
        Deactivate
      </b-button>
      <a
        v-else
        class="border-0 btn btn-success assigned-equipment w-100px h-40px d-flex align-items-center justify-content-center"
        href="https://api.samsara.com/oauth2/authorize?client_id=6a219b27a54f49a0bc923dfd6994245c&state=z3qAr0h5Ud&response_type=code"
        >Activate</a
      >
    </div>
      <button
        type="button"
        class="btn btn-secondary close-btn w-100px h-40px"
        @click="$emit('close')"
      >
        Close
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Samsara-Integration",
  props: ["status"],
  data() {
    return {
      buttonsData: [
        {
          title: "Vehicles",
          link: "samsara_vehicle_sync",
          status: true,
          data: "Vehicle",
        },
        {
          title: "Trailers",
          link: "samsara_trailer_sync",
          status: true,
          data: "Trailer",
        },
        {
          title: "Drivers",
          link: "samsara_driver_sync",
          status: true,
          data: "Driver",
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      "setLoader",
      "setNotification",
      "setAssetsData",
      "getAssetsData",
      "updateCompany"
    ]),
    async setSyncData(link) {
      try {
        this.setLoader(true);
        await this.setAssetsData(link);
        await this.setNotification({
          msg: "Sync done successfully !!",
          type: "success",
          color: "green",
        });
        this.setLoader(false);
      } catch (error) {
        this.setLoader(false);
        await this.setNotification({
          msg: error,
          type: "error",
          color: "red",
        });
      }
    },
    setButtonsData() {
      if (
        this.assetsData.filter((item) => item.partner_name === "Samsara")
          .length === 0
      )
        this.buttonsData[2].status = false;
    },
    async removeSamsaraToken(){
      try{
          delete this.company[0].logo
          await this.updateCompany({
          id: this.userCompany,
          payload: {...this.company[0], samsara_refreshtoken:"", state: this.company[0].state.id},
        });
        await this.setNotification({
          msg: "Samsara Integration removed successfully !!",
          type: "success",
          color: "green",
        });
      }
      catch(e){
        console.log(e)
      }
    }
  },
  computed: {
    ...mapGetters(["assetsData","company",'userCompany']),
  },
  mounted() {},
};
</script>
