<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" backdrop shadow :visible="!!recordID" :right="true"
            no-header-close no-close-on-backdrop no-close-on-esc>
            <template #header="{ }" v-if="currentMaintenance">
                <div class="d-flex align-items-center w-100 justify-content-between">
                    <div class="d-flex align-items-center w-100 mr-2">
                        <div class="text-nowrap" v-if="currentMaintenance.record_no">
                            {{ currentMaintenance.record_no }}
                        </div>
                        <!-- <b-button
                            class="ml-5 export pe-none d-flex justify-content-center align-items-center w-100px h-40px"
                            :class="recordPayload.status === 'New'
                                ? 'new-record'
                                : recordPayload.status === 'Urgent'
                                    ? 'urgent-record text-white'
                                    : recordPayload.status === 'Open' ? 'open-record' : 'inactive-equipment text-black'"
                            :variant="recordPayload.status === 'New' ? 'warning' : recordPayload.status === 'Urgent' ? 'danger' : recordPayload.status === 'Open' ? 'success' : 'light'">
                            <p class="mb-0">{{ recordPayload.status }}</p>
                        </b-button> -->
                    </div>
                    <b-dropdown id="dropdown-divider" text="text" class="load-dropdown mt-0" v-if="allowDeleteRecord">
                        <template slot="button-content">
                            <b-icon class="dots" icon="three-dots"></b-icon>
                        </template>
                        <b-dropdown-item-button @click="showDeleteRecordModal = true">
                            <p class="text_secondary cursor-pointer mb-0 f-14">Delete</p>
                        </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </template>
            <div class="body" v-if="currentMaintenance">
                <div class="sidebar-tabs">
                    <div class="bg-white rounded">
                        <v-tabs class="team-tabs mb-0" v-model="activeTab">
                            <v-tab key='info'>Info</v-tab>
                            <v-tab key='documents'>Documents</v-tab>
                            <v-tab key='notes'>Notes</v-tab>
                            <v-tab key='activity' v-if="showActivitySection">Activity</v-tab>
                        </v-tabs>
                    </div>
                </div>
                <v-tabs-items v-model="activeTab">
                    <v-tab-item key='info'>
                        <hr class="mt-0" />
                        <div class="d-block text-center mt-4rem">
                            <b-row class="align-items-center px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Record # :" class="text-left mb-0">
                                        <b-form-input v-model="currentMaintenance.record_no" disabled type="text"
                                            placeholder="Enter Record" class="unit" required></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left select_arrow ">
                                        <label class="state">Status</label>
                                        <b-form-select v-model="recordPayload.status" :disabled="!allowUpdateRecord"
                                            :options="[{
                                                value: 'New',
                                                text: 'New'
                                            },
                                            {
                                                value: 'Open',
                                                text: 'Open'
                                            },
                                            {
                                                value: 'Closed',
                                                text: 'Closed'
                                            },
                                            {
                                                value: 'Urgent',
                                                text: 'Urgent'
                                            }
                                            ]" />
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left ">
                                        <label for="modal-date-datepicker" class="state">Date *</label>
                                        <date-picker v-model="recordPayload.date" :formatter="momentFormat"
                                        :range-separator="' - '" @clear="recordPayload.date = ''" placeholder="Select Date"
                                        class="date-picker-custom" :teleport="'body'"
                                        :disabled="!allowUpdateRecord"></date-picker>
                                        <p v-if="errors && errors.date" class="field-error">
                                            {{ errors.date[0] }}
                                        </p>
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left select_arrow ">
                                        <label class="state">Unit #</label>
                                        <v-select class="bg_white w-100" v-model="recordPayload.unit_no"
                                            :options="nameOptions" item-value="id" :disabled="!allowUpdateRecord"
                                            item-text="label" :reduce="(option) => option.id" @search="searchAssets">
                                            <span slot="no-options">
                                                Search Unit # ...
                                            </span>
                                        </v-select>
                                        <p v-if="errors && errors.unit_no" class="field-error">
                                            {{ errors.unit_no[0] }}
                                        </p>
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left ">
                                        <b-form-group label="Odometer" class="text-left">
                                            <b-form-input type="text" v-mask="numberMask" placeholder="Enter Odometer"
                                                required v-model="recordPayload.odometer"
                                                :disabled="!allowUpdateRecord"></b-form-input>
                                            <p v-if="errors && errors.odometer" class="field-error">
                                                {{ errors.odometer[0] }}
                                            </p>
                                        </b-form-group>
                                    </div>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <div class="text-left ">
                                        <b-form-group label="Amount" class="text-left">
                                            <b-form-input type="text" v-mask="amountMask" :disabled="!allowUpdateRecord"
                                                @blur="amountHandler" placeholder="Enter Amount" required
                                                v-model="recordPayload.amount"></b-form-input>
                                            <p v-if="errors && errors.amount" class="field-error">
                                                {{ errors.amount[0] }}
                                            </p>
                                        </b-form-group>
                                    </div>
                                </b-col>
                                <b-col md="12">
                                    <div class="text-left">
                                        <label class="state">Description</label>
                                        <b-form-textarea id="textarea" class="f-14" :disabled="!allowUpdateRecord"
                                            placeholder="Enter Description" rows="5"
                                            v-model="recordPayload.description"></b-form-textarea>
                                        <p v-if="errors && errors.description" class="field-error">
                                            {{ errors.description[0] }}
                                        </p>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </v-tab-item>
                    <v-tab-item key='documents'>
                        <div class="d-block body">
                            <div class="mb-3 d-flex align-items-center justify-content-between px-4 mt-4rem">
                                <h3 class="f-18 font-weight-bold text-left px-0 mb-0">Documents</h3>
                                <div class="d-flex align-items-center justify-content-end cursor-pointer"
                                    @click="addDocument" v-if="allowUpdateRecord">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 12V6.5V12ZM6.5 6.5V1V6.5ZM6.5 6.5H12H6.5ZM6.5 6.5H1H6.5Z"
                                            fill="#367BF6" />
                                        <path d="M6.5 12V6.5M6.5 6.5V1M6.5 6.5H12M6.5 6.5H1" stroke="#367BF6"
                                            stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                    <p class="text_primary ml-2 mb-0 fw-600">Add Document</p>
                                </div>
                            </div>
                            <b-table show-empty responsive :fields="fields" :items="items" hover
                                :current-page="currentPage">
                                <template #cell(name)="data">
                                    <a :href="`#${data.value
                                        .replace(/[^a-z]+/i, '-')
                                        .toLowerCase()}`">{{ data.value }}</a>
                                </template>
                                <template #cell(description)="data">
                                    <span class="item-description" v-if="data.value && data.value !== 'null'">{{ data.value
                                    }}</span>
                                    <span v-else>-</span>
                                </template>
                                <template #cell(expiry_date)="data">
                                    <span v-html="data.value"></span>
                                </template>
                                <template #cell(driver)="data">
                                    <b-form-checkbox :disabled="!allowUpdateRecord" v-model="data.value" name="check-button"
                                        switch @change="
                                            updateDocumentOnToggle(data.item.id, 'driver', data.value)
                                            " size="lg" class="ml-2" />
                                </template>
                                <template #cell(action)="data">
                                    <svg @click="openDoc(data.item)" class="cursor-pointer mr-4" width="20" height="15"
                                        viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.6426 6.19707C18.8726 6.48384 19 6.85642 19 7.24273C19 7.62904 18.8726 8.00161 18.6426 8.28838C17.186 10.052 13.8704 13.4854 10 13.4854C6.12957 13.4854 2.81403 10.052 1.35741 8.28838C1.12735 8.00161 1 7.62904 1 7.24273C1 6.85642 1.12735 6.48384 1.35741 6.19707C2.81403 4.4335 6.12957 1 10 1C13.8704 1 17.186 4.4335 18.6426 6.19707Z"
                                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M10.0007 10.0154C11.533 10.0154 12.7752 8.77323 12.7752 7.24091C12.7752 5.70859 11.533 4.4664 10.0007 4.4664C8.46839 4.4664 7.2262 5.70859 7.2262 7.24091C7.2262 8.77323 8.46839 10.0154 10.0007 10.0154Z"
                                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    <svg @click="downloadDoc(data.item.pk, data.item.name)" class="mr-4 cursor-pointer"
                                        width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.53943 11.3822L10.001 14.8437L13.4625 11.3822" stroke="#FFC800"
                                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10.0001 14.8497V5.15741" stroke="#FFC800" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                                            stroke="#FFC800" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    <svg v-if="allowUpdateRecord" @click="showPopup(data.item.pk)" class="cursor-pointer"
                                        width="19" height="20" viewBox="0 0 19 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </template>
                            </b-table>
                        </div>
                    </v-tab-item>
                    <v-tab-item key="notes">
                        <div class="mt-4rem">
                            <Notes :key="currentMaintenance.id" :noteURL="'app/maintenance/' + currentMaintenance.id + '/note'"
                                :allowUpdate="allowUpdateRecord" />
                        </div>
                    </v-tab-item>
                    <v-tab-item key="activity">
                        <div class="mt-4rem mb-6rem" v-if="showActivitySection">
                            <ActivityLogs :record="currentMaintenance.record_no" :object_id="currentMaintenance.id" :key="currentMaintenance.id"/>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
                <div class="sidebar-footer position-fixed">
                    <div class="d-flex align-items-center justify-content-end">
                        <div class="text-right modal-footer border-0 p-0">
                            <b-button class="mx-0 h-40px w-100px m-0" @click="resetForm" variant="secondary"
                                :class="hasChanged && allowUpdateRecord ? 'mr-3' : ''">Close</b-button>
                            <b-button class="mx-0 mb-0 h-40px w-100px m-0" v-if="hasChanged && allowUpdateRecord"
                                variant="primary" @click="updateMaintenance()">Save</b-button>
                        </div>
                    </div>
                </div>
            </div>
        </b-sidebar>
        <b-modal id="modal-document" centered hide-footer no-close-on-backdrop no-close-on-esc size="lg"
            :body-class="'px-3 pt-2 overflow-preview-auto'" :footer-class="'d-block border-0 pt-0 pb-3 m-0'">
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Document
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
                <b-dropdown id="dropdown-preview" class="load-dropdown mt-0" v-if="editDocumentFlag">
                    <template slot="button-content">
                        <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button :class="fileName && allowUpdateRecord ? 'mb-2' : ''"
                        @click="downloadDoc(documentData.id, documentData.docName)">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Download</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="removeFile()" class="mb-2" v-if="fileName && allowUpdateRecord">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="allowUpdateRecord">
                        <p class="text_secondary cursor-pointer mb-0 f-14" @click="showPopup(documentData.id)">Delete</p>
                    </b-dropdown-item-button>
                </b-dropdown>
                <b-dropdown id="dropdown-preview" text="text" class="load-dropdown mt-0"
                    v-if="!editDocumentFlag && fileName">
                    <template slot="button-content">
                        <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button @click="removeFile()">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
                    </b-dropdown-item-button>
                </b-dropdown>
            </template>
            <div class="d-block text-center">
                <b-row class="mh-470px">
                    <b-col lg="4" md="8" sm="12">
                        <div class="text-left mb-3">
                            <label for="modal-datepicker" class="state">Date</label>
                                <date-picker v-model="documentData.date" :formatter="momentFormat"
                                :range-separator="' - '" @clear="documentData.date = ''" placeholder="Select Date"
                                class="date-picker-custom" :teleport="'body'"
                                :disabled="!allowUpdateRecord"></date-picker>
                            <p v-if="errors && errors.date" class="field-error">
                                {{ errors.date[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Description</label>
                            <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                                v-model="documentData.description" :disabled="!allowUpdateRecord"></b-form-textarea>
                            <!-- <p v-if="errors && errors.description" class="field-error">
                                {{ errors.description[0] }}
                            </p> -->
                        </div>
                    </b-col>
                    <b-col lg="8" sm="12">
                        <div class="upload-btn-wrapper" v-if="!fileName">
                            <b-img :src="require('@/assets/drop.svg')"></b-img>
                            <button v-if="!fileName" class="foo">
                                Drop your document here, or browse
                            </button>
                            <button v-else class="foo">{{ fileName }}</button>

                            <input ref="docFile" type="file" name="docFile" v-on:change="handleFileUpload"
                                accept=".pdf, .png, .jpg, .jpeg" />
                            <p v-if="errors && errors.document" class="field-error">
                                {{ errors.document[0] }}
                            </p>
                        </div>
                        <div v-else>
                            <div class="review-media-section" :class="mediaType === 'pdf' ? 'overflow-hidden' : ''">
                                <iframe class="w-100 h-100 border-0" v-if="mediaType === 'pdf'" :src="media"></iframe>
                                <div v-else>
                                    <div id="panzoom-element" ref="panzoomElement">
                                        <img :src="media" class="w-100 h-100 img-section" />
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center zoom-section">
                                        <b-icon @click="zoom(1)" icon="zoom-in"
                                            style="fill:white; cursor: pointer;"></b-icon>
                                        <b-icon @click="zoom(-1)" class="ml-3" icon="zoom-out"
                                            style="fill:white;cursor: pointer;"></b-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="text-right modal-footer border-0 p-0 mt-4">
                <b-button class="mx-0 h-40px w-100px m-0" @click="$bvModal.hide('modal-document'); panzoom = null"
                    variant="secondary" :class="hasDocChanged && allowUpdateRecord ? 'mr-3' : ''">{{ editDocumentFlag ?
                        'Close' : 'Cancel' }}</b-button>
                <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" v-if="hasDocChanged && allowUpdateRecord"
                    @click="sendDocumentData">Save</b-button>
            </div>
        </b-modal>
        <DeleteModal v-if="showDocumentModal" @confirm="deleteDocumentItem($event)" />
        <DeleteModal v-if="showDeleteRecordModal" @confirm="deleteRecordItem($event)" />
        <!-- <MediaPopup :media="media" :mediaID="mediaID" :mediaType="mediaType" :deleteDocItem="deleteDocumentItem"
            :deleteItemPermission="$store.state.user.user.team_member.role !== 'Admin' && $store.state.user.user.team_member.role !== 'Operations' ? true : false"
            :docName="docName" /> -->
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import DeleteModal from "@/components/DeleteModal.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import * as moment from "moment-timezone";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// import MediaPopup from "@/components/Shared/MediaPopup.vue"
import ActivityLogs from "@/components/Shared/ActivityLogs.vue"
import { checkValueExists } from "../../services/helper";
import Panzoom from '@panzoom/panzoom'
import Notes from "../Shared/Notes";
const numberMask = createNumberMask({
    prefix: "",
    allowDecimal: true,
    includeThousandsSeparator: true,
});
const amountMask = createNumberMask({
    prefix: "",
    allowDecimal: true,
    includeThousandsSeparator: true,
});
export default {
    name: "EditRecord",
    components: { DeleteModal, vSelect, ActivityLogs, Notes, DatePicker },
    data() {
        return {
            numberMask,
            checkValueExists,
            amountMask,
            activeTab: "info",
            momentFormat: {
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            panzoom: null,
            media: null,
            docName: "",
            mediaType: null,
            mediaID: null,
            itemId: null,
            showDeleteRecordModal: false,
            showDocumentModal: false,
            currentPage: 1,
            fields: [
                {
                    key: "Description",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
            editDocumentFlag: false,
            documentIndex: null,
            documentData: {
                object_id: null,
                date: null,
                document: null,
                description: "",
            },
            items: [],
            emptyTable: false,
            nameOptions: [],
            defaultData: {},
            defaultDocsData: {},
            recordPayload: {
                date: new Date(),
                unit_no: null,
                description: null,
                amount: null,
                odometer: null,
                status: "Closed"
            },
            schedulePayload: {
                unit_no: null,
                due_date: null,
                description: null,
            },
            fileName: null,
            errors: {
                date: null,
                document: null,
                description: null,
            }
        }
    },
    props: ['recordID'],
    methods: {
        ...mapActions(['retrieveMaintenanceDocument', 'deleteMaintenanceDocument', 'updateMaintenanceDocument', 'getMaintenanceRecord', 'setLoader', "getAllEquipments", "patchMaintenance", 'setNotification', 'sendMaintenanceDocument', 'deleteMaintenance']),
        zoom(level) {
            if (!this.panzoom) {
                this.panzoom = Panzoom(document.getElementById('panzoom-element'), {
                    maxScale: 5
                })
            }
            level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn()
        },
        async openDoc(data) {
            this.docName = data.name
            const doc = await this.retrieveMaintenanceDocument({ id: this.currentMaintenance.id, doc: data.pk })
            this.media = doc.document
            this.mediaID = data.pk
            this.itemId = data.pk;
            if (data.link.includes('.pdf')) this.mediaType = 'pdf'
            else this.mediaType = 'image'
            this.editDocument(data.id, data.name)
        },
        amountHandler(event) {
            const value = event.target.value;
            if (value && !value.includes('.')) {
                event.target.value = `${value}.00`;
            }
        },
        async deleteRecordItem(status) {
            if (status) {
                try {
                    this.setLoader(true)
                    await this.deleteMaintenance(`${this.recordID}/?company_id=${this.userCompany}`);
                    await this.setNotification({
                        msg: `Maintenance Record Successfully Deleted`,
                        type: "success",
                        color: "green",
                    });
                    this.showDeleteRecordModal = false
                    this.setLoader(false)
                    this.$emit("recordUpdated", true)
                    this.resetForm()
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            } else {
                this.showDeleteRecordModal = false
            }
        },
        async openLink(id) {
            const doc = await this.retrieveMaintenanceDocument({ id: this.currentMaintenance.id, doc: id })
            window.open(doc.document, "_blank");
        },
        showPopup(id) {
            this.showDocumentModal = true;
            this.itemId = id;
        },
        getFileNameFromUrl(url) {
            let urlObj = new URL(url);
            let pathname = urlObj.pathname;
            let segments = pathname.split('/');
            let fileName = segments.pop();
            return fileName;
        },
        editDocument(ind, name) {
            this.editDocumentFlag = true;
            this.documentIndex = ind;
            const { id, date, document, description } = this.currentMaintenance.documents[ind];
            this.documentData = {
                date: moment.tz(date, this.userTimeZone).toDate(),
                document,
                description,
                id
            }
            this.documentData.docName = name
            if (this.documentData.document) {
                this.fileName = this.getFileNameFromUrl(this.documentData.document)
            } else {
                this.documentData.document = null;
                this.fileName = "";
                this.errors = {
                    date: null,
                    document: null,
                    description: null,
                };
            }
            this.defaultDocsData = JSON.parse(JSON.stringify(this.documentData))
            this.$bvModal.show("modal-document");
        },
        deleteDocumentItem(item) {
            if (item) {
                this.deleteDocument(this.itemId);
            }
            this.showDocumentModal = false;
        },
        async deleteDocument(id) {
            try {
                this.setLoader(true)
                await this.deleteMaintenanceDocument({ id: this.currentMaintenance.id, doc: `${id}/?company_id=${this.userCompany}` });
                const index = this.items.findIndex((doc) => doc.pk === id)
                this.itemId = null
                this.items.splice(index, 1)
                await this.setNotification({
                    msg: "Document successfully deleted!!",
                    type: "success",
                    color: "green",
                });
                // await this.getMaintenanceRecord(this.recordID);
                this.setLoader(false)
                this.$bvModal.hide("modal-document");
            } catch (error) {
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        resetField() {
            this.$refs.docFile.value = null;
        },
        handleFileUpload() {
            this.documentData.document = this.$refs.docFile.files[0];
            this.fileName = this.documentData.document.name;
            this.media = URL.createObjectURL(this.documentData.document);
            if (this.$refs.docFile.files[0].type.includes('pdf')) this.mediaType = 'pdf'
            else this.mediaType = 'image'
        },
        async downloadDoc(id, name) {
            this.setLoader(true)
            const doc = await this.retrieveMaintenanceDocument({ id: this.currentMaintenance.id, doc: id })
            fetch(doc.document)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const newUrl = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = newUrl;
                    a.download = name
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(newUrl);
                    a.remove()
                    this.setLoader(false)
                })
                .catch(error => {
                    this.setLoader(false)
                    console.log(error)
                });
        },
        updateDocs() {
            this.items = [];
            this.currentMaintenance.documents.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase())).forEach((doc, id) => {
                this.items.push({
                    id: id,
                    pk: doc.id,
                    Date: moment(doc.date).format("MMM DD, YYYY"),
                    Description: doc.description,
                    link: doc.document,
                    name: `maintenance record-${this.currentMaintenance.record_no}-${doc.description ? doc.description : ''}`
                });
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
            });
        },
        async sendDocumentData() {
            try {
                const nullKeys = this.checkNullValues({
                    date: this.documentData.date,
                    document: this.documentData.document,
                });
                console.log(nullKeys)
                if (nullKeys.length === 0) {
                    this.setLoader(true);
                    let formData = new FormData();
                    let fileType = this.documentData.document instanceof File || this.documentData.document instanceof Blob;
                    if (!fileType) this.documentData.document = null
                    formData.append(
                        "date",
                        moment(this.documentData.date).format("YYYY-MM-DD")
                    );
                    formData.append("description", this.documentData.description);
                    if (this.documentData.document)
                        formData.append(
                            "document",
                            new File(
                                [this.documentData.document],
                                this.currentMaintenance.record_no + "_" +
                                this.nameOptions.filter(
                                    (item) => item.id === this.recordPayload.unit_no
                                )[0].label +
                                "_" + this.userCompany + "_" +
                                new Date().getTime() +
                                "." +
                                this.documentData.document["type"].split("/").pop(),
                                { type: this.documentData.document["type"] }
                            )
                        );
                    formData.append("company_id", parseInt(localStorage.getItem("userSelectedCompany")));
                    if (this.editDocumentFlag) {
                        const { id } = this.documentData
                        this.documentData = await this.updateMaintenanceDocument({
                            doc: id,
                            formData,
                            id: this.currentMaintenance.id
                        });
                        await this.setNotification({
                            msg: "Document Successfully updated!!",
                            type: "success",
                            color: "green",
                        });
                        this.currentMaintenance.documents[this.documentIndex] = this.documentData;
                        this.items[this.documentIndex].Date = moment(
                            this.documentData.date
                        ).format("MMM DD, YYYY");
                        this.items[this.documentIndex].Description =
                            this.documentData.description;
                        this.editDocumentFlag = false;
                        this.updateDocs();
                        this.documentData = {
                            object_id: null,
                            date: null,
                            document: null,
                            description: "",
                        };
                        this.fileName = null;
                        this.$bvModal.hide("modal-document");
                    } else {
                        if (this.documentData.date) {
                            await this.sendMaintenanceDocument({ formData, id: this.currentMaintenance.id });
                            await this.getMaintenanceRecord(this.recordID);
                            this.updateDocs();
                            await this.setNotification({
                                msg: `Document Successfully ${this.editDocumentFlag ? "updated" : "added"
                                    }!!`,
                                type: "success",
                                color: "green",
                            });
                            this.documentData = {
                                object_id: null,
                                date: null,
                                document: null,
                                description: "",
                            };
                            this.fileName = null;
                            this.$bvModal.hide("modal-document");
                        } else {
                            this.errors.date = this.documentData.date
                                ? null
                                : ["This field is required."];
                            // this.errors.description = this.documentData.description? null: ['This field is required.']

                            console.log(this.errors);
                        }
                    }
                    this.setLoader(false);
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            } catch (error) {
                console.log(error)
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        resetForm() {
            this.$emit("resetMaintenance", true)
        },
        removeFile() {
            this.fileName = null;
            this.documentData.document = null
            this.media = null
            this.mediaType = null
        },
        addDocument() {
            this.fileName = null;
            this.editDocumentFlag = false;
            this.defaultDocsData = {}
            this.$bvModal.show("modal-document");
            this.documentData = {
                object_id: null,
                date: new Date().toISOString().split('T')[0],
                document: null,
                description: null,

            };
            this.errors = {
                date: null,
                document: null,
                description: null,
            };
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async updateMaintenance() {
            try {
                const nullKeys = this.checkNullValues(this.recordPayload, "maintenance");
                let formData = new FormData();
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    formData.append("unit_no", this.recordPayload.unit_no);
                    formData.append(
                        "date",
                        moment(this.recordPayload.date).format("YYYY-MM-DD")
                    );
                    formData.append(
                        "amount",
                        this.recordPayload.amount
                            ? parseFloat(this.recordPayload.amount.replace(/,/g, '')).toFixed(2)
                            : 0
                    );
                    formData.append("description", this.recordPayload.description);
                    formData.append('status', this.recordPayload.status)
                    formData.append(
                        "odometer",
                        this.recordPayload.odometer
                            ? this.recordPayload.odometer.toString().replace(/,/g, "")
                            : ""
                    );
                    await this.patchMaintenance({
                        id: this.currentMaintenance.id,
                        payload: formData,
                    });
                } else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
                this.fileName = null;
                await this.setNotification({
                    msg: `Record successfully updated`,
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
                this.$emit("recordUpdated", true)
                this.resetForm()
                this.nameOptions = []
            } catch (error) {
                this.setLoader(false)
                if (typeof error === "object") {
                    if (error.data) {
                        await this.setNotification({
                            msg: `${Object.keys(error?.data)[0].charAt(0).toUpperCase() + Object.keys(error?.data)[0].slice(1)}: ${error?.data[Object.keys(error?.data)[0]]
                                }`,
                            type: "error",
                            color: "red",
                        });
                    }
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        async searchAssets(searchText) {
            if (searchText) {
                let options = []
                let url = `unit_no=${searchText}&sort_field=unit_no&sort_order=asc&page=1&page_size=10`
                const equipmentRecords = await this.getAllEquipments(url);
                equipmentRecords.records.map((item) => {
                    options.push({
                        id: `${item.id}`,
                        label: item.unit_no,
                        type: "equipment",
                    });
                });
                this.nameOptions = options
            }
        }

    },
    computed: {
        ...mapGetters(['currentMaintenance', 'userCompany', 'rolePermissions']),
        showActivitySection() {
            return this.rolePermissions && this.rolePermissions['Activity Log'] && this.rolePermissions['Activity Log'][0].allow
        },
        allowUpdateRecord() {
            return this.rolePermissions && this.rolePermissions['Maintenance'] && this.rolePermissions['Maintenance'][1].allow
        },
        allowDeleteRecord() {
            return this.rolePermissions && this.rolePermissions['Maintenance'] && this.rolePermissions['Maintenance'][3].allow
        },
        hasChanged() {
            return Object.keys(this.recordPayload).filter(field => {
                if (typeof this.recordPayload[field] === 'object' && field !== 'unit_no') return JSON.stringify(this.recordPayload[field]) !== JSON.stringify(this.defaultData[field])
                if (field === 'unit_no') return this.recordPayload['unit_no'] != this.defaultData[field]?.id
                if (field === 'odometer' || field === 'amount') return this.checkValueExists(this.recordPayload[field])?.toString()?.replaceAll(",", "") != this.checkValueExists(this.defaultData[field])?.toString()?.replaceAll(",", "")
                if (field === 'description') {
                    return this.checkValueExists(this.recordPayload[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n')) != this.checkValueExists(this.defaultData[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n'));
                }
                else return this.checkValueExists(this.recordPayload[field]) != this.checkValueExists(this.defaultData[field])
            }).length > 0
        },
        hasDocChanged() {
            return Object.keys(this.documentData).filter(field => {
                if (field !== 'updated_at') {
                    if (typeof this.documentData[field] === 'object') return JSON.stringify(this.documentData[field]) !== JSON.stringify(this.defaultDocsData[field])
                    if (field === 'description') {
                        return this.checkValueExists(this.documentData[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n')) != this.checkValueExists(this.defaultDocsData[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n'));
                    }
                    else if (!this.documentData[field] && !this.defaultDocsData[field]) return false
                    else return this.checkValueExists(this.documentData[field]) != this.checkValueExists(this.defaultDocsData[field])
                } return false
            }).length > 0
        },
    },
    watch: {
        async recordID() {
            if (this.recordID) {
                this.activeTab = 'info'
                try {
                    this.setLoader(true)
                    this.recordPayload = {
                        date: new Date(),
                        unit_no: null,
                        description: null,
                        amount: null,
                        odometer: null,
                        status: "Closed"
                    }
                    this.defaultData = {}
                    await this.getMaintenanceRecord(this.recordID);
                    this.defaultData = JSON.parse(JSON.stringify(this.currentMaintenance))
                    this.recordPayload = {
                        date: moment.tz(this.currentMaintenance.date, this.userTimeZone).toDate(),
                        unit_no: this.currentMaintenance.unit_no.id,
                        description: this.currentMaintenance.description,
                        amount: this.currentMaintenance.amount,
                        odometer: this.currentMaintenance.odometer ? this.currentMaintenance.odometer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '',
                        status: this.currentMaintenance.status
                    }
                    this.nameOptions.push({
                        id: this.currentMaintenance.unit_no.id,
                        label: this.currentMaintenance.unit_no.unit_no,
                        type: "equipment",
                    })
                    this.updateDocs();
                    this.setLoader(false)
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            }
        }
    },
}
</script>