<template>
    <div>
        <b-sidebar id="sidebar-backdrop" :backdrop-variant="'dark'" backdrop shadow :visible="!!equipmentID" :right="true"
            no-header-close no-close-on-backdrop no-close-on-esc>
            <template #header="{ }">
                <div class="d-flex align-items-center w-100 justify-content-between">
                    <div class="d-flex align-items-center w-100 mr-2">
                        <div class="text-nowrap">
                            {{ currentEquipment.unit_no }}
                        </div>
                        <!-- <b-button
                            class="ml-5 export pe-none d-flex justify-content-center align-items-center w-100px h-40px"
                            :class="currentEquipment.status === 'Active'
                                ? 'equipment'
                                : currentEquipment.status === 'Assigned'
                                    ? 'assigned-equipment'
                                    : 'inactive-equipment'
                                "
                            :variant="currentEquipment.status === 'Active' ? 'primary' : currentEquipment.status === 'Assigned' ? 'success' : 'secondary'">
                            <p class="mb-0">{{ currentEquipment.status }}</p>
                        </b-button> -->
                    </div>
                    <b-dropdown id="dropdown-divider" text="text" class="load-dropdown mt-0" v-if="allowUpdateEquipment">
                        <template slot="button-content">
                            <b-icon class="dots" icon="three-dots"></b-icon>
                        </template>
                        <b-dropdown-item-button class="mb-2"
                            v-if="currentEquipment.status === 'Assigned' || currentEquipment.status === 'Active'"
                            @click="showEquipmentModal = true">
                            <p class="text_secondary cursor-pointer mb-0 f-14">Deactivate</p>
                        </b-dropdown-item-button>
                        <b-dropdown-item-button class="mb-0" v-else @click="showEquipmentModal = true">
                            <p class="text_secondary cursor-pointer mb-0 f-14">Activate</p>
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            @click="generateEquipmentQRCode(currentEquipment.unit_no, currentEquipment.unit_no + '-qrcode', qrCodeUrl)"
                            v-if="qrCodeUrl && currentEquipment.status !== 'Inactive'">
                            <p class="text_secondary cursor-pointer mb-0 f-14">QR Code</p>
                        </b-dropdown-item-button>
                    </b-dropdown>
                </div>
            </template>
            <div class="body" v-if="currentEquipment">
                <div class="sidebar-tabs">
                    <div class="bg-white rounded">
                        <v-tabs class="team-tabs mb-0" v-model="activeTab">
                            <v-tab key='info'>Info</v-tab>
                            <v-tab key='documents' v-if="allowViewDocEquipment">Documents</v-tab>
                            <v-tab key='notes'>Notes</v-tab>
                            <v-tab key='activity' v-if="showActivitySection">Activity</v-tab>
                        </v-tabs>
                    </div>
                </div>
                <v-tabs-items v-model="activeTab">
                    <v-tab-item key='info'>
                        <hr class="mt-0" />
                        <div class="d-block text-center mt-4rem">
                            <b-row class="align-items-center px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Basic</h3>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Unit # * :" class="text-left mb-0">
                                        <b-form-input v-model="currentEquipment.unit_no" type="text"
                                            placeholder="Enter Unit #" class="unit" :disabled="!allowUpdateEquipment"
                                            required></b-form-input>
                                        <p v-if="errors && errors.unit_no" class="field-error mb-lg-3">
                                            {{ errors.unit_no[0] }}
                                        </p>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="d-none d-sm-block">
                                </b-col>
                                <b-col md="6" sm="12" class="text-left select_arrow mt-3">
                                    <label>Subtype:</label>
                                    <b-form-input type="text" v-model="currentEquipment.subtype"
                                        :disabled="!allowUpdateEquipment" placeholder="Enter Subtype"
                                        class="unit"></b-form-input>
                                    <p v-if="errors && errors.subtype" class="field-error mb-lg-3">
                                        {{ errors.subtype[0] }}
                                    </p>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <b-form-group label="Status:" class="text-left mb-0">
                                        <b-form-input type="text" :value="currentEquipment.status"
                                            placeholder="Enter Status" disabled></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row class="px-4">
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <b-form-group label="Vin #:" class="text-left mb-0">
                                        <b-form-input type="text" v-model="currentEquipment.vin_no"
                                            placeholder="Enter Vin #:" :disabled="!allowUpdateEquipment"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Year:" class="text-left mb-0">
                                        <b-form-input type="text" v-model="currentEquipment.year" placeholder="Enter Year"
                                            :disabled="!allowUpdateEquipment"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Make:" class="text-left mb-0">
                                        <b-form-input type="text" v-model="currentEquipment.make" placeholder="Enter Make"
                                            :disabled="!allowUpdateEquipment"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Model:" class="text-left mb-0">
                                        <b-form-input type="text" v-model="currentEquipment.model" placeholder="Enter Model"
                                            :disabled="!allowUpdateEquipment"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Plate #:" class="text-left mb-0">
                                        <b-form-input type="text" v-model="currentEquipment.plate_no"
                                            placeholder="Enter Plate #:" :disabled="!allowUpdateEquipment"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left mt-3" v-if="currentEquipment.state">
                                    <label class="state">State:</label>
                                    <v-select class="f-12 h-40px" :options="states" :reduce="(option) => option.id"
                                        v-model="currentEquipment.state.id" :disabled="!allowUpdateEquipment" />
                                </b-col>
                            </b-row>
                            <hr class="m-4" />
                            <b-row class="px-4">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Tags</h3>
                                <b-col md="12" class="mt-3">
                                    <v-select class="f-12" :options="allTags" multiple :reduce="(option) => option.id"
                                        v-model="existingTags" :disabled="!allowUpdateEquipment" />
                                </b-col>
                            </b-row>
                            <hr class="m-4" />
                            <b-row class="px-4 mb-7rem">
                                <h3 class="f-18 font-weight-bold col-12 text-left">Details</h3>
                                <!-- <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Length:" class="text-left mb-0">
                                        <b-form-input type="text" v-model="currentEquipment.length"
                                            placeholder="Enter Length:"
                                            :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                                $store.state.user.user.team_member.role !== 'Operations' && $store.state.user.user.team_member.role !== 'Maintenance Manager'"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Weight:" class="text-left mb-0">
                                        <b-form-input type="text" v-model="currentEquipment.weight"
                                            placeholder="Enter Weight:"
                                            :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                                $store.state.user.user.team_member.role !== 'Operations' && $store.state.user.user.team_member.role !== 'Maintenance Manager'"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Number of Axles:" class="text-left mb-0">
                                        <b-form-input type="text" v-model="currentEquipment.number_of_axles"
                                            placeholder="Enter Axles:"
                                            :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                                $store.state.user.user.team_member.role !== 'Operations' && $store.state.user.user.team_member.role !== 'Maintenance Manager'"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Color:" class="text-left mb-0">
                                        <b-form-input type="text" v-model="currentEquipment.color"
                                            placeholder="Enter Color:"
                                            :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                                $store.state.user.user.team_member.role !== 'Operations' && $store.state.user.user.team_member.role !== 'Maintenance Manager'"></b-form-input>
                                    </b-form-group>
                                </b-col> -->
                                <b-col sm="12" class="mt-3">
                                    <b-form-group label="Odometer:" class="text-left mb-0">
                                        <b-form-input type="text" v-mask="numberMask" placeholder="Enter Odometer"
                                            v-model="currentEquipment.current_odometer"
                                            :disabled="!allowUpdateEquipment"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Value:" class="text-left mb-0">
                                        <b-form-input type="text" v-model="currentEquipment.value" placeholder="Enter Value"
                                            :disabled="!allowUpdateEquipment"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" sm="12" class="mt-3">
                                    <b-form-group label="Serial #:" class="text-left mb-0">
                                        <b-form-input type="text" v-model="currentEquipment.serial_number"
                                            placeholder="Enter Serial #" :disabled="!allowUpdateEquipment"></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <!-- <b-col md="6" sm="12" class="text-left select_arrow mt-3">
                                    <label>Fuel Type:</label>
                                    <b-form-select class="f-12"
                                        :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                            $store.state.user.user.team_member.role !== 'Operations' && $store.state.user.user.team_member.role !== 'Maintenance Manager'"
                                        :options="[
                                            {
                                                value: null,
                                                text: '',
                                            },
                                            {
                                                value: 'Diesel',
                                                text: 'Diesel',
                                            },
                                            {
                                                value: 'Bio-Diesel',
                                                text: 'Bio-Diesel',
                                            },
                                            {
                                                value: 'Gasoline',
                                                text: 'Gasoline',
                                            },
                                            {
                                                value: 'Ethanol',
                                                text: 'Ethanol',
                                            },
                                        ]" v-model="currentEquipment.fuel_type"></b-form-select>
                                    <p v-if="errors && errors.fuel_type" class="field-error mb-lg-3">
                                        {{ errors.fuel_type[0] }}
                                    </p>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left select_arrow mt-3">
                                    <label>Ownership:</label>
                                    <b-form-select class="f-12"
                                        :disabled="$store.state.user.user.team_member.role !== 'Admin' &&
                                            $store.state.user.user.team_member.role !== 'Operations' && $store.state.user.user.team_member.role !== 'Maintenance Manager'"
                                        :options="[
                                            {
                                                value: null,
                                                text: '',
                                            },
                                            {
                                                value: 'Owned',
                                                text: 'Owned',
                                            },
                                            {
                                                value: 'Leased',
                                                text: 'Leased',
                                            },
                                            {
                                                value: 'Owner Operator',
                                                text: 'Owner Operator',
                                            }
                                        ]" v-model="currentEquipment.ownership"></b-form-select>
                                    <p v-if="errors && errors.ownership" class="field-error mb-lg-3">
                                        {{ errors.ownership[0] }}
                                    </p>
                                </b-col> -->
                                <b-col md="6" sm="12" class="text-left mt-3">
                                    <label for="active-datepicker" class="state">Active Date:</label>
                                    <date-picker v-model="currentEquipment.active_date" :formatter="momentFormat"
                                        :range-separator="' - '" @clear="currentEquipment.active_date = ''"
                                        placeholder="Select Date" class="date-picker-custom" :teleport="'body'"
                                        :disabled="!allowUpdateDocEquipment"></date-picker>
                                    <p v-if="errors && errors.active_date" class="field-error">
                                        {{ errors.active_date[0] }}
                                    </p>
                                </b-col>
                                <b-col md="6" sm="12" class="text-left my-3">
                                    <label for="active-datepicker" class="state">Inactive Date:</label>
                                    <date-picker v-model="currentEquipment.inactivate_date" :formatter="momentFormat"
                                        :range-separator="' - '" @clear="currentEquipment.inactivate_date = ''"
                                        placeholder="Select Date" class="date-picker-custom" :teleport="'body'"
                                        :disabled="!allowUpdateDocEquipment"></date-picker>
                                    <p v-if="errors && errors.inactivate_date" class="field-error">
                                        {{ errors.active_date[0] }}
                                    </p>
                                </b-col>
                            </b-row>
                        </div>
                    </v-tab-item>
                    <v-tab-item key='documents' v-if="allowViewDocEquipment">
                        <div class="d-block body mb-7rem">
                            <div class="mb-3 d-flex align-items-center justify-content-between px-4 mt-4rem">
                                <h3 class="f-18 font-weight-bold text-left px-0 mb-0">Documents</h3>
                                <div class="d-flex align-items-center justify-content-end cursor-pointer"
                                    v-if="allowCreateDocEquipment" @click="addDocument">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.5 12V6.5V12ZM6.5 6.5V1V6.5ZM6.5 6.5H12H6.5ZM6.5 6.5H1H6.5Z"
                                            fill="#367BF6" />
                                        <path d="M6.5 12V6.5M6.5 6.5V1M6.5 6.5H12M6.5 6.5H1" stroke="#367BF6"
                                            stroke-width="1.5" stroke-linecap="round" />
                                    </svg>
                                    <p class="text_primary ml-2 mb-0 fw-600">Add Document</p>
                                </div>
                            </div>
                            <b-table responsive :fields="fields" :items="items" hover :current-page="currentPage"
                                show-empty>
                                <template #cell(name)="data">
                                    <!-- `data.value` is the value after formatted by the Formatter -->
                                    <a :href="`#${data.value
                                        .replace(/[^a-z]+/i, '-')
                                        .toLowerCase()}`">{{ data.value }}</a>
                                </template>
                                <template #cell(description)="data">
                                    <v-app v-if="data.value && data.value !== 'null'">
                                        <v-tooltip top>
                                            <template #activator="{ on, attrs }">
                                                <span class="w-fit-content item-description text-left" v-on="on"
                                                    v-bind="attrs">{{ data.value }}</span>
                                            </template>{{ formatTooltipText(data.value) }}
                                        </v-tooltip>
                                    </v-app>
                                    <p v-else>-</p>
                                </template>
                                <template #cell(expiry_date)="data">
                                    <span v-html="data.value"></span>
                                </template>
                                <template #cell(public)="data">
                                    <b-form-checkbox :disabled="!allowUpdateDocEquipment" v-model="data.value"
                                        name="check-button" switch @change="
                                            updateDocumentOnToggle(data.item.id, 'public', data.value)
                                            " size="lg" class="ml-2" />
                                </template>
                                <template #cell(driver)="data">
                                    <b-form-checkbox :disabled="!allowUpdateDocEquipment" v-model="data.value"
                                        name="check-button" switch @change="
                                            updateDocumentOnToggle(data.item.id, 'driver', data.value)
                                            " size="lg" class="ml-2" />
                                </template>
                                <template #cell(action)="data">
                                    <svg @click="editDocument(data.item.id, data.item.pk)" class="cursor-pointer mr-4"
                                        width="20" height="15" viewBox="0 0 20 15" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.6426 6.19707C18.8726 6.48384 19 6.85642 19 7.24273C19 7.62904 18.8726 8.00161 18.6426 8.28838C17.186 10.052 13.8704 13.4854 10 13.4854C6.12957 13.4854 2.81403 10.052 1.35741 8.28838C1.12735 8.00161 1 7.62904 1 7.24273C1 6.85642 1.12735 6.48384 1.35741 6.19707C2.81403 4.4335 6.12957 1 10 1C13.8704 1 17.186 4.4335 18.6426 6.19707Z"
                                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M10.0007 10.0154C11.533 10.0154 12.7752 8.77323 12.7752 7.24091C12.7752 5.70859 11.533 4.4664 10.0007 4.4664C8.46839 4.4664 7.2262 5.70859 7.2262 7.24091C7.2262 8.77323 8.46839 10.0154 10.0007 10.0154Z"
                                            stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    <svg @click="downloadDoc(data.item.pk, data.item.name)" class="mr-4 cursor-pointer"
                                        width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.53943 11.3822L10.001 14.8437L13.4625 11.3822" stroke="#FFC800"
                                            stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10.0001 14.8497V5.15741" stroke="#FFC800" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                                            stroke="#FFC800" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>

                                    <!-- <svg @click="editDocument(data.item.id)"
                                        v-if="$store.state.user.user.team_member.role === 'Admin' ||
                                            $store.state.user.user.team_member.role === 'Operations' || $store.state.user.user.team_member.role === 'Maintenance Manager'"
                                        class="mr-4 cursor-pointer" width="21" height="21" viewBox="0 0 21 21" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.9452 17.9664L1.71484 19.7109L3.45934 13.4806L14.8678 2.12751C14.9967 1.99564 15.1508 1.89086 15.3207 1.81932C15.4907 1.74779 15.6733 1.71094 15.8578 1.71094C16.0422 1.71094 16.2248 1.74779 16.3948 1.81932C16.5647 1.89086 16.7188 1.99564 16.8477 2.12751L19.2983 4.59196C19.428 4.72066 19.531 4.87381 19.6014 5.04251C19.6717 5.21123 19.7078 5.3922 19.7078 5.57497C19.7078 5.75774 19.6717 5.93871 19.6014 6.10743C19.531 6.27615 19.428 6.42928 19.2983 6.55798L7.9452 17.9664Z"
                                            stroke="#2CCE89" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg> -->
                                    <svg @click="showPopup(data.item.pk)" class="cursor-pointer"
                                        v-if="allowDeleteDocEquipment" width="19" height="20" viewBox="0 0 19 20"
                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                                            stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </template>
                            </b-table>
                        </div>
                    </v-tab-item>
                    <v-tab-item key="notes">
                        <div class="mt-4rem">
                            <Notes :key="currentEquipment.id" :noteURL="'app/equipment/' + currentEquipment.id + '/note'"
                                :allowUpdate="allowUpdateEquipment" @updateNotes="$emit('updateData', true)" />
                        </div>
                    </v-tab-item>
                    <v-tab-item key="activity" v-if="showActivitySection">
                        <div class="mt-4rem mb-6rem">
                            <ActivityLogs :key="currentEquipment.id" :record="currentEquipment.unit_no"
                                :object_id="currentEquipment.id" />
                        </div>
                    </v-tab-item>
                </v-tabs-items>
                <div class="sidebar-footer position-fixed">
                    <div class="d-flex align-items-center justify-content-end" v-if="allowUpdateEquipment">
                        <div class="text-right modal-footer border-0 p-0">
                            <b-button class="mx-0 m-0 h-40px w-100px"
                                :class="hasChanged && allowUpdateEquipment ? 'mr-3' : ''" @click="closePopup"
                                variant="secondary">Close</b-button>
                            <b-button class="mx-0 m-0 mb-0 h-40px w-100px" v-if="hasChanged && allowUpdateEquipment"
                                variant="primary" @click="updateEquipmentData">Save</b-button>
                        </div>
                    </div>
                    <div v-else class="text-right">
                        <b-button class="mx-0 m-0 h-40px w-100px" @click="closePopup" variant="secondary">Close</b-button>
                    </div>
                </div>
            </div>
        </b-sidebar>
        <AddTags v-if="showTagsModal" @confirmTags="addTag($event)" :existingTags="currentEquipment.tags"
            :type="'Equipment'" :equipmentID="equipmentID" />
        <qriously v-if="currentEquipment" class="d-none" :value="'https://app.digitalpermitbook.com/search-equipment?equipment=' +
            currentEquipment.id
            " :size="200" ref="qrcode" />
        <DeleteModal v-if="showEquipmentModal" @confirm="changeEquipmentStatusItem($event)"
            :message="currentEquipment.status === 'Assigned' || currentEquipment.status === 'Active' ? 'Do you want to Deactivate this Equipment?' : 'Do you want to Activate this Equipment?'"
            :removeMessage="currentEquipment.status === 'Assigned' || currentEquipment.status === 'Active' ? 'Deactivate' : ''"
            :successMessage="currentEquipment.status !== 'Assigned' && currentEquipment.status !== 'Active' ? 'Activate' : ''" />
        <DeleteModal v-if="showDocumentModal" @confirm="deleteDocumentItem($event)" />
        <b-modal id="modal-document" centered hide-footer @shown="resetField" no-close-on-backdrop size="lg"
            :body-class="'px-3 pt-2 overflow-preview-auto'" no-close-on-esc :footer-class="'d-block border-0 pt-0 pb-3 m-0'"
            :header-class="'align-items-center'" scrollable>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Document
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
                <b-dropdown id="dropdown-preview" class="load-dropdown mt-0" v-if="editDocumentFlag">
                    <template slot="button-content">
                        <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button
                        :class="(fileName && allowUpdateDocEquipment && editDocumentFlag) || allowDeleteDocDrivers ? 'mb-2' : ''"
                        @click="downloadDoc(documentData.id, `${currentEquipment.unit_no}-${documentData.description ? documentData.description : ''}`)">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Download</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button v-if="(fileName && allowUpdateDocEquipment && editDocumentFlag)"
                        :class="allowDeleteDocEquipment ? 'mb-2' : ''" @click="removeFile()">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="showPopup(documentData.id)" v-if="allowDeleteDocEquipment">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Delete</p>
                    </b-dropdown-item-button>
                </b-dropdown>
                <b-dropdown id="dropdown-preview" text="text" class="load-dropdown mt-0"
                    v-if="!editDocumentFlag && fileName">
                    <template slot="button-content">
                        <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button @click="removeFile()">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
                    </b-dropdown-item-button>
                </b-dropdown>
            </template>
            <div class="d-block text-center">
                <b-row>
                    <b-col lg="4" sm="12">
                        <div class="text-left mb-3">
                            <label for="modal-datepicker" class="state">Date:</label>
                            <date-picker v-model="documentData.date" :formatter="momentFormat" :range-separator="' - '"
                                @clear="documentData.date = ''" placeholder="Select Date" class="date-picker-custom"
                                :teleport="'body'" :disabled="!allowUpdateDocEquipment"></date-picker>
                            <p v-if="errors && errors.date" class="field-error">
                                {{ errors.date[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label for="modal-expiry-date" class="state">Expiry Date:</label>
                            <date-picker v-model="documentData.expiry_date" :formatter="momentFormat"
                                :range-separator="' - '" @clear="documentData.expiry_date = ''" placeholder="Select Date"
                                class="date-picker-custom" :teleport="'body'"
                                :disabled="!allowUpdateDocEquipment"></date-picker>
                            <p v-if="errors && errors.expiry_date" class="field-error">
                                {{ errors.expiry_date[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Type:</label>
                            <b-form-select class="f-12" :options="getDotOptions" :disabled="!allowUpdateDocEquipment"
                                v-model="documentData.doc_type" @change="setDocDescription($event)">
                            </b-form-select>
                            <p v-if="errors && errors.doc_type" class="field-error">
                                {{ errors.doc_type[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Description:</label>
                            <b-form-textarea id="textarea" :disabled="!allowUpdateDocEquipment"
                                placeholder="Enter Description" rows="3"
                                v-model="documentData.description"></b-form-textarea>
                            <p v-if="errors && errors.description" class="field-error">
                                {{ errors.description[0] }}
                            </p>
                        </div>
                        <div class="text-left mb-3">
                            <label class="state">Driver:</label>
                            <b-form-checkbox :disabled="!allowUpdateDocEquipment" v-model="documentData.driver"
                                name="check-button" switch size="lg" class="ml-0-6rem" />
                        </div>
                    </b-col>
                    <b-col lg="8" sm="12">
                        <div class="upload-btn-wrapper" v-if="!fileName">
                            <b-img :src="require('@/assets/drop.svg')"></b-img>
                            <button v-if="!fileName" class="foo">
                                Drop your document here, or browse
                            </button>
                            <button v-else class="foo">{{ fileName }}</button>

                            <input ref="docFile" type="file" name="docFile" v-on:change="handleFileUpload"
                                accept=".pdf, .png, .jpg, .jpeg" />
                            <p v-if="errors && errors.document" class="field-error">
                                {{ errors.document[0] }}
                            </p>
                        </div>
                        <div v-else>
                            <div class="review-media-section" :class="mediaType === 'pdf' ? 'overflow-hidden' : ''">
                                <iframe class="w-100 h-100 border-0" v-if="mediaType === 'pdf'" :src="media"></iframe>
                                <div v-else>
                                    <div id="panzoom-element" ref="panzoomElement">
                                        <img :src="media" class="w-100 h-100 img-section" />
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center zoom-section">
                                        <b-icon @click="zoom(1)" icon="zoom-in"
                                            style="fill:white; cursor: pointer;"></b-icon>
                                        <b-icon @click="zoom(-1)" class="ml-3" icon="zoom-out"
                                            style="fill:white;cursor: pointer;"></b-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="text-right modal-footer border-0 p-0 mt-4">
                <b-button class="mx-0 h-40px w-100px m-0" @click="$bvModal.hide('modal-document'); panzoom = null"
                    variant="secondary" :class="hasDocChanged && allowUpdateDocEquipment ? 'mr-3' : ''">{{ editDocumentFlag
                        ? 'Close' :
                        'Cancel' }}</b-button>
                <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="sendDocumentData"
                    v-if="hasDocChanged && allowUpdateDocEquipment">Save</b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import vSelect from "vue-select";
import AddTags from "@/components/Equipment/AddTags.vue";
import { mapActions, mapGetters } from "vuex";
import "vue-select/dist/vue-select.css";
import generateQRCode from "../../services/generateEquipmentQRCode";
import DeleteModal from "@/components/DeleteModal.vue";
import * as moment from "moment-timezone";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import ActivityLogs from "@/components/Shared/ActivityLogs.vue"
import { checkValueExists } from "../../services/helper";
import Panzoom from '@panzoom/panzoom'
import Notes from "../Shared/Notes";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
const numberMask = createNumberMask({
    prefix: "",
    allowDecimal: true,
    includeThousandsSeparator: true,
});
export default {
    data() {
        return {
            momentFormat: {
                stringify: (date) => {
                    return date ? moment(date).format('MMM D, YYYY') : ''
                },
                parse: (value) => {
                    return value ? moment(value, 'MMM D, YYYY').toDate() : null
                }
            },
            numberMask,
            showTagsModal: false,
            checkValueExists,
            userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            activeTab: "info",
            existingTags: [],
            equipmentStatus: false,
            qrCodeUrl: "",
            media: null,
            mediaType: null,
            panzoom: null,
            mediaID: null,
            emptyTable: false,
            itemId: null,
            showDocumentModal: false,
            showEquipmentModal: false,
            fileName: "",
            num_docs: 0,
            fields: [
                {
                    key: "Description",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "expiry_date",
                    label: "Expiry Date",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Driver",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
            currentPage: 1,
            items: [],
            defaultData: {},
            defaultDocsData: {},
            editDocumentFlag: false,
            docName: "",
            documentData: {
                object_id: null,
                date: null,
                type: null,
                expiry_date: "",
                document: null,
                description: null,
                driver: true,
                public: false,
                doc_type: ""
            },
            errors: {
                date: null,
                type: null,
                document: null,
                description: null,
            },
        }
    },
    name: "Edit-Equipment",
    components: {
        vSelect,
        AddTags,
        DeleteModal,
        ActivityLogs,
        Notes,
        DatePicker
    },
    props: ["equipmentID", "states", "options", "setNotesSection"],
    computed: {
        ...mapGetters([
            "currentEquipment",
            "company",
            "userCompany",
            "tags",
            "docTypeItems",
            "defaultDocType",
            "rolePermissions"
        ]),
        showActivitySection() {
            return this.rolePermissions && this.rolePermissions['Activity Log'] && this.rolePermissions['Activity Log'][0].allow
        },
        allowUpdateEquipment() {
            return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][1].allow
        },
        allowDeleteEquipment() {
            return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][3].allow
        },
        allowViewDocEquipment() {
            return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][6].allow
        },
        allowCreateDocEquipment() {
            return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][8].allow
        },
        allowUpdateDocEquipment() {
            return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][7].allow
        },
        allowDeleteDocEquipment() {
            return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][9].allow
        },
        hasChanged() {
            return this.existingTags.toString() !== this.currentEquipment?.tags_obj?.map((tag) => tag.id).toString() || Object.keys(this.currentEquipment).filter(field => {
                if (field !== 'docs' && field !== 'num_docs' && field !== 'updated_at' && field !== 'company') {
                    if (typeof this.currentEquipment[field] === 'object') return JSON.stringify(this.currentEquipment[field]) !== JSON.stringify(this.defaultData[field])
                    if (field === 'current_odometer') return this.checkValueExists(this.currentEquipment[field])?.toString()?.replaceAll(",", "") != this.checkValueExists(this.defaultData[field])?.toString()?.replaceAll(",", "")
                    else if (!this.currentEquipment[field] && !this.defaultData[field]) return false
                    else return this.checkValueExists(this.currentEquipment[field]) != this.checkValueExists(this.defaultData[field])
                } return false
            }).length > 0
        },
        hasDocChanged() {
            return Object.keys(this.documentData).filter(field => {
                if (field !== 'updated_at') {
                    if (typeof this.documentData[field] === 'object') return JSON.stringify(this.documentData[field]) !== JSON.stringify(this.defaultDocsData[field])
                    if (field === 'description') {
                        return this.checkValueExists(this.documentData[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n')) != this.checkValueExists(this.defaultDocsData[field]?.trim()?.replace(/\r\n|\r|\n/g, '\n'));
                    }
                    else if (!this.documentData[field] && !this.defaultDocsData[field]) return false
                    else return this.checkValueExists(this.documentData[field]) != this.checkValueExists(this.defaultDocsData[field])
                } return false
            }).length > 0
        },
        getDotOptions() {
            return [...[{
                value: "",
                text: "",
            }], ...this.docTypeItems?.filter((item) => item.model === this.currentEquipment.type || item.model === 'All').map((item) => {
                return {
                    value: item.id,
                    text: item.type,
                }
            })]
        },
        allTags() {
            return this.tags.map((item) => {
                return {
                    id: item.id,
                    label: item.add_tag,
                }
            })
        }
    },
    watch: {
        currentEquipment() {
            this.existingTags = this.currentEquipment.tags_obj.map((item) => item.id);

        },
        async equipmentID() {
            if (this.equipmentID) {
                this.activeTab = 'info'
                try {
                    this.setLoader(true)
                    // this.$bvModal.show("edit-equipment-modal");
                    await this.getAllTags(this.userCompany);
                    await this.getEquipment(this.equipmentID);
                    if (this.currentEquipment.active_date) {
                        this.currentEquipment.active_date = moment.tz(this.currentEquipment.active_date, this.userTimeZone).toDate();
                    }
                    if (this.currentEquipment.inactivate_date) {
                        this.currentEquipment.inactivate_date = moment.tz(this.currentEquipment.inactivate_date, this.userTimeZone).toDate();
                    }
                    this.equipmentStatus = this.currentEquipment.status === "Active";
                    if (this.setNotesSection) {
                        if (this.allowViewDocEquipment) {
                            this.activeTab = 2
                        }
                        else this.activeTab = 1
                    }
                    this.defaultData = JSON.parse(JSON.stringify(this.currentEquipment))
                    this.updateDocs();
                    await this.$nextTick();
                    if (this.allowUpdateEquipment) {
                        this.qrCodeUrl =
                            this.$refs?.qrcode.qrious._canvasRenderer.element.toDataURL();
                    }
                    this.setLoader(false)
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            }
        }
    },
    methods: {
        ...mapActions([
            "getEquipment",
            "getAllTags",
            "sendEquipmentDocument",
            "updateEquipmentDocument",
            "deleteEquipmentDocument",
            "updateEquipment",
            "setNotification",
            "deleteEquipment",
            "setLoader",
            "retrieveDocument",
            "updateEquipmentStatus"
        ]),
        zoom(level) {
            if (!this.panzoom) {
                this.panzoom = Panzoom(document.getElementById('panzoom-element'), {
                    maxScale: 5
                })
            }
            level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn()
        },
        setDocDescription(event) {
            if (event) {
                this.documentData.description = this.getDotOptions.filter((option) => option.value === event)[0].text
            }
            else this.documentData.description = this.defaultDocsData.description
        },
        formatTooltipText(text) {
            const maxLineLength = window.screen.width <= 767 ? 50 : 80;
            let formattedText = "";
            let lineLength = 0;

            text.split(' ').forEach(word => {
                if (lineLength + word.length + 1 > maxLineLength) {
                    formattedText += "\n";
                    lineLength = 0;
                }
                formattedText += (lineLength === 0 ? "" : " ") + word;
                lineLength += word.length + 1;
            });
            return formattedText;
        },
        changeEquipmentStatusItem(item) {
            if (item) {
                this.changeEquipmentStatus()
            }
            this.showEquipmentModal = false
        },
        async changeEquipmentStatus() {
            try {
                this.setLoader(true)
                await this.updateEquipmentStatus({
                    action: this.currentEquipment.status === 'Assigned' || this.currentEquipment.status === 'Active' ? 'deactivate' : 'activate',
                    equipment_ids: [this.currentEquipment.id]
                })
                await this.setNotification({
                    msg: `Equipment ${this.currentEquipment.status === 'Assigned' || this.currentEquipment.status === 'Active' ? 'Deactivated' : 'Activated'} Successfully`,
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
                this.$emit("updateData", true);
                this.$emit("resetEquipment", true);
            }
            catch (e) {
                this.setLoader(false)
                console.log(e)
            }
        },
        showPopup(id) {
            this.showDocumentModal = true;
            this.itemId = id;
        },
        async editDocument(id, docID) {
            this.editDocumentFlag = true;
            this.documentIndex = id;
            this.documentData = { ...this.currentEquipment.docs[id], doc_type: this.currentEquipment.docs[id].doc_type_id, date: moment.tz(this.currentEquipment.docs[id].date, this.userTimeZone).toDate(), expiry_date: this.currentEquipment.docs[id].expiry_date ? moment.tz(this.currentEquipment.docs[id].expiry_date, this.userTimeZone).toDate() : "" };
            if (this.documentData.document) {
                let file_name = "";
                if (typeof this.documentData.document === "string") {
                    file_name = this.documentData.document;
                } else {
                    file_name = this.documentData.document.name;
                }
                this.fileName = file_name.substring(file_name.lastIndexOf("/") + 1);
            }
            this.errors = {
                date: null,
                type: null,
                document: null,
                expiry_date: "",
                description: null,
                ...this.company[0].doc_type ? { doc_type: null } : {}
            };
            await this.openDoc(docID)
            this.defaultDocsData = JSON.parse(JSON.stringify({ ...this.documentData, doc_type: this.currentEquipment.docs[id].doc_type_id ? this.currentEquipment.docs[id].doc_type_id : '' }))
            this.$bvModal.show("modal-document");
        },
        async downloadDoc(id, name) {
            this.setLoader(true)
            const doc = await this.retrieveDocument(id);
            fetch(doc.document)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.blob();
                })
                .then(blob => {
                    const newUrl = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = newUrl;
                    const urlWithoutParams = doc.document.split('?')[0];
                    const extension = urlWithoutParams.split('.').pop();
                    a.download = name + "." + extension
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(newUrl);
                    a.remove()
                    this.setLoader(false)
                })
                .catch(error => {
                    this.setLoader(false)
                    console.log(error)
                });
        },
        async openDoc(id) {
            const doc = await this.retrieveDocument(id);
            this.media = doc.document
            this.mediaID = id
            this.itemId = id;
            if (doc.document.includes('.pdf')) this.mediaType = 'pdf'
            else this.mediaType = 'image'
        },
        async deleteDocumentItem(item) {
            if (item) {
                await this.deleteDocument(this.itemId);
            }
            this.showDocumentModal = false;
        },
        async deleteDocument(id) {
            try {
                this.setLoader(true)
                const docs = await this.deleteEquipmentDocument(`${id}/?company_id=${this.userCompany}`);
                if (docs) {
                    this.currentEquipment.docs = docs;
                }
                this.updateDocs();

                await this.setNotification({
                    msg: "Document successfully deleted!!",
                    type: "success",
                    color: "green",
                });
                await this.getEquipment(this.equipmentID);
                this.defaultData = JSON.parse(JSON.stringify(this.currentEquipment))
                this.$bvModal.hide("modal-document");
                this.setLoader(false)
                this.$emit("updateData", true);
            } catch (error) {
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        updateDocs() {
            this.items = [];
            this.currentEquipment.docs.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase())).forEach((doc, id) => {
                let color;
                if (doc.expiry_date) {
                    const currentDate = new Date();
                    const expiryDate = new Date(doc.expiry_date);
                    const diffDays = parseInt(
                        (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
                        10
                    );
                    if (diffDays > 31) {
                        color = "color:black";
                    } else if (diffDays <= 31 && diffDays >= 1) {
                        color = "color:#FFC800";
                    } else color = "color:#FF0000";
                }
                this.items.push({
                    id: id,
                    pk: doc.id,
                    Date: moment(doc.date).format("MMM DD, YYYY"),
                    Description: doc.description,
                    expiry_date: doc.expiry_date
                        ? `<span style=${color}>${moment(doc.expiry_date).format(
                            "MMM DD, YYYY"
                        )}</span>`
                        : "-",
                    Public: doc.public,
                    Driver: doc.driver,
                    link: doc.document,
                    name: `${this.currentEquipment.unit_no}-${doc.description ? doc.description : ''}`
                });
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
            });
        },
        removeFile() {
            this.fileName = null;
            // this.$refs.docFile.value = "";
            this.documentData.document = null
            this.media = null
            this.mediaType = null
        },
        async updateDocumentOnToggle(id, key, value) {
            try {
                this.setLoader(true)
                this.currentEquipment.docs[id][key] = value;
                if (!this.currentEquipment.docs[id].expiry_date)
                    delete this.currentEquipment.docs[id].expiry_date;
                delete this.currentEquipment.docs[id].document;
                await this.updateEquipmentDocument({
                    id: this.currentEquipment.docs[id].id,
                    payload: this.currentEquipment.docs[id],
                });
                await this.setNotification({
                    msg: "Document Successfully updated!!",
                    type: "success",
                    color: "green",
                });
                this.setLoader(false)
            } catch (error) {
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (!value) {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        extractFilePath(url) {
            const pathname = new URL(url).pathname;

            return pathname.substring(pathname.indexOf("documents/"));
        },
        async sendDocumentData() {
            try {
                const nullKeys = this.checkNullValues({
                    date: this.documentData.date,
                    document: this.documentData.document,
                    ...this.company[0].doc_type ? { doc_type: this.documentData.doc_type } : {}
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true);
                    let formData = new FormData();
                    let fileType = this.documentData.document instanceof File || this.documentData.document instanceof Blob;
                    if (!fileType) this.documentData.document = null
                    formData.append("object_id", this.currentEquipment.id);
                    formData.append(
                        "date",
                        moment(this.documentData.date).format("YYYY-MM-DD")
                    );
                    formData.append("type", this.documentData.type);
                    formData.append("description", this.documentData.description);
                    if (this.documentData.doc_type) formData.append("doc_type", this.documentData.doc_type);
                    formData.append("expiry_date", this.documentData.expiry_date ? moment(this.documentData.expiry_date).format("YYYY-MM-DD") : '');
                    if (this.documentData.document) {
                        formData.append(
                            "document",
                            new File(
                                [this.documentData.document],
                                this.currentEquipment.unit_no + "_" + this.documentData.description +
                                "_" + this.userCompany + "_" +
                                new Date().getTime() +
                                "." +
                                this.documentData.document["type"].split("/").pop(),
                                { type: this.documentData.document["type"] }
                            )
                        );
                    }
                    formData.append("driver", this.documentData.driver);
                    formData.append("public", this.documentData.public);
                    formData.append("model_type", "equipment");
                    formData.append("company_id", parseInt(localStorage.getItem("userSelectedCompany")));
                    if (this.currentEquipment.current_odometer) {
                        formData.append("current_odometer", this.currentEquipment.current_odometer.toString().replace(/,/g, ""))
                    }
                    if (this.editDocumentFlag) {
                        this.documentData = await this.updateEquipmentDocument({
                            id: this.documentData.id,
                            payload: formData
                        });
                        this.editDocumentFlag = false;
                        await this.setNotification({
                            msg: "Document Successfully updated!!",
                            type: "success",
                            color: "green",
                        });
                        let color;
                        if (this.documentData.expiry_date) {
                            const currentDate = new Date();
                            const expiryDate = new Date(this.documentData.expiry_date);
                            const diffDays = parseInt(
                                (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
                                10
                            );
                            if (diffDays > 31) {
                                color = "color:black";
                            } else if (diffDays <= 31 && diffDays >= 1) {
                                color = "color:#FFC800";
                            } else color = "color:#FF0000";
                        }
                        this.currentEquipment.docs[this.documentIndex] = { ...this.documentData, doc_type_id: this.documentData.doc_type, document: this.extractFilePath(this.documentData.document) };
                        this.items[this.documentIndex].Date = moment(
                            this.documentData.date
                        ).format("MMM DD, YYYY");
                        this.items[this.documentIndex].Type = this.documentData.type;
                        this.items[this.documentIndex].Description =
                            this.documentData.description;
                        this.items[this.documentIndex].expiry_date = this.documentData
                            .expiry_date
                            ? `<span style=${color}>${moment(
                                this.documentData.expiry_date
                            ).format("MMM DD, YYYY")}</span`
                            : "-";
                        this.items[this.documentIndex] = this.documentData.driver;
                        this.editDocumentFlag = false;
                        this.$bvModal.hide("modal-document");
                        this.documentData = {
                            object_id: null,
                            date: null,
                            type: null,
                            document: null,
                            description: null,
                            expiry_date: "",
                            driver: true,
                            public: false,
                            doc_type: ""
                        };
                        this.$emit("updateData")
                        this.updateDocs();
                    } else {
                        if (this.documentData.date) {

                            const docs = await this.sendEquipmentDocument(formData);
                            if (docs) {
                                await this.getEquipment(this.equipmentID);
                                this.defaultData = JSON.parse(JSON.stringify(this.currentEquipment))
                                this.updateDocs();
                                await this.setNotification({
                                    msg: "Document Successfully added!!",
                                    type: "success",
                                    color: "green",
                                });
                            }
                            await this.getEquipment(this.equipmentID);
                            this.defaultData = JSON.parse(JSON.stringify(this.currentEquipment))
                            this.$bvModal.hide("modal-document");
                            this.$emit("updateData", true);
                            this.documentData = {
                                object_id: null,
                                date: null,
                                type: null,
                                document: null,
                                description: null,
                                expiry_date: "",
                                driver: true,
                                public: false,
                                doc_type: ""
                            };
                        } else {
                            this.errors.date = this.documentData.date
                                ? null
                                : ["This field is required."];
                            // this.errors.description = this.documentData.description? null: ['This field is required.']
                            console.log(this.errors);
                        }
                    }
                    this.setLoader(false);
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            } catch (error) {
                console.log(error)
                this.setLoader(false);
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                }
            }
        },
        addDocument() {
            this.fileName = null;
            this.editDocumentFlag = false;
            this.$bvModal.show("modal-document");
            this.documentData = {
                object_id: null,
                date: new Date(),
                type: null,
                document: null,
                expiry_date: "",
                description: null,
                driver: true,
                public: false,
                doc_type: ""
            };
            this.defaultDocsData = JSON.parse(JSON.stringify(this.documentData))
            this.errors = {
                date: null,
                type: null,
                document: null,
                expiry_date: "",
                description: null,
                ...this.company[0].doc_type ? { doc_type: null } : {}
            };
        },
        closePopup() {
            this.$emit("resetEquipment", true);
        },
        async deleteEquipmentItem(item) {
            if (item) {
                await this.deleteTeamEquipment(this.itemId);
            }
            this.showDocumentModal = false;
        },
        async deleteTeamEquipment() {
            try {
                this.setLoader(true)
                await this.deleteEquipment(`${this.equipmentID}/?company_id=${this.userCompany}`);
                await this.setNotification({
                    msg: "Equipment successfully deleted!!",
                    type: "success",
                    color: "green",
                });
                this.$emit("updateData", true);
                this.$emit("resetEquipment", true);
                this.setLoader(false)
            } catch (error) {
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },
        changeEquipmentStatusField() {
            this.currentEquipment.status = this.equipmentStatus
                ? "Active"
                : "Inactive";
        },
        handleFileUpload() {
            this.documentData.document = this.$refs.docFile.files[0];
            this.fileName = this.documentData.document.name;
            this.media = URL.createObjectURL(this.documentData.document);
            if (this.$refs.docFile.files[0].type.includes('pdf')) this.mediaType = 'pdf'
            else this.mediaType = 'image'
        },
        generateEquipmentQRCode(unitNo, fileName, qrCode) {
            generateQRCode(unitNo, fileName, qrCode)
        },
        async removeTag(tag) {
            const index = this.existingTags.indexOf(tag);
            this.existingTags.splice(index, 1);
            await this.updateEquipmentData(false);
        },
        addTag(item) {
            console.log(item);
            this.showTagsModal = false;
        },
        resetField() {
            if (this.$refs.docFile) this.$refs.docFile.value = null;
        },
        async updateEquipmentData(closePopup = true) {
            try {
                const nullKeys = this.checkNullValues({
                    unit_no: this.currentEquipment.unit_no,
                });
                if (nullKeys.length === 0) {
                    this.setLoader(true)
                    Object.keys(this.currentEquipment).forEach((e) => {
                        if (this.currentEquipment[e] === "") {
                            this.currentEquipment[e] = null;
                        }
                    });
                    delete this.currentEquipment.picture;
                    delete this.currentEquipment.company;
                    delete this.currentEquipment.country;

                    let equipmentData = {
                        ...this.currentEquipment,
                        type: this.$route.name,
                        state: this.currentEquipment.state.id
                            ? this.currentEquipment.state.id
                            : 54,
                        plate_no: this.currentEquipment.plate_no
                            ? this.currentEquipment.plate_no.toUpperCase()
                            : null,
                        vin_no: this.currentEquipment.vin_no
                            ? this.currentEquipment.vin_no.toUpperCase()
                            : null,
                        dispatch_group1: this.dispatch_group1,
                        dispatch_group2: this.dispatch_group2,
                        ...this.currentEquipment.current_odometer ? { current_odometer: this.currentEquipment.current_odometer.toString().replace(/,/g, "") } : {},
                        active_date: this.currentEquipment.active_date ? moment(this.currentEquipment.active_date).format("YYYY-MM-DD") : null,
                        inactivate_date: this.currentEquipment.inactivate_date ? moment(this.currentEquipment.inactivate_date).format("YYYY-MM-DD") : null
                    };
                    const updatedEquipment = await this.updateEquipment({
                        id: this.equipmentID,
                        payload: {
                            ...equipmentData,
                            tags: this.existingTags
                        },
                    });
                    if (this.currentEquipment) {
                        await this.setNotification({
                            msg: `Successfully updated ${this.currentEquipment.unit_no}`,
                            type: "success",
                            color: "green",
                        });
                        if (closePopup) {
                            this.$emit("updateEquipment", updatedEquipment);
                            this.$emit("resetEquipment", true);
                        }
                        else this.$emit("updateData", true);
                    }
                    this.setLoader(false)
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            } catch (error) {
                this.setLoader(false)
                if (typeof error === "object") {
                    this.errors = error;
                    // eslint-disable-next-line no-ex-assign
                } else {
                    await this.setNotification({
                        msg: error,
                        type: "error",
                        color: "red",
                    });
                }
            }
        },
    }
}
</script>
<style>
.equipment-edit-modal .body {
    padding: 0px 25px 25px 25px !important;
}

.equipment-edit-modal .equipment-tabs {
    position: sticky;
    top: 0;
    z-index: 999;
}

.equipment-edit-modal.modal-body {
    padding: 0px !important
}

.sidebar-footer {
    border-top: 1px solid #EAEAEA;
    padding: 1rem 1.4rem;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: white;
}

.sidebar-tabs {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    background-color: white;
}

.mt-4rem {
    margin-top: 4rem;
}

.mt-3rem {
    margin-top: 3rem;
}

.edit-equipment-status {
    width: 88px !important;
    height: 30px !important;
}

.remove-doc {
    top: 10px;
    z-index: 1;
    right: 15px
}

@media (max-width: 425px) {
    .sidebar-b-margin {
        margin-bottom: 9rem;
    }
}
</style>