<template>
    <div class="bg-white rounded">
        <div class="table pt-3 mb-0">
            <div class="add_license d-flex justify-content-between align-items-center flex-wrap mb-3 px-3">
                <h3 class="font-weight-bold f-20 text-left mb-0 text-black">Contacts</h3>
                <b-button @click="$bvModal.show('contact-modal')" v-if="allowAddContact"
                    class="export equipment d-flex justify-content-center align-items-center w-fit-content"
                    variant="primary">
                    <b-icon icon="plus" class="p-0" aria-hidden="true"></b-icon>
                    <p class="mb-0 ml-2 text-white p-0">Add Contact</p>
                </b-button>
            </div>
            <b-table ref="sortableTable" responsive :fields="fields" :items="items" hover :per-page="perPage"
                :current-page="currentPage" show-empty>
                <template #head(sort)="">
                    <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="16" r="2" transform="rotate(-90 2 16)" fill="#C4C4C4" />
                        <circle cx="2" cy="9" r="2" transform="rotate(-90 2 9)" fill="#C4C4C4" />
                        <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill="#C4C4C4" />
                    </svg>

                </template>
                <template #cell(sort)="">
                    <svg class="sortable-handle" width="4" height="18" viewBox="0 0 4 18" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="2" cy="16" r="2" transform="rotate(-90 2 16)" fill="#C4C4C4" />
                        <circle cx="2" cy="9" r="2" transform="rotate(-90 2 9)" fill="#C4C4C4" />
                        <circle cx="2" cy="2" r="2" transform="rotate(-90 2 2)" fill="#C4C4C4" />
                    </svg>

                </template>
                <template #cell(phone)="data">
                    {{ data.value }} <span v-if="data.item.ext_no" class="ml-2">Ext. {{ data.item.ext_no }}</span>
                </template>
                <template #cell(action)="data">
                    <div>
                        <svg @click="editContactItem(data.item)" class="mr-4 cursor-pointer" width="21" height="21"
                            viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.9452 17.9664L1.71484 19.7109L3.45934 13.4806L14.8678 2.12751C14.9967 1.99564 15.1508 1.89086 15.3207 1.81932C15.4907 1.74779 15.6733 1.71094 15.8578 1.71094C16.0422 1.71094 16.2248 1.74779 16.3948 1.81932C16.5647 1.89086 16.7188 1.99564 16.8477 2.12751L19.2983 4.59196C19.428 4.72066 19.531 4.87381 19.6014 5.04251C19.6717 5.21123 19.7078 5.3922 19.7078 5.57497C19.7078 5.75774 19.6717 5.93871 19.6014 6.10743C19.531 6.27615 19.428 6.42928 19.2983 6.55798L7.9452 17.9664Z"
                                stroke="#2CCE89" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg v-if="allowDeleteContact" @click="showContactDeletePopup(data.item.id)" class="cursor-pointer"
                            width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path
                                d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                                stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                                stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </div>
                </template>
            </b-table>
            <div class="entries-pagination px-0 px-sm-2">
                <div class="d-flex align-items-center flex-wrap">
                    <div class="text-left show-entries mr-md-3 pl-3 pl-md-0 ml-md-0">
                        <b-form-select v-model="perPage" :options="pageOptions" />
                    </div>
                    <div class="pagination">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                            aria-controls="my-table"></b-pagination>
                    </div>
                    <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">
                        {{ positionText }}
                    </p>
                </div>
            </div>
        </div>
        <b-modal id="contact-modal" centered no-close-on-backdrop :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
            :body-class="'equipment-edit-modal'" no-close-on-esc>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    {{ editContact ? 'Edit Contact' : 'Add Contact' }}
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="body">
                <b-row>
                    <b-col cols="12" class="mt-3">
                        <b-form-group label="Name:" class="text-left mb-0">
                            <b-form-input v-model="form.name"
                                :disabled="!allowAddContact && (!editContact || (editContact && !allowUpdateContact))"
                                placeholder="Enter Name" class="unit" required></b-form-input>
                            <p v-if="errors && errors.name" class="field-error">
                                {{ errors.name[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mt-3">
                        <b-form-group label="Department:" class="text-left mb-0">
                            <b-form-input v-model="form.department"
                                :disabled="!allowAddContact && (!editContact || (editContact && !allowUpdateContact))"
                                placeholder="Enter Department" class="unit" required></b-form-input>
                            <p v-if="errors && errors.department" class="field-error">
                                {{ errors.department[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                    <b-col sm="9" class="mt-3">
                        <b-form-group label="Phone #:" class="text-left mb-0">
                            <b-input-group size="lg" class="w-100" prepend="+1">
                                <b-form-input v-mask="phoneMask" v-model="form.phone"
                                :disabled="!allowAddContact && (!editContact || (editContact && !allowUpdateContact))"
                                ></b-form-input>
                            </b-input-group>
                            <p v-if="errors && errors.phone" class="field-error">
                                {{ errors.phone[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                    <b-col sm="3" class="mt-3">
                        <b-form-group label="Ext #:" class="text-left mb-0">
                            <b-form-input v-model="form.ext_no"
                                :disabled="!allowAddContact && (!editContact || (editContact && !allowUpdateContact))"
                                placeholder="Enter Ext" class="unit" required></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" class="mt-3">
                        <b-form-group label="Email:" class="text-left mb-0">
                            <b-form-input v-model="form.email"
                                :disabled="!allowAddContact && (!editContact || (editContact && !allowUpdateContact))"
                                placeholder="Enter Email" class="unit" required></b-form-input>
                            <p v-if="errors && errors.email" class="field-error">
                                {{ errors.email[0] }}
                            </p>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <template #modal-footer>
                <div class="text-right modal-footer border-0 p-0 mx-2">
                    <b-button class="mx-0 h-40px w-100px m-0"
                        :class="(allowAddContact && !editContact) || (editContact && allowUpdateContact) ? 'mr-3' : ''"
                        @click="resetForm()" variant="secondary">Cancel</b-button>
                    <div class="d-flex align-items m-0"
                        v-if="(allowAddContact && !editContact) || (editContact && allowUpdateContact)">
                        <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary"
                            @click="updateContactItem()">Save</b-button>
                    </div>
                </div>
            </template>
        </b-modal>
        <DeleteModal v-if="showDeletePopup" @confirm="deleteItem($event)" />
    </div>
</template>
<script>

import { mapGetters, mapActions } from "vuex";
import DeleteModal from "@/components/DeleteModal.vue";
import { checkPhoneFormat } from "../../services/helper";
export default {
    name: "Contacts",
    components: { DeleteModal },
    data() {
        return {
            items: [],
            editContact: false,
            perPage: 50,
            currentPage: 1,
            checkPhoneFormat,
            contactID: null,
            emptyTable: false,
            showDeletePopup: false,
            pageOptions: [
                {
                    value: 10,
                    text: "10",
                },
                {
                    value: 25,
                    text: "25",
                },
                {
                    value: 50,
                    text: "50",
                },
                {
                    value: 100,
                    text: "100",
                },
            ],
            errors: {},
            form: {
                name: "",
                department: "",
                phone: "",
                ext_no: "",
                email: ""
            },
            fields: [
                {
                    key: "sort",
                    label: "",
                    thStyle: { minWidth: "20px", width: "20px" },
                    thClass: 'font-table-weight-bold'
                },
                {
                    key: "name",
                    label: "Name",
                    thClass: 'font-table-weight-bold',
                },
                {
                    key: "department",
                    label: "Department",
                    thClass: 'font-table-weight-bold',
                },
                {
                    key: "phone",
                    label: "Phone #",
                    thClass: 'font-table-weight-bold',
                },
                {
                    key: "email",
                    label: "Email",
                    thClass: 'font-table-weight-bold',
                },
                {
                    key: "Action",
                    thClass: 'font-table-weight-bold'
                },
            ],
        }
    },
    computed: {
        ...mapGetters(["userCompany", 'rolePermissions']),
        allowViewContact() {
            return this.rolePermissions && this.rolePermissions['Contacts'] && this.rolePermissions['Contacts'][0].allow
        },
        allowAddContact() {
            return this.rolePermissions && this.rolePermissions['Contacts'] && this.rolePermissions['Contacts'][2].allow
        },
        allowUpdateContact() {
            return this.rolePermissions && this.rolePermissions['Contacts'] && this.rolePermissions['Contacts'][1].allow
        },
        allowDeleteContact() {
            return this.rolePermissions && this.rolePermissions['Contacts'] && this.rolePermissions['Contacts'][3].allow
        },
        phoneMask() {
            return this.form.phone.length > 0
                ? [
                    /\d/, /\d/, /\d/, " ",
                    /\d/, /\d/, /\d/, " ",
                    /\d/, /\d/, /\d/, /\d/
                ]
                : false; // Allows empty input
        },
        rows() {
            return this.items.length;
        },
        positionText() {
            let endIndex = this.currentPage * this.perPage,
                startIndex =
                    this.rows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0;

            return (
                startIndex +
                " to " +
                (endIndex > this.rows ? this.rows : endIndex) +
                " of " +
                this.rows
            );
        }
    },
    methods: {
        ...mapActions(['getAllContacts', "deleteContact", "setNotification", "updateContact", "addContact", "setLoader", "updateContactOrder"]),
        initSortable() {
            const tableBody = this.$refs.sortableTable.$el.querySelector('tbody');
            window.Sortable.create(tableBody, {
                animation: 150,
                handle: '.sortable-handle',
                onEnd: async (evt) => {
                    const lastItem = this.items[evt.newIndex];
                    this.items[evt.newIndex] = this.items[evt.oldIndex];
                    this.items[evt.oldIndex] = lastItem;
                    try {
                        const contact_ids = this.items.map((item) => item.id)
                        const order_nos = this.items.map((item, index) => index + 1)
                        await this.updateContactOrder({
                            contact_ids, order_nos
                        })
                    }
                    catch (e) {
                        console.log(e)
                    }
                },
            });
        },
        async deleteItem(status) {
            if (status) {
                try {
                    this.setLoader(true)
                    await this.deleteContact(`${this.contactID}/?company_id=${this.userCompany}`)
                    await this.setNotification({
                        msg: "Contact successfully deleted!!",
                        type: "success",
                        color: "green",
                    });
                    this.contactID = null
                    this.showDeletePopup = false;
                    this.setLoader(false)
                    await this.getContacts()
                }
                catch (e) {
                    this.setLoader(false)
                    console.log(e)
                }
            }
            else this.showDeletePopup = false
        },
        showContactDeletePopup(id) {
            this.showDeletePopup = true;
            this.contactID = id;
        },
        async getContacts() {
            const data = await this.getAllContacts(this.userCompany);
            this.items = [];
            data.map((item) => {
                this.items.push({
                    department: item.department,
                    id: item.id,
                    name: item.name,
                    phone: item.phone,
                    ext_no: item.ext_no,
                    email: item.email,
                    order_no: item.order_no
                })
            })
        },
        resetForm() {
            this.$bvModal.hide('contact-modal')
            this.form = {
                name: "",
                department: "",
                phone: "",
                ext_no: "",
                email: ""
            },
                this.errors = {}
            this.contactID = null
            this.editContact = false

        },
        checkNullValues(payload) {
            let nullKeys = [];
            for (let [key, value] of Object.entries(payload)) {
                if (value === "") {
                    nullKeys.push(key);
                }
            }
            return nullKeys;
        },
        async updateContactItem() {
            try {
                this.errors = {}
                const nullKeys = this.checkNullValues({
                    name: this.form.name,
                    department: this.form.department,
                    phone: this.form.phone,
                    email: this.form.email
                });
                if (this.form.phone) {
                    if (!this.checkPhoneFormat(this.form.phone)) {
                        throw {
                            phone: ["Wrong format"],
                        };
                    }
                }
                if (nullKeys.length === 0) {
                    let method = 'added'
                    if (this.editContact) {
                        method = 'updated'
                        try {
                            this.setLoader(true)
                            await this.updateContact({
                                id: this.contactID,
                                payload: { ...{...this.form, phone: this.form.phone ? !this.form.phone.includes("+1") ? "+1 " + this.form.phone : this.form.phone : ''}, company: this.userCompany }
                            })
                        }
                        catch (e) {
                            this.setLoader(false)
                            await this.setNotification({
                                msg: e,
                                type: "error",
                                color: "red",
                            });
                            return
                        }
                    }
                    else {
                        try {
                            this.setLoader(true)
                            await this.addContact({
                                id: this.userCompany,
                                payload: { ...{ ...{...this.form, phone: this.form.phone ? !this.form.phone.includes("+1") ? "+1 " + this.form.phone : this.form.phone : ''}, order_no: this.items.length + 1 }, company: this.userCompany }
                            })
                        }
                        catch (e) {
                            this.setLoader(false)
                            await this.setNotification({
                                msg: e,
                                type: "error",
                                color: "red",
                            });
                            return
                        }
                    }
                    await this.setNotification({
                        msg: `Contact successfully ${method}!!`,
                        type: "success",
                        color: "green",
                    });
                    this.setLoader(false)
                    this.resetForm()
                    this.getContacts()
                }
                else {
                    let error = {};
                    nullKeys.forEach((x) => {
                        error[x] = ["This field is required."];
                    });
                    throw error;
                }
            }
            catch (error) {
                if (typeof error === "object") {
                    this.errors = error;
                    console.log(this.errors)
                }
            }
        },
        editContactItem(data) {
            this.editContact = true
            this.form = {
                name: data.name,
                department: data.department,
                phone: data.phone.startsWith("+1") ? data.phone.slice(2).trim() : data.phone,
                ext_no: data.ext_no,
                email: data.email
            }
            this.contactID = data.id
            this.$bvModal.show('contact-modal')
        },
    },
    async mounted() {
        await this.getContacts();
        this.initSortable()
    }
}
</script>
<style>
.sortable-handle {
    cursor: pointer;
}
</style>