<template>
    <div>
        <Reports :title="'Active Driver'" :exportCsv="exportActiveDrivers"  :defaultSortField="'name'" v-if="$route.params.name === 'active-driver'" :fields="activeDriverFields" :getData="getActiveDrivers"/>
        <Reports :title="'Active Equipment'" :exportCsv="exportActiveEquipments" :defaultSortField="'unit_no'" v-if="$route.params.name === 'active-equipment'" :fields="activeEquipmentFields" :getData="getActiveEquipments"/>
        <RequiredDocs v-if="$route.params.name === 'required-docs'"/>
    </div>
</template>
<script>
import Reports from "@/components/Reports"
import RequiredDocs from "@/components/Reports/RequiredDocs.vue";
import { mapActions } from "vuex";
export default {
    name: "ReportDetails",
    components: { Reports, RequiredDocs },
    methods: {
        ...mapActions(["getActiveDrivers","getActiveEquipments","exportActiveDrivers","exportActiveEquipments"])
    },
    data() {
        return {
            activeDriverFields: [
                {
                    label: "Name",
                    key: "name",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold',
                    stickyColumn: true
                },
                {
                    label: "License #",
                    key: "license_number",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "State",
                    key: "state",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Default Vehicle #",
                    key: "default_vehicle",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Active Date",
                    key: "activate_date",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Inactive Date",
                    key: "inactive_date",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
            ],
            activeEquipmentFields: [
                {
                    label: "Unit #",
                    key: "unit_no",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold',
                    stickyColumn: true
                },
                {
                    label: "Type",
                    key: "type",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Year",
                    key: "year",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Make",
                    key: "make",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Model",
                    key: "model",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Vin #",
                    key: "vin_no",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Plate",
                    key: "plate_no",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "State",
                    key: "state",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Value",
                    key: "value",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Active Date",
                    key: "active_date",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Inactive Date",
                    key: "inactivate_date",
                    sortable: true,
                    tdClass: "align-middle",
                    field: "text",
                    thClass: 'font-table-weight-bold'
                }
            ],
        }
    },
}
</script>