<template>
    <div>
        <div class="bg-white pt-3 pt-sm-4 rounded">
            <div class="table_row">
                <div class="equipment-border filter-box">
                    <div class="d-flex mb-4 px-3 px-sm-4">
                        <b-form-group class="text-left label font-weight-normal search-field mb-0">
                            <b-input-group class="username-input-field">
                                <b-form-input type="text" placeholder="Search" v-model="filterFields.search"
                                    class="unit f-12" @keyup.enter="getFileData()"></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="primary" @click="getFileData()">
                                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                                stroke="white" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>

                        <div class="ml-auto d-none d-sm-flex">
                            <div class="position-relative">
                                <div class="position-absolute notification-badge" v-if="activeFiltersCount">
                                    {{ activeFiltersCount }}
                                </div>
                                <button class="btn btn-filter-section" @click="$bvModal.show('modal-filter-filedrop')">
                                    <div class="d-flex align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="lucide lucide-sliders-horizontal w-4 h-4"
                                            data-id="element-964">
                                            <line x1="21" x2="14" y1="4" y2="4"></line>
                                            <line x1="10" x2="3" y1="4" y2="4"></line>
                                            <line x1="21" x2="12" y1="12" y2="12"></line>
                                            <line x1="8" x2="3" y1="12" y2="12"></line>
                                            <line x1="21" x2="16" y1="20" y2="20"></line>
                                            <line x1="12" x2="3" y1="20" y2="20"></line>
                                            <line x1="14" x2="14" y1="2" y2="6"></line>
                                            <line x1="8" x2="8" y1="10" y2="14"></line>
                                            <line x1="16" x2="16" y1="18" y2="22"></line>
                                        </svg>
                                        <p class="mb-0 ml-2">Filters</p>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <b-table class="mt-2" responsive :fields="fields" :items="items" hover :current-page="currentPage"
                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy"
                    :show-empty="emptyTable" :sticky-header="'calc(100vh - 330px)'">
                    <template #cell(note)="data">
                        <v-app v-if="data.value && data.value !== 'null'">
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <span class="w-fit-content item-description text-left" v-on="on" v-bind="attrs">{{
                                        data.value
                                    }}</span>
                                </template>{{ formatTooltipText(data.value) }}
                            </v-tooltip>
                        </v-app>
                        <p v-else>-</p>
                    </template>
                    <template #cell(action)="data">
                        <svg @click="openDoc(data.item)" class="cursor-pointer mr-4" width="20" height="15"
                            viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.6426 6.19707C18.8726 6.48384 19 6.85642 19 7.24273C19 7.62904 18.8726 8.00161 18.6426 8.28838C17.186 10.052 13.8704 13.4854 10 13.4854C6.12957 13.4854 2.81403 10.052 1.35741 8.28838C1.12735 8.00161 1 7.62904 1 7.24273C1 6.85642 1.12735 6.48384 1.35741 6.19707C2.81403 4.4335 6.12957 1 10 1C13.8704 1 17.186 4.4335 18.6426 6.19707Z"
                                stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M10.0007 10.0154C11.533 10.0154 12.7752 8.77323 12.7752 7.24091C12.7752 5.70859 11.533 4.4664 10.0007 4.4664C8.46839 4.4664 7.2262 5.70859 7.2262 7.24091C7.2262 8.77323 8.46839 10.0154 10.0007 10.0154Z"
                                stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg @click="downloadDoc(data.item)" class="mr-4 cursor-pointer" width="20" height="20"
                            viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.53943 11.3822L10.001 14.8437L13.4625 11.3822" stroke="#FFC800" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10.0001 14.8497V5.15741" stroke="#FFC800" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path
                                d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
                                stroke="#FFC800" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg @click="showPopup(data.item.pk)" class="cursor-pointer" width="19" height="20"
                            viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 5.15704H17.6139" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path
                                d="M3.0766 5.15704H15.537V17.6175C15.537 17.9846 15.3911 18.3368 15.1315 18.5964C14.8719 18.856 14.5197 19.0019 14.1525 19.0019H4.46109C4.0939 19.0019 3.74175 18.856 3.4821 18.5964C3.22247 18.3368 3.0766 17.9846 3.0766 17.6175V5.15704Z"
                                stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M5.84607 5.15347V4.46123C5.84607 3.54325 6.21073 2.66287 6.85984 2.01377C7.50894 1.36466 8.38932 1 9.3073 1C10.2253 1 11.1057 1.36466 11.7548 2.01377C12.4039 2.66287 12.7685 3.54325 12.7685 4.46123V5.15347"
                                stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.23175 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M11.385 9.3065V14.8466" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </template>
                </b-table>
                <div class="entries-pagination px-0 px-sm-2">
                    <div class="d-flex align-items-center flex-wrap">
                        <div class="text-left show-entries mr-md-3 pl-3 pl-md-0 ml-md-0">
                            <b-form-select v-model="perPage" :options="pageOptions" />
                        </div>
                        <div class="pagination">
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                                aria-controls="my-table"></b-pagination>
                        </div>
                        <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
                    </div>
                </div>
            </div>
        </div>
        <DeleteModal v-if="showModal" @confirm="deleteItem($event)" />
        <b-modal id="modal-file-drop-document" centered hide-footer no-close-on-backdrop no-close-on-esc
            size="lg" :body-class="'px-3 pt-2 overflow-preview-auto'" :footer-class="'d-block border-0 pt-0 pb-3 m-0'"
            :header-class="'align-items-center'" scrollable>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Document
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
                <b-dropdown id="dropdown-media-poup" class="load-dropdown mt-0">
                    <template slot="button-content">
                        <b-icon class="dots" icon="three-dots"></b-icon>
                    </template>
                    <b-dropdown-item-button class="mb-2" @click="downloadFile(media)">
                        <p class="text_secondary cursor-pointer mb-0 f-14">Download</p>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button>
                        <p class="text_secondary cursor-pointer mb-0 f-14" @click="showPopup(mediaID)">Delete</p>
                    </b-dropdown-item-button>
                </b-dropdown>
            </template>
            <div class="d-block text-center">
                <b-row class="mh-470px">
                    <b-col lg="4" md="8" sm="12">
                        <b-form-group label="Driver:" class="text-left mb-3">
                            <b-form-input type="text" v-model="documentData.driver" disabled>
                            </b-form-input>
                        </b-form-group>
                        <b-form-group label="Type:" class="text-left mb-3">
                            <b-form-input type="text" v-model="documentData.type" disabled>
                            </b-form-input>
                        </b-form-group>
                        <div class="text-left mb-3">
                            <label class="state">Notes:</label>
                            <b-form-textarea id="textarea" v-model="documentData.description" disabled
                                rows="3"></b-form-textarea>
                        </div>
                    </b-col>
                    <b-col lg="8" sm="12" class="position-relative">
                        <div class="review-media-section">
                            <iframe class="w-100 h-100 border-0" v-if="mediaType === 'pdf'" :src="media"></iframe>
                            <div id="panzoom-element" ref="panzoomElement">
                                <vue-pdf-embed v-if="mediaType === 'pdf'" :source="media" />
                                <img :src="media" v-else class="w-100 h-100 img-section" />
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-center mt-2 zoom-section position-absolute"
                            v-if="mediaType !== 'pdf'">
                            <b-icon @click="zoom(1)" icon="zoom-in" style="fill:white; cursor: pointer;"></b-icon>
                            <b-icon @click="zoom(-1)" class="ml-3" icon="zoom-out"
                                style="fill:white;cursor: pointer;"></b-icon>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="text-right modal-footer border-0 p-0 mt-4">
                <b-button class="btn cancel m-0" block @click="resetPopup">Close</b-button>
            </div>
        </b-modal>
        <b-modal id="modal-filter-filedrop" centered no-close-on-backdrop :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
            :body-class="'px-3 pt-3 pb-1'" no-close-on-esc>
            <template #modal-header="{ close }">
                <h5 class="modal-title">
                    Filters
                </h5>
                <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
                    Close Modal
                </b-button>
            </template>
            <div class="body">
                <b-form-group label="Type" class="text-left select_arrow label mb-0">
                    <b-form-select class="input f-12 mb-3" :options="[
                        {
                            value: '',
                            text: '',
                        },
                        {
                            value: 'Driver',
                            text: 'Driver',
                        },
                        {
                            value: 'Vehicle',
                            text: 'Vehicle',
                        },
                        {
                            value: 'Trailer',
                            text: 'Trailer',
                        },
                        {
                            value: 'Maintenance',
                            text: 'Maintenance',
                        },
                        {
                            value: 'Insurance',
                            text: 'Insurance',
                        },
                        {
                            value: 'Inspection',
                            text: 'Inspection',
                        },
                        {
                            value: 'Accident',
                            text: 'Accident',
                        },
                        {
                            value: 'Load',
                            text: 'Load',
                        },
                        {
                            value: 'DOT Inspection',
                            text: 'DOT Inspection'
                        },
                        {
                            value: 'Other',
                            text: 'Other',
                        }
                    ]" v-model="filterFields.type" @change="getFileData()"></b-form-select>
                </b-form-group>

            </div>
            <template #modal-footer="">
                <div class="text-right modal-footer border-0 p-0 mt-3 justify-content-between mx-0">
                    <b-button class="mx-0 mb-0 h-40px w-100px m-0 btn-outline-primary" variant="primary"
                        @click="resetFilterFields(); $bvModal.hide('modal-filter-filedrop')">Reset</b-button>
                    <b-button class="mx-0 h-40px w-100px m-0 m-0" block
                        @click="$bvModal.hide('modal-filter-filedrop')">Close</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex";
import DeleteModal from "@/components/DeleteModal.vue";
import * as moment from "moment-timezone";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import Panzoom from '@panzoom/panzoom'

export default {
    name: "file-drop",
    components: { DeleteModal, VuePdfEmbed },
    data() {
        return {
            perPage: 25,
            currentPage: 1,
            panzoom: null,
            emptyTable: false,
            itemId: 0,
            showModal: false,
            sortDesc: undefined,
            sortBy: undefined,
            documentData: {
                driver: "",
                type: "",
                description: ""
            },
            mediaID: null,
            docName: "",
            media: null,
            isBusy: false,
            positionText: "",
            filterFields: {
                search: '',
                type: ''
            },
            mediaType: null,
            rows: 0,
            pageOptions: [
                { value: 10, text: "10" },
                { value: 25, text: "25" },
                { value: 50, text: "50" },
                { value: 100, text: "100" },
            ],
            items: [],
            fields: [
                {
                    label: "Driver",
                    key: "driver",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                    stickyColumn: true,
                    field: "text"
                },
                {
                    label: "Type",
                    key: "type",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
                {
                    label: "Note",
                    key: "note",
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                    field: "text"
                },
                {
                    label: "Date",
                    key: 'date',
                    sortable: true,
                    thClass: 'font-table-weight-bold',
                    field: "text"
                },

                {
                    key: "Action",
                    sortable: true,
                    thClass: 'font-table-weight-bold'
                },
            ],
        };
    },
    methods: {
        ...mapActions(["getAllFiles", "deleteFile", "setNotification", "setLoader"]),
        resetPopup() {
            this.$bvModal.hide('modal-file-drop-document');
            this.panzoom = null
            this.documentData = {
                driver: "",
                type: "",
                description: ""
            }
        },
        zoom(level) {
            if (!this.panzoom) {
                this.panzoom = Panzoom(document.getElementById('panzoom-element'), {
                    maxScale: 5
                })
            }
            level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn()
        },
        formatTooltipText(text) {
            const maxLineLength = window.screen.width <= 767 ? 50 : 80;
            let formattedText = "";
            let lineLength = 0;

            text.split(' ').forEach(word => {
                if (lineLength + word.length + 1 > maxLineLength) {
                    formattedText += "\n";
                    lineLength = 0;
                }
                formattedText += (lineLength === 0 ? "" : " ") + word;
                lineLength += word.length + 1;
            });

            return formattedText;
        },
        async getFileData() {
            try {
                this.isBusy = true
                let url = ""
                Object.keys(this.filterFields).map((key) => {
                    if (this.filterFields[key] !== '') {
                        url += `${key}=${this.filterFields[key]}&`
                    }
                })
                if (this.sortBy) {
                    url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
                }
                else {
                    url += `sort_field=date&sort_order=desc&`
                }
                url += `page=${this.currentPage}&page_size=${this.perPage}`
                const data = await this.getAllFiles(url);
                if (data.count) {
                    this.rows = data.count
                    this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} to ${this.perPage * (this.currentPage - 1) + (this.perPage > data.count ? data.count : this.perPage)} of ${data.count}`
                }
                else {
                    this.rows = 0
                    this.positionText = `0 - 0 of 0`
                }
                this.items = []
                this.allFiles.forEach((_file, id) => {
                    this.items.push({
                        ID: id + 1,
                        pk: _file.id,
                        date: moment(_file.date).format("MMM DD, YYYY"),
                        driver: _file.driver.name,
                        type: _file.type,
                        note: _file.note ? _file.note : "-",
                        link: _file.document,
                        name: `${_file.driver.name}-${_file.note ? _file.note : "-"}`
                    });
                });
                if (this.items.length === 0) this.emptyTable = true
                else this.emptyTable = false
                this.isBusy = false
            }
            catch (e) {
                this.isBusy = false
                console.log(e)
            }
        },
        async resetFilterFields() {
            this.filterFields = {
                search: '',
                type: ''
            }
            await this.getFileData();
        },
        async downloadDoc(data) {
            this.docName = data.name
            this.downloadFile(data.link)
        },
        async openDoc(data) {
            this.docName = data.name
            this.media = data.link
            this.mediaID = data.pk
            this.documentData = {
                driver: data.driver,
                type: data.type,
                description: data.note
            }
            if (data.link.includes('.pdf')) this.mediaType = 'pdf'
            else this.mediaType = 'image'
            this.$bvModal.show("modal-file-drop-document");
        },
        downloadFile(uri) {
            this.setLoader(true)
            fetch(uri)
                .then(response => response.blob())
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `${this.docName}.${uri.split('.').pop().split(/[#?]/)[0]}`
                    link.click();
                    link.remove()
                    this.setLoader(false)
                })
                .catch(this.setLoader(false));
        },
        deleteItem(item) {
            if (item) {
                this.deleteRecord(this.itemId);
            }
            this.showModal = false;
        },
        showPopup(id) {
            this.showModal = true;
            this.itemId = id;
        },
        async deleteRecord(id) {
            try {
                this.setLoader(true)
                await this.deleteFile(`${id}/?company_id=${this.userCompany}`);
                const item = this.items.filter((record) => record.pk === id)[0];
                const index = this.items.indexOf(item);
                this.items.splice(index, 1);
                await this.setNotification({
                    msg: "File successfully deleted!!",
                    type: "success",
                    color: "green",
                });
                this.$bvModal.hide("modal-file-drop-document");
                this.setLoader(false)
                this.$emit('refreshService')
                await this.getFileData();
            } catch (error) {
                this.setLoader(false)
                await this.setNotification({
                    msg: error,
                    type: "error",
                    color: "red",
                });
            }
        },

    },
    watch: {
        perPage: {
            async handler() {
                await this.getFileData();
            },
            immediate: true,
        },
        currentPage: {
            async handler() {
                await this.getFileData();
            },
        },
        sortBy: {
            async handler() {
                await this.getFileData();
            },
        },
        sortDesc: {
            async handler() {
                await this.getFileData();
            },
        },
        filterFields: {
            handler() {
                this.currentPage = 1
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters(["allFiles", "userCompany"]),
        activeFiltersCount() {
            return Object.keys(this.filterFields).reduce((count, key) => {
                if (
                    this.filterFields[key] !== '' &&
                    this.filterFields[key] !== null
                ) {
                    return count + 1;
                }
                return count;
            }, 0);
        },
    },
};
</script>
  
<style>
.filedrop fieldset {
    margin-right: 20px;
}

.filedrop .b-form-btn-label-control.form-control>.form-control {
    font-size: 12px !important;
}

.vue-pdf-embed__page canvas {
    width: 100% !important;
    height: 100% !important;
}
</style>
  
  