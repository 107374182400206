var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-bulk-dot-mode-update","no-close-on-esc":"","centered":"","no-close-on-backdrop":"","hide-footer":"","body-class":'pt-3'},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h5',{staticClass:"modal-title"},[_vm._v(" Dot Mode ")]),_c('b-button',{staticClass:"d-none",attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return close()}}},[_vm._v(" Close Modal ")])]}}])},[_c('div',{staticClass:"text-left"},[_c('b-form-group',{attrs:{"label":"Doc Type"}},[_c('b-form-select',{staticClass:"input",attrs:{"options":[
                    {
                        value: null,
                        text: '',
                    },
                    {
                        value: true,
                        text: 'Yes'
                    },
                    {
                        value: false,
                        text: 'No'
                    } ]},model:{value:(_vm.dot_mode),callback:function ($$v) {_vm.dot_mode=$$v},expression:"dot_mode"}}),(_vm.errors && _vm.errors.dot_mode)?_c('p',{staticClass:"field-error"},[_vm._v(" "+_vm._s(_vm.errors.dot_mode[0])+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"text-right modal-footer border-0 p-0 mt-3"},[_c('b-button',{staticClass:"mx-0 mr-3 h-40px w-100px",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.resetPopup()}}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"mx-0 mb-0 h-40px w-100px mt-0",attrs:{"variant":"primary"},on:{"click":_vm.bulkUpdateDocs}},[_vm._v("Save")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }