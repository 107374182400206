import { getReq, postReq, deleteReq, patchReq } from "../../../services/api";

const state = {
  user: null,
  userCompany: null,
  userRole: localStorage.getItem("role") ? localStorage.getItem("role") : null,
  userCompanies: [],
  companyPinCode: null,
  appVersion: null,
  userPreferences: null,
  userNotifications: null
};

const getters = {
  user: (state) => state.user,
  appVersion: (state) => state.appVersion,
  userCompanies: (state) => state.userCompanies,
  userCompany: (state) => state.userCompany,
  userRole: (state) => state.userRole,
  companyPinCode: (state) => state.companyPinCode,
  userPreferences: (state) => state.userPreferences,
  userNotifications: (state) => state.userNotifications
};

const actions = {
  async registerUser(context, user) {
    try {
      const response = await postReq("registration/", user);
      return response;
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status == 500) {
        throw "Internal server error";
      }
      const errors = e.response.data;
      const error = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          error.push(errors[key]);
        }
      }

      throw error[0][0];
    }
  },

  async checkUserExists(context, email) {
    try {
      const response = await postReq("app/check-email-exists/", email);
      return response;
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status == 500) {
        throw "Internal server error";
      }
      const errors = e.response.data;
      const error = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          error.push(errors[key]);
        }
      }
      throw error[0][0];
    }
  },
  async checkAdminUserExists(_, email) {
    try {
      const response = await postReq("app/check-admin-email-exists/", email);
      return response;
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status == 500) {
        throw "Internal server error";
      }
      throw e.response;
    }
  },
  async verifyOtp(_, body) {
    try {
      const response = await postReq("app/verify-otp/", body);
      return response;
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status == 500) {
        throw "Internal server error";
      }
      throw e.response;
    }
  },
  async createCompanyOnly(context, { data, key }) {
    try {
      const {company_id} = await postReq("app/create-company/", data, key);
      return company_id;
    } catch (e) {
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw e.response.data;
    }
  },

  getUserCompany({ commit }) {
    try {
      commit("setUserCompany");
    }
    catch (e) {
      console.log(e)
    }
  },
  async getUserCompanies({ commit }) {
    try {
      const response = await getReq("app/user-detail/");
      if (response.companies.length === 1) {
        localStorage.setItem("userSelectedCompany", response.companies[0].id)
        commit("setUserCompany");

      }
      commit("setUserCompanies", response.companies.sort((a, b) => a.name.localeCompare(b.name)));
    }
    catch (e) {
      console.log(e)
    }
  },
  async loginUser({ commit }, { payload, registerCompany }) {
    try {
      const response = await postReq("login/", payload);
      if (response.key && !registerCompany) {
        localStorage.setItem("token", response.key);
        localStorage.setItem("companies", JSON.stringify(response.company_ids))
        localStorage.setItem("role", response.role);
        commit("setUserRole", response.role);
      }
      return response;
    } catch (e) {
      const errors = e?.response?.data
      const error = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          error.push(errors[key]);
        }
      }

      throw error[0][0];
    }
  },
  async resetPassword(_, email) {
    try {
      const form = new FormData();
      form.append("email", email);
      const response = await postReq("password/reset/", form);
      return response;
    } catch (e) {
      const errors = e.response.data;
      const error = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          error.push(errors[key]);
        }
      }
      throw error[0][0];
    }
  },
  async resetPasswordConfirm(_, user) {
    try {
      const form = new FormData();
      form.append("token", user.token),
        form.append("uid", user.uid),
        form.append("new_password1", user.password1),
        form.append("new_password2", user.password2);
      const response = await postReq("password/reset/confirm/", form);
      return response;
    } catch (e) {
      const errors = e.response.data;
      const error = [];
      for (const key in errors) {
        if (Object.hasOwnProperty.call(errors, key)) {
          error.push(errors[key]);
        }
      }
      throw error[0][0];
    }
  },
  async changePasswordConfirm({ commit }, user) {
    try {
      const form = new FormData();
      form.append("token", user.token),
        form.append("uidb64", user.uid),
        form.append("new_password1", user.password1),
        form.append("new_password2", user.password2);
      const response = await postReq("app/set-password-confirm/", form);
      localStorage.setItem("token", response.key);
      localStorage.setItem("companies", JSON.stringify(response.company_ids))
      localStorage.setItem("role", response.role);
      commit("setUserRole", response.role);
    } catch (error) {

      throw error.response.data;
    }
  },
  async logoutUser({ commit }) {
    localStorage.clear();
    commit("setUserCompany");
    commit("setUser", null);
  },
  async getUser({ commit }) {
    try {
      const user = await getReq("app/users/");

      commit("setUser", user);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getAppVersion({ commit }) {
    try {
      const { version } = await getReq("app/version/");
      commit("setAppVersion", version);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  setUserData({ commit }, user) {
    commit("setUser", user);
  },
  async getUserPreferences({ commit }) {
    try {
      const data = await getReq("app/user-preferences/");
      commit("setUserPreferences", data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getUserNotifications({ commit }) {
    try {
      const { data } = await getReq("app/notifications/");
      commit("setUserNotifications", data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async clearUserNotification({ commit }, id) {
    try {
      const { data } = await patchReq("app/notifications", id, {});
      commit("setUserNotifications", data);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response.data;
    }
  },
  async clearUserNotifications({ commit }) {
    try {
      const { data } = await postReq("app/notifications/", {});
      commit("setUserNotifications", data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async updateUserNotifications({ commit }, notifications) {
    commit("setUserNotifications", notifications);
  },
  async updateUserPreferences({ commit }, payload) {
    try {
      const data = await postReq("app/user-preferences/", payload);
      commit("setUserPreferences", data);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response.data;
    }
  },
  async deleteUserPreferences({ commit }, id) {
    try {
      const data = await deleteReq("app/user-preferences", id);
      commit("setUserPreferences", data);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      throw error.response.data;
    }
  },
  setAppVersion({ commit }, version) {
    commit("setAppVersion", version);
  },
  async setCompanyPinCode({ commit }, pinCode) {
    commit("setCompanyPinCode", pinCode)
  },
  async getUserByID({ commit }) {
    try {
      let user
      let userData = await getReq(`app/users/`);
      if (userData.id && localStorage.getItem("userSelectedCompany")) {
        user = await getReq(`user/${userData.id}`);
        commit("setUser", { ...user, version: userData.version });
      }
      else {
        commit("setUser", userData);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async contactUs(_, data) {
    try {
      await postReq("contact_us", data);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
};

const mutations = {
  setUser: (state, user) => (state.user = user),
  setAppVersion: (state, appVersion) => (state.appVersion = appVersion),
  setUserPreferences: (state, userPreferences) => (state.userPreferences = userPreferences),
  setUserRole: (state, role) => (state.userRole = role),
  setUserCompany: (state) => (state.userCompany = localStorage.getItem("userSelectedCompany") ? localStorage.getItem("userSelectedCompany") : null),
  setUserCompanies: (state, companies) => (state.userCompanies = companies),
  setCompanyPinCode: (state, companyPinCode) => (state.companyPinCode = companyPinCode),
  setUserNotifications: (state, userNotifications) => (state.userNotifications = userNotifications)
};

export default {
  state,
  getters,
  actions,
  mutations,
};
