<template>
  <b-container fluid class="mt-2 px-1 px-sm-3 pb-3 equipment-list-page">
    <b-row>
      <b-col cols="">
        <b-row>
          <b-col cols="">
            <div class="expiry-data d-flex justify-content-between align-items-center flex-wrap mb-3">
              <h2 class="text-left mb-0">{{ $route.name }}s</h2>
              <div class="d-flex d-sm-none justify-content-between justify-content-sm-center flex-wrap">
                <b-button @click="allowAddEquipment = true" v-if="allowCreateEquipment"
                  class="export equipment d-flex justify-content-center align-items-center mx-1 my-1 w-fit-content"
                  variant="primary">
                  <b-icon icon="plus" aria-hidden="true"></b-icon>
                  <p class="mb-0 ml-2 text-white">Add {{ $route.name }}</p>
                </b-button>
                <b-dropdown id="dropdown-divider" text="text" class="load-dropdown-2"
                  v-if="allowImportEquipments || allowExportEquipments || (allowUpdateEquipment && selectedRows.length)">
                  <template slot="button-content">
                    <b-icon class="dots" icon="three-dots"></b-icon>
                  </template>
                  <b-dropdown-item-button v-if="allowExportEquipments" @click="downloadExcel"
                    :class="allowImportEquipments || allowUpdateEquipment && selectedRows.length ? 'mb-2' : ''">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Export CSV</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button v-if="allowImportEquipments"
                    :class="allowUpdateEquipment && selectedRows.length ? 'mb-2' : 'mb-0'"
                    @click="$bvModal.show('modal-bulk-upload')">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Import CSV</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button class="mb-2" v-if="allowUpdateEquipment && selectedRows.length"
                    @click="showDeactivateModal = true">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Deactivate</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button class="mb-2" v-if="(allowUpdateEquipment && selectedRows.length)"
                    @click="$bvModal.show('modal-add-bulk-tags')">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Add Tags</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button v-if="allowUpdateEquipment && selectedRows.length" @click="addDocument">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Add Document</p>
                  </b-dropdown-item-button>
                </b-dropdown>
              </div>
              <div class="d-none d-sm-flex justify-content-between justify-content-sm-center flex-wrap">
                <b-button @click="allowAddEquipment = true" v-if="allowCreateEquipment"
                  class="export equipment d-flex justify-content-center align-items-center mx-1 my-1 w-fit-content"
                  variant="primary">
                  <b-icon icon="plus" aria-hidden="true"></b-icon>
                  <p class="mb-0 ml-2 text-white">Add {{ $route.name }}</p>
                </b-button>
                <b-dropdown id="dropdown-divider" text="text" class="load-dropdown-2"
                  v-if="allowImportEquipments || allowExportEquipments || (allowUpdateEquipment && selectedRows.length)">
                  <template slot="button-content">
                    <b-icon class="dots" icon="three-dots"></b-icon>
                  </template>
                  <b-dropdown-item-button v-if="allowExportEquipments" @click="downloadExcel"
                    :class="allowImportEquipments || allowUpdateEquipment && selectedRows.length ? 'mb-2' : ''">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Export CSV</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button v-if="allowImportEquipments"
                    :class="allowUpdateEquipment && selectedRows.length ? 'mb-2' : 'mb-0'"
                    @click="$bvModal.show('modal-bulk-upload')">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Import CSV</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button class="mb-2" v-if="allowUpdateEquipment && selectedRows.length"
                    @click="showDeactivateModal = true">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Deactivate</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button class="mb-2" v-if="(allowUpdateEquipment && selectedRows.length)"
                    @click="$bvModal.show('modal-add-bulk-tags')">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Add Tags</p>
                  </b-dropdown-item-button>
                  <b-dropdown-item-button v-if="allowUpdateEquipment && selectedRows.length" @click="addDocument">
                    <p class="text_secondary cursor-pointer mb-0 f-14">Add Document</p>
                  </b-dropdown-item-button>
                </b-dropdown>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="data-table team-data-table table-custom-border">
              <div class="bg-white rounded">
                <v-tabs class="team-tabs mb-0" v-if="isTableMounted">
                  <v-tab @click="setEquipmentType('Active,Assigned')">Active <span class="f-12 ml-1"> ({{
                    activeCount ?
                    activeCount : 0 }})</span></v-tab>
                  <v-tab @click="setEquipmentType('Inactive')">Inactive <span class="f-12 ml-1">({{
                    inactiveCount ?
                    inactiveCount : 0 }})</span></v-tab>
                </v-tabs>
              </div>
              <div class="bg-white pt-3 pt-sm-4 rounded">
                <div class="table_row">
                  <div class="equipment-border filter-box">
                    <div class="d-flex mb-4 px-3 px-sm-4">
                      <b-form-group class="text-left label font-weight-normal search-field mb-0">
                        <b-input-group class="username-input-field">
                          <b-form-input type="text" placeholder="Search" v-model="filterFields.search_field"
                            class="unit f-12" @keyup.enter="getEquipmentData()"></b-form-input>
                          <b-input-group-append>
                            <b-button variant="primary" @click="getEquipmentData()">
                              <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M6.14286 11.7857C8.98318 11.7857 11.2857 9.48318 11.2857 6.64286C11.2857 3.80254 8.98318 1.5 6.14286 1.5C3.30254 1.5 1 3.80254 1 6.64286C1 9.48318 3.30254 11.7857 6.14286 11.7857Z"
                                  stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M10 10.5L13 13.5" stroke="white" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" />
                              </svg>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>

                      <div class="ml-auto d-none d-sm-flex align-items-center flex-wrap">
                        <div class="position-relative">
                          <div class="position-absolute notification-badge" v-if="activeFiltersCount">
                            {{ activeFiltersCount }}
                          </div>
                          <button class="btn btn-filter-section" @click="$bvModal.show('modal-filter')">
                            <div class="d-flex align-items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="lucide lucide-sliders-horizontal w-4 h-4"
                                data-id="element-964">
                                <line x1="21" x2="14" y1="4" y2="4"></line>
                                <line x1="10" x2="3" y1="4" y2="4"></line>
                                <line x1="21" x2="12" y1="12" y2="12"></line>
                                <line x1="8" x2="3" y1="12" y2="12"></line>
                                <line x1="21" x2="16" y1="20" y2="20"></line>
                                <line x1="12" x2="3" y1="20" y2="20"></line>
                                <line x1="14" x2="14" y1="2" y2="6"></line>
                                <line x1="8" x2="8" y1="10" y2="14"></line>
                                <line x1="16" x2="16" y1="18" y2="22"></line>
                              </svg>
                              <p class="mb-0 ml-2">Filters</p>
                            </div>
                          </button>
                        </div>
                        <div class="table-columns-section ml-2 mt-0" @click="showColumns = true">
                          <button class="btn btn-table-columns-section">
                            <div class="d-flex align-items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="lucide lucide-columns2 w-4 h-4" data-id="element-961">
                                <rect width="18" height="18" x="3" y="3" rx="2"></rect>
                                <path d="M12 3v18"></path>
                              </svg>
                              <p class="mb-0 ml-2">Columns</p>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <b-table ref="tableRef" class="mt-2" responsive :fields="visibleFields" :items="items" hover
                    :sticky-header="'calc(100vh - 330px)'" :current-page="currentPage" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :no-local-sorting="true" :busy.sync="isBusy" :show-empty="emptyTable"
                    selectable select-mode="multi" no-select-on-click @row-selected="onRowSelected">
                    <template #head(selected)="">
                      <b-form-checkbox class="pl-0 w-fit-content" @change="selectRows($event)" v-model="allRowsSelected">
                      </b-form-checkbox>
                    </template>
                    <template #cell(selected)="data">
                      <b-form-checkbox class="pl-0 w-fit-content" @change="checked(data.index, data.rowSelected)"
                        v-model="data.rowSelected">
                      </b-form-checkbox>
                    </template>
                    <template #cell(unit_no)="data" v-if="allowUpdateEquipment || allowViewEquipment">
                      <p class="text_primary mb-0 cursor-pointer" @click="setEquipmentData(data.item.id)">
                        {{ data.value }}
                      </p>
                    </template>
                    <template #cell(notes)="data">
                      <div v-if="data.value">
                        <span v-html="`${data.value}`" class="notes-item cursor-pointer"
                          @click="showNotesSection(data.item.id)"></span>
                      </div>
                      <span v-else>-</span>
                    </template>
                    <template #cell(tags)="data">
                      <div v-if="data.value.length" class="d-flex align-items-center">
                        <div class="table-tags mr-2">{{ data.value[0] }}</div>
                        <v-app>
                          <v-tooltip bottom v-if="data.value.length > 1">
                            <template #activator="{ on, attrs }">
                              <div class="table-tags" v-on="on" v-bind="attrs">+{{ data.value.length - 1 }}</div>
                            </template> <span v-html="data.value.slice(1).join('<br>')"></span>
                          </v-tooltip>
                        </v-app>
                      </div>
                      <p class="mb-0" v-else>-</p>
                    </template>
                    <template #cell(num_docs)="data">
                      <span :style='data.item.color' class="docs-circle">{{ data.value }}</span>
                    </template>
                    <template #cell(status)="data">
                      <b-button class="btn export pe-none d-flex justify-content-center align-items-center"
                        :variant="data.item.status === 'Active' ? 'primary-status' : data.item.status === 'Assigned' ? 'success-status' : 'secondary-status'">
                        <p class="mb-0">{{ data.value }}</p>
                      </b-button>
                    </template>
                  </b-table>
                  <div class="entries-pagination px-0 px-sm-2">
                    <div class="d-flex align-items-center flex-wrap">
                      <div class="text-left show-entries mr-md-3 pl-3 pl-md-0 ml-md-0">
                        <b-form-select v-model="perPage" :options="pageOptions" />
                      </div>
                      <p class="my-0 pr-3 pr-md-0 mr-md-1 mt-0 f-14">{{ positionText }}</p>
                      <div class="pagination">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                          aria-controls="my-table"></b-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Import CSV Modal  -->
    <b-modal id="modal-bulk-upload" centered hide-footer @shown="resetField" no-close-on-backdrop :body-class="'p-4'"
      no-close-on-esc>
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Import CSV
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <div class="d-block text-center">
        <b-row class="import-excel-modal mx-0">
          <b-col cols="12" class="px-0">
            <div class="upload-btn-wrapper mt-0">
              <b-img :src="require('@/assets/drop.svg')"></b-img>
              <button class="foo" v-if="!fileName">
                Drop your document here, or browse
              </button>
              <button v-else class="foo">{{ fileName }}</button>
              <input ref="docFile" type="file" name="docFile" @change="handleFileUpload" accept=".csv" />
              <p v-if="errors && errors.document" class="field-error">
                {{ errors.document }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" class="text-left cursor-pointer mt-2 pt-1 pl-1">
            <a class="download-sample-file" href="/files/sample-vehicles.csv" download="sample-vehicles.csv"
              v-if="$route.name === 'Vehicle'">
              <b-img :src="require('@/assets/sample-file.svg')"></b-img>
              Download Sample File
            </a>
            <a class="download-sample-file" href="/files/sample-trailers.csv" download="sample-trailers.csv" v-else>
              <b-img :src="require('@/assets/sample-file.svg')"></b-img>
              Download Sample File
            </a>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0">
        <b-button class="mx-0 mr-3 h-40px w-100px" @click="$bvModal.hide('modal-bulk-upload')"
          variant="secondary">Cancel</b-button>
        <b-button class="mx-0 mb-0 h-40px w-100px" variant="primary" @click="bulkUploadEquipment">Save</b-button>
      </div>
    </b-modal>
    <AddEquipment :allowAddEquipment="allowAddEquipment" :states="states" :options="options"
      @close="allowAddEquipment = false" @getNewData="getEquipmentNewData($event)" />
    <TableColumns :showColumns="showColumns" :fields="fields" @getUpdatedFieldsList="getUpdatedFieldsList($event)"
      @updateFields="updateFields($event)" @resetTableFields="resetTableFields()" @resetData="showColumns = false" />
    <EditEquipment :equipmentID="equipmentID" :states="states" :options="options" @updateEquipment="resetEquipmentList"
      @updateData="getEquipmentData()" @resetEquipment="equipmentID = ''; setNotesSection = false"
      :setNotesSection="setNotesSection" />
    <AddTags :ids="selectedRows.map((data) => data.id)" v-if="selectedRows.length"
      @close="$refs.tableRef.clearSelected(); selectedRows = []; allRowsSelected = false" @updateTags="getEquipmentData()"
      :type="'equipment'" />
    <DeleteModal v-if="showDeactivateModal" @confirm="changeEquipmentStatusItem($event)"
      :message="'Do you want to deactivate the selected equipments?'" :removeMessage="'Deactivate'" />
    <b-modal id="modal-bulk-equipment-docs" centered hide-footer @shown="resetBulkDocFields" no-close-on-backdrop
      size="lg" :body-class="'px-3 pt-2 overflow-preview-auto'" no-close-on-esc
      :footer-class="'d-block border-0 pt-0 pb-3 m-0'" :header-class="'align-items-center'" scrollable>
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Document
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
        <b-dropdown id="dropdown-preview" text="text" class="load-dropdown mt-0" v-if="docFileName">
          <template slot="button-content">
            <b-icon class="dots" icon="three-dots"></b-icon>
          </template>
          <b-dropdown-item-button @click="removeFile()">
            <p class="text_secondary cursor-pointer mb-0 f-14">Replace Doc</p>
          </b-dropdown-item-button>
        </b-dropdown>
      </template>
      <div class="d-block text-center">
        <b-row>
          <b-col lg="4" sm="12">
            <div class="text-left mb-3">
              <label for="modal-datepicker" class="state">Date:</label>
              <date-picker v-model="documentData.date" :formatter="momentFormat" :range-separator="' - '"
                @clear="documentData.date = ''" placeholder="Select Date" class="date-picker-custom"
                :teleport="'body'"></date-picker>
              <p v-if="docErrors && docErrors.date" class="field-error">
                {{ docErrors.date[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label for="modal-expiry-date" class="state">Expiry Date:</label>
              <date-picker v-model="documentData.expiry_date" :formatter="momentFormat" :range-separator="' - '"
                @clear="documentData.expiry_date = ''" placeholder="Select Date" class="date-picker-custom"
                :teleport="'body'"></date-picker>
              <p v-if="docErrors && docErrors.expiry_date" class="field-error">
                {{ docErrors.expiry_date[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label class="state">Type:</label>
              <b-form-select class="f-12" :options="getDotOptions" v-model="documentData.doc_type"
                @change="setDocDescription($event)">
              </b-form-select>
              <p v-if="docErrors && docErrors.doc_type" class="field-error">
                {{ docErrors.doc_type[0] }}
              </p>
            </div>
            <div class="text-left mb-3">
              <label class="state">Description:</label>
              <b-form-textarea id="textarea" placeholder="Enter Description" rows="3"
                v-model="documentData.description"></b-form-textarea>
              <p v-if="docErrors && errors.docErrors" class="field-error">
                {{ docErrors.description[0] }}
              </p>
            </div>
          </b-col>
          <b-col lg="8" sm="12">
            <div class="upload-btn-wrapper" v-if="!docFileName">
              <b-img :src="require('@/assets/drop.svg')"></b-img>
              <button v-if="!docFileName" class="foo">
                Drop your document here, or browse
              </button>
              <button v-else class="foo">{{ docFileName }}</button>

              <input ref="bulkDocFile" type="file" name="bulkDocFile" v-on:change="handleBulkFileUpload"
                accept=".pdf, .png, .jpg, .jpeg" />
              <p v-if="errors && errors.document" class="field-error">
                {{ errors.document[0] }}
              </p>
            </div>
            <div v-else>
              <div class="review-media-section" :class="mediaType === 'pdf' ? 'overflow-hidden' : ''">
                <iframe class="w-100 h-100 border-0" v-if="mediaType === 'pdf'" :src="media"></iframe>
                <div v-else>
                  <div id="panzoom-element" ref="panzoomElement">
                    <img :src="media" class="w-100 h-100 img-section" />
                  </div>
                  <div class="d-flex align-items-center justify-content-center zoom-section">
                    <b-icon @click="zoom(1)" icon="zoom-in" style="fill:white; cursor: pointer;"></b-icon>
                    <b-icon @click="zoom(-1)" class="ml-3" icon="zoom-out" style="fill:white;cursor: pointer;"></b-icon>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="text-right modal-footer border-0 p-0 mt-4">
        <b-button class="mx-0 h-40px w-100px m-0 mr-3" @click="$bvModal.hide('modal-bulk-equipment-docs'); panzoom = null"
          variant="secondary">Cancel</b-button>
        <b-button class="mx-0 mb-0 h-40px w-100px m-0" variant="primary" @click="sendDocumentData">Save</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-filter" centered no-close-on-backdrop :footer-class="'d-block px-3 pt-0 pb-3 m-0'"
      :body-class="'px-3 pt-3 pb-1'" no-close-on-esc>
      <template #modal-header="{ close }">
        <h5 class="modal-title">
          Filters
        </h5>
        <b-button size="sm" class="d-none" variant="outline-danger" @click="close()">
          Close Modal
        </b-button>
      </template>
      <div class="body">
        <b-form-group label="Docs" v-if="checkTableField('num_docs')" class="text-left select_arrow label mb-3">
          <b-form-select type="text" class="input f-12" :options="[
            {
              value: '',
              text: '',
            },
            {
              value: 'True',
              text: 'Yes',
            },
            {
              value: 'False',
              text: 'No',
            },
          ]" v-model="filterFields.doc" @change="getEquipmentData()"></b-form-select>
        </b-form-group>
        <b-form-group label="Tags" class="text-left w-equipment-box mb-3">
          <v-select multiple class="f-12 bg-grey" :options="allTags" :reduce="(option) => option.id"
            v-model="filterFields.tags" @input="getEquipmentData()" />
        </b-form-group>

        <b-form-group label="Status" class="text-left select_arrow label w-equipment-box mb-3"
          v-if="equipmentType === 'Active,Assigned'">
          <b-form-select type="text" class="input f-12" :options="statusOptions" v-model="filterFields.status"
            @change="getEquipmentData()"></b-form-select>
        </b-form-group>

      </div>
      <template #modal-footer="">
        <div class="text-right modal-footer border-0 p-0 mt-0 justify-content-between mx-0">
          <b-button class="mx-0 mb-0 h-40px w-100px m-0 btn-outline-primary" variant="primary"
            @click="resetFilterFields(); $bvModal.hide('modal-filter')">Reset</b-button>
          <b-button class="mx-0 h-40px w-100px m-0 m-0" block @click="$bvModal.hide('modal-filter')">Close</b-button>
        </div>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "../../router";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as moment from "moment-timezone";
import EditEquipment from "@/components/Equipment/EditEquipment.vue";
import AddEquipment from "@/components/Equipment/AddEquipment.vue";
import AddTags from "@/components/Shared/AddTags.vue";
import DeleteModal from "@/components/DeleteModal.vue";
import TableColumns from "@/components/Shared/TableColumns.vue";
import Panzoom from '@panzoom/panzoom'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  name: "equipment-list",
  components: {
    vSelect,
    EditEquipment,
    AddEquipment,
    DeleteModal,
    AddTags,
    DatePicker,
    TableColumns
  },
  data() {
    return {
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('MMM D, YYYY') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'MMM D, YYYY').toDate() : null
        }
      },
      showColumns: false,
      states: [],
      equipmentType: 'Active,Assigned',
      countries: [],
      allRowsSelected: false,
      document: null,
      activeCount: 0,
      inactiveCount: 0,
      allowAddEquipment: false,
      rows: 1,
      emptyTable: false,
      positionText: "",
      equipmentID: null,
      sortBy: undefined,
      sortDesc: undefined,
      showDeactivateModal: false,
      isBusy: false,
      isTableMounted: false,
      fileName: null,
      docFileName: null,
      perPage: 10,
      currentPage: 1,
      widthBox: 100,
      items: [],
      errors: {
        document: null,
      },
      filterFields: {
        search_field: '',
        doc: '',
        tags: [],
        status: 'Active,Assigned'
      },
      equipmentDispatchGroups: [],
      allTags: [],
      pageOptions: [
        {
          value: 10,
          text: "10",
        },
        {
          value: 25,
          text: "25",
        },
        {
          value: 50,
          text: "50",
        },
        {
          value: 100,
          text: "100",
        },
      ],
      newEquipment: {
        unit_no: null,
        subtype: null,
        year: null,
        make: null,
        vin_no: null,
        plate_no: null,
        state: {
          id: 54,
          country: 1,
        },
      },
      selectedRows: [],
      defaultFields: [
        {
          key: "selected",
          label: "",
          sortable: false,
          heading: "Select",
          visible: true,
          disabled: true,
          thStyle: { minWidth: "20px", width: "20px" },
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "unit_no",
          label: "Unit #",
          sortable: true,
          visible: true,
          disabled: true,
          field: "text",
          stickyColumn: true,
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Type",
          key: "type",
          sortable: true,
          visible: true,
          disabled: true,
          field: "Dropdown",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "subtype",
          label: "Subtype",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "vin_no",
          label: "Vin #",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "serial_number",
          label: "Serial #",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "year",
          label: "Year",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "make",
          label: "Make",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "model",
          label: "Model",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "plate_no",
          label: "Plate",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "plate_state",
          label: "State",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          label: "Tags",
          key: "tags",
          sortable: true,
          tdClass: "align-middle",
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        // {
        //   key: "ownership",
        //   label: "Ownership",
        //   sortable: true,
        //   visible: false,
        //   disabled: false,
        //   field: "text",
        //   thClass: 'font-table-weight-bold'
        // },
        {
          key: "value",
          label: "Value",
          sortable: true,
          visible: false,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "driver",
          label: "Driver",
          sortable: true,
          visible: true,
          disabled: false,
          field: "text",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "num_docs",
          label: "Docs",
          sortable: true,
          visible: true,
          disabled: false,
          field: "dropdown",
          thClass: 'font-table-weight-bold'
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          visible: true,
          disabled: true,
          field: "dropdown",
          thClass: 'font-table-weight-bold'
          // Variant applies to the whole column, including the header and footer
        },
      ],
      fields: [],
      selected: null,
      options: [
        {
          value: "",
          text: "",
        },
        {
          value: "Vehicle",
          text: "Vehicle",
        },
        {
          value: "Trailer",
          text: "Trailer",
        },
      ],
      typeOptions: [
        {
          value: "",
          text: "",
        },
        {
          value: "Vehicle",
          text: "Vehicle",
        },
        {
          value: "Trailer",
          text: "Trailer",
        },
      ],
      statusOptions: [
        {
          value: "Active,Assigned",
          text: "",
        },
        {
          value: "Active",
          text: "Active",
        },
        {
          value: "Assigned",
          text: "Assigned",
        }
      ],
      media: null,
      mediaType: null,
      panzoom: null,
      mediaID: null,
      documentData: {
        object_id: null,
        date: null,
        type: null,
        expiry_date: "",
        document: null,
        description: null,
        driver: true,
        public: false,
        doc_type: ""
      },
      docErrors: {
        date: null,
        type: null,
        document: null,
        description: null,
      },

      setNotesSection: false
    };
  },
  computed: {
    ...mapGetters([
      "allEquipments",
      "dispatchGroups",
      "licenseInventory",
      "company",
      "allCountries",
      "tags",
      "userCompany",
      "rolePermissions",
      "userPreferences",
      "user",
      "docTypeItems"
    ]),
    allowViewEquipment() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][0].allow
    },
    allowCreateEquipment() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][2].allow
    },
    allowUpdateEquipment() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][1].allow
    },
    allowDeleteEquipment() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][3].allow
    },
    allowImportEquipments() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][4].allow
    },
    allowExportEquipments() {
      return this.rolePermissions && this.rolePermissions['Equipment'] && this.rolePermissions['Equipment'][5].allow
    },
    getDotOptions() {
      return [...[{
        value: "",
        text: "",
      }], ...this.docTypeItems?.filter((item) => item.model === this.$route.name || item.model === 'All').map((item) => {
        return {
          value: item.id,
          text: item.type,
        }
      })]
    },
    activeFiltersCount() {
      return Object.keys(this.filterFields).reduce((count, key) => {
        if (key === 'tags') {
          return count + (this.filterFields.tags.length > 0 ? 1 : 0);
        } else if (
          this.filterFields[key] !== '' &&
          this.filterFields[key] !== null && (key !== 'status' ||
            (key === 'status' && (this.filterFields['status'] !== 'Active,Assigned' && this.filterFields['status'] !== 'Inactive'))
          )
        ) {
          return count + 1;
        }
        return count;
      }, 0);
    },
    visibleFields() {
      if (!this.allowUpdateEquipment) return this.fields.filter(field => field.visible && field.key !== 'selected')
      return this.fields.filter(field => field.visible)
    },
    subTypeOptions() {
      let options = [];
      if (this.$route.name === "Vehicle") {
        options = [
          {
            value: null,
            text: "",
          },
          {
            value: "Truck",
            text: "Truck",
          },
          {
            value: "Semi-Truck",
            text: "Semi-Truck",
          },
          {
            value: "Day-Cab",
            text: "Day-Cab",
          },
          {
            value: "Straight-Truck",
            text: "Straight-Truck",
          },
          {
            value: "Box-Truck",
            text: "Box-Truck",
          },
          {
            value: "Flatbed-Truck",
            text: "Flatbed-Truck",
          },
          {
            value: "Dump-Truck",
            text: "Dump-Truck",
          },
          {
            value: "Refrigerated-Truck",
            text: "Refrigerated-Truck",
          },
          {
            value: "Tow-Truck",
            text: "Tow-Truck",
          },
          {
            value: "Cargo-Van",
            text: "Cargo-Van",
          },
          {
            value: "Van",
            text: "Van",
          },
          {
            value: "Auto",
            text: "Auto",
          },
          {
            value: "SUV",
            text: "SUV",
          },
        ];
      } else if (this.$route.name === "Trailer") {
        options = [
          {
            value: null,
            text: "",
          },
          {
            value: "Dry-Van",
            text: "Dry-Van",
          },
          {
            value: "Dry-Van-Air-Ride",
            text: "Dry-Van-Air-Ride",
          },
          {
            value: "Reefer",
            text: "Reefer",
          },
          {
            value: "Flatbed",
            text: "Flatbed",
          },
          {
            value: "Stepdeck",
            text: "Stepdeck",
          },
          {
            value: "Conestoga",
            text: "Conestoga",
          },
          {
            value: "Conestoga-Drop",
            text: "Conestoga-Drop",
          },
          {
            value: "Double-Drop",
            text: "Double-Drop",
          },
          {
            value: "Gooseneck",
            text: "Gooseneck",
          },
          {
            value: "Tanker",
            text: "Tanker",
          },
          {
            value: "Dump",
            text: "Dump",
          },
          {
            value: "Lowboy",
            text: "Lowboy",
          },
          {
            value: "Auto-Hauler",
            text: "Auto-Hauler",
          },
          {
            value: "Chassis",
            text: "Chassis",
          },
          {
            value: "Container",
            text: "Container",
          },
          {
            value: "Livestock",
            text: "Livestock",
          },
        ];
      }
      return options;
    },
  },
  methods: {
    ...mapActions([
      "getAllEquipments",
      "setNotification",
      "postExcelData",
      "addBulkData",
      "getAllCountries",
      "getAllTags",
      "setLoader",
      "getEquipmentsExportData",
      "updateEquipmentStatus",
      "updateUserPreferences",
      "deleteUserPreferences",
      "addBulkEquipmentsDocument"
    ]),
    checkNullValues(payload) {
      let nullKeys = [];
      for (let [key, value] of Object.entries(payload)) {
        if (!value) {
          nullKeys.push(key);
        }
      }
      return nullKeys;
    },
    resetBulkDocFields() {
      if (this.$refs.bulkDocFile) this.$refs.bulkDocFile.value = null;
    },
    async sendDocumentData() {
      try {
        const nullKeys = this.checkNullValues({
          date: this.documentData.date,
          document: this.documentData.document,
          ...this.company[0].doc_type ? { doc_type: this.documentData.doc_type } : {}
        });
        if (nullKeys.length === 0) {
          this.setLoader(true);
          let formData = new FormData();
          let fileType = this.documentData.document instanceof File || this.documentData.document instanceof Blob;
          if (!fileType) this.documentData.document = null
          formData.append("equipment_ids", JSON.stringify(this.selectedRows.map((item) => item.id)));
          formData.append(
            "date",
            moment(this.documentData.date).format("YYYY-MM-DD")
          );
          formData.append("type", this.documentData.type);
          formData.append("description", this.documentData.description);
          if (this.documentData.doc_type) formData.append("doc_type", this.documentData.doc_type);
          formData.append("expiry_date", this.documentData.expiry_date ? moment(this.documentData.expiry_date).format("YYYY-MM-DD") : '');
          formData.append(
            "document",
            this.documentData.document
          );
          formData.append("driver", this.documentData.driver);
          formData.append("public", this.documentData.public);
          formData.append("model_type", "equipment");
          formData.append("company_id", parseInt(localStorage.getItem("userSelectedCompany")));
          this.documentData = await this.addBulkEquipmentsDocument(
            formData
          );
          await this.setNotification({
            msg: "Document Successfully added!!",
            type: "success",
            color: "green",
          });
          await this.getEquipmentData();
          this.$refs.tableRef.clearSelected()
          this.allRowsSelected = false
          this.setLoader(false);
          this.$bvModal.hide("modal-bulk-equipment-docs");
          this.documentData = {
            object_id: null,
            date: null,
            type: null,
            document: null,
            description: null,
            expiry_date: "",
            driver: true,
            public: false,
            doc_type: ""
          };

        }
        else {
          let error = {};
          nullKeys.forEach((x) => {
            error[x] = ["This field is required."];
          });
          throw error;
        }
      } catch (error) {
        console.log(error)
        this.setLoader(false);
        if (typeof error === "object") {
          this.docErrors = error;
        }
      }
    },
    zoom(level) {
      if (!this.panzoom) {
        this.panzoom = Panzoom(document.getElementById('panzoom-element'), {
          maxScale: 5
        })
      }
      level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn()
    },
    setDocDescription(event) {
      if (event) {
        this.documentData.description = this.getDotOptions.filter((option) => option.value === event)[0].text
      }
      else this.documentData.description = this.defaultDocsData.description
    },
    removeFile() {
      this.docFileName = null;
      this.documentData.document = null
      this.media = null
      this.mediaType = null
    },
    addDocument() {
      this.docFileName = null;
      this.$bvModal.show("modal-bulk-equipment-docs");
      this.documentData = {
        object_id: null,
        date: new Date(),
        type: null,
        document: null,
        expiry_date: "",
        description: null,
        driver: true,
        public: false,
        doc_type: ""
      };
      this.errors = {
        date: null,
        type: null,
        document: null,
        expiry_date: "",
        description: null,
        ...this.company[0].doc_type ? { doc_type: null } : {}
      };
    },
    async getUpdatedFieldsList(updatedFields) {
      this.fields = updatedFields
      try {
        const updatedOrderList = updatedFields.map((item, index) => {
          return { ...item, display_order: index + 1 }
        })
        await this.updateUserPreferences(
          {
            "columns": updatedOrderList
          })
      }
      catch (e) {
        console.log(e)
      }
    },
    changeEquipmentStatusItem(item) {
      if (item) {
        this.changeEquipmentStatus()
      }
      this.showDeactivateModal = false
    },
    async changeEquipmentStatus() {
      try {
        this.setLoader(true)
        await this.updateEquipmentStatus({
          action: 'deactivate',
          equipment_ids: this.selectedRows.map((data) => data.id)
        })
        await this.setNotification({
          msg: `Equipments Deactivated Successfully`,
          type: "success",
          color: "green",
        });
        this.$refs.tableRef.clearSelected()
        await this.getEquipmentData()
        this.setLoader(false)
      }
      catch (e) {
        this.setLoader(false)
        console.log(e)
      }
    },
    checked(index, checked) {
      let tableRef = this.$refs.tableRef
      if (checked === true) tableRef.selectRow(index)
      else tableRef.unselectRow(index)
    },
    onRowSelected(items) {
      this.selectedRows = items
    },
    selectRows(checked) {
      if (checked) {
        this.$refs.tableRef.selectAllRows()
      }
      else {
        this.$refs.tableRef.clearSelected()
        this.allRowsSelected = false
      }
    },
    async setEquipmentType(type = "") {
      this.equipmentType = type
      this.currentPage = 1
      this.resetFilterFields()
    },
    async getEquipmentNewData(id = null) {
      this.allowAddEquipment = false
      await this.getEquipmentData()
      if (id) {
        this.equipmentID = id
        this.setNotesSection = false
      }
    },
    async resetFilterFields() {
      this.filterFields = {
        search_field: '',
        doc: '',
        type: '',
        tags: [],
        status: this.equipmentType
      }
      await this.getEquipmentData();
    },
    resetEquipmentList(equip) {
      this.items = this.items.map((equipment) => {
        if (equipment.id === equip.id) {
          let color;
          if (equip.docs.length === 0) {
            color = "background:#C4C4C4";
          }
          else if (equip.docs.filter((item) => item.expiry_date && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) <= 0).length) {
            color = "background:#FF0000";
          }
          else if (equip.docs.filter((item) => item.expiry_date && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) <= 31 && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) >= 1).length) {
            color = "background:#FFC800";
          }
          else {
            color = "background:#2CCE89";
          }

          const data = {
            id: equip.id,
            unit_no: equip.unit_no,
            Expiry: equip.inactivate_date,
            type: equip.type,
            num_docs: equip.num_docs,
            subtype: equip.subtype ? equip.subtype : '-',
            vin_no: equip.vin_no ? equip.vin_no : '-',
            make: equip.make ? equip.make : '-',
            model: equip.model ? equip.model : '-',
            plate_no: equip.plate_no ? equip.plate_no : '-',
            year: equip.year,
            plate_state: equip.state?.name,
            driver: equip.driver
              ? equip.driver["first name"] + " " + equip.driver["last name"]
              : "-",
            status: equip.status,
            value: equip.value ? equip.value : '-',
            dispatch_group1: equip.dispatch_group1,
            dispatch_group2: equip.dispatch_group2,
            serial_number: equip.serial_number ? equip.serial_number : '-',
            Tags: equip.tags,
            color,
            tags: equip.tags.map((tag) => tag.add_tag),
            active_date: equip.active_date ? moment(equip.active_date).format("MMM DD, YYYY") : '-',
            inactivate_date: equip.inactivate_date ? moment(equip.inactivate_date).format("MMM DD, YYYY") : '-',
            notes: equip.notes ? equip.notes : null
          }
          return data
        }
        else return equipment
      })
      this.equipmentID = ""
      this.setNotesSection = false
    },
    setEquipmentData(id) {
      this.equipmentID = id
      this.setNotesSection = false
    },
    showNotesSection(id) {
      if (this.allowUpdateEquipment || this.allowViewEquipment) {
        this.equipmentID = id
        this.setNotesSection = true
      }
    },
    async updateFields(updatedFields) {
      this.fields = updatedFields
      this.filterFields = {
        search_field: '',
        doc: '',
        tags: [],
        status: this.equipmentType,
        type: ''
      }
      try {
        await this.updateUserPreferences({
          "columns": updatedFields
        })
        await this.getEquipmentData();
      }
      catch (e) {
        console.log(e)
      }
    },
    async resetTableFields() {
      try {
        this.setLoader(true)
        await this.deleteUserPreferences(`Equipment/`)
        this.showColumns = false
        this.setLoader(false)
      }
      catch (e) {
        this.setLoader(false)
        console.log(e)
      }
    },
    async bulkUploadEquipment() {
      if (this.document) {
        this.setLoader(true)
        this.errors = {
          document: null,
        };
        try {
          let formData = new FormData();
          formData.append("file", this.document);
          formData.append("target_table", "Equipment");
          formData.append("equipment_type", this.$route.name);
          formData.append("company_id", this.userCompany)
          const newEquipments = await this.addBulkData(formData);
          if (newEquipments) {
            await this.getEquipmentData();
            await this.setNotification({
              msg: `Successfully Imported Equipments`,
              type: "success",
              color: "green",
            });
            this.$bvModal.hide("modal-bulk-upload");
            this.setLoader(false)
          }
        } catch (e) {
          this.setLoader(false)
          await this.setNotification({
            msg: "The Uploaded CSV has Wrong/Missing Fields.",
            type: "error",
            color: "red",
          });
        }
      } else {
        this.errors = {
          document: "This field is required.",
        };
      }
    },
    resetField() {
      this.$refs.docFile.value = null;
      this.fileName = null;
      this.document = null;
      this.errors = {
        document: null,
      };
    },
    handleFileUpload() {
      this.document = this.$refs.docFile.files[0];
      this.fileName = this.document.name;
    },
    handleBulkFileUpload() {
      this.documentData.document = this.$refs.bulkDocFile.files[0];
      this.docFileName = this.documentData.document.name;
      this.media = URL.createObjectURL(this.documentData.document);
      if (this.$refs.bulkDocFile.files[0].type.includes('pdf')) this.mediaType = 'pdf'
      else this.mediaType = 'image'
    },
    async downloadExcel() {
      try {
        this.setLoader(true)
        let url = ""
        Object.keys(this.filterFields).map((key) => {
          if (this.filterFields[key] !== '' && key != 'tags') {
            if (key !== 'search_field') url += `${key}=${this.filterFields[key]}&`
            else {
              let searchList = ""
              this.fields.filter((item) => item.visible && item.field === 'text').map(({ key }, index) => index === 0 ? searchList = key : searchList += ',' + key)
              url += `${key}=${this.filterFields[key]}&search_list=${searchList}&`
            }
          }
          else if (key === "tags" && this.filterFields[key].length > 0) url += `${key}=${this.filterFields[key].map((item, index) => index === 0 ? item : "," + item)}&`
        })
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}`
        }
        else {
          url += `sort_field=unit_no&sort_order=asc&`
        }
        url += `type=${this.$route.name}&`
        const { excel_file } = await this.getEquipmentsExportData(url)
        window.open(process.env.VUE_APP_API_URL + excel_file, "_blank");
        await this.setNotification({
          msg: `File Exported Successfully`,
          type: "success",
          color: "green",
        });
        this.setLoader(false)
      }
      catch (e) {
        console.log(e)
        this.setLoader(false)
      }
    },
    checkTableField(key) {
      return this.fields.filter((item) => item.key === key)[0]?.visible
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async getEquipmentData() {
      try {
        this.isBusy = true
        let url = ""
        let tags = ""
        Object.keys(this.filterFields).map((key) => {
          if (this.filterFields[key] !== '' && key != 'tags') {
            if (key !== 'search_field') url += `${key}=${this.filterFields[key]}&`
            else {
              let searchList = ""
              this.fields.filter((item) => item.visible && item.field === 'text').map(({ key }, index) => index === 0 ? searchList = key : searchList += ',' + key)
              url += `${key}=${this.filterFields[key]}&search_list=${searchList}&`
            }
          }
          else if (key === "tags" && this.filterFields[key].length > 0) {
            this.filterFields[key].map((item, index) => index === 0 ? tags = item : tags += "," + item)
            url += `${key}=${tags}&`
          }
        })
        if (this.sortBy) {
          url += `sort_field=${this.sortBy}&sort_order=${this.sortDesc ? "desc" : "asc"}&`
        }
        else {
          url += `sort_field=unit_no&sort_order=asc&`
        }
        url += `type=${this.$route.name}&`
        url += `page=${this.currentPage}&page_size=${this.perPage}`
        const data = await this.getAllEquipments(url);
        this.activeCount = data.total_active_count >= 0 ? data.total_active_count : 0
        this.inactiveCount = data.total_inactive_count >= 0 ? data.total_inactive_count : 0
        if (data.total_entries) {
          this.rows = data.total_entries
          this.positionText = `${this.currentPage === 1 ? `1` : `${(this.perPage * (this.currentPage - 1)) + 1}`} - ${this.perPage * (this.currentPage - 1) + data.queryset_count} of ${data.total_entries}`
        }
        else {
          this.rows = 0
          this.positionText = `0 - 0 of 0`
        }
        this.items = [];
        this.allEquipments.forEach((equip) => {
          let color;
          if (equip.docs.length === 0) {
            color = "background:#C4C4C4";
          }
          else if (equip.docs.filter((item) => item.expiry_date && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) <= 0).length) {
            color = "background:#FF0000";
          }
          else if (equip.docs.filter((item) => item.expiry_date && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) <= 31 && parseInt((new Date(item.expiry_date) - new Date()) / (1000 * 60 * 60 * 24), 10) >= 1).length) {
            color = "background:#FFC800";
          }
          else {
            color = "background:#2CCE89";
          }


          this.items.push({
            id: equip.id,
            unit_no: equip.unit_no,
            Expiry: equip.inactivate_date,
            type: equip.type,
            subtype: equip.subtype ? equip.subtype : '-',
            num_docs: equip.num_docs,
            vin_no: equip.vin_no ? equip.vin_no : '-',
            year: equip.year,
            make: equip.make ? equip.make : '-',
            model: equip.model ? equip.model : '-',
            plate_no: equip.plate_no ? equip.plate_no : '-',
            plate_state: equip.state?.name,
            driver: equip.driver
              ? equip.driver["first name"] + " " + equip.driver["last name"]
              : "-",
            status: equip.status,
            value: equip.value ? equip.value : '-',
            dispatch_group1: equip.dispatch_group1,
            dispatch_group2: equip.dispatch_group2,
            serial_number: equip.serial_number ? equip.serial_number : '-',
            Tags: equip.tags,
            color,
            tags: equip.tags.map((tag) => tag.add_tag),
            active_date: equip.active_date ? moment(equip.active_date).format("MMM DD, YYYY") : '-',
            inactivate_date: equip.inactivate_date ? moment(equip.inactivate_date).format("MMM DD, YYYY") : '-',
            notes: equip.notes ? equip.notes : null
          });
        });
        if (this.items.length === 0) this.emptyTable = true
        else this.emptyTable = false
        this.isBusy = false
        this.isTableMounted = true
      }
      catch (e) {
        this.isBusy = false
        console.log(e)
      }
    },
    async getCountries(item) {
      await this.getAllCountries();
      this.countries = this.allCountries.map((item) => ({
        id: item.id,
        label: item.name,
      }));
      this.getStates(this.countries[0], item);
    },
    getStates(selectedOption, item) {
      this.states = this.allCountries
        .filter((country) => country.id === selectedOption.id)[0]
        .state.map((item) => ({
          id: item.id,
          label: item.name,
        }));
      if (this.states.length === 0) {
        this.newEquipment.state.id = null;
      } else {
        if (this.newEquipment.state && item) {
          this.newEquipment.state.id = item;
        } else if (this.newEquipment.state) {
          this.newEquipment.state.id = this.states.filter(
            (state) => state.label === "-"
          )[0].id;
        }
      }
    },
    getCountryName(id) {
      return this.allCountries.filter((item) => item.id === id)[0].name;
    },
  },
  watch: {
    userPreferences: {
      async handler() {
        if (this.userPreferences) {
          this.fields = this.userPreferences['Equipment'].sort((a, b) => a.display_order - b.display_order).map((col) => {
            return { ...col, thClass: 'font-table-weight-bold', ...col.key === 'selected' ? { thStyle: { minWidth: "20px", width: "20px" } } : {} }
          })
        }
      },
      immediate: true
    },
    perPage: {
      async handler() {
        await this.getEquipmentData();
      },
      immediate: true,
    },
    currentPage: {
      async handler() {
        await this.getEquipmentData();
      },
    },
    sortBy: {
      async handler() {
        await this.getEquipmentData();
      },
    },
    sortDesc: {
      async handler() {
        await this.getEquipmentData();
      },
    },
    filterFields: {
      handler() {
        this.currentPage = 1
      },
      deep: true
    },
    company: {
      async handler() {
        await this.getAllTags(this.userCompany);
        // this.dispatchGroups.map((item) => {
        //   this.equipmentDispatchGroups.push({
        //     id: item.id,
        //     label: item.name,
        //   });
        // });
        this.tags.map((item) => {
          this.allTags.push({
            id: item.add_tag,
            label: item.add_tag,
          });
        });
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    try {
      await this.getCountries(this.newEquipment.state.id);
    } catch (error) {
      await this.setNotification({
        msg: error,
        type: "error",
        color: "red",
      });
      await router.push("/");
    }
  },
};
</script>

<style>
/* .equipment-border{
        border-bottom: 1px solid rgba(0 0 0 / 10%);
    } */
.equipment {
  background-color: var(--btn-bg-color) !important;
}

.inactive-equipment {
  background-color: #F4F4F4 !important;
  color: black !important;
  border: 1px solid #EAEAEA;
}

.assigned-equipment {
  background-color: var(--bg-green) !important;
  color: white !important;
}

.modal-header {
  background-color: #fff;
  color: black;
  padding: 1rem !important;
}

.modal-header h5 {
  color: black !important
}

.modal-title {
  font-size: 18px;
}

.close {
  background: none;
  color: #fff;
  font-size: 16px;
}

.modal .col-form-label {
  color: var(--black);
  opacity: 1;
  text-transform: capitalize;
}

.modal-body label {
  font-size: var(--small);
  color: var(--black);
}

.unit.form-control,
.v-select.vs--searchable,
.unit.form-control:focus {
  background: #fafafa !important;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  font-size: 14px;
}

.v-select .vs__dropdown-toggle {
  border: none !important;
}

.modal-footer .cancel {
  background-color: #F4F4F4;
  color: var(--black);
  font-size: 16px;
  width: 100px !important;
  height: 40px;
}

.modal-footer .cancel.w-150px {
  width: 150px !important;
}

.modal-footer .cancel:hover,
.modal-footer .cancel:focus,
.modal-footer .cancel:active {
  background-color: #dddddd;
  color: var(--black);
}

.modal-footer .save {
  background-color: var(--btn-bg-color) !important;
  color: #fff;
  font-size: 16px;
  width: 100px !important;
  height: 40px;
}

.modal-footer .w-150px .save.w-100 {
  width: 100% !important;
}

.modal-footer .save.w-fit-content {
  width: fit-content !important;
}

.modal-footer .save.danger-btn {
  background-color: var(--danger-color) !important;
  color: white !important
}

.modal-footer .save:hover,
.modal-footer .save:focus,
.modal-footer .save:active {
  background-color: var(--btn-bg-color);
}

.custom-select {
  background: #ffffff;
  border: 1px solid #dee2e6;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: -webkit-fill-available;
  height: 40px;
  font-size: 14px;
  padding-left: 10px;
}

.custom-select:focus-visible,
.custom-select:active {
  box-shadow: unset !important;
}

.equipment-list-page .select_arrow {
  position: relative;
}

.equipment-list-page .select_arrow svg {
  position: absolute;
  top: 40px;
  right: 10px;
}

.w-equipment-box>div>div>div {
  min-height: 40px !important;
  height: auto !important;
}

.table-columns-section .btn.btn-table-columns-section {
  height: 40px !important;
}

.table-columns-section {
  margin-top: 0rem !important;
}

.filter-section ul {
  width: 240px;
  min-width: 100% !important;
  padding-bottom: 0px !important
}

.filter-section-items {
  width: 190px;
  margin: 0 auto;
}

.filter-section .dropdown-menu.show {
  transform: translate3d(-185px, 40px, 0px) !important
}


.table-columns-dropdown .checkbox-section>div>div,
.table-columns-dropdown .checkbox-section .heading {
  padding: 0px 25px;
}

.table-columns-dropdown .checkbox-section>div>div .custom-control {
  padding-left: 0rem !important;
}

.table-columns-dropdown .checkbox-section {
  height: 380px;
  overflow-y: auto;
}

.table-columns-dropdown .buttons-section {
  border-radius: 0px 0px 5px 5px;
  border-top: 1px solid #EAEAEA;
  background: #FFF;
  padding: 23px 27px;
}

.ghost {
  opacity: 0.5;
  background: #e9e9e9 !important;
  height: 30px;
}

.docs-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
}

.filter-box>div .search-field {
  width: 335px !important;
}

.equipment-table-section {
  border-radius: 10px;
  border: 1px solid #EAEAEA;
  padding: 16px 25px;
}

.equipment-table-section>table>thead>tr>th {
  border: none !important
}

.ml-1 {
  margin-left: 0.1rem !important;
}

.w-150px {
  width: 150px !important;
}

@media (max-width: 575px) {
  .filter-box>div .search-field {
    width: 100% !important;
    margin-right: 0px !important;
  }
}
</style>
