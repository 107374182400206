<template>
  <b-form @submit.prevent="handleFormSubmit">
    <b-row>
      <b-col sm="12">
        <b-form-group label="First Name:" class="text-left label">
          <b-form-input type="text" placeholder="Enter First Name" class="input" required
            v-model="user.first_name"></b-form-input>
        </b-form-group>
      </b-col>

      <b-col sm="12">
        <b-form-group label="Last Name:" class="text-left label">
          <b-form-input type="text" placeholder="Enter Last Name" class="input" required
            v-model="user.last_name"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group label="Email:" label-for="input-1" class="text-left label text-capitalize">
      <b-form-input type="email" class="mb-3 input" placeholder="Enter Email" required v-model="user.email"
        disabled></b-form-input>
    </b-form-group>

    <b-form-group label="Password:" class="text-left label password password-2">
      <b-form-input class="input" placeholder="Enter Password" required :type="showPassword1 ? 'text' : 'password'"
        v-model="user.password1"></b-form-input>
      <b-icon :icon="showPassword1 ? 'eye-fill' : 'eye-slash-fill'" @click="showPassword1 = !showPassword1"></b-icon>
    </b-form-group>

    <b-form-group label="Confirm Password:" class="text-left label password password-2">
      <b-form-input class="input" placeholder="Enter Confirm Password" required
        :type="showPassword2 ? 'text' : 'password'" v-model="user.password2"></b-form-input>
      <b-icon :icon="showPassword2 ? 'eye-fill' : 'eye-slash-fill'" @click="showPassword2 = !showPassword2"></b-icon>
    </b-form-group>
    <b-form-checkbox id="checkbox-privacy" name="checkbox-privacy" class="text-left mt-3 px-0" v-model="privacyChecked">
      <span class="font-weight-normal">
        I read and agree to <a href="https://www.digitalpermitbook.com/privacy-policy" class="text-primary"
          target="_blank">Privacy Policy</a>
      </span>
    </b-form-checkbox>
    <p v-if="errors && errors.privacy" class="field-error text-left mb-4">
      {{ errors.privacy[0] }}
    </p>
    <b-form-checkbox id="checkbox-terms" name="checkbox-terms" v-model="termsChecked" class="text-left px-0 mt-3">
      <span class="font-weight-normal">
        I read and agree to <a href="https://www.digitalpermitbook.com/terms" class="text-primary"
          target="_blank">Terms</a>
      </span>
    </b-form-checkbox>
    <p v-if="errors && errors.terms" class="field-error text-left mb-4">
      {{ errors.terms[0] }}
    </p>
    <b-button type="submit" class="w-100 mt-3 login h-40px" variant="primary">Next</b-button>
  </b-form>
</template>
<script>
export default {
  data() {
    return {
      showPassword1: false,
      showPassword2: false,
      privacyChecked: false,
      termsChecked: false,
      errors: {}
    }
  },
  name: "Step2",
  props: ['user','onSignUp'],
  methods: {
    checkNullValues(payload) {
      let nullKeys = [];
      for (let [key, value] of Object.entries(payload)) {
        if (!value) {
          nullKeys.push(key);
        }
      }
      return nullKeys;
    },
    handleFormSubmit() {
      try {
        this.errors = {};
        const nullKeys = this.checkNullValues({
          privacy: this.privacyChecked,
          terms: this.termsChecked
        });
        if (nullKeys.length === 0) {
          this.onSignUp()
        }
        else {
          let error = {};
          nullKeys.forEach((x) => {
            error[x] = ["This field is required."];
          });
          throw error;
        }

      } catch (error) {
        if (typeof error === "object") {
          this.errors = error;
          // eslint-disable-next-line no-ex-assign
        }
      }
    }
  }
}
</script>